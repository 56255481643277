import { Form, Formik, FieldArray, getIn } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { commercialCommgasValidationSchema } from "../../../../../utils/validation";
import useQuestionnaire from "../useQuestionnaire";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import AdminComment from "../../../../ui/adminComment";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import { STAGE_FOUR_STATUS, USER_TYPE } from "../../../../../utils/constants";
import { useEffect } from "react";
import { countNonNullValues } from "../../../../../utils/functions";
import Radio from "../../../../ui/radio";

export default function Commercial({ handleStageFourTabClick }) {
  const { user } = useSelector((state) => state.auth);
  const { questionnaire } = useSelector((state) => state.user);

  const {
    disableForm,
    loading,
    canAdminComment,
    initialValues,
    handleSubmitCommgasCommercial,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useQuestionnaire(handleStageFourTabClick);

  useEffect(() => {
    countNonNullValues(adminComments?.commercialCommgas);
  }, [adminComments]);

  return (
    <Formik
      initialValues={initialValues.commercialCommgas}
      validationSchema={commercialCommgasValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setErrors,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <div className="py-8 space-y-3">
              <h6 className="font-semibold text-black">
                Section 3: Commercial Due Diligence
              </h6>
              <p className="text-sm max-w-[324px]">
                Provide Detailed Commercial Information and Supporting Documents
                for the Proposed Project
              </p>
            </div>
            <FormContainer width type="user" title="1. Project Description">
              <SectionHeader title="Provide a comprehensive description of the project, including its scope, objectives, and key components*" />
              <AdminComment
                name="projectDescription"
                adminComment={
                  adminComments?.commercialCommgas.projectDescription || ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialCommgas.projectDescription
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="commercialCommgas"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="projectDescription"
                  id="projectDescription"
                  value={values.projectDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.projectDescription && touched.projectDescription
                      ? errors.projectDescription
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialCommgas?.projectDescription &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="2. Projected Product Streams"
            >
              <SectionHeader title="Detail the expected outputs of the project, including types and quantities of products*" />
              <AdminComment
                name="projectedProductStreams"
                adminComment={
                  adminComments?.commercialCommgas.projectedProductStreams || ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialCommgas.projectedProductStreams
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="commercialCommgas"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="projectedProductStreams"
                  id="projectedProductStreams"
                  value={values.projectedProductStreams}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.projectedProductStreams &&
                    touched.projectedProductStreams
                      ? errors.projectedProductStreams
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialCommgas
                      ?.projectedProductStreams &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="3. Planned Generation Capacity"
            >
              <SectionHeader title="Specify the projected energy or product generation capacity of the project*" />
              <AdminComment
                name="plannedGenerationCapacity"
                adminComment={
                  adminComments?.commercialCommgas?.plannedGenerationCapacity ||
                  ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialCommgas?.plannedGenerationCapacity
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="commercialCommgas"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="plannedGenerationCapacity"
                  id="plannedGenerationCapacity"
                  value={values.plannedGenerationCapacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.plannedGenerationCapacity &&
                    touched.plannedGenerationCapacity
                      ? errors.plannedGenerationCapacity
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialCommgas
                      ?.plannedGenerationCapacity &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer width type="user" title="4. Feedstock Requirements">
              <SectionHeader title="Outline the types and quantities of feedstock needed for the project, including sourcing strategies.*" />
              <AdminComment
                name="feedstockRequirements"
                adminComment={
                  adminComments?.commercialCommgas.feedstockRequirements || ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialCommgas.feedstockRequirements
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="commercialCommgas"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="feedstockRequirements"
                  id="feedstockRequirements"
                  value={values.feedstockRequirements}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.feedstockRequirements &&
                    touched.feedstockRequirements
                      ? errors.feedstockRequirements
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialCommgas?.feedstockRequirements &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="5. Plan for Product Off-take:"
            >
              <>
                <SectionHeader
                  title="Off-Take Plan Overview"
                  description="Describe your current off-take agreements or arrangements. Include the nature and duration of these agreements*"
                />
                <AdminComment
                  name="offTakePlanOverview"
                  adminComment={
                    adminComments?.commercialCommgas?.offTakePlanOverview || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas?.offTakePlanOverview
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercialCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="offTakePlanOverview"
                    id="offTakePlanOverview"
                    value={values.offTakePlanOverview}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.offTakePlanOverview && touched.offTakePlanOverview
                        ? errors.offTakePlanOverview
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas?.offTakePlanOverview &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Evidence of Off-Take Agreements"
                  description="If available, provide copies of off-take agreements or official letters of intent."
                />
                <AdminComment
                  name="evidenceOfOffTakeAgreements"
                  adminComment={
                    adminComments?.commercialCommgas
                      ?.evidenceOfOffTakeAgreements || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas
                      ?.evidenceOfOffTakeAgreements
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercialCommgas"
                >
                  <FileUpload
                    name="evidenceOfOffTakeAgreements"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.evidenceOfOffTakeAgreements
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    onBlur={handleBlur}
                    required
                    errors={
                      errors.evidenceOfOffTakeAgreements &&
                      touched.evidenceOfOffTakeAgreements
                        ? getIn(errors, `evidenceOfOffTakeAgreements`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas
                        ?.evidenceOfOffTakeAgreements &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Other Supporting Document"
                  description="Upload other related and supporting documents eg market assessment plans, off-take logistics etc."
                />
                <FieldArray
                  name="otherSupportingDocument"
                  render={(arrayHelpers) => (
                    <AdminComment
                      name={`otherSupportingDocument[${0}]`}
                      adminComment={
                        adminComments?.commercialCommgas
                          ?.otherSupportingDocument[0] || ""
                      }
                      isAdminCommentAvailable={
                        adminComments?.commercialCommgas
                          ?.otherSupportingDocument[0]
                          ? true
                          : false
                      }
                      canAdminComment={canAdminComment}
                      allComments={adminComments}
                      setAdminComments={setAdminComments}
                      setIsCommentNonNull={setIsCommentNonNull}
                      type="commercialCommgas"
                    >
                      <FileUpload
                        name={`otherSupportingDocument[${0}]`}
                        fileType=".pdf, .xls, .xlsx"
                        format="PDF or Excel format"
                        value={
                          questionnaire?.CommercialDueDiligence
                            ? values.otherSupportingDocument[0]
                            : ""
                        }
                        onBlur={handleBlur}
                        onChange={(fieldName, file) => {
                          setFieldTouched(fieldName, true);
                          setFieldValue(fieldName, file);
                        }}
                        required
                        errors={
                          errors.otherSupportingDocument &&
                          touched.otherSupportingDocument
                            ? getIn(errors, `otherSupportingDocument[${0}]`)
                            : ""
                        }
                        disabled={
                          disableForm ||
                          (!adminComments?.commercialCommgas
                            ?.otherSupportingDocument[0] &&
                            questionnaire?.status ===
                              STAGE_FOUR_STATUS.FEEDBACK)
                        }
                      />
                    </AdminComment>
                  )}
                />
              </>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="6. Project delivery timescales"
            >
              <SectionHeader title="Work plan/schedule for project execution up to first gas date, indicating time towards financial closure, FEED etc. Highlight all critical dependencies" />
              <AdminComment
                name="projectDeliveryTimescales"
                adminComment={
                  adminComments?.commercialCommgas?.projectDeliveryTimescales ||
                  ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialCommgas?.projectDeliveryTimescales
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="commercialCommgas"
              >
                <FileUpload
                  name="projectDeliveryTimescales"
                  fileType=".pdf, .xls, .xlsx"
                  format="PDF or Excel format"
                  value={
                    questionnaire?.CommercialDueDiligence
                      ? values.projectDeliveryTimescales
                      : ""
                  }
                  onBlur={handleBlur}
                  onChange={(fieldName, file) => {
                    setFieldValue(fieldName, file);
                    setFieldTouched(fieldName, true);
                  }}
                  required
                  errors={
                    errors.projectDeliveryTimescales &&
                    touched.projectDeliveryTimescales
                      ? getIn(errors, `projectDeliveryTimescales`)
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialCommgas
                      ?.projectDeliveryTimescales &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer width type="user" title="7. Financing Plan">
              <SectionHeader title="Provide details of the planned debt/equity ratio and status of engagement of financing parties.*" />
              <AdminComment
                name="financingPlan"
                adminComment={adminComments?.reputation.financingPlan || ""}
                isAdminCommentAvailable={
                  adminComments?.commercialCommgas.financingPlan ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="commercialCommgas"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="financingPlan"
                  id="financingPlan"
                  value={values.financingPlan}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.financingPlan && touched.financingPlan
                      ? errors.financingPlan
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialCommgas?.financingPlan &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>

              <AdminComment
                name="financialPlanningDocument"
                adminComment={
                  adminComments?.commercialCommgas?.financialPlanningDocument ||
                  ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialCommgas?.financialPlanningDocument
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="commercialCommgas"
              >
                <FileUpload
                  name="financialPlanningDocument"
                  fileType=".pdf, .xls, .xlsx"
                  format="PDF or Excel format"
                  value={
                    questionnaire?.CommercialDueDiligence
                      ? values.financialPlanningDocument
                      : ""
                  }
                  onBlur={handleBlur}
                  onChange={(fieldName, file) => {
                    setFieldValue(fieldName, file);
                  }}
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialCommgas
                      ?.financialPlanningDocument &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="8. GACNs Payment Assurance Framework"
            >
              <>
                <AdminComment
                  name="commitToPaymentAssuranceFramework"
                  adminComment={
                    adminComments?.commercialCommgas
                      ?.commitToPaymentAssuranceFramework || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas
                      ?.commitToPaymentAssuranceFramework
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialCommgas"
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.commitToPaymentAssuranceFramework}
                    onChange={handleChange}
                    name="commitToPaymentAssuranceFramework"
                    title="Do you Commit to abide by GACN’s Payment Assurance Framework by showing proof of creditworthiness by either bank guarantee, letter of credit, or prepayment as may be required?"
                    errors={
                      Boolean(
                        getIn(errors, "commitToPaymentAssuranceFramework") &&
                          getIn(touched, "commitToPaymentAssuranceFramework")
                      )
                        ? getIn(errors, "commitToPaymentAssuranceFramework")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas
                        ?.commitToPaymentAssuranceFramework &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
                {values.commitToPaymentAssuranceFramework === "No" && (
                  <AdminComment
                    name="reasonsForNotCommitting"
                    adminComment={
                      adminComments?.commercialCommgas
                        ?.reasonsForNotCommitting || ""
                    }
                    isAdminCommentAvailable={
                      adminComments?.commercialCommgas?.reasonsForNotCommitting
                        ? true
                        : false
                    }
                    canAdminComment={canAdminComment}
                    allComments={adminComments}
                    setIsCommentNonNull={setIsCommentNonNull}
                    setAdminComments={setAdminComments}
                    type="commercialCommgas"
                  >
                    <Textarea
                      placeholder="Describe your reasons"
                      name="reasonsForNotCommitting"
                      id="reasonsForNotCommitting"
                      value={values.reasonsForNotCommitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={
                        Boolean(
                          getIn(errors, "reasonsForNotCommitting") &&
                            getIn(touched, "reasonsForNotCommitting")
                        )
                          ? getIn(errors, "reasonsForNotCommitting")
                          : ""
                      }
                      disabled={
                        disableForm ||
                        (!adminComments?.commercialCommgas
                          ?.reasonsForNotCommitting &&
                          questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                      }
                    />
                  </AdminComment>
                )}
              </>
              <>
                <SectionHeader
                  border
                  title="Kindly provide a letter of creditworthiness from your bank, if available (Optional)"
                />
                <AdminComment
                  name="letterOfCreditworthiness"
                  adminComment={
                    adminComments?.commercialCommgas
                      ?.letterOfCreditworthiness || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas?.letterOfCreditworthiness
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialCommgas"
                >
                  <FileUpload
                    name="letterOfCreditworthiness"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.letterOfCreditworthiness
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas
                        ?.letterOfCreditworthiness &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="9. Evidence of status of all relevant statutory permits or progress towards securing these permits (including but not limited to the under-listed)"
            >
              <>
                <SectionHeader title="Incorporation Certificate" />
                <AdminComment
                  name="statutoryPermits.incorporationCertificate"
                  adminComment={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.incorporationCertificate || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.incorporationCertificate
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercialCommgas"
                >
                  <FileUpload
                    name="statutoryPermits.incorporationCertificate"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.incorporationCertificate
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.incorporationCertificate`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.incorporationCertificate`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.incorporationCertificate`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas?.statutoryPermits
                        ?.incorporationCertificate &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="NMPDRA Gas Distribution License" />
                <AdminComment
                  name="statutoryPermits.nmdpraLicense.nmdpraGasDistributionLicense"
                  adminComment={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.nmdpraLicense?.nmdpraGasDistributionLicense || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.nmdpraLicense?.nmdpraGasDistributionLicense
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercialCommgas"
                >
                  <FileUpload
                    name="statutoryPermits.nmdpraLicense.nmdpraGasDistributionLicense"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.nmdpraLicense
                            .nmdpraGasDistributionLicense
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.nmdpraLicense.nmdpraGasDistributionLicense`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.nmdpraLicense.nmdpraGasDistributionLicense`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.nmdpraLicense.nmdpraGasDistributionLicense`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas?.statutoryPermits
                        ?.nmdpraLicense?.nmdpraGasDistributionLicense &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="NMPDRA Gas Processing License" />
                <AdminComment
                  name="statutoryPermits.nmdpraLicense.nmdpraGasProcessingLicense"
                  adminComment={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.nmdpraLicense?.nmdpraGasProcessingLicense || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.nmdpraLicense?.nmdpraGasProcessingLicense
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercialCommgas"
                >
                  <FileUpload
                    name="statutoryPermits.nmdpraLicense.nmdpraGasProcessingLicense"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.nmdpraLicense
                            .nmdpraGasProcessingLicense
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.nmdpraLicense.nmdpraGasProcessingLicense`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.nmdpraLicense.nmdpraGasProcessingLicense`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.nmdpraLicense.nmdpraGasProcessingLicense`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas?.statutoryPermits
                        ?.nmdpraLicense?.nmdpraGasProcessingLicense &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="NMDPRA Gas Transportation License (Optional)"
                />
                <AdminComment
                  name="statutoryPermits.nmdpraLicense.nmdpraGasTransportationLicense"
                  adminComment={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.nmdpraLicense?.nmdpraGasTransportationLicense || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.nmdpraLicense?.nmdpraGasTransportationLicense
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercialCommgas"
                >
                  <FileUpload
                    name="statutoryPermits.nmdpraLicense.nmdpraGasTransportationLicense"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.nmdpraLicense
                            .nmdpraGasTransportationLicense
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas?.statutoryPermits
                        ?.nmdpraLicense?.nmdpraGasTransportationLicense &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Nigerian Content Compliance Plan"
                />
                <AdminComment
                  name="statutoryPermits.nigerianContentCompliancePlan"
                  adminComment={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.nigerianContentCompliancePlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.nigerianContentCompliancePlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercialCommgas"
                >
                  <FileUpload
                    name="statutoryPermits.nigerianContentCompliancePlan"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.nigerianContentCompliancePlan
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.nigerianContentCompliancePlan`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.nigerianContentCompliancePlan`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.nigerianContentCompliancePlan`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas?.statutoryPermits
                        ?.nigerianContentCompliancePlan &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="Environmental Permit" />
                <AdminComment
                  name="statutoryPermits.environmentalPermit"
                  adminComment={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.environmentalPermit || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialCommgas?.statutoryPermits
                      ?.environmentalPermit
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercialCommgas"
                >
                  <FileUpload
                    name="statutoryPermits.environmentalPermit"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.environmentalPermit
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(errors, `statutoryPermits.environmentalPermit`) &&
                          getIn(touched, `statutoryPermits.environmentalPermit`)
                      )
                        ? getIn(errors, `statutoryPermits.environmentalPermit`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialCommgas?.statutoryPermits
                        ?.environmentalPermit &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Any other permit. (Note that the project sponsor is responsible for ascertaining all permit requirements.)"
                />

                <FieldArray
                  name="statutoryPermits.otherPermits"
                  render={(arrayHelpers) => (
                    <AdminComment
                      name="statutoryPermits.otherPermits[0]"
                      adminComment={
                        adminComments?.commercialCommgas?.statutoryPermits
                          ?.otherPermits?.[0] ?? ""
                      }
                      isAdminCommentAvailable={
                        adminComments?.commercialCommgas?.statutoryPermits
                          ?.otherPermits?.[0]
                          ? true
                          : false
                      }
                      canAdminComment={canAdminComment}
                      allComments={adminComments}
                      setAdminComments={setAdminComments}
                      setIsCommentNonNull={setIsCommentNonNull}
                      type="commercialCommgas"
                    >
                      <FileUpload
                        name="statutoryPermits.otherPermits[0]"
                        fileType=".pdf, .xls, .xlsx"
                        format="PDF or Excel format"
                        value={
                          questionnaire?.CommercialDueDiligence
                            ? values.statutoryPermits?.otherPermits[0]
                            : ""
                        }
                        onBlur={handleBlur}
                        onChange={(fieldName, file) => {
                          setFieldTouched(fieldName, true);
                          setFieldValue(fieldName, file);
                        }}
                        disabled={
                          disableForm ||
                          (!adminComments?.commercialCommgas?.statutoryPermits
                            ?.otherPermits?.[0] &&
                            questionnaire?.status ===
                              STAGE_FOUR_STATUS.FEEDBACK)
                        }
                      />
                    </AdminComment>
                  )}
                />
              </>
            </FormContainer>

            {(user?.role === USER_TYPE.USER ||
              user?.role === USER_TYPE.PROXY) &&
            (!questionnaire ||
              questionnaire?.status === STAGE_FOUR_STATUS.PENDING ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) ? (
              <div className="flex justify-between w-full pt-16">
                <button
                  onClick={() => handleStageFourTabClick(1)}
                  className="text-primary-1 uppercase text-sm font-bold leading-[16.8px]"
                >
                  Previous
                </button>
                <div className="flex justify-end gap-6 ">
                  <Button
                    disabled={isSubmitting || loading}
                    onClick={() => {
                      setErrors({});
                      handleSubmitCommgasCommercial(
                        values,
                        "save",
                        setSubmitting
                      );
                      const res = validateForm();
                      setErrors({ ...res });
                    }}
                    loading={isSubmitting}
                    className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                  >
                    Save progress
                  </Button>
                  <Button
                    onClick={async () => {
                      setSubmitting(false);
                      const res = await validateForm();
                      setErrors({ ...res });

                      if (Object.keys(res).length !== 0)
                        toast.error("Please fill all the required fields");
                      else
                        handleSubmitCommgasCommercial(
                          values,
                          "next",
                          setSubmitting
                        );
                    }}
                    disabled={!isValid || loading || isSubmitting}
                    className="py-4 px-6 self-end"
                    loading={loading}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end gap-6 w-full pt-16">
                <div className="flex justify-end gap-6">
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(1)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    {Icons.ic_arrow_left} Previous
                  </Button>
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(3)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    Next {Icons.ic_arrow_right}
                  </Button>
                </div>
                {(user?.role === USER_TYPE.ADMIN ||
                  user?.role === USER_TYPE.SUPER_ADMIN) &&
                  (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
                    questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
                    <Button
                      disabled={loadingAddComments || !isCommentNonNull}
                      onClick={() => {
                        handleAddAdminComment(
                          adminComments.commercialCommgas,
                          "adminUpdateCommercial"
                        );
                      }}
                      loading={loadingAddComments}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                    >
                      Save Comment
                    </Button>
                  )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
