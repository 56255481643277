import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Router from "./router";
import { store } from "./config/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        icon={
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 9.5C7.48528 9.5 9.5 7.48528 9.5 5C9.5 2.51472 7.48528 0.5 5 0.5C2.51472 0.5 0.5 2.51472 0.5 5C0.5 7.48528 2.51472 9.5 5 9.5ZM6.8377 4.36873C7.04134 4.18222 7.05523 3.86594 6.86873 3.6623C6.68222 3.45866 6.36594 3.44477 6.1623 3.63127L4.31626 5.32199L3.8377 4.88369C3.63406 4.69719 3.31778 4.71107 3.13128 4.91472C2.94477 5.11836 2.95866 5.43463 3.1623 5.62114L3.97856 6.36872C4.16967 6.54376 4.46285 6.54376 4.65396 6.36872L6.8377 4.36873Z"
              fill="currentColor"
            />
          </svg>
        }
        theme="light"
      />

      <Router />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
