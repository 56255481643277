const Icons = {
  ic_doc: require("./ic_doc.svg").default,
  ic_empty_state: require("./ic_empty_state.svg").default,
  ic_error: require("./ic_error.svg").default,
  ic_file_upload: require("./ic_file_upload.svg").default,
  ic_meeting: require("./ic_meeting.svg").default,
  ic_no_email: require("../icons/ic_no_email.svg").default,
  ic_pdf: require("./ic_pdf.svg").default,
  ic_pending: require("./ic_pending.svg").default,
  ic_success: require("../icons/ic_success.svg").default,
  ic_warning: require("../icons/ic_warning.svg").default,
  ic_xls: require("../icons/ic_xls.svg").default,

  ic_add: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83073 6.49935C9.83073 6.03911 9.45763 5.66602 8.9974 5.66602C8.53716 5.66602 8.16406 6.03911 8.16406 6.49935V8.16602H6.4974C6.03716 8.16602 5.66406 8.53911 5.66406 8.99935C5.66406 9.45959 6.03716 9.83268 6.4974 9.83268H8.16406V11.4993C8.16406 11.9596 8.53716 12.3327 8.9974 12.3327C9.45763 12.3327 9.83073 11.9596 9.83073 11.4993V9.83268H11.4974C11.9576 9.83268 12.3307 9.45959 12.3307 8.99935C12.3307 8.53911 11.9576 8.16602 11.4974 8.16602H9.83073V6.49935Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9974 0.666016C4.39502 0.666016 0.664062 4.39698 0.664062 8.99935C0.664062 13.6017 4.39502 17.3327 8.9974 17.3327C13.5998 17.3327 17.3307 13.6017 17.3307 8.99935C17.3307 4.39698 13.5998 0.666016 8.9974 0.666016ZM2.33073 8.99935C2.33073 5.31745 5.3155 2.33268 8.9974 2.33268C12.6793 2.33268 15.6641 5.31745 15.6641 8.99935C15.6641 12.6812 12.6793 15.666 8.9974 15.666C5.3155 15.666 2.33073 12.6812 2.33073 8.99935Z"
        fill="white"
      />
    </svg>
  ),
  ic_arrow_left: (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.912046 5.58991C0.58661 5.26447 0.58661 4.73683 0.912046 4.4114L4.24538 1.07806C4.57082 0.752625 5.09845 0.752625 5.42389 1.07806C5.74933 1.4035 5.74933 1.93114 5.42389 2.25657L3.51315 4.16732L16.5013 4.16732C16.9615 4.16732 17.3346 4.54041 17.3346 5.00065C17.3346 5.46089 16.9615 5.83398 16.5013 5.83398L3.51315 5.83398L5.42389 7.74473C5.74933 8.07017 5.74933 8.5978 5.42389 8.92324C5.09845 9.24868 4.57082 9.24868 4.24538 8.92324L0.912046 5.58991Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_arrow_right: (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0906 5.58991C17.416 5.26447 17.416 4.73683 17.0906 4.4114L13.7572 1.07806C13.4318 0.752625 12.9042 0.752625 12.5787 1.07806C12.2533 1.4035 12.2533 1.93114 12.5787 2.25657L14.4895 4.16732L1.5013 4.16732C1.04107 4.16732 0.667969 4.54041 0.667969 5.00065C0.667969 5.46089 1.04107 5.83398 1.5013 5.83398L14.4895 5.83398L12.5787 7.74473C12.2533 8.07017 12.2533 8.5978 12.5787 8.92324C12.9042 9.24868 13.4318 9.24868 13.7572 8.92324L17.0906 5.58991Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_arrow_up: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      <path d="M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z"></path>
    </svg>
  ),
  ic_bank: (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52835 0.296465C9.18624 0.182428 8.81637 0.182429 8.47426 0.296465L1.80759 2.51869C1.12702 2.74554 0.667969 3.38244 0.667969 4.09983V5.99919C0.667969 6.91967 1.41416 7.66586 2.33464 7.66586H3.16797V11.8325H2.33464C1.41416 11.8325 0.667969 12.5787 0.667969 13.4992V15.1659C0.667969 16.0863 1.41416 16.8325 2.33464 16.8325H15.668C16.5884 16.8325 17.3346 16.0863 17.3346 15.1659V13.4992C17.3346 12.5787 16.5884 11.8325 15.668 11.8325H14.8346V7.66586H15.668C16.5884 7.66586 17.3346 6.91967 17.3346 5.99919V4.09983C17.3346 3.38244 16.8756 2.74554 16.195 2.51869L9.52835 0.296465ZM13.168 11.8325V7.66586H11.5013V11.8325H13.168ZM9.83464 11.8325V7.66586H8.16797V11.8325H9.83464ZM6.5013 11.8325V7.66586H4.83464V11.8325H6.5013ZM2.33464 13.4992V15.1659H15.668V13.4992H2.33464ZM2.33464 5.99919L2.33463 4.09983L9.0013 1.8776L15.668 4.09983V5.99919H2.33464Z"
        fill="#98A2B3"
      />
    </svg>
  ),
  ic_calendar: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66406 0.333984C6.1243 0.333984 6.4974 0.70708 6.4974 1.16732V2.00065H11.4974V1.16732C11.4974 0.70708 11.8705 0.333984 12.3307 0.333984C12.791 0.333984 13.1641 0.70708 13.1641 1.16732V2.00065H13.9974C15.8383 2.00065 17.3307 3.49304 17.3307 5.33398V14.5007C17.3307 16.3416 15.8383 17.834 13.9974 17.834H3.9974C2.15645 17.834 0.664062 16.3416 0.664062 14.5007V5.33398C0.664062 3.49304 2.15645 2.00065 3.9974 2.00065H4.83073V1.16732C4.83073 0.70708 5.20383 0.333984 5.66406 0.333984ZM11.4974 3.66732C11.4974 4.12755 11.8705 4.50065 12.3307 4.50065C12.791 4.50065 13.1641 4.12755 13.1641 3.66732H13.9974C14.9179 3.66732 15.6641 4.41351 15.6641 5.33398V5.75065H2.33073V5.33398C2.33073 4.41351 3.07692 3.66732 3.9974 3.66732H4.83073C4.83073 4.12755 5.20383 4.50065 5.66406 4.50065C6.1243 4.50065 6.4974 4.12755 6.4974 3.66732H11.4974ZM15.6641 7.41732H2.33073V14.5007C2.33073 15.4211 3.07692 16.1673 3.9974 16.1673H13.9974C14.9179 16.1673 15.6641 15.4211 15.6641 14.5007V7.41732Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_checked: (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute w-4 h-4 pointer-events-none top-1 left-0"
    >
      <path
        d="M9.14506 4.96431C9.40149 4.7001 9.39519 4.27803 9.13097 4.0216C8.86676 3.76517 8.4447 3.77148 8.18827 4.03569L4.78446 7.54278L3.81173 6.54053C3.5553 6.27632 3.13324 6.27001 2.86903 6.52644C2.60482 6.78287 2.59851 7.20493 2.85494 7.46914L4.30607 8.96431C4.43161 9.09367 4.6042 9.16667 4.78446 9.16667C4.96473 9.16667 5.13731 9.09367 5.26286 8.96431L9.14506 4.96431Z"
        fill="#0F973D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66667 0.5C1.19391 0.5 0 1.69391 0 3.16667V9.83333C0 11.3061 1.19391 12.5 2.66667 12.5H9.33333C10.8061 12.5 12 11.3061 12 9.83333V3.16667C12 1.69391 10.8061 0.5 9.33333 0.5H2.66667ZM1.33333 3.16667C1.33333 2.43029 1.93029 1.83333 2.66667 1.83333H9.33333C10.0697 1.83333 10.6667 2.43029 10.6667 3.16667V9.83333C10.6667 10.5697 10.0697 11.1667 9.33333 11.1667H2.66667C1.93029 11.1667 1.33333 10.5697 1.33333 9.83333V3.16667Z"
        fill="#0F973D"
      />
    </svg>
  ),
  ic_chevron_left: (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.27646 9.41415C4.79326 8.90075 4.79326 8.09991 5.27646 7.58651L10.1798 2.37675C10.4321 2.10864 10.854 2.09585 11.1221 2.34819C11.3903 2.60054 11.403 3.02245 11.1507 3.29057L6.2474 8.50033L11.1507 13.7101C11.403 13.9782 11.3903 14.4001 11.1221 14.6525C10.854 14.9048 10.4321 14.892 10.1798 14.6239L5.27646 9.41415Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_chevron_right: (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.7195 7.91317C7.2027 7.39977 7.2027 6.59893 6.7195 6.08553L1.8162 0.875774C1.56385 0.607659 1.14194 0.594873 0.873821 0.847218C0.605706 1.09956 0.59292 1.52148 0.845264 1.78959L5.74857 6.99935L0.845265 12.2091C0.592921 12.4772 0.605706 12.8991 0.873822 13.1515C1.14194 13.4038 1.56385 13.391 1.8162 13.1229L6.7195 7.91317Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_clock: (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0013 5.58268C9.0013 5.12245 8.62821 4.74935 8.16797 4.74935C7.70773 4.74935 7.33464 5.12245 7.33464 5.58268V9.74935C7.33464 10.2096 7.70773 10.5827 8.16797 10.5827H10.668C11.1282 10.5827 11.5013 10.2096 11.5013 9.74935C11.5013 9.28911 11.1282 8.91602 10.668 8.91602H9.0013V5.58268Z"
        fill="#98A2B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0013 0.166016C4.39893 0.166016 0.667969 3.89698 0.667969 8.49935C0.667969 13.1017 4.39893 16.8327 9.0013 16.8327C13.6037 16.8327 17.3346 13.1017 17.3346 8.49935C17.3346 3.89698 13.6037 0.166016 9.0013 0.166016ZM2.33464 8.49935C2.33464 4.81745 5.3194 1.83268 9.0013 1.83268C12.6832 1.83268 15.668 4.81745 15.668 8.49935C15.668 12.1812 12.6832 15.166 9.0013 15.166C5.3194 15.166 2.33464 12.1812 2.33464 8.49935Z"
        fill="#98A2B3"
      />
    </svg>
  ),
  ic_close: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.05086 18.3638C6.66033 18.7544 6.02717 18.7544 5.63664 18.3638C5.24612 17.9733 5.24612 17.3402 5.63664 16.9496L10.5864 11.9999L5.63664 7.05014C5.24612 6.65961 5.24612 6.02645 5.63664 5.63592C6.02717 5.2454 6.66033 5.2454 7.05086 5.63592L12.0006 10.5857L16.9504 5.63592C17.3409 5.2454 17.974 5.2454 18.3646 5.63592C18.7551 6.02645 18.7551 6.65961 18.3646 7.05014L13.4148 11.9999L18.3646 16.9496C18.7551 17.3402 18.7551 17.9733 18.3646 18.3638C17.974 18.7544 17.3409 18.7544 16.9504 18.3638L12.0006 13.4141L7.05086 18.3638Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_comment: (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9974 5.08203C3.9974 4.62179 4.37049 4.2487 4.83073 4.2487H8.9974C9.45763 4.2487 9.83073 4.62179 9.83073 5.08203C9.83073 5.54227 9.45763 5.91536 8.9974 5.91536H4.83073C4.37049 5.91536 3.9974 5.54227 3.9974 5.08203Z"
        fill="black"
      />
      <path
        d="M4.83073 7.58203C4.37049 7.58203 3.9974 7.95513 3.9974 8.41536C3.9974 8.8756 4.37049 9.2487 4.83073 9.2487H12.3307C12.791 9.2487 13.1641 8.8756 13.1641 8.41536C13.1641 7.95513 12.791 7.58203 12.3307 7.58203H4.83073Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8307 14.6237L12.9974 13.7487C13.2859 13.5323 13.6368 13.4154 13.9974 13.4154C15.8383 13.4154 17.3307 11.923 17.3307 10.082V3.41536C17.3307 1.57442 15.8383 0.0820312 13.9974 0.0820312H3.9974C2.15645 0.0820312 0.664062 1.57442 0.664062 3.41536V10.082C0.664062 11.923 2.15645 13.4154 3.9974 13.4154H5.66406C6.02468 13.4154 6.37557 13.5323 6.66406 13.7487L7.83073 14.6237C9.01591 15.5126 10.6455 15.5126 11.8307 14.6237ZM13.9974 11.7487C13.2762 11.7487 12.5744 11.9826 11.9974 12.4154L10.8307 13.2904C10.2381 13.7348 9.42332 13.7348 8.83073 13.2904L7.66406 12.4154C7.08708 11.9826 6.3853 11.7487 5.66406 11.7487H3.9974C3.07692 11.7487 2.33073 11.0025 2.33073 10.082V3.41536C2.33073 2.49489 3.07692 1.7487 3.9974 1.7487H13.9974C14.9179 1.7487 15.6641 2.49489 15.6641 3.41536V10.082C15.6641 11.0025 14.9179 11.7487 13.9974 11.7487Z"
        fill="black"
      />
    </svg>
  ),
  ic_company: (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91683 6.33333C7.91683 6.79357 7.54373 7.16667 7.0835 7.16667H6.25016C5.78993 7.16667 5.41683 6.79357 5.41683 6.33333C5.41683 5.8731 5.78993 5.5 6.25016 5.5H7.0835C7.54373 5.5 7.91683 5.8731 7.91683 6.33333Z"
        fill="currentColor"
      />
      <path
        d="M10.4168 9.66667C10.8771 9.66667 11.2502 9.29357 11.2502 8.83333C11.2502 8.3731 10.8771 8 10.4168 8H9.5835C9.12326 8 8.75016 8.3731 8.75016 8.83333C8.75016 9.29357 9.12326 9.66667 9.5835 9.66667H10.4168Z"
        fill="currentColor"
      />
      <path
        d="M7.91683 11.3333C7.91683 11.7936 7.54373 12.1667 7.0835 12.1667H6.25016C5.78993 12.1667 5.41683 11.7936 5.41683 11.3333C5.41683 10.8731 5.78993 10.5 6.25016 10.5H7.0835C7.54373 10.5 7.91683 10.8731 7.91683 11.3333Z"
        fill="currentColor"
      />
      <path
        d="M10.4168 7.16667C10.8771 7.16667 11.2502 6.79357 11.2502 6.33333C11.2502 5.8731 10.8771 5.5 10.4168 5.5H9.5835C9.12326 5.5 8.75016 5.8731 8.75016 6.33333C8.75016 6.79357 9.12326 7.16667 9.5835 7.16667H10.4168Z"
        fill="currentColor"
      />
      <path
        d="M7.91683 8.83333C7.91683 9.29357 7.54373 9.66667 7.0835 9.66667H6.25016C5.78993 9.66667 5.41683 9.29357 5.41683 8.83333C5.41683 8.3731 5.78993 8 6.25016 8H7.0835C7.54373 8 7.91683 8.3731 7.91683 8.83333Z"
        fill="currentColor"
      />
      <path
        d="M10.4168 12.1667C10.8771 12.1667 11.2502 11.7936 11.2502 11.3333C11.2502 10.8731 10.8771 10.5 10.4168 10.5H9.5835C9.12326 10.5 8.75016 10.8731 8.75016 11.3333C8.75016 11.7936 9.12326 12.1667 9.5835 12.1667H10.4168Z"
        fill="currentColor"
      />
      <path
        d="M7.91683 13.8333C7.91683 14.2936 7.54373 14.6667 7.0835 14.6667H6.25016C5.78993 14.6667 5.41683 14.2936 5.41683 13.8333C5.41683 13.3731 5.78993 13 6.25016 13H7.0835C7.54373 13 7.91683 13.3731 7.91683 13.8333Z"
        fill="currentColor"
      />
      <path
        d="M10.4168 14.6667C10.8771 14.6667 11.2502 14.2936 11.2502 13.8333C11.2502 13.3731 10.8771 13 10.4168 13H9.5835C9.12326 13 8.75016 13.3731 8.75016 13.8333C8.75016 14.2936 9.12326 14.6667 9.5835 14.6667H10.4168Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.3335 4.66667C3.3335 3.74619 4.07969 3 5.00016 3H11.6668C12.5873 3 13.3335 3.74619 13.3335 4.66667V8H15.8335C16.754 8 17.5002 8.74619 17.5002 9.66667V16.3333H18.3335C18.7937 16.3333 19.1668 16.7064 19.1668 17.1667C19.1668 17.6269 18.7937 18 18.3335 18H1.66683C1.20659 18 0.833496 17.6269 0.833496 17.1667C0.833496 16.7064 1.20659 16.3333 1.66683 16.3333H3.3335V4.66667ZM5.00016 16.3333H11.6668V4.66667H5.00016V16.3333ZM15.8335 13V12.1667H15.4168C14.9566 12.1667 14.5835 11.7936 14.5835 11.3333C14.5835 10.8731 14.9566 10.5 15.4168 10.5H15.8335V9.66667H13.3335V16.3333H15.8335V14.6667H15.4168C14.9566 14.6667 14.5835 14.2936 14.5835 13.8333C14.5835 13.3731 14.9566 13 15.4168 13H15.8335Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_copy: (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.668 0.166016C8.82702 0.166016 7.33464 1.6584 7.33464 3.49935V5.16602H4.0013C2.16035 5.16602 0.667969 6.6584 0.667969 8.49935V13.4993C0.667969 15.3403 2.16035 16.8327 4.0013 16.8327H7.33464C9.17558 16.8327 10.668 15.3403 10.668 13.4993V11.8327H14.0013C15.8423 11.8327 17.3346 10.3403 17.3346 8.49935V3.49935C17.3346 1.6584 15.8423 0.166016 14.0013 0.166016H10.668ZM10.668 10.166H14.0013C14.9218 10.166 15.668 9.41982 15.668 8.49935V3.49935C15.668 2.57887 14.9218 1.83268 14.0013 1.83268H10.668C9.74749 1.83268 9.0013 2.57887 9.0013 3.49935V5.61196C9.99764 6.18831 10.668 7.26554 10.668 8.49935V10.166ZM2.33464 8.49935C2.33464 7.57887 3.08083 6.83268 4.0013 6.83268H7.33464C8.25511 6.83268 9.0013 7.57887 9.0013 8.49935V13.4993C9.0013 14.4198 8.25511 15.166 7.33464 15.166H4.0013C3.08083 15.166 2.33464 14.4198 2.33464 13.4993V8.49935Z"
        fill="#98A2B3"
      />
    </svg>
  ),
  ic_delete: (
    <svg
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5001 0.75C4.08444 0.75 3.71212 1.00713 3.56491 1.39585L3.33532 2.00213C2.84314 1.95295 2.38967 1.90387 2.02258 1.86265C1.74817 1.83184 1.52259 1.80549 1.3659 1.78687L1.18515 1.76518L1.12377 1.75768C0.575675 1.68981 0.0755572 2.07901 0.0076843 2.6271C-0.0601894 3.1752 0.32911 3.67455 0.877208 3.74242L0.943999 3.75059L1.12998 3.77291C1.29032 3.79196 1.52019 3.81881 1.79942 3.85016C2.35738 3.91281 3.11468 3.99369 3.90922 4.0662C4.981 4.16401 6.15582 4.25 7.0001 4.25C7.84438 4.25 9.01921 4.16401 10.091 4.0662C10.8855 3.99369 11.6428 3.91281 12.2008 3.85016C12.48 3.81881 12.7099 3.79196 12.8702 3.77291L13.0562 3.75059L13.1229 3.74244C13.671 3.67456 14.0604 3.1752 13.9925 2.6271C13.9247 2.07901 13.4253 1.68972 12.8772 1.75758L12.8151 1.76518L12.6343 1.78687C12.4776 1.80549 12.252 1.83184 11.9776 1.86265C11.6105 1.90387 11.1571 1.95295 10.6649 2.00213L10.4353 1.39585C10.2881 1.00713 9.91577 0.75 9.5001 0.75H4.5001Z"
        fill="#D42620"
      />
      <path
        d="M6.0001 10.75C6.0001 10.1977 5.55239 9.75 5.0001 9.75C4.44782 9.75 4.0001 10.1977 4.0001 10.75V15.75C4.0001 16.3023 4.44782 16.75 5.0001 16.75C5.55239 16.75 6.0001 16.3023 6.0001 15.75V10.75Z"
        fill="#D42620"
      />
      <path
        d="M9.0001 9.75C9.55239 9.75 10.0001 10.1977 10.0001 10.75V15.75C10.0001 16.3023 9.55239 16.75 9.0001 16.75C8.44782 16.75 8.0001 16.3023 8.0001 15.75V10.75C8.0001 10.1977 8.44782 9.75 9.0001 9.75Z"
        fill="#D42620"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9098 7.05056C14.0004 5.78179 12.9065 4.76463 11.6634 4.9083C10.3914 5.0553 8.42844 5.25 7.0001 5.25C5.57176 5.25 3.60884 5.0553 2.33681 4.9083C1.09368 4.76463 -0.000173315 5.78179 0.090453 7.05056L0.947186 19.0448C1.01213 19.954 1.68937 20.7228 2.61875 20.8626C3.61535 21.0124 5.44405 21.2518 7.00126 21.25C8.53934 21.2482 10.3755 21.0099 11.3765 20.8614C12.3074 20.7232 12.9881 19.954 13.0532 19.0421L13.9098 7.05056ZM11.893 6.89507C11.8965 6.89467 11.8991 6.89491 11.8991 6.89491L11.9018 6.89551C11.904 6.89628 11.9075 6.89811 11.9109 6.90137C11.9132 6.90353 11.9149 6.90617 11.9149 6.90617L11.9148 6.90806L11.0592 18.8865C10.0685 19.0329 8.37063 19.2484 6.99895 19.25C5.61239 19.2516 3.92497 19.0359 2.94112 18.8885L2.08537 6.90806L2.0853 6.90617C2.0853 6.90617 2.087 6.90353 2.08928 6.90137C2.09272 6.89811 2.09621 6.89628 2.09843 6.89551L2.10108 6.89491C2.10108 6.89491 2.10374 6.89467 2.1072 6.89507C3.38149 7.04234 5.44555 7.25 7.0001 7.25C8.55466 7.25 10.6187 7.04234 11.893 6.89507Z"
        fill="#D42620"
      />
    </svg>
  ),
  ic_download: (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7071 11.7071L10.7071 15.7071C10.3166 16.0976 9.68342 16.0976 9.29289 15.7071L5.29289 11.7071C4.90237 11.3166 4.90237 10.6834 5.29289 10.2929C5.68342 9.90237 6.31658 9.90237 6.70711 10.2929L9 12.5858V1C9 0.447715 9.44772 0 10 0C10.5523 0 11 0.447715 11 1V12.5858L13.2929 10.2929C13.6834 9.90237 14.3166 9.90237 14.7071 10.2929C15.0976 10.6834 15.0976 11.3166 14.7071 11.7071Z"
        fill="#475367"
      />
      <path
        d="M2 15.5C2 14.9477 1.55228 14.5 1 14.5C0.447715 14.5 0 14.9477 0 15.5V17C0 19.2091 1.79086 21 4 21H16C18.2091 21 20 19.2091 20 17V15.5C20 14.9477 19.5523 14.5 19 14.5C18.4477 14.5 18 14.9477 18 15.5V17C18 18.1046 17.1046 19 16 19H4C2.89543 19 2 18.1046 2 17V15.5Z"
        fill="#475367"
      />
    </svg>
  ),
  ic_email: (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8337 18C17.6747 18 19.167 16.5076 19.167 14.6667V7.18557C19.1673 7.17283 19.1673 7.16005 19.167 7.14725V6.33333C19.167 4.49238 17.6747 3 15.8337 3H4.16705C2.3261 3 0.833718 4.49238 0.833718 6.33333V7.14726C0.833422 7.16005 0.833423 7.17282 0.833718 7.18556V14.6667C0.833718 16.5076 2.3261 18 4.16705 18H15.8337ZM2.50038 14.6667C2.50038 15.5871 3.24658 16.3333 4.16705 16.3333H15.8337C16.7542 16.3333 17.5004 15.5871 17.5004 14.6667V8.39753L11.2384 10.9023C10.4436 11.2202 9.55712 11.2202 8.76241 10.9023L2.50038 8.39753V14.6667ZM10.6194 9.35488L17.5004 6.60247V6.33333C17.5004 5.41286 16.7542 4.66667 15.8337 4.66667H4.16705C3.24658 4.66667 2.50038 5.41286 2.50038 6.33333V6.60247L9.3814 9.35488C9.77875 9.51382 10.222 9.51382 10.6194 9.35488Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_eye_closed: (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2544 0.744078C16.5799 1.06951 16.5799 1.59715 16.2544 1.92259L2.92108 15.2559C2.59565 15.5814 2.06801 15.5814 1.74257 15.2559C1.41714 14.9305 1.41714 14.4028 1.74257 14.0774L15.0759 0.744078C15.4013 0.418641 15.929 0.418641 16.2544 0.744078Z"
        fill="currentColor"
      />
      <path
        d="M12.1648 2.4767C11.2262 2.03469 10.1679 1.75 8.99848 1.75C6.54507 1.75 4.5808 3.00308 3.18482 4.33307C1.78563 5.66611 0.877746 7.14973 0.51836 7.7915C0.282842 8.21207 0.254792 8.71426 0.448115 9.16031C0.583126 9.47181 0.81273 9.95437 1.15114 10.5143C1.38919 10.9082 1.90148 11.0346 2.29537 10.7965C2.68927 10.5585 2.81561 10.0462 2.57757 9.65232C2.30538 9.20192 2.1165 8.81241 2.00194 8.55369C2.34007 7.95778 3.14086 6.67693 4.33447 5.53975C5.56736 4.36513 7.14019 3.41667 8.99848 3.41667C9.66686 3.41667 10.2983 3.53937 10.8903 3.75116L12.1648 2.4767Z"
        fill="currentColor"
      />
      <path
        d="M13.7406 5.61491C14.8911 6.73288 15.6643 7.97087 15.995 8.55369C15.8805 8.81241 15.6916 9.20192 15.4194 9.65232C15.1813 10.0462 15.3077 10.5585 15.7016 10.7965C16.0955 11.0346 16.6078 10.9082 16.8458 10.5143C17.1842 9.95437 17.4138 9.47181 17.5488 9.1603C17.7422 8.71426 17.7141 8.21207 17.4786 7.7915C17.1285 7.16625 16.2577 5.74193 14.9192 4.43629L13.7406 5.61491Z"
        fill="currentColor"
      />
      <path
        d="M8.99849 4.66667C9.30134 4.66667 9.59664 4.69898 9.88114 4.76034L8.16628 6.47519C7.45503 6.7262 6.89136 7.28987 6.64035 8.00112L4.9255 9.71597C4.86414 9.43148 4.83183 9.13618 4.83183 8.83333C4.83183 6.53215 6.69731 4.66667 8.99849 4.66667Z"
        fill="currentColor"
      />
      <path
        d="M8.99849 11.3333C8.7061 11.3333 8.42543 11.2831 8.16463 11.1909L6.91376 12.4418C7.52693 12.7968 8.23898 13 8.99849 13C11.2997 13 13.1652 11.1345 13.1652 8.83333C13.1652 8.07382 12.9619 7.36177 12.6069 6.74859L11.3561 7.99947C11.4483 8.26027 11.4985 8.54094 11.4985 8.83333C11.4985 10.214 10.3792 11.3333 8.99849 11.3333Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_eye_open: (
    <svg
      width="18"
      height="16"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.79464 9.98278C3.08029 10.4555 2.92868 11.0702 2.45601 11.3559C1.98333 11.6415 1.36859 11.4899 1.08294 11.0172C0.676839 10.3452 0.401314 9.76617 0.239301 9.39237C0.00731261 8.85711 0.0409731 8.25449 0.323594 7.7498C0.754858 6.97968 1.84432 5.19934 3.52335 3.59968C5.19852 2.00369 7.55564 0.5 10.4997 0.5C13.4438 0.5 15.801 2.00369 17.4761 3.59968C19.1552 5.19933 20.2446 6.97968 20.6759 7.7498C20.9585 8.25449 20.9922 8.85711 20.7602 9.39237C20.5982 9.76617 20.3226 10.3452 19.9165 11.0172C19.6309 11.4899 19.0161 11.6415 18.5435 11.3559C18.0708 11.0702 17.9192 10.4555 18.2048 9.98278C18.5315 9.44231 18.7581 8.97489 18.8956 8.66443C18.4898 7.94934 17.5289 6.41231 16.0966 5.0477C14.6171 3.63816 12.7297 2.5 10.4997 2.5C8.26978 2.5 6.3824 3.63816 4.90292 5.0477C3.4706 6.41232 2.50964 7.94933 2.10389 8.66442C2.24137 8.97489 2.46802 9.4423 2.79464 9.98278Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4997 4C7.73831 4 5.49974 6.23858 5.49974 9C5.49974 11.7614 7.73831 14 10.4997 14C13.2612 14 15.4997 11.7614 15.4997 9C15.4997 6.23858 13.2612 4 10.4997 4ZM7.49974 9C7.49974 7.34315 8.84288 6 10.4997 6C12.1566 6 13.4997 7.34315 13.4997 9C13.4997 10.6569 12.1566 12 10.4997 12C8.84288 12 7.49974 10.6569 7.49974 9Z"
        fill="currentColor"
      />
    </svg>
  ),
  
  ic_file: (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.83333 10.0007C3.83333 9.54041 4.20643 9.16732 4.66667 9.16732H11.3333C11.7936 9.16732 12.1667 9.54041 12.1667 10.0007C12.1667 10.4609 11.7936 10.834 11.3333 10.834H4.66667C4.20643 10.834 3.83333 10.4609 3.83333 10.0007Z"
        fill="currentColor"
      />
      <path
        d="M4.66667 13.334C4.20643 13.334 3.83333 13.7071 3.83333 14.1673C3.83333 14.6276 4.20643 15.0006 4.66667 15.0006H11.3333C11.7936 15.0006 12.1667 14.6276 12.1667 14.1673C12.1667 13.7071 11.7936 13.334 11.3333 13.334H4.66667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83333 0.833984C1.99238 0.833984 0.5 2.32637 0.5 4.16732V15.834C0.5 17.6749 1.99238 19.1673 3.83333 19.1673H12.1667C14.0076 19.1673 15.5 17.6749 15.5 15.834V7.57244C15.5 6.79265 15.2266 6.03754 14.7274 5.43849L11.8898 2.03337C11.2565 1.27339 10.3183 0.833984 9.32906 0.833984H3.83333ZM2.16667 4.16732C2.16667 3.24684 2.91286 2.50065 3.83333 2.50065H8.83333V5.00065C8.83333 6.8416 10.3257 8.33398 12.1667 8.33398H13.8333V15.834C13.8333 16.7545 13.0871 17.5006 12.1667 17.5006H3.83333C2.91286 17.5006 2.16667 16.7545 2.16667 15.834V4.16732ZM13.5661 6.66732C13.5299 6.61127 13.4901 6.5572 13.447 6.50547L10.6094 3.10034C10.5747 3.0587 10.5382 3.01898 10.5 2.98127V5.00065C10.5 5.92112 11.2462 6.66732 12.1667 6.66732H13.5661Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_info: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00065 4.00065C8.36884 4.00065 8.66732 4.29913 8.66732 4.66732V9.33398C8.66732 9.70218 8.36884 10.0007 8.00065 10.0007C7.63246 10.0007 7.33398 9.70217 7.33398 9.33398V4.66732C7.33398 4.29913 7.63246 4.00065 8.00065 4.00065Z"
        fill="#0F973D"
      />
      <path
        d="M7.16732 11.1673C7.16732 11.6276 7.54041 12.0007 8.00065 12.0007C8.46089 12.0007 8.83398 11.6276 8.83398 11.1673C8.83398 10.7071 8.46089 10.334 8.00065 10.334C7.54041 10.334 7.16732 10.7071 7.16732 11.1673Z"
        fill="#0F973D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33398 8.00065C1.33398 4.31875 4.31875 1.33398 8.00065 1.33398C11.6825 1.33398 14.6673 4.31875 14.6673 8.00065C14.6673 11.6825 11.6825 14.6673 8.00065 14.6673C4.31875 14.6673 1.33398 11.6825 1.33398 8.00065ZM8.00065 2.66732C5.05513 2.66732 2.66732 5.05513 2.66732 8.00065C2.66732 10.9462 5.05513 13.334 8.00065 13.334C10.9462 13.334 13.334 10.9462 13.334 8.00065C13.334 5.05513 10.9462 2.66732 8.00065 2.66732Z"
        fill="#0F973D"
      />
    </svg>
  ),
  ic_loading: (
    <svg
      className="animate-spin h-8 w-8"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  ),
  ic_location: (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99964 3.58294C4.92857 3.58294 3.24964 5.26187 3.24964 7.33294C3.24964 9.40401 4.92857 11.0829 6.99964 11.0829C9.07071 11.0829 10.7496 9.40401 10.7496 7.33294C10.7496 5.26187 9.07071 3.58294 6.99964 3.58294ZM4.91631 7.33294C4.91631 6.18235 5.84905 5.24961 6.99964 5.24961C8.15023 5.24961 9.08297 6.18235 9.08297 7.33294C9.08297 8.48353 8.15023 9.41627 6.99964 9.41627C5.84905 9.41627 4.91631 8.48353 4.91631 7.33294Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.50734 1.99293C5.62211 0.583086 8.37717 0.583086 10.4919 1.99293C13.351 3.89898 14.1537 7.74545 12.2956 10.6359L9.10259 15.6027C8.11849 17.1335 5.88078 17.1335 4.89669 15.6027L1.70372 10.6359C-0.154421 7.74545 0.648261 3.89898 3.50734 1.99293ZM4.43184 3.37968C5.98677 2.34306 8.01251 2.34306 9.56744 3.37968C11.6696 4.78115 12.2598 7.60936 10.8936 9.73463L7.70062 14.7015C7.37259 15.2117 6.62669 15.2117 6.29866 14.7015L3.10569 9.73463C1.73944 7.60936 2.32963 4.78115 4.43184 3.37968Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_logout: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.24935 3.33268C6.70959 3.33268 7.08268 2.95959 7.08268 2.49935C7.08268 2.03911 6.70959 1.66602 6.24935 1.66602H4.99935C3.1584 1.66602 1.66602 3.1584 1.66602 4.99935V14.9993C1.66602 16.8403 3.1584 18.3327 4.99935 18.3327H6.24935C6.70959 18.3327 7.08268 17.9596 7.08268 17.4993C7.08268 17.0391 6.70959 16.666 6.24935 16.666H4.99935C4.07887 16.666 3.33268 15.9198 3.33268 14.9993L3.33268 4.99935C3.33268 4.07887 4.07888 3.33268 4.99935 3.33268H6.24935Z"
        fill="currentColor"
      />
      <path
        d="M18.9219 10.5886C19.2474 10.2632 19.2474 9.73553 18.9219 9.41009L15.5886 6.07676C15.2632 5.75132 14.7355 5.75132 14.4101 6.07676C14.0847 6.4022 14.0847 6.92983 14.4101 7.25527L16.3208 9.16602L6.66602 9.16602C6.20578 9.16602 5.83268 9.53911 5.83268 9.99935C5.83268 10.4596 6.20578 10.8327 6.66602 10.8327L16.3208 10.8327L14.4101 12.7434C14.0847 13.0689 14.0847 13.5965 14.4101 13.9219C14.7355 14.2474 15.2632 14.2474 15.5886 13.9219L18.9219 10.5886Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_menu: (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.26758 4.5H21.2676"
        stroke="#0F973D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.26758 12.5H21.2676"
        stroke="#0F973D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.26758 20.5H21.2676"
        stroke="#0F973D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ic_money: (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.24125 2.84762C3.78276 2.8075 3.37857 3.14666 3.33846 3.60515C3.29835 4.06364 3.6375 4.46783 4.09599 4.50794L5.75631 4.6532C6.2148 4.69331 6.61899 4.35415 6.65911 3.89567C6.69922 3.43718 6.36006 3.03299 5.90157 2.99288L4.24125 2.84762Z"
        fill="#98A2B3"
      />
      <path
        d="M13.3385 4.43848C13.3786 3.98 13.7828 3.64084 14.2412 3.68095L15.9016 3.82621C16.3601 3.86632 16.6992 4.27052 16.6591 4.729C16.619 5.18749 16.2148 5.52665 15.7563 5.48653L14.096 5.34128C13.6375 5.30116 13.2983 4.89697 13.3385 4.43848Z"
        fill="#98A2B3"
      />
      <path
        d="M4.24125 10.0036C3.78276 9.96347 3.37857 10.3026 3.33846 10.7611C3.29835 11.2196 3.6375 11.6238 4.09599 11.6639L5.75631 11.8092C6.2148 11.8493 6.61899 11.5101 6.65911 11.0516C6.69922 10.5931 6.36006 10.189 5.90157 10.1488L4.24125 10.0036Z"
        fill="#98A2B3"
      />
      <path
        d="M13.3385 11.9385C13.3786 11.48 13.7828 11.1408 14.2412 11.181L15.9016 11.3262C16.3601 11.3663 16.6992 11.7705 16.6591 12.229C16.619 12.6875 16.2148 13.0266 15.7563 12.9865L14.096 12.8413C13.6375 12.8012 13.2983 12.397 13.3385 11.9385Z"
        fill="#98A2B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9987 5.00041C11.3794 5.00041 12.4987 6.1197 12.4987 7.50041C12.4987 8.88112 11.3794 10.0004 9.9987 10.0004C8.61798 10.0004 7.4987 8.88112 7.4987 7.50041C7.4987 6.1197 8.61798 5.00041 9.9987 5.00041ZM10.832 7.50041C10.832 7.04017 10.4589 6.66708 9.9987 6.66708C9.53846 6.66708 9.16536 7.04017 9.16536 7.50041C9.16536 7.96065 9.53846 8.33375 9.9987 8.33375C10.4589 8.33375 10.832 7.96065 10.832 7.50041Z"
        fill="#98A2B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.36897 0.20174C2.44128 0.0362797 0.832031 1.56365 0.832031 3.47426V10.1334C0.832031 11.9044 2.21117 13.3592 3.95557 13.5059C4.67058 13.5661 5.54769 13.6448 6.58405 13.7464C6.76983 13.7647 7.1829 13.8489 7.7801 13.987C8.17275 14.0778 8.61474 14.1845 9.07907 14.2966C9.29797 14.3494 9.52255 14.4037 9.74854 14.4578C10.4479 14.6253 11.163 14.7926 11.7784 14.9185C12.3677 15.0391 12.9451 15.1394 13.3285 15.141C14.2816 15.145 15.2136 15.127 16.0113 15.1027C17.7956 15.0482 19.1654 13.5742 19.1654 11.818V5.16108C19.1654 3.23407 17.5457 1.72781 15.6517 1.779C14.9412 1.7982 14.1446 1.81108 13.3356 1.80765C13.1432 1.80684 12.7247 1.74431 12.1125 1.61905C11.5266 1.49914 10.8349 1.33752 10.1368 1.1703C9.91812 1.11792 9.69831 1.06486 9.48141 1.01251L9.48057 1.01231C9.01161 0.899107 8.55616 0.789169 8.15561 0.696542C7.58879 0.565465 7.06194 0.451963 6.74667 0.421054C5.8309 0.331272 5.03769 0.259138 4.36897 0.20174ZM2.4987 3.47426C2.4987 2.51611 3.29588 1.78243 4.22644 1.8623C4.8885 1.91913 5.67502 1.99065 6.58405 2.07977C6.76983 2.09798 7.1829 2.18225 7.7801 2.32036C8.17281 2.41117 8.61488 2.51788 9.0793 2.62999C9.29835 2.68286 9.52238 2.73694 9.74854 2.79112C10.4479 2.95865 11.163 3.12595 11.7784 3.25188C12.3677 3.37246 12.9451 3.47268 13.3285 3.4743C14.159 3.47782 14.9734 3.4646 15.6967 3.44506C16.6854 3.41834 17.4987 4.20433 17.4987 5.16108V11.818C17.4987 12.7026 16.8157 13.4107 15.9605 13.4368C15.1763 13.4607 14.2643 13.4783 13.3356 13.4743C13.1432 13.4735 12.7247 13.411 12.1125 13.2857C11.5266 13.1658 10.8349 13.0042 10.1368 12.837C9.91789 12.7845 9.69785 12.7314 9.48073 12.679C9.01169 12.5658 8.55622 12.4559 8.15561 12.3632C7.58879 12.2321 7.06194 12.1186 6.74667 12.0877C5.70263 11.9854 4.81789 11.9059 4.0953 11.8451C3.19375 11.7693 2.4987 11.0178 2.4987 10.1334V3.47426Z"
        fill="#98A2B3"
      />
    </svg>
  ),
  ic_more: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.95573 8.99935C7.95573 9.57465 8.4221 10.041 8.9974 10.041C9.57269 10.041 10.0391 9.57465 10.0391 8.99935C10.0391 8.42405 9.57269 7.95768 8.9974 7.95768C8.4221 7.95768 7.95573 8.42405 7.95573 8.99935Z"
        fill="#475367"
      />
      <path
        d="M8.9974 6.29102C8.4221 6.29102 7.95573 5.82465 7.95573 5.24935C7.95573 4.67405 8.4221 4.20768 8.9974 4.20768C9.57269 4.20768 10.0391 4.67405 10.0391 5.24935C10.0391 5.82465 9.57269 6.29102 8.9974 6.29102Z"
        fill="#475367"
      />
      <path
        d="M7.95573 12.7493C7.95573 13.3246 8.4221 13.791 8.9974 13.791C9.57269 13.791 10.0391 13.3246 10.0391 12.7493C10.0391 12.1741 9.57269 11.7077 8.9974 11.7077C8.4221 11.7077 7.95573 12.1741 7.95573 12.7493Z"
        fill="#475367"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.9974 0.666016C4.39502 0.666016 0.664062 4.39698 0.664062 8.99935C0.664062 13.6017 4.39502 17.3327 8.9974 17.3327C13.5998 17.3327 17.3307 13.6017 17.3307 8.99935C17.3307 4.39698 13.5998 0.666016 8.9974 0.666016ZM2.33073 8.99935C2.33073 5.31745 5.3155 2.33268 8.9974 2.33268C12.6793 2.33268 15.6641 5.31745 15.6641 8.99935C15.6641 12.6812 12.6793 15.666 8.9974 15.666C5.3155 15.666 2.33073 12.6812 2.33073 8.99935Z"
        fill="#475367"
      />
    </svg>
  ),
  ic_next: (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0867 5.58893C17.4121 5.26349 17.4121 4.73586 17.0867 4.41042L13.7533 1.07709C13.4279 0.751649 12.9002 0.751649 12.5748 1.07709C12.2494 1.40252 12.2494 1.93016 12.5748 2.2556L14.4856 4.16634L1.4974 4.16634C1.03716 4.16634 0.664062 4.53944 0.664062 4.99968C0.664062 5.45991 1.03716 5.83301 1.4974 5.83301L14.4856 5.83301L12.5748 7.74375C12.2494 8.06919 12.2494 8.59683 12.5748 8.92226C12.9002 9.2477 13.4279 9.2477 13.7533 8.92226L17.0867 5.58893Z"
        fill="#344054"
      />
    </svg>
  ),
  ic_new_tab: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5893 3.86328C10.1291 3.86328 9.75596 4.23638 9.75596 4.69661C9.75596 5.15685 10.1291 5.52995 10.5893 5.52995H13.2915L4.10748 14.714C3.78204 15.0394 3.78204 15.567 4.10748 15.8925C4.43292 16.2179 4.96056 16.2179 5.28599 15.8925L14.47 6.70846V9.41066C14.47 9.8709 14.8431 10.244 15.3033 10.244C15.7636 10.244 16.1367 9.8709 16.1367 9.41066V4.69661C16.1367 4.23638 15.7636 3.86328 15.3033 3.86328H10.5893Z"
        fill="#98A2B3"
      />
    </svg>
  ),
  ic_not_checked: (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute w-4 h-4 pointer-events-none top-1 left-0"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66667 0.5C1.19391 0.5 0 1.69391 0 3.16667V9.83333C0 11.3061 1.19391 12.5 2.66667 12.5H9.33333C10.8061 12.5 12 11.3061 12 9.83333V3.16667C12 1.69391 10.8061 0.5 9.33333 0.5H2.66667ZM1.33333 3.16667C1.33333 2.43029 1.93029 1.83333 2.66667 1.83333H9.33333C10.0697 1.83333 10.6667 2.43029 10.6667 3.16667V9.83333C10.6667 10.5697 10.0697 11.1667 9.33333 11.1667H2.66667C1.93029 11.1667 1.33333 10.5697 1.33333 9.83333V3.16667Z"
        fill="black"
      />
    </svg>
  ),
  ic_pencil: (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4844 3.43333C16.1175 2.06649 13.9014 2.06649 12.5346 3.43333L12.0082 3.95977L3.81362 12.1543C3.20163 12.7663 2.80465 13.5603 2.68225 14.4171L2.42298 16.232C2.23442 17.5519 3.36579 18.6833 4.68572 18.4947L6.50063 18.2354C7.35741 18.113 8.15138 17.716 8.76337 17.1041L16.9579 8.90952L17.4844 8.38308C18.8512 7.01624 18.8512 4.80016 17.4844 3.43333ZM16.2355 6.77287L14.1448 4.6822C14.7303 4.26823 15.5459 4.32335 16.0701 4.84754C16.5943 5.37174 16.6495 6.18739 16.2355 6.77287ZM12.7153 6.08109L14.8366 8.20241L7.34916 15.6898C7.04316 15.9958 6.64618 16.1943 6.21779 16.2555L4.40288 16.5148L4.66215 14.6999C4.72335 14.2715 4.92184 13.8745 5.22784 13.5685L12.7153 6.08109Z"
        fill="#98A2B3"
      />
    </svg>
  ),
  ic_phone: (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22854 2.83965C6.92679 1.5379 4.81625 1.53791 3.5145 2.83965L3.06822 3.28593C2.45291 3.90124 2.03206 4.8233 2.27211 5.82213C2.64012 7.35339 3.74753 10.1438 7.05003 13.4463C10.3525 16.7488 13.1429 17.8562 14.6742 18.2242C15.673 18.4642 16.5951 18.0434 17.2104 17.4281L17.6566 16.9818C18.9584 15.68 18.9584 13.5695 17.6566 12.2677L16.8826 11.4937C15.8215 10.4326 14.1823 10.2113 12.8779 10.9531L12.7974 10.9988C12.2629 11.3028 11.6488 11.2779 11.2138 10.9631C10.8628 10.709 10.5273 10.4417 10.2909 10.2053C10.0546 9.969 9.78724 9.63351 9.53321 9.28248C9.21837 8.84746 9.19353 8.23339 9.49748 7.69886L9.54322 7.61841C10.2849 6.314 10.0637 4.67477 9.00261 3.61372L8.22854 2.83965ZM4.69301 4.01817C5.34388 3.36729 6.39916 3.36729 7.05003 4.01816L7.8241 4.79223C8.35462 5.32275 8.46527 6.14237 8.0944 6.79458L8.04866 6.87502C7.46124 7.90807 7.44063 9.2338 8.18303 10.2596C8.45572 10.6364 8.78343 11.0549 9.11243 11.3839C9.44142 11.7129 9.85987 12.0406 10.2367 12.3133C11.2625 13.0557 12.5882 13.035 13.6213 12.4476L13.7017 12.4019C14.3539 12.031 15.1735 12.1417 15.7041 12.6722L16.4781 13.4463C17.129 14.0971 17.129 15.1524 16.4781 15.8033L16.0318 16.2496C15.7095 16.5719 15.3542 16.6735 15.0636 16.6037C13.8394 16.3094 11.3199 15.3591 8.22854 12.2677C5.13715 9.17635 4.18685 6.65687 3.89263 5.43267C3.82281 5.14213 3.92436 4.78682 4.24673 4.46444L4.69301 4.01817Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_previous: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.90814 10.5889C1.5827 10.2635 1.5827 9.73586 1.90814 9.41042L5.24147 6.07709C5.56691 5.75165 6.09455 5.75165 6.41998 6.07709C6.74542 6.40252 6.74542 6.93016 6.41998 7.2556L4.50924 9.16634L17.4974 9.16634C17.9576 9.16634 18.3307 9.53944 18.3307 9.99968C18.3307 10.4599 17.9576 10.833 17.4974 10.833L4.50924 10.833L6.41998 12.7438C6.74542 13.0692 6.74542 13.5968 6.41998 13.9223C6.09455 14.2477 5.56691 14.2477 5.24147 13.9223L1.90814 10.5889Z"
        fill="#344054"
      />
    </svg>
  ),
  ic_role: (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83268 6.10185C9.83268 5.64161 9.45959 5.26852 8.99935 5.26852C8.53911 5.26852 8.16602 5.64161 8.16602 6.10185V6.84259C8.16602 7.30283 8.53911 7.67593 8.99935 7.67593C9.45959 7.67593 9.83268 7.30283 9.83268 6.84259V6.10185Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.77713 0.5C11.1758 0.5 12.3549 1.43972 12.7177 2.72222H14.6475C16.1305 2.72222 17.3327 3.92442 17.3327 5.40741V6.50926C17.3327 7.10273 17.0062 7.61994 16.523 7.89105L16.0561 12.5024C15.8837 14.2047 14.4506 15.5 12.7397 15.5H5.25901C3.54808 15.5 2.11498 14.2047 1.94263 12.5024L1.47572 7.89105C0.992533 7.61994 0.666016 7.10273 0.666016 6.50926V5.40741C0.666016 3.92442 1.86822 2.72222 3.3512 2.72222H5.28103C5.64377 1.43972 6.82293 0.5 8.22157 0.5H9.77713ZM7.11036 2.72222C7.36375 2.38488 7.76718 2.16667 8.22157 2.16667H9.77713C10.2315 2.16667 10.6349 2.38488 10.8883 2.72222H7.11036ZM2.33268 5.40741C2.33268 4.8449 2.78869 4.38889 3.3512 4.38889H14.6475C15.21 4.38889 15.666 4.8449 15.666 5.40741V6.43004C15.5876 6.43783 15.5124 6.45648 15.4419 6.48448L9.88012 8.5444C9.69484 8.61302 9.49884 8.64815 9.30126 8.64815H8.69744C8.49986 8.64815 8.30386 8.61302 8.11858 8.5444L2.55682 6.48449C2.48633 6.45648 2.4111 6.43783 2.33268 6.43004V5.40741ZM10.459 10.1073L14.7856 8.50484L14.3979 12.3346C14.3117 13.1857 13.5952 13.8333 12.7397 13.8333H5.25901C4.40354 13.8333 3.68699 13.1857 3.60082 12.3346L3.21306 8.50484L7.53972 10.1073C7.91028 10.2446 8.30228 10.3148 8.69744 10.3148H9.30126C9.69641 10.3148 10.0884 10.2446 10.459 10.1073Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_scan: (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66732 4.32161C1.66732 2.48067 3.1597 0.988281 5.00065 0.988281H6.66732C7.12755 0.988281 7.50065 1.36138 7.50065 1.82161C7.50065 2.28185 7.12755 2.65495 6.66732 2.65495H5.00065C4.08018 2.65495 3.33398 3.40114 3.33398 4.32161V5.98828C3.33398 6.44852 2.96089 6.82161 2.50065 6.82161C2.04041 6.82161 1.66732 6.44852 1.66732 5.98828V4.32161Z"
        fill="currentColor"
      />
      <path
        d="M18.334 4.32161C18.334 2.48067 16.8416 0.988281 15.0006 0.988281H13.334C12.8737 0.988281 12.5007 1.36138 12.5007 1.82161C12.5007 2.28185 12.8737 2.65495 13.334 2.65495H15.0006C15.9211 2.65495 16.6673 3.40114 16.6673 4.32161V5.98828C16.6673 6.44852 17.0404 6.82161 17.5006 6.82161C17.9609 6.82161 18.334 6.44852 18.334 5.98828V4.32161Z"
        fill="currentColor"
      />
      <path
        d="M5.00065 17.6549C3.1597 17.6549 1.66732 16.1626 1.66732 14.3216V12.6549C1.66732 12.1947 2.04041 11.8216 2.50065 11.8216C2.96089 11.8216 3.33398 12.1947 3.33398 12.6549V14.3216C3.33398 15.2421 4.08018 15.9883 5.00065 15.9883H6.66732C7.12755 15.9883 7.50065 16.3614 7.50065 16.8216C7.50065 17.2819 7.12755 17.6549 6.66732 17.6549H5.00065Z"
        fill="currentColor"
      />
      <path
        d="M18.334 14.3216C18.334 16.1626 16.8416 17.6549 15.0006 17.6549H13.334C12.8737 17.6549 12.5007 17.2819 12.5007 16.8216C12.5007 16.3614 12.8737 15.9883 13.334 15.9883H15.0006C15.9211 15.9883 16.6673 15.2421 16.6673 14.3216V12.6549C16.6673 12.1947 17.0404 11.8216 17.5006 11.8216C17.9609 11.8216 18.334 12.1947 18.334 12.6549V14.3216Z"
        fill="currentColor"
      />
      <path
        d="M1.66732 9.32162C1.20708 9.32162 0.833984 9.69471 0.833984 10.1549C0.833984 10.6152 1.20708 10.9883 1.66732 10.9883H18.334C18.7942 10.9883 19.1673 10.6152 19.1673 10.1549C19.1673 9.69471 18.7942 9.32162 18.334 9.32162H1.66732Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_search: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 15L10.3333 10.3333M11.8889 6.44444C11.8889 9.45133 9.45133 11.8889 6.44444 11.8889C3.43756 11.8889 1 9.45133 1 6.44444C1 3.43756 3.43756 1 6.44444 1C9.45133 1 11.8889 3.43756 11.8889 6.44444Z"
        stroke="#667185"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ic_settings: (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00046 4.32258C6.23904 4.32258 4.00046 6.56115 4.00046 9.32258C4.00046 12.084 6.23904 14.3226 9.00046 14.3226C11.7619 14.3226 14.0005 12.084 14.0005 9.32258C14.0005 6.56115 11.7619 4.32258 9.00046 4.32258ZM5.66713 9.32258C5.66713 7.48163 7.15951 5.98924 9.00046 5.98924C10.8414 5.98924 12.3338 7.48163 12.3338 9.32258C12.3338 11.1635 10.8414 12.6559 9.00046 12.6559C7.15951 12.6559 5.66713 11.1635 5.66713 9.32258Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0853 1.01333C9.92287 -0.111213 8.07805 -0.111213 6.91562 1.01333C6.62037 1.29895 6.20983 1.43234 5.80308 1.37481C4.20166 1.1483 2.70917 2.23266 2.42973 3.82569C2.35876 4.23031 2.10503 4.57954 1.74215 4.77207C0.313435 5.53011 -0.256645 7.28464 0.453644 8.73768C0.634053 9.10674 0.634053 9.53841 0.453644 9.90747C-0.256645 11.3605 0.313435 13.115 1.74215 13.8731C2.10503 14.0656 2.35876 14.4148 2.42973 14.8195C2.70917 16.4125 4.20166 17.4968 5.80308 17.2703C6.20983 17.2128 6.62037 17.3462 6.91562 17.6318C8.07805 18.7564 9.92287 18.7564 11.0853 17.6318C11.3806 17.3462 11.7911 17.2128 12.1978 17.2703C13.7993 17.4968 15.2917 16.4125 15.5712 14.8195C15.6422 14.4148 15.8959 14.0656 16.2588 13.8731C17.6875 13.115 18.2576 11.3605 17.5473 9.90747C17.3669 9.53841 17.3669 9.10674 17.5473 8.73768C18.2576 7.28464 17.6875 5.53011 16.2588 4.77207C15.8959 4.57954 15.6422 4.23031 15.5712 3.82569C15.2917 2.23266 13.7993 1.1483 12.1978 1.37481C11.7911 1.43234 11.3806 1.29895 11.0853 1.01333ZM8.07444 2.2112C8.59076 1.71172 9.41016 1.71172 9.92648 2.2112C10.5912 2.85426 11.5155 3.15458 12.4313 3.02505C13.1426 2.92444 13.8055 3.40608 13.9296 4.11365C14.0894 5.02462 14.6606 5.81087 15.4776 6.24435C16.1122 6.58104 16.3654 7.36034 16.0499 8.00573C15.6438 8.83665 15.6438 9.8085 16.0499 10.6394C16.3654 11.2848 16.1122 12.0641 15.4776 12.4008C14.6606 12.8343 14.0894 13.6205 13.9296 14.5315C13.8055 15.2391 13.1426 15.7207 12.4313 15.6201C11.5155 15.4906 10.5912 15.7909 9.92648 16.434C9.41016 16.9334 8.59076 16.9334 8.07444 16.434C7.40971 15.7909 6.48542 15.4906 5.56966 15.6201C4.85837 15.7207 4.19545 15.2391 4.07134 14.5315C3.91154 13.6205 3.3403 12.8343 2.5233 12.4008C1.88871 12.0641 1.6355 11.2848 1.95099 10.6394C2.35716 9.8085 2.35716 8.83665 1.95099 8.00573C1.6355 7.36034 1.88871 6.58104 2.5233 6.24435C3.3403 5.81087 3.91154 5.02462 4.07134 4.11365C4.19545 3.40608 4.85837 2.92444 5.56966 3.02505C6.48542 3.15458 7.40971 2.85426 8.07444 2.2112Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_tick: (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3559 2.14302C15.7574 1.7638 15.7755 1.13089 15.3963 0.729381C15.0171 0.32787 14.3841 0.309799 13.9826 0.689018L4.96376 9.20717L2.02258 6.42927C1.62107 6.05005 0.988161 6.06811 0.608941 6.46962C0.229721 6.87114 0.247791 7.50404 0.649301 7.88326L4.27712 11.3097C4.66252 11.6737 5.265 11.6737 5.6504 11.3097L15.3559 2.14302Z"
        fill="white"
      />
    </svg>
  ),
  ic_users: (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6673 0C7.90585 0 5.66727 2.23858 5.66727 5C5.66727 7.76142 7.90585 10 10.6673 10C13.4287 10 15.6673 7.76142 15.6673 5C15.6673 2.23858 13.4287 0 10.6673 0ZM7.33394 5C7.33394 3.15905 8.82632 1.66667 10.6673 1.66667C12.5082 1.66667 14.0006 3.15905 14.0006 5C14.0006 6.84095 12.5082 8.33333 10.6673 8.33333C8.82632 8.33333 7.33394 6.84095 7.33394 5Z"
        fill="#98A2B3"
      />
      <path
        d="M5.79677 14.606C5.55413 14.9971 5.0404 15.1174 4.64932 14.8748C4.25824 14.6321 4.1379 14.1184 4.38054 13.7273C5.52171 11.888 7.85626 10 10.6673 10C13.4783 10 15.8128 11.888 16.954 13.7273C17.1967 14.1184 17.0763 14.6321 16.6852 14.8748C16.2942 15.1174 15.7804 14.9971 15.5378 14.606C14.6027 13.0988 12.7376 11.6667 10.6673 11.6667C8.597 11.6667 6.7319 13.0988 5.79677 14.606Z"
        fill="#98A2B3"
      />
      <path
        d="M5.58215 2.96643C5.78479 3.37966 5.61407 3.87891 5.20084 4.08155C4.44362 4.45287 4.0006 5.12649 4.0006 5.83333C4.0006 6.54017 4.44362 7.2138 5.20084 7.58512C5.61407 7.78775 5.78479 8.28701 5.58215 8.70024C5.37952 9.11346 4.88026 9.28418 4.46703 9.08155C3.23636 8.47806 2.33394 7.27794 2.33394 5.83333C2.33394 4.38872 3.23636 3.1886 4.46703 2.58512C4.88026 2.38248 5.37952 2.5532 5.58215 2.96643Z"
        fill="#98A2B3"
      />
      <path
        d="M2.46344 14.606C2.2208 14.9971 1.70707 15.1174 1.31599 14.8748C0.924904 14.6321 0.804568 14.1184 1.04721 13.7273C1.52979 12.9495 2.3019 12.3003 3.1633 11.7966C3.5606 11.5643 4.071 11.698 4.30332 12.0953C4.53564 12.4926 4.40189 13.003 4.00459 13.2354C3.28484 13.6562 2.75528 14.1356 2.46344 14.606Z"
        fill="#98A2B3"
      />
    </svg>
  ),
  ic_user: (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 2.16666C7.69898 2.16666 5.8335 4.03214 5.8335 6.33332C5.8335 8.63451 7.69898 10.5 10.0002 10.5C12.3013 10.5 14.1668 8.63451 14.1668 6.33332C14.1668 4.03214 12.3013 2.16666 10.0002 2.16666ZM7.50016 6.33332C7.50016 4.95261 8.61945 3.83332 10.0002 3.83332C11.3809 3.83332 12.5002 4.95261 12.5002 6.33332C12.5002 7.71404 11.3809 8.83332 10.0002 8.83332C8.61945 8.83332 7.50016 7.71404 7.50016 6.33332Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 19.6667C8.71641 19.6667 6.85834 19.3752 5.46302 18.7645C4.77802 18.4647 4.08137 18.0356 3.67662 17.4145C3.46376 17.0878 3.32919 16.7031 3.3336 16.2735C3.33797 15.8479 3.47803 15.4401 3.71343 15.0607C4.85459 13.2213 7.18915 11.3333 10.0002 11.3333C12.8112 11.3333 15.1457 13.2213 16.2869 15.0607C16.5223 15.4401 16.6624 15.8479 16.6667 16.2735C16.6711 16.7031 16.5366 17.0878 16.3237 17.4145C15.919 18.0356 15.2223 18.4647 14.5373 18.7645C13.142 19.3752 11.2839 19.6667 10.0002 19.6667ZM5.12966 15.9393C5.01993 16.1162 5.0008 16.2298 5.00018 16.2906C4.9996 16.3475 5.01422 16.4144 5.07297 16.5045C5.21145 16.717 5.54772 16.9822 6.13127 17.2376C7.27303 17.7374 8.89453 18 10.0002 18C11.1058 18 12.7273 17.7374 13.8691 17.2376C14.4526 16.9822 14.7889 16.717 14.9274 16.5045C14.9861 16.4144 15.0007 16.3475 15.0001 16.2906C14.9995 16.2298 14.9804 16.1162 14.8707 15.9393C13.9355 14.4321 12.0704 13 10.0002 13C7.92988 13 6.06478 14.4321 5.12966 15.9393Z"
        fill="currentColor"
      />
    </svg>
  ),
  ic_web_link: (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9987 0.333984C4.93609 0.333984 0.832031 4.43804 0.832031 9.50065C0.832031 14.5633 4.93609 18.6673 9.9987 18.6673C15.0613 18.6673 19.1654 14.5633 19.1654 9.50065C19.1654 4.43804 15.0613 0.333984 9.9987 0.333984ZM3.4648 8.91924L2.49933 9.40197C2.52187 7.65418 3.14226 6.05055 4.16536 4.78621V6.46727L3.4648 8.91924ZM5.83203 3.26361C7.02378 2.46588 8.45691 2.00065 9.9987 2.00065C14.0277 2.00065 17.3146 5.17753 17.4912 9.16294L14.6194 12.0348L14.2115 15.7066C13.0107 16.5233 11.5604 17.0006 9.9987 17.0006C6.43112 17.0006 3.44534 14.5097 2.68565 11.1722L4.86593 10.0821L5.17972 8.98377C5.0599 9.67513 5.38293 10.4161 6.07681 10.7631L7.94897 11.6991L8.14446 14.6316C8.25522 16.293 10.4654 16.7829 11.2678 15.3239L14.296 9.8181C14.5284 9.39562 14.5664 8.89321 14.4002 8.44058L13.7003 6.53388C13.4225 5.77699 12.6427 5.32751 11.8485 5.46648L8.03115 6.1344C7.6935 6.19348 7.38227 6.35523 7.13989 6.59761L5.64366 8.09385C5.4789 8.25861 5.35711 8.44651 5.27636 8.64554L5.83203 6.7007V3.26361ZM16.2113 12.7999L17.1044 11.9068C16.8642 12.6163 16.5208 13.2782 16.0919 13.8745L16.2113 12.7999ZM8.69433 10.2084L6.82217 9.27236L8.3184 7.77612L12.1357 7.1082L12.8356 9.0149L9.80744 14.5207L9.61195 11.5883C9.5726 10.9981 9.22338 10.473 8.69433 10.2084Z"
        fill="#98A2B3"
      />
    </svg>
  ),
};

export default Icons;
