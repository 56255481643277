import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setSearchUserText,
} from "../../../../config/reducers/adminSlice";
import useUser from "../../../../hooks/useUser";

export default function useAdminTable() {
  const { getAllAdmins, createAdmin } = useUser();
  const { team, loading, searchUserText, currentTeamPage } = useSelector(
    (state) => state.admin
  );
  const dispatch = useDispatch();

  const handleSearch = (value) =>
    dispatch(setSearchUserText({ searchUserText: value }));

  useEffect(() => {
    dispatch(setLoading({ loading: true }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getAllAdmins,
    createAdmin,
    team,
    loading,
    searchUserText,
    currentTeamPage,
    handleSearch,
  };
}
