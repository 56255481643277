import Loader from "./loader";

const Button = ({
  children,
  onClick,
  fullWidth,
  height,
  className,
  disabled,
  loading,
}) => (
  <button
    className={`text-sm uppercase font-semibold tracking-[1.68px] rounded-lg py-2 px-4 pointer  text-white 
    ${fullWidth ? "w-full" : "w-[220px]"} 
    ${height ? height : "h-[56px]"}
    ${className} 
    ${disabled ? "bg-grey-300" : "bg-primary-1"}`}
    onClick={onClick}
    disabled={disabled}
  >
    {loading ? <Loader /> : children}
  </button>
);

export default Button;
