import React from "react";
import { Form, Formik, getIn } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { siteVisitPowerValidationSchema } from "../../../../../utils/validation";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import useSiteVisitQuestionnaire from "../useSiteVisitQuestionnaire";
import Radio from "../../../../ui/radio";
import AdminComment from "../../../../ui/adminComment";
import { USER_TYPE } from "../../../../../utils/constants";

export default function PowerSiteVisitQuestionnaire({ handleStageSixStep }) {
  const { user } = useSelector((state) => state.auth);
  const { currentUserId } = useSelector((state) => state.admin);

  const {
    disableForm,
    loading,
    initialValues,
    handleSaveSiteVisitQuestionnaire,
    // handleSubmitSiteVisitQuestionnaire,
    handleApproveSiteVisitQuestionnaire,
    status,
    siteVisitQuestionnaire,
    siteVisit,

    canAdminComment,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useSiteVisitQuestionnaire(handleStageSixStep);

  return (
    <Formik
      initialValues={initialValues.power}
      validationSchema={siteVisitPowerValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <FormContainer
              type="user"
              width
              title="1. Site Specification and Proximity Inquiry"
            >
              <>
                <SectionHeader title="Your coordinates*" />
                <AdminComment
                  name="coordinates"
                  adminComment={adminComments?.power.coordinates || ""}
                  isAdminCommentAvailable={
                    adminComments?.power.coordinates ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="coordinates"
                    id="coordinates"
                    value={values.coordinates}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.coordinates && touched.coordinates
                        ? errors.coordinates
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.coordinates &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader border title="Layout map" />
                <AdminComment
                  name="layoutMap"
                  adminComment={adminComments?.power.layoutMap || ""}
                  isAdminCommentAvailable={
                    adminComments?.power.layoutMap ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <FileUpload
                    name="layoutMap"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={values.layoutMap}
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.layoutMap && touched.layoutMap
                        ? getIn(errors, `layoutMap`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.layoutMap &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader border title="Plot plan" />
                <AdminComment
                  name="plotPlan"
                  adminComment={adminComments?.power.plotPlan || ""}
                  isAdminCommentAvailable={
                    adminComments?.power.plotPlan ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <FileUpload
                    name="plotPlan"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={values.plotPlan}
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.plotPlan && touched.plotPlan
                        ? getIn(errors, `plotPlan`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.plotPlan &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Land size showing your coordinates"
                />
                <AdminComment
                  name="landSizeDoc"
                  adminComment={adminComments?.power.landSizeDoc || ""}
                  isAdminCommentAvailable={
                    adminComments?.power.landSizeDoc ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <FileUpload
                    name="landSizeDoc"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={values.landSizeDoc}
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.landSizeDoc && touched.landSizeDoc
                        ? getIn(errors, `landSizeDoc`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.landSizeDoc &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Proximity to gas suppliers (processing plant, pipeline, or metering station)*"
                />
                <AdminComment
                  name="proximityToGasSupplier"
                  adminComment={
                    adminComments?.power.proximityToGasSupplier || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.proximityToGasSupplier ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="proximityToGasSupplier"
                    id="proximityToGasSupplier"
                    value={values.proximityToGasSupplier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.proximityToGasSupplier &&
                      touched.proximityToGasSupplier
                        ? errors.proximityToGasSupplier
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.proximityToGasSupplier &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>
            <FormContainer
              type="user"
              width
              title="2. Facility Layout and Infrastructure Details"
            >
              <SectionHeader title="Where will your turbines, control room, clinic, and other facilities be located?*" />
              <AdminComment
                name="facilityLayout"
                adminComment={adminComments?.power.facilityLayout || ""}
                isAdminCommentAvailable={
                  adminComments?.power.facilityLayout ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="power"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="facilityLayout"
                  id="facilityLayout"
                  value={values.facilityLayout}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.facilityLayout && touched.facilityLayout
                      ? errors.facilityLayout
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.power.facilityLayout &&
                      siteVisitQuestionnaire?.status === "feedback")
                  }
                />
              </AdminComment>
            </FormContainer>
            <FormContainer type="user" width title="3. Road Access">
              <SectionHeader title="Can you confirm your road network (internal and access roads)?*" />
              <AdminComment
                name="roadAccess"
                adminComment={adminComments?.power.roadAccess || ""}
                isAdminCommentAvailable={
                  adminComments?.power.roadAccess ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="power"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="roadAccess"
                  id="roadAccess"
                  value={values.roadAccess}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.roadAccess && touched.roadAccess
                      ? errors.roadAccess
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.power.roadAccess &&
                      siteVisitQuestionnaire?.status === "feedback")
                  }
                />
              </AdminComment>
            </FormContainer>
            <FormContainer type="user" width title="4. Power Evacuation">
              <>
                <SectionHeader title="What is your power evacuation plan?*" />
                <AdminComment
                  name="evacuationPlan.powerEvacuationPlan"
                  adminComment={
                    adminComments?.power.evacuationPlan.powerEvacuationPlan ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.evacuationPlan.powerEvacuationPlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="evacuationPlan.powerEvacuationPlan"
                    id="evacuationPlan.powerEvacuationPlan"
                    value={values.evacuationPlan.powerEvacuationPlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "evacuationPlan.powerEvacuationPlan") &&
                          getIn(touched, "evacuationPlan.powerEvacuationPlan")
                      )
                        ? getIn(errors, "evacuationPlan.powerEvacuationPlan")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.evacuationPlan
                        .powerEvacuationPlan &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="If there is a problem evacuating to the grid, what alternative evacuation plan do you have? i.e. can you supply any other user, apart from the grid (Are there other commercial users?)*"
                />
                <AdminComment
                  name="evacuationPlan.alternativeEvacuationPlan"
                  adminComment={
                    adminComments?.power.evacuationPlan
                      .alternativeEvacuationPlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.evacuationPlan
                      .alternativeEvacuationPlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="evacuationPlan.alternativeEvacuationPlan"
                    id="evacuationPlan.alternativeEvacuationPlan"
                    value={values.evacuationPlan.alternativeEvacuationPlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "evacuationPlan.alternativeEvacuationPlan"
                        ) &&
                          getIn(
                            touched,
                            "evacuationPlan.alternativeEvacuationPlan"
                          )
                      )
                        ? getIn(
                            errors,
                            "evacuationPlan.alternativeEvacuationPlan"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.evacuationPlan
                        .alternativeEvacuationPlan &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="What is your backup evacuation strategy, if your main evacuation strategy is down?*" />
                <AdminComment
                  name="evacuationPlan.backupEvacuationStrategy"
                  adminComment={
                    adminComments?.power.evacuationPlan
                      .backupEvacuationStrategy || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.evacuationPlan.backupEvacuationStrategy
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="evacuationPlan.backupEvacuationStrategy"
                    id="evacuationPlan.backupEvacuationStrategy"
                    value={values.evacuationPlan.backupEvacuationStrategy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "evacuationPlan.backupEvacuationStrategy"
                        ) &&
                          getIn(
                            touched,
                            "evacuationPlan.backupEvacuationStrategy"
                          )
                      )
                        ? getIn(
                            errors,
                            "evacuationPlan.backupEvacuationStrategy"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.evacuationPlan
                        .backupEvacuationStrategy &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>
            <FormContainer
              type="user"
              width
              title="5. Project and Emergency Planning"
            >
              <>
                <SectionHeader title="In case of gas availability issues, what alternative fuel are you considering and what is the storage plan of the Alternative fuel? (Diesel, Coal, etc). How will you switch from gas to an alternative?*" />
                <AdminComment
                  name="emergencyPlanning.alternativeFuel"
                  adminComment={
                    adminComments?.power.emergencyPlanning.alternativeFuel || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.emergencyPlanning.alternativeFuel
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="emergencyPlanning.alternativeFuel"
                    id="emergencyPlanning.alternativeFuel"
                    value={values.emergencyPlanning.alternativeFuel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "emergencyPlanning.alternativeFuel") &&
                          getIn(touched, "emergencyPlanning.alternativeFuel")
                      )
                        ? getIn(errors, "emergencyPlanning.alternativeFuel")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.emergencyPlanning
                        .alternativeFuel &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border />
                <AdminComment
                  name="emergencyPlanning.expansionPlan"
                  adminComment={
                    adminComments?.power.emergencyPlanning.expansionPlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.emergencyPlanning.expansionPlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                  marginTop
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.emergencyPlanning.expansionPlan}
                    onChange={handleChange}
                    name="emergencyPlanning.expansionPlan"
                    title="Do you have an expansion plan?*"
                    errors={
                      Boolean(
                        getIn(errors, "emergencyPlanning.expansionPlan") &&
                          getIn(touched, "emergencyPlanning.expansionPlan")
                      )
                        ? getIn(errors, "emergencyPlanning.expansionPlan")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.emergencyPlanning.expansionPlan &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              {values.emergencyPlanning.expansionPlan === "Yes" && (
                <>
                  <SectionHeader
                    border
                    title="If yes, what is the plan and when will you commence the expansion?*"
                  />
                  <AdminComment
                    name="emergencyPlanning.expansionTimeline"
                    adminComment={
                      adminComments?.power.emergencyPlanning
                        .expansionTimeline || ""
                    }
                    isAdminCommentAvailable={
                      adminComments?.power.emergencyPlanning.expansionTimeline
                        ? true
                        : false
                    }
                    canAdminComment={canAdminComment}
                    allComments={adminComments}
                    setAdminComments={setAdminComments}
                    setIsCommentNonNull={setIsCommentNonNull}
                    type="power"
                  >
                    <Textarea
                      placeholder="Enter description here"
                      name="emergencyPlanning.expansionTimeline"
                      id="emergencyPlanning.expansionTimeline"
                      value={values.emergencyPlanning.expansionTimeline}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={
                        Boolean(
                          getIn(
                            errors,
                            "emergencyPlanning.expansionTimeline"
                          ) &&
                            getIn(
                              touched,
                              "emergencyPlanning.expansionTimeline"
                            )
                        )
                          ? getIn(errors, "emergencyPlanning.expansionTimeline")
                          : ""
                      }
                      disabled={
                        disableForm ||
                        (!adminComments?.power.emergencyPlanning
                          .expansionTimeline &&
                          siteVisitQuestionnaire?.status === "feedback")
                      }
                    />
                  </AdminComment>
                </>
              )}
              <>
                <SectionHeader
                  border
                  title="Can you confirm the employment opportunities your project will generate for locals and others and the category of the Jobs?*"
                />
                <AdminComment
                  name="emergencyPlanning.employmentOpportunities"
                  adminComment={
                    adminComments?.power.emergencyPlanning
                      .employmentOpportunities || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.emergencyPlanning
                      .employmentOpportunities
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="emergencyPlanning.employmentOpportunities"
                    id="emergencyPlanning.employmentOpportunities"
                    value={values.emergencyPlanning.employmentOpportunities}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "emergencyPlanning.employmentOpportunities"
                        ) &&
                          getIn(
                            touched,
                            "emergencyPlanning.employmentOpportunities"
                          )
                      )
                        ? getIn(
                            errors,
                            "emergencyPlanning.employmentOpportunities"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.emergencyPlanning
                        .employmentOpportunities &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Can you confirm what portion of the power generated will be for your own use and what portion will go to the grid?*"
                />
                <AdminComment
                  name="emergencyPlanning.powerDistribution"
                  adminComment={
                    adminComments?.power.emergencyPlanning.powerDistribution ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.emergencyPlanning.powerDistribution
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="emergencyPlanning.powerDistribution"
                    id="emergencyPlanning.powerDistribution"
                    value={values.emergencyPlanning.powerDistribution}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "emergencyPlanning.powerDistribution") &&
                          getIn(touched, "emergencyPlanning.powerDistribution")
                      )
                        ? getIn(errors, "emergencyPlanning.powerDistribution")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.emergencyPlanning
                        .powerDistribution &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Can you provide a detailed explanation of your emergency shutdown plan, in case of fire and any emergencies?"
                />
                <AdminComment
                  name="emergencyPlanning.emergencyShutdownPlan"
                  adminComment={
                    adminComments?.power.emergencyPlanning
                      .emergencyShutdownPlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.emergencyPlanning.emergencyShutdownPlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="emergencyPlanning.emergencyShutdownPlan"
                    id="emergencyPlanning.emergencyShutdownPlan"
                    value={values.emergencyPlanning.emergencyShutdownPlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "emergencyPlanning.emergencyShutdownPlan"
                        ) &&
                          getIn(
                            touched,
                            "emergencyPlanning.emergencyShutdownPlan"
                          )
                      )
                        ? getIn(
                            errors,
                            "emergencyPlanning.emergencyShutdownPlan"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.emergencyPlanning
                        .emergencyShutdownPlan &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border />
                <AdminComment
                  name="emergencyPlanning.thirdPartyPower"
                  adminComment={
                    adminComments?.power.emergencyPlanning.thirdPartyPower || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.emergencyPlanning.thirdPartyPower
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                  marginTop
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.emergencyPlanning.thirdPartyPower}
                    onChange={handleChange}
                    name="emergencyPlanning.thirdPartyPower"
                    title="Is your company willing for the situation of third-party power solutions within the company’s premises?*"
                    errors={
                      Boolean(
                        getIn(errors, "emergencyPlanning.thirdPartyPower") &&
                          getIn(touched, "emergencyPlanning.thirdPartyPower")
                      )
                        ? getIn(errors, "emergencyPlanning.thirdPartyPower")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.emergencyPlanning
                        .thirdPartyPower &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Is there availability of space to situate a power solution (please specify the area)?"
                />
                <AdminComment
                  name="emergencyPlanning.thirdPartyPowerSpace"
                  adminComment={
                    adminComments?.power.emergencyPlanning
                      .thirdPartyPowerSpace || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.emergencyPlanning.thirdPartyPowerSpace
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="emergencyPlanning.thirdPartyPowerSpace"
                    id="emergencyPlanning.thirdPartyPowerSpace"
                    value={values.emergencyPlanning.thirdPartyPowerSpace}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "emergencyPlanning.thirdPartyPowerSpace"
                        ) &&
                          getIn(
                            touched,
                            "emergencyPlanning.thirdPartyPowerSpace"
                          )
                      )
                        ? getIn(
                            errors,
                            "emergencyPlanning.thirdPartyPowerSpace"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.emergencyPlanning
                        .thirdPartyPowerSpace &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>
            <FormContainer
              type="user"
              width
              title="6. Market Experience and Agreements"
            >
              <>
                <SectionHeader title="Where will your meters be located and what type of metering system will you use for the project?*" />
                <AdminComment
                  name="marketExperience.meterLocation"
                  adminComment={
                    adminComments?.power.marketExperience.meterLocation || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.marketExperience.meterLocation
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.meterLocation"
                    id="marketExperience.meterLocation"
                    value={values.marketExperience.meterLocation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "marketExperience.meterLocation") &&
                          getIn(touched, "marketExperience.meterLocation")
                      )
                        ? getIn(errors, "marketExperience.meterLocation")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.marketExperience.meterLocation &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border />
                <AdminComment
                  name="marketExperience.unusedGridSales"
                  adminComment={
                    adminComments?.power.marketExperience.unusedGridSales || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.marketExperience.unusedGridSales
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                  marginTop
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.marketExperience.unusedGridSales}
                    onChange={handleChange}
                    name="marketExperience.unusedGridSales"
                    title="Do you intend to sell off unused grid/captive power?*"
                    errors={
                      Boolean(
                        getIn(errors, "marketExperience.unusedGridSales") &&
                          getIn(touched, "marketExperience.unusedGridSales")
                      )
                        ? getIn(errors, "marketExperience.unusedGridSales")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.marketExperience.unusedGridSales &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="What is your market track record? Have you done this type of project before?*"
                />
                <AdminComment
                  name="marketExperience.marketTrackRecord"
                  adminComment={
                    adminComments?.power.marketExperience.marketTrackRecord ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.marketExperience.marketTrackRecord
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.marketTrackRecord"
                    id="marketExperience.marketTrackRecord"
                    value={values.marketExperience.marketTrackRecord}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "marketExperience.marketTrackRecord") &&
                          getIn(touched, "marketExperience.marketTrackRecord")
                      )
                        ? getIn(errors, "marketExperience.marketTrackRecord")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.marketExperience
                        .marketTrackRecord &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border />
                <AdminComment
                  name="marketExperience.powerPurchaseAgreement"
                  adminComment={
                    adminComments?.power.marketExperience
                      .powerPurchaseAgreement || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.marketExperience.powerPurchaseAgreement
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                  marginTop
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.marketExperience.powerPurchaseAgreement}
                    onChange={handleChange}
                    name="marketExperience.powerPurchaseAgreement"
                    title="Do you have a Power Purchase Agreement (PPA)?*"
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "marketExperience.powerPurchaseAgreement"
                        ) &&
                          getIn(
                            touched,
                            "marketExperience.powerPurchaseAgreement"
                          )
                      )
                        ? getIn(
                            errors,
                            "marketExperience.powerPurchaseAgreement"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.marketExperience
                        .powerPurchaseAgreement &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border />
                <AdminComment
                  name="marketExperience.offtakeAgreement"
                  adminComment={
                    adminComments?.power.marketExperience.offtakeAgreement || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.power.marketExperience.offtakeAgreement
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="power"
                  marginTop
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.marketExperience.offtakeAgreement}
                    onChange={handleChange}
                    name="marketExperience.offtakeAgreement"
                    title="Do you have a direct off-take agreement with off-takers?*"
                    errors={
                      Boolean(
                        getIn(errors, "marketExperience.offtakeAgreement") &&
                          getIn(touched, "marketExperience.offtakeAgreement")
                      )
                        ? getIn(errors, "marketExperience.offtakeAgreement")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.power.marketExperience
                        .offtakeAgreement &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            {user?.role === USER_TYPE.USER || user?.role === USER_TYPE.PROXY
              ? (!siteVisitQuestionnaire ||
                  siteVisitQuestionnaire?.status === "pending" ||
                  siteVisitQuestionnaire?.status === "feedback") && (
                  <div className="flex justify-end gap-6 pt-16">
                    <Button
                      onClick={async () => {
                        setSubmitting(false);
                        const res = await validateForm();
                        if (Object.keys(res).length !== 0)
                          toast.error("Please fill all the required fields");
                        else
                          handleSaveSiteVisitQuestionnaire(
                            "power",
                            values,
                            setSubmitting
                          );
                      }}
                      disabled={!isValid || isSubmitting}
                      className="py-4 px-6 self-end"
                      loading={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                )
              : status !== "approved" && (
                  <div className="flex justify-end gap-6 w-full pt-6">
                    <Button
                      onClick={() =>
                        handleAddAdminComment(
                          adminComments.power,
                          "adminUpdatePower"
                        )
                      }
                      disabled={loadingAddComments || !isCommentNonNull}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700"
                      loading={loadingAddComments}
                    >
                      Submit Comment
                    </Button>
                    <Button
                      onClick={() =>
                        handleApproveSiteVisitQuestionnaire(currentUserId)
                      }
                      disabled={
                        loading ||
                        status === "feedback" ||
                        isCommentNonNull ||
                        siteVisit?.status !== "meeting_held"
                      }
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                      loading={loading}
                    >
                      Approve Questionnaire
                    </Button>
                  </div>
                )}
          </Form>
        );
      }}
    </Formik>
  );
}
