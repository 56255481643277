import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Icons from "../../../../../assets/icons";
import PdfDownload from "../../../../ui/pdfDownload";
import Loader from "../../../../ui/loader";
import ConfirmPaymentDialog from "./confirmPaymentDialog";
import useUser from "../../../../../hooks/useUser";
import { Info } from "../../../General/MeetingInfo";
import { addCommasToNumber } from "../../../../../utils/functions";
import { format } from "date-fns";
import PaymentReceipt from "../../../../ui/paymentReceipt";

export const CompanyInfoNotPaid = ({ details }) => {
  const { bacfPrice } = useSelector((state) => state.user);

  const [isConfirmPaymentDialogOpen, setIsConfirmPaymentDialogOpen] =
    useState(false);

  const toggleConfirmPaymentDialog = (value) =>
    setIsConfirmPaymentDialogOpen(value);

  return (
    <div className="space-y-12 pt-4">
      <div>
        <Info
          title="Company name"
          content={details?.company_name}
          icon={Icons.ic_company}
        />
        <Info
          title="Amount to be paid"
          content={`NGN ${addCommasToNumber(bacfPrice)}`}
          icon={Icons.ic_email}
        />
        <Info
          title="Invoice date"
          content={
            details && format(new Date(details?.updatedAt), "do MMM, yyyy")
          }
          icon={Icons.ic_clock}
        />
      </div>
      <div className="border border-grey-200 bg-grey-50 py-[18px] px-4 rounded-lg !mt-4">
        <p className="text-sm ">
          {" "}
          For external payments,{" "}
          <span
            className="text-primary-1 underline cursor-pointer"
            onClick={() => toggleConfirmPaymentDialog(true)}
          >
            Manually Confirm Payment
          </span>{" "}
          to advance the buyer.
        </p>
      </div>
      <ConfirmPaymentDialog
        isConfirmPaymentDialogOpen={isConfirmPaymentDialogOpen}
        toggleConfirmPaymentDialog={toggleConfirmPaymentDialog}
        companyName={details?.company_name}
        companyId={details?.userId}
      />
    </div>
  );
};

export const CompanyInfoPaid = ({ details }) => {
  const { bacfHistory } = useSelector((state) => state.user);
  const { currentUserId } = useSelector((state) => state.admin);

  const { getLastPaymentHistory } = useUser();
  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    await getLastPaymentHistory(currentUserId);
    setLoading(false);
  };

  useEffect(() => {
    getDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <div className="py-4">
      <Loader />
    </div>
  ) : (
    <div className="space-y-12 pt-4">
      <div>
        <Info
          title="Transaction ID"
          content={bacfHistory?.payment_ref ?? "---"}
          icon={Icons.ic_company}
        />
        <Info
          title="Company name"
          content={details?.company_name}
          icon={Icons.ic_pencil}
        />
        <Info
          title="Payment method"
          content="Bank transfer" // check if there are other payment method ot pay_type
          icon={Icons.ic_money}
        />
        <Info
          title="Payment amount"
          content={`NGN ${addCommasToNumber(bacfHistory?.new_bal)}`}
          icon={Icons.ic_money}
        />
        <Info
          title="Date payment"
          content={
            bacfHistory?.date_of_payment
              ? format(new Date(bacfHistory?.date_of_payment), "do MMM, yyyy")
              : "---"
          }
          icon={Icons.ic_calendar}
        />
      </div>
      <div className=" !mt-4">
        <PdfDownload
          type="pdf"
          title="Payment receipt"
          darkBg
          paymentBtn={<PaymentReceipt paymentDetails={bacfHistory} />}
        />
      </div>
    </div>
  );
};
