import React, { useState } from "react";
import NDAStatus from "./status";
import NDAForm from "./ndaForm";
import { useSelector } from "react-redux";

export default function StageThree() {
  const { userRequest } = useSelector((state) => state.user);

  const [stageThreeStep, setStageThreeStep] = useState(
    userRequest?.nda_status === "pending" ? 1 : 2
  );
  const handleStageThreeStep = (value) => setStageThreeStep(value);
  return (
    <div className="">
      {stageThreeStep === 1 && (
        <NDAForm handleStageThreeStep={handleStageThreeStep} />
      )}
      {stageThreeStep === 2 && (
        <NDAStatus handleStageThreeStep={handleStageThreeStep} />
      )}
    </div>
  );
}
