import React from "react";

function Dialog({ isDialogModalOpen, children, maxWidth }) {
  return (
    <section
      className={`bg-[#00000080] !m-0 w-full fixed top-0 left-0 z-[999999] transition-all ease-in-out duration-300  ${
        isDialogModalOpen
          ? "visible min-h-screen h-full opacity-100"
          : "invisible min-h-0 opacity-0"
      }`}
    >
      <div className="flex justify-center items-center h-full w-full px-4 py-8 overflow-auto">
        <div
          className={`relative w-full ${maxWidth ? maxWidth : "max-w-[448px]"}`}
        >
          {children}
        </div>
      </div>
    </section>
  );
}

export default Dialog;
