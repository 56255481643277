import { format } from "date-fns";
import { USER_TYPE } from "../../../utils/constants";
import MoreButton from "./dropDown";
import RemoveButton from "./removeButton";

const TableRowCellDesktop = ({ content, className }) => (
  <td
    className={`px-6 py-[26px] whitespace-nowrap w-full text-sm text-grey-700 ${
      className ?? ""
    }`}
  >
    {content}
  </td>
);

const TableRowDesktop = ({
  items,
  handleDeleteUser,
  resendInvite,
  resendInviteLoading,
}) => {
  return (
    <tr>
      <TableRowCellDesktop
        content={items?.fullName}
        className="text-grey-900 font-medium"
      />
      <TableRowCellDesktop content={items?.email} />
      <TableRowCellDesktop
        content={items?.role === USER_TYPE.PROXY ? "User" : "Admin"}
        className="capitalize"
      />
      <TableRowCellDesktop
        content={
          items?.createdAt && format(new Date(items?.createdAt), "LLL dd, yyyy")
        }
      />
      <TableRowCellDesktop
        content={items?.isActive ? "Active" : "Pending"}
        className={items?.isActive ? "text-primary-1" : "!text-grey-400"}
      />
      <TableRowCellDesktop
        content={
          !items?.isActive ? (
            <MoreButton
              removeUser={() => handleDeleteUser(items?._id)}
              resendInvite={() => resendInvite(items?.email)}
              resendInviteLoading={resendInviteLoading}
            />
          ) : items?.role !== USER_TYPE.SUPER_ADMIN &&
            items?.role !== USER_TYPE.USER ? (
            <RemoveButton onClick={() => handleDeleteUser(items?._id)} />
          ) : (
            ""
          )
        }
      />
    </tr>
  );
};

export default TableRowDesktop;
