import { useEffect, useState } from "react";
import Button from "../../../../ui/button";
import Modal from "../../../../ui/modal";
import {
  checkIfInputIsNum,
  generateIndexArray,
} from "../../../../../utils/functions";

const tableHead = [
  { title: "⁠No. of Years", type: "years", info: "" },
  { title: "Daily quantity", type: "daily", info: "(MMscfd)" },
  { title: "Annual quantity", type: "annual", info: "(Bcf)" },
];

function RequestModal({
  value,
  name,
  setValue,
  setTouched,
  years,
  toggleRequestModal,
  isRequestModalOpen,
  disabled,
}) {
  const [quantities, setQuantities] = useState(value);
  const [inputValues, setInputValues] = useState({});
  const [totalAnnualQuantity, setTotalAnnualQuantity] = useState(0);
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  const indexArray = generateIndexArray(years);

  useEffect(() => {
    if (value.length > 0) {
      const updatedQuantities = value.map((item, index) => ({
        ...item,
        index: index,
      }));
      setQuantities(updatedQuantities);

      const updatedInputValues = {};
      value.forEach((item, index) => {
        updatedInputValues[`years-${index}`] = item.years || "";
        updatedInputValues[`daily-${index}`] = item.dailyQuantity || "";
        updatedInputValues[`annual-${index}`] = item.annualQuantity || "";
      });
      setInputValues(updatedInputValues);
      setTotalAnnualQuantity(
        value.reduce((total, item) => total + item.annualQuantity, 0)
      );
    }
  }, [value]);

  useEffect(() => {
    const allFieldsFilled = indexArray.every((index) => {
      const years = inputValues[`years-${index}`];
      const daily = inputValues[`daily-${index}`];
      const annual = inputValues[`annual-${index}`];
      return years && daily && annual;
    });
    setIsContinueDisabled(!allFieldsFilled);
  }, [inputValues, indexArray]);

  const handleQuantityChange = (index, type, quantityValue) => {
    quantityValue = parseInt(quantityValue, 10);
    const updatedQuantities = [...quantities];

    const itemIndex = updatedQuantities.findIndex(
      (item) => item.index === index
    );

    if (itemIndex !== -1) {
      updatedQuantities[itemIndex] = {
        ...updatedQuantities[itemIndex],
        [type === "years"
          ? "years"
          : type === "daily"
          ? "dailyQuantity"
          : "annualQuantity"]: quantityValue ? quantityValue : 0,
      };
    } else {
      updatedQuantities.push({
        index: index,
        years: type === "years" ? quantityValue : null,
        dailyQuantity: type === "daily" ? quantityValue : null,
        annualQuantity: type === "annual" ? quantityValue : null,
      });
    }

    setQuantities(updatedQuantities);
    setTotalAnnualQuantity(
      updatedQuantities.reduce((total, item) => total + item.annualQuantity, 0)
    );
    setInputValues({
      ...inputValues,
      [`${type}-${index}`]: quantityValue,
    });
  };

  const handleSubmit = async () => {
    const valuesWithoutIndex = quantities.map(({ index, ...rest }) => rest);
    setValue(name, valuesWithoutIndex);
    setTouched(name, true);
    toggleRequestModal(false);
  };

  return (
    <Modal
      isModalOpen={isRequestModalOpen}
      onClick={() => toggleRequestModal(false)}
      width="max-w-[667px]"
    >
      <div className="bg-white rounded-lg w-full max-w-[667px] p-6 space-y-8">
        <div className="space-y-4 text-center">
          <h2 className="text-h2 text-black">Contract Quantity Submission</h2>
          <p className="text-sm">
            Complete Your Daily and Yearly Supply Forecast
          </p>
        </div>
        <div>
          <table className="w-full text-sm text-black">
            <thead>
              {tableHead.map((item) => (
                <th
                  key={item.title}
                  scope="col"
                  className="bg-grey-50 border border-grey-200 py-3 px-6 text-grey-700"
                >
                  {item.title}{" "}
                  <span className="font-normal">
                    - {item.type === "years" ? `${years}yrs` : item.info}
                  </span>
                </th>
              ))}
            </thead>
            <tbody>
              {indexArray?.map((index) => (
                <tr key={index}>
                  <td className="border border-grey-200 py-4 px-6 font-medium">
                    <input
                      value={inputValues[`years-${index}`] || ""}
                      type="number"
                      name={`years-${index}`}
                      className="border-none p-0 w-full appearance-none focus:outline-none text-sm"
                      placeholder="Enter Year"
                      onChange={(event) =>
                        handleQuantityChange(index, "years", event.target.value)
                      }
                      min={0}
                      onKeyDown={checkIfInputIsNum}
                      disabled={disabled}
                    />
                  </td>
                  <td className="border border-grey-200 py-4 px-6">
                    <input
                      value={inputValues[`daily-${index}`] || ""}
                      type="number"
                      name={`daily-${index}`}
                      className="border-none p-0 w-full appearance-none focus:outline-none text-sm"
                      placeholder="Enter Qty"
                      onChange={(event) =>
                        handleQuantityChange(index, "daily", event.target.value)
                      }
                      min={0}
                      onKeyDown={checkIfInputIsNum}
                      disabled={disabled}
                    />
                  </td>
                  <td className="border border-grey-200 py-4 px-6">
                    <input
                      value={inputValues[`annual-${index}`] || ""}
                      type="number"
                      name={`annual-${index}`}
                      className="border-none p-0 w-full appearance-none focus:outline-none text-sm"
                      placeholder="Enter Qty"
                      onChange={(event) =>
                        handleQuantityChange(
                          index,
                          "annual",
                          event.target.value
                        )
                      }
                      min={0}
                      onKeyDown={checkIfInputIsNum}
                      disabled={disabled}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="bg-grey-50 border border-grey-200 border-t-grey-250 py-3 px-6 text-grey-700 font-medium flex justify-between">
            <p>Total contract volume:</p>
            <p>{totalAnnualQuantity} Vl</p>
          </div>
        </div>
        {!disabled && (
          <div className="flex gap-6">
            <Button
              type="button"
              onClick={() => toggleRequestModal(false)}
              className="bg-transparent border border-grey-200 !text-black w-full"
            >
              Cancel
            </Button>
            <Button
              disabled={isContinueDisabled || disabled}
              type="button"
              onClick={handleSubmit}
              className="w-full"
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default RequestModal;
