import PasswordForm from "../../AuthForm/passwordForm";
import Modal from "../../ui/modal";

function PasswordModal({
  togglePasswordModal,
  isPasswordModalOpen,
  password,
  type,
}) {
  return (
    <Modal
      isModalOpen={isPasswordModalOpen}
      onClick={() => togglePasswordModal(false)}
    >
      <div className="bg-white rounded-lg w-full max-w-[448px] p-6 space-y-8">
        <div className="space-y-4 text-center">
          <h2 className="text-h2 text-black">Create new password</h2>
          <p className="text-sm">Create a new password to your account</p>
        </div>
        <PasswordForm
          type="changePassword"
          otherValues={password}
          accountType={type}
          togglePasswordModal={togglePasswordModal}
        />
      </div>
    </Modal>
  );
}

export default PasswordModal;
