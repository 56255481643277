import React from "react";
import DashboardLayout from "../../../components/Layout/dashboardLayout";
import Tab from "../../../components/ui/tab";
import useTab from "../../../hooks/useTab";
import AdminGasRequests from "../../../components/DueDiligence/Admin";

export default function AdminDueDiligence() {
  const tabsItems = ["All", "Not Started", "In Progress", "Completed"];
  const { tabs, activeTab, handleTabClick } = useTab(tabsItems);

  return (
    <DashboardLayout
      type="admin"
      title="Due Diligence"
      description="Explore details and current statuses of all companies currently under due diligence"
    >
      <Tab tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
      <div
        className={`border border-grey-200 rounded-lg  w-full space-y-6 bg-transaprent md:bg-white border-none md:border md:border-grey-200`}
      >
        <AdminGasRequests activeTab={activeTab} />
      </div>
    </DashboardLayout>
  );
}
