import React, { useState } from "react";
import { itemsPerPage } from "../../../utils/constants";
import TableHead from "./tableHead";
import TableRowDesktop from "./tableRowDesktop";
import TableRowMobile from "./tableRowMobile";
import Pagination from "../pagination";
import usePagination from "../../../hooks/usePagination";
import DeleteUserDialog from "../../Account/deleteUserDialog";

export default function Table({
  items,
  type,
  role,
  resendInvite,
  searchUserText,
  totalTeam,
  currentTeamPage,
  setCurrentTeamPage,
}) {
  const { currentItems, pageCount, handlePageClick } = usePagination(
    items,
    itemsPerPage,
    searchUserText,
    totalTeam,
    setCurrentTeamPage
  );

  const [resendInviteLoading, setResendInviteLoading] = useState(false);
  const [userTobeDeleted, setUserToBeDeleted] = useState("");
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);

  const toggleDeleteUserDialog = (value) => setIsDeleteUserDialogOpen(value);

  const handleDeleteUser = (id) => {
    setUserToBeDeleted(id);
    toggleDeleteUserDialog(true);
  };

  const handleResendAdminInvite = async (email) => {
    setResendInviteLoading(true);
    await resendInvite(email);
    setResendInviteLoading(false);
  };

  return (
    <Pagination
      curentPage={currentTeamPage}
      pageCount={pageCount}
      handlePageClick={handlePageClick}
    >
      <div className="overflow-auto">
        <table className="hidden md:block w-full min-w-full divide-y divide-grey-200 border-t border-grey-200 bg-white md:mt-7">
          <TableHead />
          <tbody className="divide-y divide-grey-200 ">
            {currentItems?.map((item, index) => (
              <TableRowDesktop
                key={index}
                items={item}
                handleDeleteUser={handleDeleteUser}
                resendInvite={handleResendAdminInvite}
                resendInviteLoading={resendInviteLoading}
              />
            ))}
          </tbody>
        </table>
        <div className="block md:hidden space-y-4">
          {currentItems?.map((item, index) => (
            <TableRowMobile
              key={index}
              items={item}
              handleDeleteUser={handleDeleteUser}
              resendInvite={handleResendAdminInvite}
              resendInviteLoading={resendInviteLoading}
            />
          ))}
        </div>
        <DeleteUserDialog
          toggleDeleteUserDialog={toggleDeleteUserDialog}
          isDeleteUserDialogOpen={isDeleteUserDialogOpen}
          userTobeDeleted={userTobeDeleted}
          team={items}
          role={role}
          type={type}
        />
      </div>
    </Pagination>
  );
}
