import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { passwordValidation } from "../../utils/validation";
import Input from "../ui/input";
import Icons from "../../assets/icons";
import Button from "../ui/button";
import useAuthentication from "../../hooks/useAuthentication";
import useUser from "../../hooks/useUser";

const PasswordForm = ({
  otherValues,
  type,
  accountType,
  togglePasswordModal,
}) => {
  const {
    isPasswordHidden,
    isConfirmPasswordHidden,
    toggleHiddenPassword,
    toggleHiddenConfirmPassword,
    isLengthValid,
    isCaseValid,
    isCharacterValid,
    createUserAccount,
    resetPassword,
  } = useAuthentication();

  const { changePassword } = useUser();
  const [isPasswordChecked, setIsPasswordChecked] = useState(false);

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        checked: { length: false, letters: false, numbers: false },
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        if (type === "creatAccount") {
          await createUserAccount({
            ...otherValues,
            password: values.password,
          });
          setSubmitting(false);
        }

        if (type === "resetPassword") {
          await resetPassword(
            {
              ...otherValues,
              password: values.password,
            },
            accountType
          );
          setSubmitting(false);
        }

        if (type === "changePassword") {
          const isPasswordChanged = await changePassword({
            currentPassword: otherValues.currentPassword,
            newPassword: values.password,
          });
          if (isPasswordChanged) {
            togglePasswordModal(false);
          }

          setSubmitting(false);
        }
      }}
      validationSchema={passwordValidation}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          dirty,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
        } = props;

        return (
          <Form className="flex flex-col gap-16">
            <div className="flex flex-col gap-6 max-h-[336px] md:max-h-[374px] overflow-auto scrollbar ">
              <Input
                title="PASSWORD"
                icon={
                  isPasswordHidden ? Icons.ic_eye_closed : Icons.ic_eye_open
                }
                placeholder="Enter password"
                type={isPasswordHidden ? "password" : "text"}
                name="password"
                id="password"
                value={values.password}
                onChange={(event) => {
                  handleChange(event);
                  const lengthValid = isLengthValid(event.target.value);
                  const caseValid = isCaseValid(event.target.value);
                  const characterValid = isCharacterValid(event.target.value);
                  setFieldValue("checked.length", lengthValid);
                  setFieldValue("checked.letters", caseValid);
                  setFieldValue("checked.numbers", characterValid);
                }}
                onBlur={handleBlur}
                errors={
                  errors.password && touched.password ? errors.password : ""
                }
                toggleHiddenPassword={toggleHiddenPassword}
              />
              {!isPasswordChecked && values.password && (
                <div
                  role="group"
                  aria-labelledby="checkbox-group"
                  className="flex flex-col text-left text-xs leading-[17.4px] space-y-3"
                >
                  <p className="text-black">Password requirement</p>
                  <label className="relative">
                    <Field
                      type="checkbox"
                      name="checked.length"
                      disabled
                      className="appearance-none w-4 h-4"
                    />
                    {values.checked.length
                      ? Icons.ic_checked
                      : Icons.ic_not_checked}
                    <span className="ml-2">Minimum of 8 characters.</span>
                  </label>
                  <label className="relative">
                    <Field
                      type="checkbox"
                      name="checked.letters"
                      className="appearance-none w-4 h-4"
                      disabled
                    />
                    {values.checked.letters
                      ? Icons.ic_checked
                      : Icons.ic_not_checked}
                    <span className="ml-2">
                      A combination of uppercase and lowercase letters.
                    </span>
                  </label>
                  <label className="relative">
                    <Field
                      type="checkbox"
                      name="checked.numbers"
                      className="appearance-none w-4 h-4"
                      disabled
                    />
                    {values.checked.numbers
                      ? Icons.ic_checked
                      : Icons.ic_not_checked}
                    <span className="ml-2">
                      Inclusion of numbers and special characters (e.g., !, @,
                      #).
                    </span>
                  </label>
                </div>
              )}
              {!isPasswordChecked && (
                <Button
                  disabled={
                    !values.checked.length ||
                    !values.checked.letters ||
                    !values.checked.numbers
                  }
                  className="w-full"
                  onClick={() => setIsPasswordChecked(true)}
                >
                  Continue
                </Button>
              )}
              {isPasswordChecked && (
                <Input
                  title="CONFIRM PASSWORD"
                  icon={
                    isConfirmPasswordHidden
                      ? Icons.ic_eye_closed
                      : Icons.ic_eye_open
                  }
                  placeholder="Re-enter password"
                  type={isConfirmPasswordHidden ? "password" : "text"}
                  name="confirmPassword"
                  id="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.confirmPassword && touched.confirmPassword
                      ? errors.confirmPassword
                      : ""
                  }
                  toggleHiddenPassword={toggleHiddenConfirmPassword}
                />
              )}
            </div>
            {isPasswordChecked && (
              <Button
                disabled={
                  !isValid ||
                  !dirty ||
                  isSubmitting ||
                  !values.checked.length ||
                  !values.checked.letters ||
                  !values.checked.numbers
                }
                type="submit"
                className="py-4 px-6"
                fullWidth
                loading={isSubmitting}
              >
                {type === "resetPassword" || type === "changePassword"
                  ? "Create new password"
                  : "Create account"}
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default PasswordForm;
