import React from "react";

import { FieldArray, Form, Formik, getIn } from "formik";
import { object, string, array, boolean } from "yup";
import FileUpload from "../../../../ui/fileUpload";
import SwitchUI from "../../../../ui/switch";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import useUser from "../../../../../hooks/useUser";
import Card from "../../../General/Card";

export default function AdditionalDocument({ handleStageSevenStep, id }) {
  const { createAdditionalDetail } = useUser();

  const addQuestion = (values, setValues) => {
    const details = [...values.details];
    details.push({
      detailTitle: "",
      detailDesc: "",
      detailDocumentRequest: false,
      saveQuestion: false,
    });
    setValues({ ...values, details });
  };

  const deleteQuestion = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  };

  return (
    <Card
      title="Supplementary information"
      description="List out the questions and document you require this buyer to provider."
    >
      <Formik
        initialValues={{
          details: [
            {
              detailTitle: "",
              detailDesc: "",
              detailDocumentRequest: false,
              saveQuestion: false,
            },
          ],
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const valuesWithoutSaveQuestion = values.details.map(
            ({ saveQuestion, ...rest }) => rest
          );
          const response = await createAdditionalDetail(
            { details: valuesWithoutSaveQuestion },
            id
          );
          if (response) {
            handleStageSevenStep(1);
          }
          setSubmitting(false);
        }}
        validationSchema={object().shape({
          details: array()
            .of(
              object().shape({
                detailTitle: string().required("Enter a question"),
                saveQuestion: boolean().required(),
              })
            )
            .test(
              "all-questions-saved",
              "All questions must be saved",
              (value) => value.every((question) => question.saveQuestion)
            ),
        })}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isValid,
            dirty,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setValues,
          } = props;

          return (
            <Form className="pt-4">
              <div>
                <FieldArray
                  name="details"
                  render={(arrayHelpers) =>
                    values.details.map((detail, index) => (
                      <div
                        className="border-b last:border-none border-grey-200 space-y-6 py-6 first:pt-0 last:pb-0"
                        key={index}
                      >
                        <p className="text-black font-semibold">
                          Question {index + 1}:
                        </p>
                        {detail.saveQuestion ? (
                          <div className="space-y-4">
                            <p className="text-black">{detail.detailTitle}</p>
                            {detail.detailDocumentRequest && (
                              <FileUpload
                                fileType=".pdf, .xls, .xlsx"
                                format="PDF or Excel format"
                                required
                                placeholder
                              />
                            )}
                          </div>
                        ) : (
                          <div className="border border-grey-200 bg-grey-50 rounded-lg p-4 space-y-6">
                            <div className="border-b border-grey-200 pb-6">
                              <Textarea
                                placeholder="Enter question"
                                name={`details[${index}].detailTitle`}
                                id={`details[${index}].detailTitle`}
                                value={detail.detailTitle}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={
                                  Boolean(
                                    getIn(
                                      errors,
                                      `details[${index}].detailTitle`
                                    ) &&
                                      getIn(
                                        touched,
                                        `details[${index}].detailTitle`
                                      )
                                  )
                                    ? getIn(
                                        errors,
                                        `details[${index}].detailTitle`
                                      )
                                    : ""
                                }
                              />

                              <div className="pt-5 flex items-center gap-3">
                                <p className="text-black font-semibold">
                                  Request a file upload
                                </p>
                                <SwitchUI
                                  enabled={detail.detailDocumentRequest}
                                  setEnabled={setFieldValue}
                                  title="Request a file upload"
                                  type="finalize"
                                  name={`details[${index}].detailDocumentRequest`}
                                />
                              </div>
                            </div>
                            <div className="flex gap-6 justify-end ">
                              <Button
                                onClick={() =>
                                  deleteQuestion(arrayHelpers, index)
                                }
                                className="bg-transparent border border-grey-200 !text-error-1 px-3 py-2 h-fit capitalize w-fit !tracking-[0]"
                              >
                                delete
                              </Button>
                              <Button
                                className="px-3 py-2 h-fit capitalize w-fit !tracking-[0]"
                                disabled={!detail.detailTitle}
                                onClick={() =>
                                  setFieldValue(
                                    `details[${index}].saveQuestion`,
                                    true
                                  )
                                }
                              >
                                save
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  }
                />
              </div>

              <div className="flex justify-between gap-6 p-4 mt-9 -mx-6 border-t border-grey-200">
                <Button
                  onClick={() => addQuestion(values, setValues)}
                  className="bg-transparent border-none !text-black px-3 py-2 h-fit capitalize w-fit !tracking-[0]"
                >
                  Add question
                </Button>
                <div className="flex gap-6">
                  <Button
                    onClick={() => handleStageSevenStep(1)}
                    className="bg-transparent border border-grey-200 !text-black px-3 py-2 h-fit capitalize w-fit !tracking-[0]"
                  >
                    cancel
                  </Button>
                  <Button
                    className="px-3 py-2 h-fit capitalize w-fit !tracking-[0]"
                    disabled={!isValid || !dirty || isSubmitting}
                    type="submit"
                    loading={isSubmitting}
                  >
                    Send Request
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
}
