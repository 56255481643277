import React, { useState } from "react";
import Dialog from "../../../../ui/dialog";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import useUser from "../../../../../hooks/useUser";

export default function RejectNDADialog({
  toggleRejectNDADialog,
  isRejectNDADialogOpen,
  companyId,
}) {
  const { rejectNda } = useUser();
  const [loading, setLoading] = useState(false);
  const [rejectionDescription, setRejectionDescription] = useState("");
  const [error, setError] = useState("");

  return (
    <Dialog isDialogModalOpen={isRejectNDADialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Confirm Rejection</h2>
          <p>
            Please confirm your decision to reject the NDA submission. Include a
            reason for rejection to inform the user and guide any necessary
            corrections.
          </p>
          <p>
            This feedback is essential for improving the submission for future
            consideration.
          </p>
          <Textarea
            placeholder="Enter rejection reason here"
            name="rejectionDescription"
            id="rejectionDescription"
            value={rejectionDescription}
            onChange={(e) => setRejectionDescription(e.target.value)}
            errors={error ? error : ""}
          />
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleRejectNDADialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setError("");
              if (rejectionDescription) {
                setLoading(true);
                const response = await rejectNda(companyId, {
                  adminNote: rejectionDescription,
                });
                if (response) {
                  toggleRejectNDADialog(false);
                  setRejectionDescription("");
                }
                setLoading(false);
              } else {
                setError("Please enter the reason for rejection");
              }
            }}
            disabled={loading}
            className="!bg-error-1"
            loading={loading}
          >
            Yes, Reject
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
