import React from "react";
import { format } from "date-fns";
import Pagination from "./pagination";
import usePagination from "../../hooks/usePagination";
import { Link } from "react-router-dom";
import Icons from "../../assets/icons";
import { useSelector } from "react-redux";
import { setCurrentRequestPage } from "../../config/reducers/adminSlice";
import { itemsPerPage } from "../../utils/constants";

const tableHead = [
  "Company name",
  "Contact person",
  "Contact email",
  "Phone number",
  "Date joined",
  "Status",
  "",
];

const ViewMore = ({ link, payload }) => (
  <Link to={link} state={payload}>
    <button className="text-black p-2 rounded-lg border border-grey-200 !text-black">
      {Icons.ic_chevron_right}
    </button>
  </Link>
);

const RequestsTableHead = () => (
  <thead>
    <tr>
      {tableHead.map((item) => (
        <th
          key={item}
          scope="col"
          className="px-6 py-[13.5px] bg-grey-50 text-start text-xs font-medium text-grey-700 capitalize"
        >
          {item}
        </th>
      ))}
    </tr>
  </thead>
);

const RequestsTableRowCell = ({
  title,
  content,
  className,
  contentClassName,
  titleClassName,
}) => (
  <div
    className={`flex justify-between items-center px-6 py-3 ${className ?? ""}`}
  >
    <p className={`text-xs text-grey-500 ${titleClassName ?? ""}`}>{title}</p>
    <p className={`text-sm text-grey-900 ${contentClassName ?? ""}`}>
      {content}
    </p>
  </div>
);

const RequestsTableRowCellDesktop = ({ content, className }) => (
  <td
    className={`px-6 py-[26px] whitespace-nowrap w-full text-sm text-grey-700 ${
      className ?? ""
    }`}
  >
    {content}
  </td>
);

const RequestsTableRow = ({ items }) => {
  const {
    company_name,
    full_name,
    fullName,
    email,
    phone_number,
    phoneNumber,
    createdAt,
    userId,
    _id,
    due_dligence_status,
  } = items;

  return (
    <div className="divide-y divide-grey-200 border border-grey-200 rounded-lg bg-white">
      <RequestsTableRowCell
        title={company_name}
        content={
          <ViewMore
            link={`/admin/company-info/${userId === undefined ? _id : userId}`}
            payload={{
              company_name,
              full_name: fullName,
              email,
              phone_number: phoneNumber,
              createdAt,
              _id,
            }}
          />
        }
        titleClassName="!text-sm text-grey-900 font-medium"
      />
      <RequestsTableRowCell
        title={tableHead[1]}
        content={full_name || fullName}
      />
      <RequestsTableRowCell title={tableHead[2]} content={email} />
      <RequestsTableRowCell
        title={tableHead[3]}
        content={phone_number || phoneNumber}
      />

      <RequestsTableRowCell
        title={tableHead[4]}
        content={format(new Date(createdAt), "LLL dd, yyyy")}
      />

      <RequestsTableRowCell
        title={tableHead[5]}
        content={
          due_dligence_status === "pending"
            ? "In Progress"
            : due_dligence_status === "accepted"
            ? "Completed"
            : "Not Started"
        }
        contentClassName={
          due_dligence_status === "pending"
            ? "text-warning-500"
            : due_dligence_status === "accepted"
            ? "text-primary-1"
            : "text-grey-400"
        }
      />
    </div>
  );
};

const RequestsTableRowDesktop = ({ items }) => {
  const {
    company_name,
    full_name,
    fullName,
    email,
    phone_number,
    phoneNumber,
    createdAt,
    userId,
    _id,
    due_dligence_status,
  } = items;

  return (
    <tr>
      <RequestsTableRowCellDesktop
        content={company_name}
        className="text-grey-900 font-medium"
      />
      <RequestsTableRowCellDesktop
        content={full_name || fullName}
        className="text-grey-900 font-medium min-w-[200px]"
      />
      <RequestsTableRowCellDesktop content={email} />
      <RequestsTableRowCellDesktop
        content={phone_number || phoneNumber}
        className="capitalize"
      />
      <RequestsTableRowCellDesktop
        content={format(new Date(createdAt), "LLL dd, yyyy")}
      />
      <RequestsTableRowCellDesktop
        content={
          due_dligence_status === "pending"
            ? "In Progress"
            : due_dligence_status === "accepted"
            ? "Completed"
            : "Not Started"
        }
        className={
          due_dligence_status === "pending"
            ? "text-warning-500"
            : due_dligence_status === "accepted"
            ? "text-primary-1"
            : "text-grey-400"
        }
      />

      <RequestsTableRowCellDesktop
        content={
          <ViewMore
            link={`/admin/company-info/${userId === undefined ? _id : userId}`}
            payload={{
              company_name,
              full_name: fullName,
              email,
              phone_number: phoneNumber,
              createdAt,
              _id,
            }}
          />
        }
      />
    </tr>
  );
};

export default function RequestsTable({ items }) {
  const { currentRequestPage, searchRequestText, totalRequest } = useSelector(
    (state) => state.admin
  );

  const { currentItems, pageCount, handlePageClick } = usePagination(
    items ?? [],
    itemsPerPage,
    searchRequestText,
    totalRequest,
    setCurrentRequestPage
  );

  return (
    <Pagination
      curentPage={currentRequestPage}
      pageCount={pageCount}
      handlePageClick={handlePageClick}
    >
      <div className="overflow-auto">
        <table className="hidden md:block w-full min-w-full divide-y divide-grey-200 border-t border-grey-200">
          <RequestsTableHead />
          <tbody className="divide-y divide-grey-200 ">
            {currentItems &&
              currentItems.map((item, index) => (
                <RequestsTableRowDesktop items={item} key={index} />
              ))}
          </tbody>
        </table>
        <div className="block md:hidden space-y-4">
          {currentItems &&
            currentItems.map((item, index) => (
              <RequestsTableRow items={item} key={index} />
            ))}
        </div>
      </div>
    </Pagination>
  );
}
