import React from "react";
import DashboardLayout from "../../components/Layout/dashboardLayout";
import Tab from "../../components/ui/tab";
import useTab from "../../hooks/useTab";
import PersonalInfo from "../../components/Account/personalInfo";
import CompanyInfo from "../../components/Account/companyInfo";
import ChangePassword from "../../components/Account/changePassword";
import ProxyUserManagement from "../../components/Account/teamManagement/user";

export default function AccountSettings() {
  const tabsItems = [
    "Personal Info",
    "Company Info",
    "Team Management",
    "Change Password",
  ];

  const { tabs, activeTab, handleTabClick } = useTab(tabsItems);

  return (
    <DashboardLayout
      type="user"
      title="Account settings"
      description="Manage Your Personal and Company Information"
    >
      <Tab tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
      <div
        className={`rounded-lg w-full space-y-6 ${
          activeTab === tabs[2]
            ? "bg-transaprent "
            : "max-w-[448px] px-4 py-6 bg-white border border-grey-200"
        }`}
      >
        {activeTab === tabs[0] && <PersonalInfo />}
        {activeTab === tabs[1] && <CompanyInfo />}
        {activeTab === tabs[2] && <ProxyUserManagement />}
        {activeTab === tabs[3] && <ChangePassword type="user" />}
      </div>
    </DashboardLayout>
  );
}
