import React from "react";
import useStageSix from "./useStageSix";
import Loader from "../../../../ui/loader";
import SiteVisitQuestionnaire from "../../../General/SiteVisitQuestionnaire";
import SiteVisitQuestionnaireStatus from "./status";
import { STAGE_SIX_STATUS } from "../../../../../utils/constants";
import { BackButton } from "../../../../ui/goback";
import { useSelector } from "react-redux";

export default function StageSix() {
  const { loading, stageSixStep, handleStageSixStep } = useStageSix();
  const { siteVisitQuestionnaire } = useSelector((state) => state.user);

  return loading ? (
    <Loader />
  ) : (
    <div>
      {stageSixStep === 1 && (
        <>
          {siteVisitQuestionnaire &&
            (siteVisitQuestionnaire?.status === STAGE_SIX_STATUS.APPROVED ||
              siteVisitQuestionnaire?.status === STAGE_SIX_STATUS.REVIEW) && (
              <div className="mb-4">
                <BackButton onClick={() => handleStageSixStep(2)} />
              </div>
            )}
          <SiteVisitQuestionnaire handleStageSixStep={handleStageSixStep} />
        </>
      )}
      {stageSixStep === 2 && (
        <SiteVisitQuestionnaireStatus handleStageSixStep={handleStageSixStep} />
      )}
    </div>
  );
}
