import { useState } from "react";

const useTab = (tabs) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return { tabs, activeTab, handleTabClick };
};

export default useTab;
