import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./reducers/authSlice";
import loginFormSlice from "./reducers/loginFormSlice";
import adminSlice from "./reducers/adminSlice";
import userSlice from "./reducers/userSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    loginForm: loginFormSlice,
    admin: adminSlice,
    user: userSlice,
  },
});
