import { useLocation } from "react-router-dom";
import InnerPageLayout from "../../../../components/Layout/innerPageLayout";
import Loader from "../../../../components/ui/loader";
import RequestInfo from "../../../../components/DueDiligence/Admin/requestInfo";
import useAdmin from "../../../../hooks/useAdmin";

export default function RequestsPage() {
  let { state } = useLocation();
  const { request, loading, id } = useAdmin(state);

  return (
    <InnerPageLayout type="admin" back="/admin/due-diligence" id={id}>
      {loading ? <Loader /> : <RequestInfo request={request} />}
    </InnerPageLayout>
  );
}
