import React, { useState } from "react";
import Dialog from "../../../../ui/dialog";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import useUser from "../../../../../hooks/useUser";
import { toast } from "react-toastify";

export default function RejectFormDialog({
  toggleRejectFormDialog,
  isRejectFormDialogOpen,
  companyName,
  companyId,
}) {
  const { rejectGasRequestForm } = useUser();
  const [loading, setLoading] = useState(false);
  const [rejectionDescription, setRejectionDescription] = useState("");
  const [error, setError] = useState("");

  return (
    <Dialog isDialogModalOpen={isRejectFormDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Confirm Rejection</h2>
          <p>Confirm rejection of {companyName} Gas Request Form?</p>
          <p>Provide a brief rejection reason</p>
          <Textarea
            placeholder="Enter description here"
            name="rejectionDescription"
            id="rejectionDescription"
            value={rejectionDescription}
            onChange={(e) => setRejectionDescription(e.target.value)}
            errors={error ? error : ""}
          />
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleRejectFormDialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setError("");
              if (rejectionDescription) {
                setLoading(true);
                const response = await rejectGasRequestForm(
                  companyId,
                  rejectionDescription
                );
                if (response) {
                  toast.success("The buyer was not approved");
                  toggleRejectFormDialog(false);
                  setRejectionDescription("");
                }
                setLoading(false);
              } else {
                setError("Please enter the reason for rejection");
              }
            }}
            disabled={loading}
            className="!bg-error-1"
            loading={loading}
          >
            Yes, Reject
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
