import AuthLayout from "../../components/Layout/authLayout";
import VerifyEmail from "../../components/AuthForm/verifyEmail";

const VerificationPage = () => {
  return (
    <AuthLayout type="user" page="confirmation">
      <VerifyEmail />
    </AuthLayout>
  );
};

export default VerificationPage;
