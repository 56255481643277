import { useState } from "react";
import { useCalendlyEventListener } from "react-calendly";
import useUser from "./useUser";

export default function useScheduleMeeting(handleStageOneStep) {
  const { scheduleMeeting } = useUser();
  const [loading, setLoading] = useState(false);
  const [openCalendly, setOpenCalendly] = useState(false);

  const handleOpenCalendly = (value) => setOpenCalendly(value);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      handleSubmitMeetingSchedule(e.data.payload?.event?.uri);
    },
  });

  const handleSubmitMeetingSchedule = async (url) => {
    const baseUrlToRemove = "https://api.calendly.com/scheduled_events/";
    const id = url.replace(baseUrlToRemove, "");

    setLoading(true);

    try {
      const response = await scheduleMeeting(id);
      if (response) {
        handleStageOneStep(3);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  

  return { loading, openCalendly, handleOpenCalendly };
}
