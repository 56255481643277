import React from "react";
import InnerPageLayout from "../../../../../components/Layout/innerPageLayout";
import useAdmin from "../../../../../hooks/useAdmin";
import Loader from "../../../../../components/ui/loader";
import StageSix from "../../../../../components/DueDiligence/Admin/stages/stageSix";

export default function StageSixPage() {
  const { loading, id } = useAdmin();

  return (
    <InnerPageLayout
      type="admin"
      title="Site Visit Questionnaire"
      back={`/admin/company-info/${id}`}
    >
      {loading ? <Loader /> : <StageSix />}
    </InnerPageLayout>
  );
}
