import React, { useState } from "react";
import { format } from "date-fns";
import { PopupModal } from "react-calendly";
import Icons from "../../../../assets/icons";
import Button from "../../../ui/button";
import useScheduleMeeting from "../../../../hooks/useScheduleMeeting";
import RescheduleMeetingDialog from "./rescheduleMeetingDialog";
import { CopyInfo } from "../MeetingInfo";
import { USER_TYPE } from "../../../../utils/constants";

const NotSet = () => (
  <span className="italic text-grey-400">Meeting not set</span>
);

export default function MeetingInfo({ details, handleStageOneStep, user }) {
  const { openCalendly, handleOpenCalendly } =
    useScheduleMeeting(handleStageOneStep);
  const [isRescheduleMeetingDialogOpen, setIsRescheduleMeetingDialogOpen] =
    useState(false);

  const toggleRescheduleMeetingDialog = (value) =>
    setIsRescheduleMeetingDialogOpen(value);

  return (
    <div className="space-y-12 pt-4">
      <div>
        <CopyInfo
          title="Name of Meeting"
          content="Introductory Meeting with the GACN Team"
          icon={Icons.ic_users}
        />
        <CopyInfo
          title="Date"
          content={
            details ? (
              format(new Date(details?.start_time), "EEEE, LLL dd, yyyy")
            ) : (
              <NotSet />
            )
          }
          icon={Icons.ic_email}
        />
        <CopyInfo
          title="Time"
          content={
            details ? (
              format(new Date(details?.start_time), "h:mm a")
            ) : (
              <NotSet />
            )
          }
          icon={Icons.ic_clock}
        />
        <CopyInfo
          title="Meeting link"
          content={details ? details?.meeting_link : <NotSet />}
          icon={Icons.ic_web_link}
          type="link"
        />
      </div>

      {(user?.role === USER_TYPE.USER || user?.role === USER_TYPE.PROXY) && (
        <>
          {details?.status === "awaiting" && (
            <Button
              onClick={() => toggleRescheduleMeetingDialog(true)}
              className="bg-transparent border-[1.5px] border-primary-1 !text-primary-1 w-full"
            >
              Reschedule meeting
            </Button>
          )}
          <PopupModal
            url="https://calendly.com/gacnltdgte/gas-request-meeting"
            rootElement={document.getElementById("root")}
            text="Click here to schedule!"
            textColor="#ffffff"
            color="#00a2ff"
            open={openCalendly}
            onModalClose={() => handleOpenCalendly(false)}
            prefill={{
              email: user?.email ?? "",
              name: user?.fullName ?? "",
            }}
          />
          {isRescheduleMeetingDialogOpen && (
            <RescheduleMeetingDialog
              toggleRescheduleMeetingDialog={toggleRescheduleMeetingDialog}
              isRescheduleMeetingDialogOpen={isRescheduleMeetingDialogOpen}
              handleOpenCalendly={handleOpenCalendly}
            />
          )}
        </>
      )}
    </div>
  );
}
