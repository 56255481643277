import { createBrowserRouter } from "react-router-dom";
import SignupPage from "../pages/auth/signup";
import LoginPage from "../pages/auth/login";
import ForgotPasswordPage from "../pages/auth/forgotPassword";
import ConfirmationPage from "../pages/auth/confirmation";
import VerificationPage from "../pages/auth/verification";
import AuthSessionProvider from "../components/SessionProvider/auth";
import DashboardSessionProvider from "../components/SessionProvider/dashboard";
import ResetPasswordPage from "../pages/auth/resetPassword";
import ResetPasswordSuccessPage from "../pages/auth/resetPasswordSuccess";
import AccountSettings from "../pages/dashboard/accountSettings";
import DueDiligence from "../pages/dashboard/dueDiligence";
import HomePage from "../pages/intro";
import AdminPage from "../pages/admin";
import AdminAuthSessionProvider from "../components/SessionProvider/adminAuth";
import AdminLoginPage from "../pages/admin/auth/login";
import AdminForgotPasswordPage from "../pages/admin/auth/forgotPassword";
import AdminResetPasswordPage from "../pages/admin/auth/resetPassword";
import AdminResetPasswordSuccessPage from "../pages/admin/auth/resetPasswordSuccess";
import AdminConfirmationPage from "../pages/admin/auth/confirmation";
import AdminAccountSettings from "../pages/admin/dashboard/accountSettings";
import AdminDueDiligence from "../pages/admin/dashboard/dueDiligence";
import AdminDashboardSessionProvider from "../components/SessionProvider/adminDashboard";
import RequestsPage from "../pages/admin/dashboard/requestInfo";
import NotFoundPage from "../pages/notFound";
import StageOnePage from "../pages/admin/dashboard/requestInfo/stages/one";
import StageTwoPage from "../pages/admin/dashboard/requestInfo/stages/two";
import StageThreePage from "../pages/admin/dashboard/requestInfo/stages/three";
import StageFourPage from "../pages/admin/dashboard/requestInfo/stages/four";
import StageFivePage from "../pages/admin/dashboard/requestInfo/stages/five";
import StageSevenPage from "../pages/admin/dashboard/requestInfo/stages/seven";
import StageSixPage from "../pages/admin/dashboard/requestInfo/stages/six";

const routes = createBrowserRouter([
  {
    path: "/",
    exact: true,
    element: <HomePage />,
  },

  {
    path: "/login",
    exact: true,
    element: (
      <AuthSessionProvider>
        <LoginPage />
      </AuthSessionProvider>
    ),
  },
  {
    path: "/signup",
    exact: true,
    element: (
      <AuthSessionProvider>
        <SignupPage />
      </AuthSessionProvider>
    ),
  },
  {
    path: "/forgot-password",
    exact: true,
    element: (
      <AuthSessionProvider>
        <ForgotPasswordPage />
      </AuthSessionProvider>
    ),
  },
  {
    path: "/reset-password/:token",
    exact: true,
    element: (
      <AuthSessionProvider>
        <ResetPasswordPage />
      </AuthSessionProvider>
    ),
  },
  {
    path: "/reset-password-successful",
    exact: true,
    element: (
      <AuthSessionProvider>
        <ResetPasswordSuccessPage />
      </AuthSessionProvider>
    ),
  },
  {
    path: "/confirmation/:email",
    exact: true,
    element: (
      <AuthSessionProvider>
        <ConfirmationPage />
      </AuthSessionProvider>
    ),
  },
  {
    path: "/verification/:token",
    exact: true,
    element: (
      <AuthSessionProvider>
        <VerificationPage />
      </AuthSessionProvider>
    ),
  },

  {
    path: "/account-settings",
    exact: true,
    element: (
      <DashboardSessionProvider>
        <AccountSettings />
      </DashboardSessionProvider>
    ),
  },
  {
    path: "/due-diligence",
    exact: true,
    element: (
      <DashboardSessionProvider>
        <DueDiligence />
      </DashboardSessionProvider>
    ),
  },

  // admin routes
  {
    path: "/admin",
    exact: true,
    element: <AdminPage />,
  },

  {
    path: "/admin/login",
    exact: true,
    element: (
      <AdminAuthSessionProvider>
        <AdminLoginPage />
      </AdminAuthSessionProvider>
    ),
  },

  {
    path: "/admin/forgot-password",
    exact: true,
    element: (
      <AdminAuthSessionProvider>
        <AdminForgotPasswordPage />
      </AdminAuthSessionProvider>
    ),
  },
  {
    path: "/admin/reset-password/:token",
    exact: true,
    element: (
      <AdminAuthSessionProvider>
        <AdminResetPasswordPage />
      </AdminAuthSessionProvider>
    ),
  },
  {
    path: "/admin/reset-password-successful",
    exact: true,
    element: (
      <AdminAuthSessionProvider>
        <AdminResetPasswordSuccessPage />
      </AdminAuthSessionProvider>
    ),
  },
  {
    path: "/admin/confirmation/:email",
    exact: true,
    element: (
      <AdminAuthSessionProvider>
        <AdminConfirmationPage />
      </AdminAuthSessionProvider>
    ),
  },

  {
    path: "/admin/account-settings",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <AdminAccountSettings />
      </AdminDashboardSessionProvider>
    ),
  },
  {
    path: "/admin/due-diligence",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <AdminDueDiligence />
      </AdminDashboardSessionProvider>
    ),
  },
  {
    path: "/admin/company-info/:id",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <RequestsPage />
      </AdminDashboardSessionProvider>
    ),
  },
  {
    path: "/admin/company-info/:id/stageOne",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <StageOnePage />
      </AdminDashboardSessionProvider>
    ),
  },
  {
    path: "/admin/company-info/:id/stageTwo",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <StageTwoPage />
      </AdminDashboardSessionProvider>
    ),
  },
  {
    path: "/admin/company-info/:id/stageThree",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <StageThreePage />
      </AdminDashboardSessionProvider>
    ),
  },
  {
    path: "/admin/company-info/:id/stageFour",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <StageFourPage />
      </AdminDashboardSessionProvider>
    ),
  },

  {
    path: "/admin/company-info/:id/stageFive",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <StageFivePage />
      </AdminDashboardSessionProvider>
    ),
  },

  {
    path: "/admin/company-info/:id/stageSix",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <StageSixPage />
      </AdminDashboardSessionProvider>
    ),
  },

  {
    path: "/admin/company-info/:id/stageSeven",
    exact: true,
    element: (
      <AdminDashboardSessionProvider>
        <StageSevenPage />
      </AdminDashboardSessionProvider>
    ),
  },

  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default routes;