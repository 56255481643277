import Icons from "../../../../assets/icons";
import Button from "../../../ui/button";
import Dialog from "../../../ui/dialog";

function InviteSentDialog({
  toggleInviteSentDialog,
  isInviteSentDialogOpen,
  email,
}) {
  return (
    <Dialog isDialogModalOpen={isInviteSentDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[355px] mx-auto p-6 space-y-8">
        <img src={Icons.ic_success} alt="" className="mx-auto" />
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Invite sent successfully </h2>
          <p>
            An invitation link with login credentials has been sent to {email}.
          </p>
        </div>
        <Button onClick={() => toggleInviteSentDialog(false)} fullWidth>
          Ok
        </Button>
      </div>
    </Dialog>
  );
}

export default InviteSentDialog;
