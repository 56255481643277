import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Icons from "../../assets/icons";
import { links } from "./links";
import LogoutDialog from "../AuthForm/logoutDailog";

const MenuItem = ({ onClick, link, name, icon }) => {
  return (
    <li onClick={onClick}>
      <NavLink
        to={link}
        className={({ isActive }) =>
          `px-4 py-3 text-sm leading-[20.3px] text-grey-700 flex items-center gap-3 ${
            isActive ? "text-primary-1 font-medium [&>span]:text-grey-900" : ""
          }`
        }
      >
        {icon}
        <span className="">{name}</span>
      </NavLink>
    </li>
  );
};

const Navbar = ({ type }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const toggleLogoutDialog = (value) => setIsLogoutDialogOpen(value);
  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  return (
    <div className="py-3 px-2 h-full">
      <div className="container !px-4 flex items-center justify-between gap-10">
        <Link
          to="/"
          aria-label="homepage"
          className={isMenuOpen ? "invisible" : "visible"}
        >
          <img src={logo} className="w-[170px] md:w-[170px]" alt="logo" />
        </Link>
        <span
          className={
            !isMenuOpen
              ? "block md:hidden cursor-pointer"
              : "flex md:hidden cursor-pointer block self-end w-10 h-10 bg-primary-1 border border-solid border-primary-75 rounded-full backdrop-blur-[8px] items-center justify-center text-white"
          }
          onClick={toggleMenu}
        >
          {!isMenuOpen ? Icons.ic_menu : Icons.ic_close}
        </span>
      </div>

      <nav
        className={`flex fixed md:relative top-[70px] md:top-0 left-0 px-2 md:px-0 flex-col bg-white pt-6 z-30 transition-all ease-in-out duration-300 w-full h-full justify-between items-center ${
          isMenuOpen
            ? "visible max-h-[calc(100vh-70px)] h-[calc(100vh-70px)] translate-y-0"
            : "invisible md:visible max-h-0 md:max-h-[calc(100vh-70px)] translate-y-[-100%] md:translate-y-0"
        }`}
      >
        <div className="container !px-0 flex flex-col justify-between gap-3 h-full">
          <ul
            className={`hidden md:block w-full border-b grey-10 pb-3 ${
              isMenuOpen ? "!block " : ""
            }`}
          >
            {links.map((link) => (
              <MenuItem
                key={link.name}
                link={type === "admin" ? `/admin${link.link}` : link.link}
                name={link.name}
                icon={link.icon}
              />
            ))}
          </ul>
          <button
            onClick={() => toggleLogoutDialog(true)}
            className="flex items-center gap-3 px-4 py-3 text-sm leading-[20.3px] text-grey-700"
          >
            {Icons.ic_logout} Logout
          </button>
        </div>
      </nav>
      <LogoutDialog
        toggleLogoutDialog={toggleLogoutDialog}
        isLogoutDialogOpen={isLogoutDialogOpen}
        type={type}
      />
    </div>
  );
};

export default Navbar;
