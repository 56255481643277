import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useUser from "../../../../hooks/useUser";
import { toast } from "react-toastify";
import { PROJECT_TYPE, USER_TYPE } from "../../../../utils/constants";

export default function useQuestionnaire(handleStageFourTabClick) {
  const { user } = useSelector((state) => state.auth);
  const { questionnaire, project_type, userRequest } = useSelector(
    (state) => state.user
  );

  const [disableForm, setDisableForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    request,
    reputation,
    commercial,
    technical,
    economic,
    questionnaireAdminNote,
    requestDetailAdminNote,
    submitQuestionnaireAdminNote,
  } = useUser();

  const userType =
    user?.role !== USER_TYPE.USER && user?.role !== USER_TYPE.PROXY
      ? USER_TYPE.ADMIN
      : USER_TYPE.USER;

  useEffect(() => {
    if (userType === USER_TYPE.USER && !questionnaire) setDisableForm(false);
    else if (
      userType !== USER_TYPE.USER ||
      (questionnaire?.status !== "pending" &&
        questionnaire?.status !== "feedback")
    )
      setDisableForm(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, questionnaire]);

  const canAdminComment =
    questionnaire?.status !== "accepted" && userType !== USER_TYPE.USER
      ? true
      : false;

  const initialValues = {
    request: {
      company_name:
        questionnaire?.request_detail?.company_name ||
        userRequest?.company_name ||
        "",
      company_address:
        questionnaire?.request_detail?.company_address ||
        userRequest?.company_address ||
        "",
      natureOfBusiness:
        questionnaire?.request_detail?.natureOfBusiness || project_type,
      projectDesc: questionnaire?.request_detail?.projectDesc || null,
      endProducts: questionnaire?.request_detail?.endProducts || "",
      franchiseOrDistributionArea:
        questionnaire?.request_detail?.franchiseOrDistributionArea || null,
      likelyDateOfFirstGasDelivery:
        questionnaire?.request_detail?.likelyDateOfFirstGasDelivery || null,
      likelyDateOfFirstDeliveryOfNGLOrLPG:
        questionnaire?.request_detail?.likelyDateOfFirstDeliveryOfNGLOrLPG ||
        null,
      proposedDurationOfContract:
        questionnaire?.request_detail?.proposedDurationOfContract || 0,
      totalContractVolume:
        questionnaire?.request_detail?.totalContractVolume || 0,
      totalContractVolumeForNGLOrLPG:
        questionnaire?.request_detail?.totalContractVolumeForNGLOrLPG || 0,
      proposedLocationOfProject:
        questionnaire?.request_detail?.proposedLocationOfProject || "",
      gasContractQuantityYear:
        questionnaire?.request_detail?.gasContractQuantity.length || 0,
      gasContractQuantity:
        questionnaire?.request_detail?.gasContractQuantity || [],
      nglContractQuantityYear:
        questionnaire?.request_detail?.nglContractQuantity.length || 0,
      nglContractQuantity:
        questionnaire?.request_detail?.nglContractQuantity || [],
      lpgContractQuantityYear:
        questionnaire?.request_detail?.lpgContractQuantity.length || 0,
      lpgContractQuantity:
        questionnaire?.request_detail?.lpgContractQuantity || [],
    },
    reputation: {
      companyName:
        questionnaire?.ReputationDueDiligence?.companyName?.userResponse ||
        userRequest?.company_name ||
        "",
      registeredOfficeAddress:
        questionnaire?.ReputationDueDiligence?.registeredOfficeAddress
          ?.userResponse || "",
      dateOfIncorporation:
        questionnaire?.ReputationDueDiligence?.dateOfIncorporation
          ?.userResponse || "",
      companyRegistrationNumber:
        questionnaire?.ReputationDueDiligence?.companyRegistrationNumber
          ?.userResponse || "",
      equityDistribution:
        questionnaire?.ReputationDueDiligence?.equityDistribution
          ?.userResponse || "",
      partnerContributions:
        questionnaire?.ReputationDueDiligence?.partnerContributions
          ?.userResponse || "",
      companyExperienceSummary:
        questionnaire?.ReputationDueDiligence?.companyExperienceSummary
          ?.userResponse || "",

      ethicsRequirement: {
        politicallyExposedPerson:
          questionnaire?.ReputationDueDiligence?.ethicsRequirement
            ?.politicallyExposedPerson?.userResponse || "",
        politicallyExposedPersonDetails:
          questionnaire?.ReputationDueDiligence?.ethicsRequirement
            ?.politicallyExposedPersonDetails?.userResponse || "",
        criminalConviction:
          questionnaire?.ReputationDueDiligence?.ethicsRequirement
            ?.criminalConviction?.userResponse || "",
        criminalConvictionDetails:
          questionnaire?.ReputationDueDiligence?.ethicsRequirement
            ?.criminalConvictionDetails?.userResponse || "",
      },

      holdValidShipperLicense:
        questionnaire?.ReputationDueDiligence?.holdValidShipperLicense
          ?.userResponse || "",
      willingToCollaborateForShipperLicense:
        questionnaire?.ReputationDueDiligence
          ?.willingToCollaborateForShipperLicense?.userResponse || "",
      planForSecuringShipperLicense:
        questionnaire?.ReputationDueDiligence?.planForSecuringShipperLicense
          ?.userResponse || "",
      willingToAppointGACNAsShipperAgent:
        questionnaire?.ReputationDueDiligence
          ?.willingToAppointGACNAsShipperAgent?.userResponse || "",
      planForSecuringShipperAgent:
        questionnaire?.ReputationDueDiligence?.planForSecuringShipperAgent
          ?.userResponse || "",
      cacCertificate:
        questionnaire?.ReputationDueDiligence?.cacCertificate?.userResponse ||
        "",

      auditedFinancialReportsYear1:
        questionnaire?.ReputationDueDiligence?.auditedFinancialReportsYear1
          ?.userResponse || "",
      auditedFinancialReportsYear2:
        questionnaire?.ReputationDueDiligence?.auditedFinancialReportsYear2
          ?.userResponse || "",
      auditedFinancialReportsYear3:
        questionnaire?.ReputationDueDiligence?.auditedFinancialReportsYear3
          ?.userResponse || "",

      deedOfAdherenceDeclaration:
        questionnaire?.ReputationDueDiligence?.deedOfAdherenceDeclaration
          ?.userResponse || "",
    },
    commercialPower: {
      projectDescription:
        questionnaire?.CommercialDueDiligence?.projectDescription
          ?.userResponse || "", //required
      projectedProductStreams:
        questionnaire?.CommercialDueDiligence?.projectedProductStreams
          ?.userResponse || "", //required
      plannedGenerationCapacity:
        questionnaire?.CommercialDueDiligence?.plannedGenerationCapacity
          ?.userResponse || "", //required
      feedstockRequirements:
        questionnaire?.CommercialDueDiligence?.feedstockRequirements
          ?.userResponse || "", //required
      offTakePlanOverview:
        questionnaire?.CommercialDueDiligence?.offTakePlanOverview
          ?.userResponse || "", //required
      evidenceOfOffTakeAgreements:
        questionnaire?.CommercialDueDiligence?.evidenceOfOffTakeAgreements
          ?.userResponse || "",
      otherSupportingDocument: [
        questionnaire?.CommercialDueDiligence?.otherSupportingDocument?.["0"]
          ?.userResponse || "",
      ], //response from backend should be  an array
      projectDeliveryTimescales:
        questionnaire?.CommercialDueDiligence?.projectDeliveryTimescales
          ?.userResponse || "",
      financingPlan:
        questionnaire?.CommercialDueDiligence?.financingPlan?.userResponse ||
        "",
      financialPlanningDocument:
        questionnaire?.CommercialDueDiligence?.financialPlanningDocument
          ?.userResponse || "", //optional

      nbetPowerPurchaseAgreement:
        questionnaire?.CommercialDueDiligence?.statutoryPermits
          ?.nbetPowerPurchaseAgreement?.userResponse || "",

      commitToPaymentAssuranceFramework:
        questionnaire?.CommercialDueDiligence?.commitToPaymentAssuranceFramework
          ?.userResponse || "",
      reasonsForNotCommitting:
        questionnaire?.CommercialDueDiligence?.reasonsForNotCommitting
          ?.userResponse || "",
      letterOfCreditworthiness:
        questionnaire?.CommercialDueDiligence?.letterOfCreditworthiness
          ?.userResponse || "",

      statutoryPermits: {
        nercLicensing:
          questionnaire?.CommercialDueDiligence?.statutoryPermits?.nercLicensing
            ?.userResponse || "",
        tcnEvacuationApplication:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.tcnEvacuationApplication?.userResponse || "",
        environmentalPermits:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.environmentalPermits?.userResponse || "",
        fedMinOfHousingAndEnvStudy:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.fedMinOfHousingAndEnvStudy?.userResponse || "",
        taxRegistration:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.taxRegistration?.userResponse || "",
        gasUseLicense:
          questionnaire?.CommercialDueDiligence?.statutoryPermits?.gasUseLicense
            ?.userResponse || "",
        riskAssessmentSafetyHazop:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.riskAssessmentSafetyHazop?.userResponse || "",
        simulationStudies:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.simulationStudies?.userResponse || "",
        pollutionControlEffluentTreatment:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.pollutionControlEffluentTreatment?.userResponse || "",
        constructionOperatingPermits:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.constructionOperatingPermits?.userResponse || "",
        localGovernmentApprovals:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.localGovernmentApprovals?.userResponse || "",
        otherPermits: [
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.otherPermits?.["0"]?.userResponse || "",
        ], //response from backend should be  an array
      },
    },
    commercialCommgas: {
      projectDescription:
        questionnaire?.CommercialDueDiligence?.projectDescription
          ?.userResponse || "", //required
      projectedProductStreams:
        questionnaire?.CommercialDueDiligence?.projectedProductStreams
          ?.userResponse || "", //required
      plannedGenerationCapacity:
        questionnaire?.CommercialDueDiligence?.plannedGenerationCapacity
          ?.userResponse || "", //required
      feedstockRequirements:
        questionnaire?.CommercialDueDiligence?.feedstockRequirements
          ?.userResponse || "", //required
      offTakePlanOverview:
        questionnaire?.CommercialDueDiligence?.offTakePlanOverview
          ?.userResponse || "", //required
      evidenceOfOffTakeAgreements:
        questionnaire?.CommercialDueDiligence?.evidenceOfOffTakeAgreements
          ?.userResponse || "",
      otherSupportingDocument: [
        questionnaire?.CommercialDueDiligence?.otherSupportingDocument?.["0"]
          ?.userResponse || "",
      ], //response from backend should be  an array
      projectDeliveryTimescales:
        questionnaire?.CommercialDueDiligence?.projectDeliveryTimescales
          ?.userResponse || "",
      financingPlan:
        questionnaire?.CommercialDueDiligence?.financingPlan?.userResponse ||
        "",
      financialPlanningDocument:
        questionnaire?.CommercialDueDiligence?.financialPlanningDocument
          ?.userResponse || "", //optional

      commitToPaymentAssuranceFramework:
        questionnaire?.CommercialDueDiligence?.commitToPaymentAssuranceFramework
          ?.userResponse || "",
      reasonsForNotCommitting:
        questionnaire?.CommercialDueDiligence?.reasonsForNotCommitting
          ?.userResponse || "",

      letterOfCreditworthiness:
        questionnaire?.CommercialDueDiligence?.letterOfCreditworthiness
          ?.userResponse || "",

      statutoryPermits: {
        incorporationCertificate:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.incorporationCertificate?.userResponse || "",
        nmdpraLicense: {
          nmdpraGasDistributionLicense:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.nmdpraLicense?.nmdpraGasDistributionLicense?.userResponse || "",
          nmdpraGasProcessingLicense:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.nmdpraLicense?.nmdpraGasProcessingLicense?.userResponse || "",
          nmdpraGasTransportationLicense:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.nmdpraLicense?.nmdpraGasTransportationLicense?.userResponse ||
            "",
        },
        nigerianContentCompliancePlan:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.nigerianContentCompliancePlan?.userResponse || "",
        environmentalPermit:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.environmentalPermit?.userResponse || "",
        otherPermits: [
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.otherPermits?.["0"]?.userResponse || "",
        ],
      },
    },
    technicalPower: {
      plantNominalInstalledCapacity:
        questionnaire?.TechnicalDueDiligence?.plantNominalInstalledCapacity
          ?.userResponse || "", //required
      locationAndLandAcquisitionStatus:
        questionnaire?.TechnicalDueDiligence?.locationAndLandAcquisitionStatus
          ?.userResponse || "", //required
      technologyDeployed:
        questionnaire?.TechnicalDueDiligence?.technologyDeployed
          ?.userResponse || "",

      gasRequirement: {
        volumeRequirement:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.volumeRequirement?.userResponse || "", //required
        deliveryPressures:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.deliveryPressures?.userResponse || "", //required
        deliveryDate:
          questionnaire?.TechnicalDueDiligence?.gasRequirement?.deliveryDate
            ?.userResponse || "", //required
        anticipatedDemandSwing:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.anticipatedDemandSwing?.userResponse || "", //required
        plantAvailability:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.plantAvailability?.userResponse || "", //required
      },

      gasInfrastructureArrangement:
        questionnaire?.TechnicalDueDiligence?.gasInfrastructureArrangement
          ?.userResponse || "", //required
      gasInfrastructureArrangementDocument:
        questionnaire?.TechnicalDueDiligence
          ?.gasInfrastructureArrangementDocument?.userResponse || "", //optional file

      electricityEvacuation: {
        mode:
          questionnaire?.TechnicalDueDiligence?.electricityEvacuation?.mode
            ?.userResponse || "", //required
        offGridDetails: {
          marketAssessmentStudy:
            questionnaire?.TechnicalDueDiligence?.electricityEvacuation
              ?.offGridDetails?.marketAssessmentStudy?.userResponse || "",
          supplyNicheDescription:
            questionnaire?.TechnicalDueDiligence?.electricityEvacuation
              ?.offGridDetails?.supplyNicheDescription?.userResponse || "",
        },
        onGridDetails: {
          evacuationArrangement:
            questionnaire?.TechnicalDueDiligence?.electricityEvacuation
              ?.onGridDetails?.evacuationArrangement?.userResponse || "",
          tcnApprovedStudy:
            questionnaire?.TechnicalDueDiligence?.electricityEvacuation
              ?.onGridDetails?.tcnApprovedStudy?.userResponse || "",
        },
      },

      projectEngineeringStatus:
        questionnaire?.TechnicalDueDiligence?.projectEngineeringStatus
          ?.userResponse || "", //required
      projectEngineeringStatusDocument:
        questionnaire?.TechnicalDueDiligence?.projectEngineeringStatusDocument
          ?.userResponse || "", //optional file

      statusOfEpcContracts:
        questionnaire?.TechnicalDueDiligence?.statusOfEpcContracts
          ?.userResponse || "", //required
      statusOfEpcContractsDocument:
        questionnaire?.TechnicalDueDiligence?.statusOfEpcContractsDocument
          ?.userResponse || "", //optional file

      evidenceOfTechnicalPartners:
        questionnaire?.TechnicalDueDiligence?.evidenceOfTechnicalPartners
          ?.userResponse || "", //required
      nigerianContentCompliancePlan:
        questionnaire?.TechnicalDueDiligence?.nigerianContentCompliancePlan
          ?.userResponse || "", //required

      operationsAndMaintenancePlan:
        questionnaire?.TechnicalDueDiligence?.operationsAndMaintenancePlan
          ?.userResponse || "", //required
      operationsAndMaintenancePlanDocument:
        questionnaire?.TechnicalDueDiligence
          ?.operationsAndMaintenancePlanDocument?.userResponse || "", //optional file

      uptimePerformanceExpectation:
        questionnaire?.TechnicalDueDiligence?.uptimePerformanceExpectation
          ?.userResponse || "", //required
      uptimePerformanceExpectationDocument:
        questionnaire?.TechnicalDueDiligence
          ?.uptimePerformanceExpectationDocument?.userResponse || "", //optional file

      technicalMaintenanceAgreementsStatus:
        questionnaire?.TechnicalDueDiligence
          ?.technicalMaintenanceAgreementsStatus?.userResponse || "", //required
      technicalMaintenanceAgreementsStatusDocument:
        questionnaire?.TechnicalDueDiligence
          ?.technicalMaintenanceAgreementsStatusDocument?.userResponse || "", //optional file

      staffingAndRecruitingPlans:
        questionnaire?.TechnicalDueDiligence?.staffingAndRecruitingPlans
          ?.userResponse || "", //required
      staffingAndRecruitingPlansDocument:
        questionnaire?.TechnicalDueDiligence?.staffingAndRecruitingPlansDocument
          ?.userResponse || "", //optional file

      hsePlans:
        questionnaire?.TechnicalDueDiligence?.hsePlans?.userResponse || "", //required
      hsePlansDocument:
        questionnaire?.TechnicalDueDiligence?.hsePlansDocument?.userResponse ||
        "", //optional file

      existingAndExpansionFacilities:
        questionnaire?.TechnicalDueDiligence?.existingAndExpansionFacilities
          ?.userResponse || "", //required
      existingAndExpansionFacilitiesDocument:
        questionnaire?.TechnicalDueDiligence
          ?.existingAndExpansionFacilitiesDocument?.userResponse || "", //optional file

      operatingManuals:
        questionnaire?.TechnicalDueDiligence?.operatingManuals?.userResponse ||
        "", //required
      operatingManualsDocument:
        questionnaire?.TechnicalDueDiligence?.operatingManualsDocument
          ?.userResponse || "", //optional file

      sustainableDevelopmentAndCommunityEngagementPlans:
        questionnaire?.TechnicalDueDiligence
          ?.sustainableDevelopmentAndCommunityEngagementPlans?.userResponse ||
        "", //required
      sustainableDevelopmentAndCommunityEngagementPlansDocument:
        questionnaire?.TechnicalDueDiligence
          ?.sustainableDevelopmentAndCommunityEngagementPlansDocument
          ?.userResponse || "", //optional file

      constructionSchedule:
        questionnaire?.TechnicalDueDiligence?.constructionSchedule
          ?.userResponse || "", //required
      constructionScheduleDocument:
        questionnaire?.TechnicalDueDiligence?.constructionScheduleDocument
          ?.userResponse || "", //optional file
    },
    technicalCommgas: {
      proposedLocation:
        questionnaire?.TechnicalDueDiligence?.proposedLocation?.userResponse ||
        "", //required
      plantCapacity:
        questionnaire?.TechnicalDueDiligence?.plantCapacity?.userResponse || "", //required
      gasRequirement: {
        volumeRequirement:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.volumeRequirement?.userResponse || "", //required
        deliveryPressures:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.deliveryPressures?.userResponse || "", //required
        deliveryDate:
          questionnaire?.TechnicalDueDiligence?.gasRequirement?.deliveryDate
            ?.userResponse || "", //required
      },

      technologyStatement:
        questionnaire?.TechnicalDueDiligence?.technologyStatement
          ?.userResponse || "", //required
      technologyStatementDocument:
        questionnaire?.TechnicalDueDiligence?.technologyStatementDocument
          ?.userResponse || "", //optional file

      engineeringDesignStatus:
        questionnaire?.TechnicalDueDiligence?.engineeringDesignStatus
          ?.userResponse || "", //required
      engineeringDesignStatusDocument:
        questionnaire?.TechnicalDueDiligence?.engineeringDesignStatusDocument
          ?.userResponse || "", //optional file

      landAcquisition:
        questionnaire?.TechnicalDueDiligence?.landAcquisition?.userResponse ||
        "", //required
      landAcquisitionDocument:
        questionnaire?.TechnicalDueDiligence?.landAcquisitionDocument
          ?.userResponse || "", //optional file

      statusOfEpcContracts:
        questionnaire?.TechnicalDueDiligence?.statusOfEpcContracts
          ?.userResponse || "", //required
      statusOfEpcContractsDocument:
        questionnaire?.TechnicalDueDiligence?.statusOfEpcContractsDocument
          ?.userResponse || "", //optional file

      evidenceOfTechnicalPartners:
        questionnaire?.TechnicalDueDiligence?.evidenceOfTechnicalPartners
          ?.userResponse || "", //required

      operationsAndMaintenancePlan:
        questionnaire?.TechnicalDueDiligence?.operationsAndMaintenancePlan
          ?.userResponse || "", //required
      operationsAndMaintenancePlanDocument:
        questionnaire?.TechnicalDueDiligence
          ?.operationsAndMaintenancePlanDocument?.userResponse || "", //optional file
    },

    economicPower: {
      businessPlan:
        questionnaire?.EconomicDueDiligence?.businessPlan?.userResponse || "",

      projectEconomicsAndFinancialRatios:
        questionnaire?.EconomicDueDiligence?.projectEconomicsAndFinancialRatios
          ?.userResponse || "",

      financialRatios:
        questionnaire?.EconomicDueDiligence?.financialRatios?.userResponse ||
        "",

      guaranteesCreditworthiness:
        questionnaire?.EconomicDueDiligence?.guaranteesCreditworthiness
          ?.userResponse || "",

      competitiveAdvantage:
        questionnaire?.EconomicDueDiligence?.competitiveAdvantage
          ?.userResponse || "",

      competitiveAdvantageDocument:
        questionnaire?.EconomicDueDiligence?.competitiveAdvantageDocument
          ?.userResponse || "",

      riskAssessmentStudy:
        questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
          ?.userResponse || "",

      riskAssessmentDocument:
        questionnaire?.EconomicDueDiligence?.riskAssessmentDocument
          ?.userResponse || "",

      marketViability:
        questionnaire?.EconomicDueDiligence?.marketViability?.userResponse ||
        "",

      marketViabilityDocument:
        questionnaire?.EconomicDueDiligence?.marketViabilityDocument
          ?.userResponse || "",

      commitmentByInvestors:
        questionnaire?.EconomicDueDiligence?.commitmentByInvestors
          ?.userResponse || "",

      evidenceOfFederalOrStateGovernmentSupport:
        questionnaire?.EconomicDueDiligence
          ?.evidenceOfFederalOrStateGovernmentSupport?.userResponse || "",

      involvementOfNGO:
        questionnaire?.EconomicDueDiligence?.involvementOfNGO?.userResponse ||
        "",
    },

    economicCommgas: {
      businessPlan:
        questionnaire?.EconomicDueDiligence?.businessPlan?.userResponse || "",

      projectEconomics: {
        assumptions:
          questionnaire?.EconomicDueDiligence?.projectEconomics?.assumptions
            ?.userResponse || "",
        duration:
          questionnaire?.EconomicDueDiligence?.projectEconomics?.duration
            ?.userResponse || "",
        indicators: {
          npv:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.npv?.userResponse || "",
          irr:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.irr?.userResponse || "",
          roe:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.roe?.userResponse || "",
          roce:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.roce?.userResponse || "",
          paybackPeriod:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.paybackPeriod?.userResponse || "",
        },
      },

      riskAssessmentStudy: {
        demandRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.demandRisk
            ?.userResponse || "",
        priceRisks:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.priceRisks
            ?.userResponse || "",
        countryPoliticalRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
            ?.countryPoliticalRisk?.userResponse || "",
        marketRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.marketRisk
            ?.userResponse || "",
        operationalRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
            ?.operationalRisk?.userResponse || "",
        constructionCompletionRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
            ?.constructionCompletionRisk?.userResponse || "",
      },
    },
  };

  const handleSubmitRequest = async (values, type, setSubmitting) => {
    const {
      gasContractQuantityYear,
      nglContractQuantityYear,
      lpgContractQuantityYear,
      ...valuesWithoutQuantityYear
    } = values;

    type === "next" ? setLoading(true) : setSubmitting(true);

    const response = await request(valuesWithoutQuantityYear);
    if (response) {
      type === "next" && handleStageFourTabClick(1);
      type === "save" && toast.success("Progress saved successfully");
    }
    type === "next" ? setLoading(false) : setSubmitting(false);
  };

  const handleSubmitReputation = async (values, type, setSubmitting) => {
    try {
      type === "next" ? setLoading(true) : setSubmitting(true);

      const response = await reputation(
        project_type === PROJECT_TYPE.POWER
          ? PROJECT_TYPE.POWER
          : PROJECT_TYPE.COMMGAS,
        values,
        project_type === PROJECT_TYPE.GAS ? PROJECT_TYPE.GAS : PROJECT_TYPE.COMM
      );
      if (response) {
        type === "next" && handleStageFourTabClick(2);
        type === "save" && toast.success("Progress saved successfully");
      }
    } catch (error) {
    } finally {
      type === "next" ? setLoading(false) : setSubmitting(false);
    }
  };

  const handleSubmitCommgasCommercial = async (values, type, setSubmitting) => {
    try {
      type === "next" ? setLoading(true) : setSubmitting(true);

      const response = await commercial(
        PROJECT_TYPE.COMMGAS,
        values,
        project_type === PROJECT_TYPE.GAS ? PROJECT_TYPE.GAS : PROJECT_TYPE.COMM
      );
      if (response) {
        type === "next" && handleStageFourTabClick(3);
        type === "save" && toast.success("Progress saved successfully");
      }
    } catch (error) {
    } finally {
      type === "next" ? setLoading(false) : setSubmitting(false);
    }
  };

  const handleSubmitPowerCommercial = async (values, type, setSubmitting) => {
    try {
      type === "next" ? setLoading(true) : setSubmitting(true);

      const response = await commercial(PROJECT_TYPE.POWER, values);
      if (response) {
        type === "next" && handleStageFourTabClick(3);
        type === "save" && toast.success("Progress saved successfully");
      }
    } catch (error) {
    } finally {
      type === "next" ? setLoading(false) : setSubmitting(false);
    }
  };

  const handleSubmitCommgasTechnical = async (values, type, setSubmitting) => {
    try {
      type === "next" ? setLoading(true) : setSubmitting(true);

      const response = await technical(
        PROJECT_TYPE.COMMGAS,
        values,
        project_type === PROJECT_TYPE.GAS ? PROJECT_TYPE.GAS : PROJECT_TYPE.COMM
      );
      if (response) {
        type === "next" && handleStageFourTabClick(4);
        type === "save" && toast.success("Progress saved successfully");
      }
    } catch (error) {
    } finally {
      type === "next" ? setLoading(false) : setSubmitting(false);
    }
  };

  const handleSubmitPowerTechnical = async (values, type, setSubmitting) => {
    try {
      type === "next" ? setLoading(true) : setSubmitting(true);

      const response = await technical(PROJECT_TYPE.POWER, values);
      if (response) {
        type === "next" && handleStageFourTabClick(4);
        type === "save" && toast.success("Progress saved successfully");
      }
    } catch (error) {
    } finally {
      type === "next" ? setLoading(false) : setSubmitting(false);
    }
  };

  const handleSubmitCommgasEconomic = async (values, type, setSubmitting) => {
    try {
      type === "next" ? setLoading(true) : setSubmitting(true);

      const response = await economic(
        PROJECT_TYPE.COMMGAS,
        values,
        project_type === PROJECT_TYPE.GAS ? PROJECT_TYPE.GAS : PROJECT_TYPE.COMM
      );
      if (response) {
        type === "next" && handleStageFourTabClick(5);
        type === "save" && toast.success("Progress saved successfully");
      }
    } catch (error) {
    } finally {
      type === "next" ? setLoading(false) : setSubmitting(false);
    }
  };

  const handleSubmitPowerEconomic = async (values, type, setSubmitting) => {
    try {
      type === "next" ? setLoading(true) : setSubmitting(true);

      const response = await economic(PROJECT_TYPE.POWER, values);
      if (response) {
        type === "next" && handleStageFourTabClick(5);
        type === "save" && toast.success("Progress saved successfully");
      }
    } catch (error) {
    } finally {
      type === "next" ? setLoading(false) : setSubmitting(false);
    }
  };

  // ==== Admin Comments
  const initialValuesForAdmin = {
    request: {
      company_name:
        questionnaire?.request_detail?.adminNote?.company_name || null,
      company_address:
        questionnaire?.request_detail?.adminNote?.company_address || null,
      natureOfBusiness:
        questionnaire?.request_detail?.adminNote?.natureOfBusiness || null,
      projectDesc:
        questionnaire?.request_detail?.adminNote?.projectDesc || null,
      endProducts:
        questionnaire?.request_detail?.adminNote?.endProducts || null,
      franchiseOrDistributionArea:
        questionnaire?.request_detail?.adminNote?.franchiseOrDistributionArea ||
        null,
      likelyDateOfFirstGasDelivery:
        questionnaire?.request_detail?.adminNote
          ?.likelyDateOfFirstGasDelivery || null,
      likelyDateOfFirstDeliveryOfNGLOrLPG:
        questionnaire?.request_detail?.adminNote
          ?.likelyDateOfFirstDeliveryOfNGLOrLPG || null,
      proposedDurationOfContract:
        questionnaire?.request_detail?.adminNote?.proposedDurationOfContract ||
        null,
      totalContractVolume:
        questionnaire?.request_detail?.adminNote?.totalContractVolume || null,
      totalContractVolumeForNGLOrLPG:
        questionnaire?.request_detail?.adminNote
          ?.totalContractVolumeForNGLOrLPG || null,
      proposedLocationOfProject:
        questionnaire?.request_detail?.adminNote?.proposedLocationOfProject ||
        null,
      gasContractQuantity:
        questionnaire?.request_detail?.adminNote?.gasContractQuantity || null,
      nglContractQuantity:
        questionnaire?.request_detail?.adminNote?.nglContractQuantity || null,
      lpgContractQuantity:
        questionnaire?.request_detail?.adminNote?.lpgContractQuantity || null,
    },
    reputation: {
      companyName:
        questionnaire?.ReputationDueDiligence?.companyName?.adminNote || null,
      registeredOfficeAddress:
        questionnaire?.ReputationDueDiligence?.registeredOfficeAddress
          ?.adminNote || null,
      dateOfIncorporation:
        questionnaire?.ReputationDueDiligence?.dateOfIncorporation?.adminNote ||
        null,
      companyRegistrationNumber:
        questionnaire?.ReputationDueDiligence?.companyRegistrationNumber
          ?.adminNote || null,
      equityDistribution:
        questionnaire?.ReputationDueDiligence?.equityDistribution?.adminNote ||
        null,
      partnerContributions:
        questionnaire?.ReputationDueDiligence?.partnerContributions
          ?.adminNote || null,
      companyExperienceSummary:
        questionnaire?.ReputationDueDiligence?.companyExperienceSummary
          ?.adminNote || null,

      ethicsRequirement: {
        politicallyExposedPerson:
          questionnaire?.ReputationDueDiligence?.ethicsRequirement
            ?.politicallyExposedPerson?.adminNote || null,
        politicallyExposedPersonDetails:
          questionnaire?.ReputationDueDiligence?.ethicsRequirement
            ?.politicallyExposedPersonDetails?.adminNote || null,
        criminalConviction:
          questionnaire?.ReputationDueDiligence?.ethicsRequirement
            ?.criminalConviction?.adminNote || null,
        criminalConvictionDetails:
          questionnaire?.ReputationDueDiligence?.ethicsRequirement
            ?.criminalConvictionDetails?.adminNote || null,
      },

      holdValidShipperLicense:
        questionnaire?.ReputationDueDiligence?.holdValidShipperLicense
          ?.adminNote || null,
      willingToCollaborateForShipperLicense:
        questionnaire?.ReputationDueDiligence
          ?.willingToCollaborateForShipperLicense?.adminNote || null,
      planForSecuringShipperLicense:
        questionnaire?.ReputationDueDiligence?.planForSecuringShipperLicense
          ?.adminNote || null,
      willingToAppointGACNAsShipperAgent:
        questionnaire?.ReputationDueDiligence
          ?.willingToAppointGACNAsShipperAgent?.adminNote || null,
      planForSecuringShipperAgent:
        questionnaire?.ReputationDueDiligence?.planForSecuringShipperAgent
          ?.adminNote || null,
      cacCertificate:
        questionnaire?.ReputationDueDiligence?.cacCertificate?.adminNote ||
        null,
      auditedFinancialReportsYear1:
        questionnaire?.ReputationDueDiligence?.auditedFinancialReportsYear1
          ?.adminNote || null,
      auditedFinancialReportsYear2:
        questionnaire?.ReputationDueDiligence?.auditedFinancialReportsYear2
          ?.adminNote || null,
      auditedFinancialReportsYear3:
        questionnaire?.ReputationDueDiligence?.auditedFinancialReportsYear3
          ?.adminNote || null,
      deedOfAdherenceDeclaration:
        questionnaire?.ReputationDueDiligence?.deedOfAdherenceDeclaration
          ?.adminNote || null,
    },
    commercialPower: {
      projectDescription:
        questionnaire?.CommercialDueDiligence?.projectDescription?.adminNote ||
        null,
      projectedProductStreams:
        questionnaire?.CommercialDueDiligence?.projectedProductStreams
          ?.adminNote || null,
      plannedGenerationCapacity:
        questionnaire?.CommercialDueDiligence?.plannedGenerationCapacity
          ?.adminNote || null,
      feedstockRequirements:
        questionnaire?.CommercialDueDiligence?.feedstockRequirements
          ?.adminNote || null,
      offTakePlanOverview:
        questionnaire?.CommercialDueDiligence?.offTakePlanOverview?.adminNote ||
        null,
      evidenceOfOffTakeAgreements:
        questionnaire?.CommercialDueDiligence?.evidenceOfOffTakeAgreements
          ?.adminNote || null,
      otherSupportingDocument: [
        questionnaire?.CommercialDueDiligence?.otherSupportingDocument?.["0"]
          ?.adminNote || "",
      ],
      projectDeliveryTimescales:
        questionnaire?.CommercialDueDiligence?.projectDeliveryTimescales
          ?.adminNote || null,
      financingPlan:
        questionnaire?.CommercialDueDiligence?.financingPlan?.adminNote || null,
      financialPlanningDocument:
        questionnaire?.CommercialDueDiligence?.financialPlanningDocument
          ?.adminNote || null,

      nbetPowerPurchaseAgreement:
        questionnaire?.CommercialDueDiligence?.statutoryPermits
          ?.nbetPowerPurchaseAgreement?.adminNote || null,
      commitToPaymentAssuranceFramework:
        questionnaire?.CommercialDueDiligence?.commitToPaymentAssuranceFramework
          ?.adminNote || null,
      reasonsForNotCommitting:
        questionnaire?.CommercialDueDiligence?.reasonsForNotCommitting
          ?.adminNote || null,

      letterOfCreditworthiness:
        questionnaire?.CommercialDueDiligence?.letterOfCreditworthiness
          ?.adminNote || null,

      statutoryPermits: {
        nercLicensing:
          questionnaire?.CommercialDueDiligence?.statutoryPermits?.nercLicensing
            ?.adminNote || null,
        tcnEvacuationApplication:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.tcnEvacuationApplication?.adminNote || null,
        environmentalPermits:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.environmentalPermits?.adminNote || null,
        fedMinOfHousingAndEnvStudy:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.fedMinOfHousingAndEnvStudy?.adminNote || null,
        taxRegistration:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.taxRegistration?.adminNote || null,
        gasUseLicense:
          questionnaire?.CommercialDueDiligence?.statutoryPermits?.gasUseLicense
            ?.adminNote || null,
        riskAssessmentSafetyHazop:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.riskAssessmentSafetyHazop?.adminNote || null,
        simulationStudies:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.simulationStudies?.adminNote || null,
        pollutionControlEffluentTreatment:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.pollutionControlEffluentTreatment?.adminNote || null,
        constructionOperatingPermits:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.constructionOperatingPermits?.adminNote || null,
        localGovernmentApprovals:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.localGovernmentApprovals?.adminNote || null,
        otherPermits: [
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.otherPermits?.["0"]?.adminNote || "",
        ],
      },
    },
    commercialCommgas: {
      projectDescription:
        questionnaire?.CommercialDueDiligence?.projectDescription?.adminNote ||
        null,
      projectedProductStreams:
        questionnaire?.CommercialDueDiligence?.projectedProductStreams
          ?.adminNote || null,
      plannedGenerationCapacity:
        questionnaire?.CommercialDueDiligence?.plannedGenerationCapacity
          ?.adminNote || null,
      feedstockRequirements:
        questionnaire?.CommercialDueDiligence?.feedstockRequirements
          ?.adminNote || null,
      offTakePlanOverview:
        questionnaire?.CommercialDueDiligence?.offTakePlanOverview?.adminNote ||
        null,
      evidenceOfOffTakeAgreements:
        questionnaire?.CommercialDueDiligence?.evidenceOfOffTakeAgreements
          ?.adminNote || null,
      otherSupportingDocument: [
        questionnaire?.CommercialDueDiligence?.otherSupportingDocument?.["0"]
          ?.adminNote || "",
      ],
      projectDeliveryTimescales:
        questionnaire?.CommercialDueDiligence?.projectDeliveryTimescales
          ?.adminNote || null,
      financingPlan:
        questionnaire?.CommercialDueDiligence?.financingPlan?.adminNote || null,
      financialPlanningDocument:
        questionnaire?.CommercialDueDiligence?.financialPlanningDocument
          ?.adminNote || null,

      commitToPaymentAssuranceFramework:
        questionnaire?.CommercialDueDiligence?.commitToPaymentAssuranceFramework
          ?.adminNote || null,
      reasonsForNotCommitting:
        questionnaire?.CommercialDueDiligence?.reasonsForNotCommitting
          ?.adminNote || null,

      letterOfCreditworthiness:
        questionnaire?.CommercialDueDiligence?.letterOfCreditworthiness
          ?.adminNote || null,

      statutoryPermits: {
        incorporationCertificate:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.incorporationCertificate?.adminNote || null,
        nmdpraLicense: {
          nmdpraGasDistributionLicense:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.nmdpraLicense?.nmdpraGasDistributionLicense?.adminNote || null,
          nmdpraGasProcessingLicense:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.nmdpraLicense?.nmdpraGasProcessingLicense?.adminNote || null,
          nmdpraGasTransportationLicense:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.nmdpraLicense?.nmdpraGasTransportationLicense?.adminNote ||
            null,
        },
        nigerianContentCompliancePlan:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.nigerianContentCompliancePlan?.adminNote || null,
        environmentalPermit:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.environmentalPermit?.adminNote || null,
        otherPermits: [
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.otherPermits?.["0"]?.adminNote || "",
        ],
      },
    },
    technicalPower: {
      plantNominalInstalledCapacity:
        questionnaire?.TechnicalDueDiligence?.plantNominalInstalledCapacity
          ?.adminNote || null,
      locationAndLandAcquisitionStatus:
        questionnaire?.TechnicalDueDiligence?.locationAndLandAcquisitionStatus
          ?.adminNote || null,
      technologyDeployed:
        questionnaire?.TechnicalDueDiligence?.technologyDeployed?.adminNote ||
        null,

      gasRequirement: {
        volumeRequirement:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.volumeRequirement?.adminNote || null,
        deliveryPressures:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.deliveryPressures?.adminNote || null,
        deliveryDate:
          questionnaire?.TechnicalDueDiligence?.gasRequirement?.deliveryDate
            ?.adminNote || null,
        anticipatedDemandSwing:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.anticipatedDemandSwing?.adminNote || null,
        plantAvailability:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.plantAvailability?.adminNote || null,
      },

      gasInfrastructureArrangement:
        questionnaire?.TechnicalDueDiligence?.gasInfrastructureArrangement
          ?.adminNote || null,
      gasInfrastructureArrangementDocument:
        questionnaire?.TechnicalDueDiligence
          ?.gasInfrastructureArrangementDocument?.adminNote || null,

      electricityEvacuation: {
        mode:
          questionnaire?.TechnicalDueDiligence?.electricityEvacuation?.mode
            ?.adminNote || null,
        offGridDetails: {
          marketAssessmentStudy:
            questionnaire?.TechnicalDueDiligence?.electricityEvacuation
              ?.offGridDetails?.marketAssessmentStudy?.adminNote || null,
          supplyNicheDescription:
            questionnaire?.TechnicalDueDiligence?.electricityEvacuation
              ?.offGridDetails?.supplyNicheDescription?.adminNote || null,
        },
        onGridDetails: {
          evacuationArrangement:
            questionnaire?.TechnicalDueDiligence?.electricityEvacuation
              ?.onGridDetails?.evacuationArrangement?.adminNote || null,
          tcnApprovedStudy:
            questionnaire?.TechnicalDueDiligence?.electricityEvacuation
              ?.onGridDetails?.tcnApprovedStudy?.adminNote || null,
        },
      },

      projectEngineeringStatus:
        questionnaire?.TechnicalDueDiligence?.projectEngineeringStatus
          ?.adminNote || null,
      projectEngineeringStatusDocument:
        questionnaire?.TechnicalDueDiligence?.projectEngineeringStatusDocument
          ?.adminNote || null,

      statusOfEpcContracts:
        questionnaire?.TechnicalDueDiligence?.statusOfEpcContracts?.adminNote ||
        null,
      statusOfEpcContractsDocument:
        questionnaire?.TechnicalDueDiligence?.statusOfEpcContractsDocument
          ?.adminNote || null,

      evidenceOfTechnicalPartners:
        questionnaire?.TechnicalDueDiligence?.evidenceOfTechnicalPartners
          ?.adminNote || null,
      nigerianContentCompliancePlan:
        questionnaire?.TechnicalDueDiligence?.nigerianContentCompliancePlan
          ?.adminNote || null,

      operationsAndMaintenancePlan:
        questionnaire?.TechnicalDueDiligence?.operationsAndMaintenancePlan
          ?.adminNote || null,
      operationsAndMaintenancePlanDocument:
        questionnaire?.TechnicalDueDiligence
          ?.operationsAndMaintenancePlanDocument?.adminNote || null,

      uptimePerformanceExpectation:
        questionnaire?.TechnicalDueDiligence?.uptimePerformanceExpectation
          ?.adminNote || null,
      uptimePerformanceExpectationDocument:
        questionnaire?.TechnicalDueDiligence
          ?.uptimePerformanceExpectationDocument?.adminNote || null,

      technicalMaintenanceAgreementsStatus:
        questionnaire?.TechnicalDueDiligence
          ?.technicalMaintenanceAgreementsStatus?.adminNote || null,
      technicalMaintenanceAgreementsStatusDocument:
        questionnaire?.TechnicalDueDiligence
          ?.technicalMaintenanceAgreementsStatusDocument?.adminNote || null,

      staffingAndRecruitingPlans:
        questionnaire?.TechnicalDueDiligence?.staffingAndRecruitingPlans
          ?.adminNote || null,
      staffingAndRecruitingPlansDocument:
        questionnaire?.TechnicalDueDiligence?.staffingAndRecruitingPlansDocument
          ?.adminNote || null,

      hsePlans:
        questionnaire?.TechnicalDueDiligence?.hsePlans?.adminNote || null,
      hsePlansDocument:
        questionnaire?.TechnicalDueDiligence?.hsePlansDocument?.adminNote ||
        null,

      existingAndExpansionFacilities:
        questionnaire?.TechnicalDueDiligence?.existingAndExpansionFacilities
          ?.adminNote || null,
      existingAndExpansionFacilitiesDocument:
        questionnaire?.TechnicalDueDiligence
          ?.existingAndExpansionFacilitiesDocument?.adminNote || null,

      operatingManuals:
        questionnaire?.TechnicalDueDiligence?.operatingManuals?.adminNote ||
        null,
      operatingManualsDocument:
        questionnaire?.TechnicalDueDiligence?.operatingManualsDocument
          ?.adminNote || null,

      sustainableDevelopmentAndCommunityEngagementPlans:
        questionnaire?.TechnicalDueDiligence
          ?.sustainableDevelopmentAndCommunityEngagementPlans?.adminNote ||
        null,
      sustainableDevelopmentAndCommunityEngagementPlansDocument:
        questionnaire?.TechnicalDueDiligence
          ?.sustainableDevelopmentAndCommunityEngagementPlansDocument
          ?.adminNote || null,

      constructionSchedule:
        questionnaire?.TechnicalDueDiligence?.constructionSchedule?.adminNote ||
        null,
      constructionScheduleDocument:
        questionnaire?.TechnicalDueDiligence?.constructionScheduleDocument
          ?.adminNote || null,
    },
    technicalCommgas: {
      proposedLocation:
        questionnaire?.TechnicalDueDiligence?.proposedLocation?.adminNote ||
        null,
      plantCapacity:
        questionnaire?.TechnicalDueDiligence?.plantCapacity?.adminNote || null,
      gasRequirement: {
        volumeRequirement:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.volumeRequirement?.adminNote || null,
        deliveryPressures:
          questionnaire?.TechnicalDueDiligence?.gasRequirement
            ?.deliveryPressures?.adminNote || null,
        deliveryDate:
          questionnaire?.TechnicalDueDiligence?.gasRequirement?.deliveryDate
            ?.adminNote || null,
      },

      technologyStatement:
        questionnaire?.TechnicalDueDiligence?.technologyStatement?.adminNote ||
        null,
      technologyStatementDocument:
        questionnaire?.TechnicalDueDiligence?.technologyStatementDocument
          ?.adminNote || null,

      engineeringDesignStatus:
        questionnaire?.TechnicalDueDiligence?.engineeringDesignStatus
          ?.adminNote || null,
      engineeringDesignStatusDocument:
        questionnaire?.TechnicalDueDiligence?.engineeringDesignStatusDocument
          ?.adminNote || null,

      landAcquisition:
        questionnaire?.TechnicalDueDiligence?.landAcquisition?.adminNote ||
        null,
      landAcquisitionDocument:
        questionnaire?.TechnicalDueDiligence?.landAcquisitionDocument
          ?.adminNote || null,

      statusOfEpcContracts:
        questionnaire?.TechnicalDueDiligence?.statusOfEpcContracts?.adminNote ||
        null,
      statusOfEpcContractsDocument:
        questionnaire?.TechnicalDueDiligence?.statusOfEpcContractsDocument
          ?.adminNote || null,

      evidenceOfTechnicalPartners:
        questionnaire?.TechnicalDueDiligence?.evidenceOfTechnicalPartners
          ?.adminNote || null,

      operationsAndMaintenancePlan:
        questionnaire?.TechnicalDueDiligence?.operationsAndMaintenancePlan
          ?.adminNote || null,
      operationsAndMaintenancePlanDocument:
        questionnaire?.TechnicalDueDiligence
          ?.operationsAndMaintenancePlanDocument?.adminNote || null,
    },
    economicPower: {
      businessPlan:
        questionnaire?.EconomicDueDiligence?.businessPlan?.adminNote || null,

      projectEconomicsAndFinancialRatios:
        questionnaire?.EconomicDueDiligence?.projectEconomicsAndFinancialRatios
          ?.adminNote || null,

      financialRatios:
        questionnaire?.EconomicDueDiligence?.financialRatios?.adminNote || null,

      guaranteesCreditworthiness:
        questionnaire?.EconomicDueDiligence?.guaranteesCreditworthiness
          ?.adminNote || null,

      competitiveAdvantage:
        questionnaire?.EconomicDueDiligence?.competitiveAdvantage?.adminNote ||
        null,

      competitiveAdvantageDocument:
        questionnaire?.EconomicDueDiligence?.competitiveAdvantageDocument
          ?.adminNote || null,

      riskAssessmentStudy:
        questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.adminNote ||
        null,

      riskAssessmentDocument:
        questionnaire?.EconomicDueDiligence?.riskAssessmentDocument
          ?.adminNote || null,

      marketViability:
        questionnaire?.EconomicDueDiligence?.marketViability?.adminNote || null,

      marketViabilityDocument:
        questionnaire?.EconomicDueDiligence?.marketViabilityDocument
          ?.adminNote || null,

      commitmentByInvestors:
        questionnaire?.EconomicDueDiligence?.commitmentByInvestors?.adminNote ||
        null,

      evidenceOfFederalOrStateGovernmentSupport:
        questionnaire?.EconomicDueDiligence
          ?.evidenceOfFederalOrStateGovernmentSupport?.adminNote || null,

      involvementOfNGO:
        questionnaire?.EconomicDueDiligence?.involvementOfNGO?.adminNote ||
        null,
    },
    economicCommgas: {
      businessPlan:
        questionnaire?.EconomicDueDiligence?.businessPlan?.adminNote || null,

      projectEconomics: {
        assumptions:
          questionnaire?.EconomicDueDiligence?.projectEconomics?.assumptions
            ?.adminNote || null,
        duration:
          questionnaire?.EconomicDueDiligence?.projectEconomics?.duration
            ?.adminNote || null,
        indicators: {
          npv:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.npv?.adminNote || null,
          irr:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.irr?.adminNote || null,
          roe:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.roe?.adminNote || null,
          roce:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.roce?.adminNote || null,
          paybackPeriod:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
              ?.paybackPeriod?.adminNote || null,
        },
      },

      riskAssessmentStudy: {
        demandRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.demandRisk
            ?.adminNote || null,
        priceRisks:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.priceRisks
            ?.adminNote || null,
        countryPoliticalRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
            ?.countryPoliticalRisk?.adminNote || null,
        marketRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.marketRisk
            ?.adminNote || null,
        operationalRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
            ?.operationalRisk?.adminNote || null,
        constructionCompletionRisk:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
            ?.constructionCompletionRisk?.adminNote || null,
      },
    },
    extras: {
      additionalNotes:
        questionnaire?.Extras?.additionalNotes?.adminNote || null,
      additionalDocumentation:
        questionnaire?.Extras?.additionalDocumentation?.adminNote || null,
    },
  };

  const [isCommentNonNull, setIsCommentNonNull] = useState(false);
  const [adminComments, setAdminComments] = useState(initialValuesForAdmin);
  const [loadingAddComments, setLoadingAddComments] = useState(false);
  const { currentUserId } = useSelector((state) => state.admin);

  const handleSubmitAdminComment = async (name) => {
    setLoadingAddComments(true);
    await submitQuestionnaireAdminNote(
      project_type === PROJECT_TYPE.POWER
        ? PROJECT_TYPE.POWER
        : PROJECT_TYPE.COMMGAS,
      project_type === PROJECT_TYPE.GAS ? PROJECT_TYPE.GAS : PROJECT_TYPE.COMM,
      currentUserId,
      name
    );
    setLoadingAddComments(false);
  };

  const handleAddAdminComment = async (values, name) => {
    setLoadingAddComments(true);
    const res = await questionnaireAdminNote(
      values,
      project_type === PROJECT_TYPE.POWER
        ? PROJECT_TYPE.POWER
        : PROJECT_TYPE.COMMGAS,
      project_type === PROJECT_TYPE.GAS ? PROJECT_TYPE.GAS : PROJECT_TYPE.COMM,
      currentUserId,
      name
    );
    if (res) setIsCommentNonNull(false);

    setLoadingAddComments(false);
  };

  const handleAddRequestDetailAdminComment = async (values) => {
    setLoadingAddComments(true);
    const res = await requestDetailAdminNote(values, currentUserId);
    if (res) setIsCommentNonNull(false);

    setLoadingAddComments(false);
  };

  return {
    disableForm,
    loading,
    setLoading,
    canAdminComment,
    initialValues,
    adminComments,
    setAdminComments,
    handleSubmitRequest,
    handleSubmitReputation,
    handleSubmitCommgasCommercial,
    handleSubmitPowerCommercial,
    handleSubmitCommgasTechnical,
    handleSubmitPowerTechnical,
    handleSubmitCommgasEconomic,
    handleSubmitPowerEconomic,

    loadingAddComments,
    handleAddAdminComment,
    handleAddRequestDetailAdminComment,
    handleSubmitAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  };
}
