import React, { useState } from "react";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import FinalizeBuyerDialog from "./finalizeBuyerDialog";
import { useSelector } from "react-redux";
import { getDownloadFileLink } from "../../../../../utils/functions";

export default function FinalizeStatus({ handleStageSevenStep }) {
  const { userRequest, finalize } = useSelector((state) => state.user);

  const [isFinalizeBuyerDialogOpen, setIsFinalizeBuyerDialogOpen] =
    useState(false);

  const toggleIsFinalizeBuyerDialog = (value) =>
    setIsFinalizeBuyerDialogOpen(value);

  return finalize?.status === "approved" ? (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <img src={Icons.ic_success} alt="" />
      <div className="space-y-4 text-center">
        <h2 className="text-h2 text-grey-800">User Approval Successful</h2>
        <p className="text-base max-w-[455px]">
          Congratulations! {userRequest?.company_name} has been successfully
          approved.
        </p>
        <div className="flex justify-center gap-6">
          {finalize?.details?.length > 0 && (
            <Button
              onClick={() => handleStageSevenStep(3)}
              className="bg-transparent border border-primary-1 !text-primary-1 mx-auto"
            >
              View Responses
            </Button>
          )}
          {finalize?.report && (
            <a href={getDownloadFileLink(finalize?.report)}>
              <Button>View report</Button>
            </a>
          )}
        </div>
      </div>
    </div>
  ) : finalize?.status === "pending" || finalize?.status === "feedback" ? (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <img src={Icons.ic_pending} alt="" />
      <div className="space-y-4 text-center">
        <h2 className="text-h2 text-grey-800">Requested document is pending</h2>
        <p className="text-base max-w-[455px]">
          Buyer is yet to fill the additional document, you will be notified
          when the questionnaire is submitted
        </p>
      </div>
    </div>
  ) : (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <img src={Icons.ic_pending} alt="" />
      <div className="space-y-4 text-center">
        <h2 className="text-h2 text-grey-800">Pending Final approval</h2>
        <p className="text-base max-w-[455px]">
          Buyer is pending your final approval. Proceed to finalise buyer if all
          requirements have been met or you can request more buyer to provide
          more supplementary documents
        </p>
        <div className="flex gap-6">
          <Button
            onClick={() => handleStageSevenStep(2)}
            className="bg-transparent border border-primary-1 !text-primary-1"
          >
            Request document
          </Button>
          <Button onClick={() => toggleIsFinalizeBuyerDialog(true)}>
            Finalize buyer
          </Button>
        </div>
      </div>

      <FinalizeBuyerDialog
        handleStageSevenStep={handleStageSevenStep}
        isFinalizeBuyerDialogOpen={isFinalizeBuyerDialogOpen}
        toggleIsFinalizeBuyerDialog={toggleIsFinalizeBuyerDialog}
        companyId={userRequest?.userId}
      />
    </div>
  );
}
