import React, { useState } from "react";
import useUser from "../../../../../hooks/useUser";
import Button from "../../../../ui/button";
import Dialog from "../../../../ui/dialog";

export default function ConfirmDateUpdateDialog({
  toggleConfirmDateUpdateDialog,
  isConfirmDateUpdateDialogOpen,
  updatedSiteInformation,
  companyId,
  time,
  date,
}) {
  const { updateSiteVisit } = useUser();

  const [loading, setLoading] = useState(false);

  return (
    <Dialog isDialogModalOpen={isConfirmDateUpdateDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">
            Confirm Site Visit Rescheduling
          </h2>
          <p>
            You're about to update the site visit to a new date and time:
            <span className="text-black font-semibold">
              {" "}
              {date} at {time}.
            </span>
          </p>
          <p>
            Please confirm that this change has been agreed upon with the buyer.
          </p>
          <p>Note: Changes cannot be modified once confirmed</p>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleConfirmDateUpdateDialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              const response = await updateSiteVisit(
                updatedSiteInformation,
                companyId
              );
              if (response) {
                toggleConfirmDateUpdateDialog(false);
              }
              setLoading(false);
            }}
            disabled={loading}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
