import React from "react";
import { useDispatch } from "react-redux";
import Button from "../ui/button";
import Dialog from "../ui/dialog";
import { setDisplayNoInternetDialog } from "../../config/reducers/authSlice";

function NoInternetDialog({ isNoInternetDialogOpen }) {
  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    window.location.reload();
    dispatch(setDisplayNoInternetDialog(false));
  };

  return (
    <Dialog isDialogModalOpen={isNoInternetDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[448px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Ooops!</h2>
          <p>No internet connection. Check your connection.</p>
        </div>
        <Button className="mx-auto block" onClick={handleCloseDialog}>
          Try Again
        </Button>
      </div>
    </Dialog>
  );
}

export default NoInternetDialog;
