import React from "react";
import InnerPageLayout from "../../../../../components/Layout/innerPageLayout";
import useAdmin from "../../../../../hooks/useAdmin";
import Loader from "../../../../../components/ui/loader";
import StageTwo from "../../../../../components/DueDiligence/Admin/stages/stageTwo";

export default function StageTwoPage() {
  const { loading, id } = useAdmin();

  return (
    <InnerPageLayout
      type="admin"
      title="BACF Payment"
      back={`/admin/company-info/${id}`}
    >
      {loading ? <Loader /> : <StageTwo />}
    </InnerPageLayout>
  );
}
