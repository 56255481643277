import Icons from "../../../assets/icons";
import Button from "../../ui/button";
import AddTeamModal from "./addTeam/addTeamModal";
import InviteSentDialog from "./addTeam/inviteSentDialog";
import useTeamHeader from "./useTeamHeader";
import { USER_TYPE } from "../../../utils/constants";

export default function TeamHeader({
  searchText,
  handleSearch,
  fetchTeam,
  currentTeamPage,
  role,
  type,
  id,
  createTeamMember,
}) {
  const {
    email,
    setEmail,
    isAddTeamModalOpen,
    isInviteSentDialogOpen,
    toggleAddTeamModal,
    toggleInviteSentDialog,
  } = useTeamHeader(searchText, fetchTeam, currentTeamPage, type, id);

  return (
    <div className="p-4 md:p-6 md:pb-0 flex flex-col lg:flex-row lg:justify-between lg:items-center gap-4 bg-white border md:border-none rounded-t-lg md:rounded-none mb-4 md:mb-0">
      <p className="text-base">
        {(type === "user" && role !== USER_TYPE.ADMIN) || type === "admin"
          ? "Overview and Control of Your Team Members"
          : "Overview of Team Members"}
      </p>
      <div className="flex items-center gap-6">
        {((type === "user" && role !== USER_TYPE.ADMIN) ||
          type === "admin") && (
          <Button
            className="py-2 px-3 w-fit lg:min-w-[174px] h-fit"
            onClick={() => toggleAddTeamModal(true)}
          >
            <div className="flex items-center gap-2">
              {Icons.ic_add}
              <p className="text-sm capitalize tracking-[0]">
                <span className="block lg:hidden">Add</span>
                <span className="hidden lg:block">Add New Member</span>
              </p>
            </div>
          </Button>
        )}
      </div>

      <AddTeamModal
        toggleAddTeamModal={toggleAddTeamModal}
        isAddTeamModalOpen={isAddTeamModalOpen}
        setEmail={setEmail}
        toggleInviteSentDialog={toggleInviteSentDialog}
        createTeamMember={createTeamMember}
      />

      {isInviteSentDialogOpen && (
        <InviteSentDialog
          toggleInviteSentDialog={toggleInviteSentDialog}
          isInviteSentDialogOpen={isInviteSentDialogOpen}
          email={email}
        />
      )}
    </div>
  );
}
