import { toast } from "react-toastify";
import api from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  initialize,
  logout,
  setDisplaySessionTimeoutDialog,
  setUser,
} from "../config/reducers/authSlice";
import {
  setLoading,
  setRequests,
  setTeam,
} from "../config/reducers/adminSlice";
import {
  setBacfHistory,
  setBacfPrice,
  setFinalize,
  setMeetingDetails,
  setProxyUsers,
  setQuestionnaire,
  setSiteVisit,
  setSiteVisitQuestionnaire,
  setUserRequest,
} from "../config/reducers/userSlice";
import { PROJECT_TYPE, USER_TYPE } from "../utils/constants";
import { refreshToken } from "../utils/api/refreshAuth";

function handleError(error, showToast, message) {
  if (error?.response?.status === 400) {
    if (showToast) {
      if (Array.isArray(error?.response?.data?.message)) {
        toast.error(error?.response?.data?.message[0]);
      } else {
        toast.error(message);
      }
    }
  }
  if (error?.response?.status === 406) {
    toast.error(message);
  }
}

export default function useUser() {
  const token = localStorage.getItem("refresh-token");
  const dispatch = useDispatch();
  const { userRequest, meetingDetails, questionnaire, proxyUsers } =
    useSelector((state) => state.user);
  const { team } = useSelector((state) => state.admin);

  const fetchUserData = async () => {
    try {
      const response = await api.get("/users/getUser");
      if (response.data.role === USER_TYPE.PROXY) {
        const res = await fetchSuperUser();

        dispatch(
          initialize({
            isAuthenticated: true,
            user: {
              ...response.data,
              company_name: res.data.company_name,
              company_address: res.data.company_address,
            },
            refreshToken: token,
          })
        );
      } else {
        dispatch(
          initialize({
            isAuthenticated: true,
            user: { ...response.data },
            refreshToken: token,
          })
        );
      }
    } catch (error) {
      dispatch(
        initialize({ isAuthenticated: false, user: null, refreshToken: token })
      );
      if (error?.response?.data?.message === "User not found") {
        dispatch(logout({}));
      }
    }
  };

  const fetchSuperUser = async () => {
    try {
      const response = await api.get(`/users/getProxySuperUser`);
      return { data: response.data };
    } catch (error) {
      handleError(error, false);
      return { error: error?.response?.data?.message };
    }
  };

  const updateUser = async (values) => {
    try {
      const response = await api.put("/users/updateUser", values);
      dispatch(setUser({ user: { ...response.data } }));

      toast.success("Information Updated Successfully!");
    } catch (error) {
      handleError(error);
      toast.error("There was an error updating your Information");
    }
  };

  const verifyPassword = async (values) => {
    try {
      await api.post("/users/verify/password", values);

      return true;
    } catch (error) {
      handleError(error);
      toast.error("There was an error updating your Information");
      return false;
    }
  };

  const changePassword = async (values) => {
    try {
      await api.put("/users/changePassword", values);
      toast.success("Password change successful!");

      return true;
    } catch (error) {
      handleError(error);
      toast.error(error?.response?.data?.message);
      return false;
    }
  };

  const createAdmin = async (values) => {
    try {
      const response = await api.post("/auth/createUserAdmin", {
        ...values,
        role: "admin",
      });

      dispatch(
        setTeam({
          team: [...team, response.data.data],
        })
      );

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);
      return false;
    }
  };

  const resendAdminInvite = async (email) => {
    try {
      const response = await api.post(`/auth/resendAdminInvite/${email}`);
      if (response) {
        toast.success(`New invite link sent to ${email}`);
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);
      return false;
    }
  };

  const getAllAdmins = async (searchText, limit, page, sort = "desc") => {
    dispatch(setLoading({ loading: true }));
    try {
      if (searchText && searchText.trim() !== "") {
        const response = await api.post(
          `/users/searchAdmin?limit=${limit}&page=${page}`,
          { searchText }
        );

        dispatch(
          setTeam({
            team: response.data,
            totalTeam: response.data?.total || response.data.length,
          })
        );
      } else {
        const response = await api.get(
          `/users?filter=admin&page=${page}&limit=${limit}&sort=${sort}`
        );

        dispatch(
          setTeam({
            team: response.data.users,
            totalTeam: response.data.total,
          })
        );
      }
    } catch (error) {
      handleError(error, false);
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const getAllProxyUsers = async (
    searchText,
    limit,
    page,
    id,
    sort = "desc"
  ) => {
    dispatch(setProxyUsers({ loadingProxyUser: true }));

    try {
      if (searchText && searchText.trim() !== "") {
        const response = await api.post(
          `/users/searchUsers?limit=${limit}&page=${page}`,
          { searchText }
        );

        dispatch(
          setProxyUsers({
            proxyUsers: response.data,
            totalTeam: response.data?.total || response.data.length, // remove response.data.length
          })
        );
      } else {
        const response = await api.get(
          `/users/getTeamMembers/${id}?page=${page}&limit=${limit}&sort=${sort}`
        );

        dispatch(
          setProxyUsers({
            proxyUsers: response.data.users,
            totalTeam: response.data.total,
          })
        );
      }
    } catch (error) {
      handleError(error, false);
    } finally {
      dispatch(setProxyUsers({ loadingProxyUser: false }));
    }
  };

  const createProxyUser = async (values) => {
    try {
      const response = await api.post("/auth/createProxyUser", {
        ...values,
      });

      dispatch(
        setProxyUsers({
          proxyUsers: [...proxyUsers, response.data.data],
        })
      );

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);
      return false;
    }
  };

  const getUserById = async (userId) => {
    try {
      const response = await api.get(`/users/${userId}`);
      return { data: response.data };
    } catch (error) {
      handleError(error, false);

      return { error: error?.response?.data?.message };
    }
  };

  const deleteUser = async (userId, team, role) => {
    if (userId) {
      try {
        await api.delete(`/users/${userId}`);

        const remainingTeamMembers = team.filter(
          (user) => user?.superuser_id ?? user?._id !== userId
        );
        dispatch(
          setTeam({
            team: remainingTeamMembers,
          })
        );
        toast.success(
          role === "user"
            ? "User removed successfully"
            : "Admin removed successfully"
        );
        return true;
      } catch (error) {
        handleError(error, true, error?.response?.data?.message);
        return false;
      }
    }
  };

  const deleteTeamMember = async (userId, team) => {
    if (userId) {
      try {
        await api.delete(`/users/deleteTeamMember/${userId}`);

        const remainingTeamMembers = team.filter(
          (user) => user?._id !== userId
        );
        dispatch(
          setProxyUsers({
            proxyUsers: remainingTeamMembers,
          })
        );
        toast.success("Team member removed successfully");
        return true;
      } catch (error) {
        handleError(error, true, error?.response?.data?.message);
        return false;
      }
    }
  };

  // ============== Gas request
  const getAllRequests = async (activeTab, searchText, limit, page) => {
    const filter =
      activeTab === "Not Started"
        ? "not_started"
        : activeTab === "Completed"
        ? "complete"
        : "progress";
    dispatch(setLoading({ loading: true }));
    try {
      if (searchText && searchText?.trim() !== "") {
        const response = await api.post(
          "/due-diligence/searchGasRequest",
          activeTab === "All"
            ? {
                searchText,
              }
            : {
                searchText,
                filterDueDiligence: filter,
              }
        );

        dispatch(
          setRequests({
            requests: response.data,
            totalRequest: response.data.total || response.data.length,
          })
        );
      } else {
        const response = await api.get(
          `/due-diligence/findAllGasRequest?limit=${limit}&page=${page}&${
            activeTab !== "All" ? `filter=${filter}` : ""
          }`
        );

        if (Array.isArray(response?.data?.data)) {
          dispatch(
            setRequests({
              requests: response.data.data,
              totalRequest: response.data.total,
            })
          );
        } else {
          dispatch(
            setRequests({
              requests: [],
              totalRequest: 0,
            })
          );
        }
      }
    } catch (error) {
      handleError(error, false);
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const getUserRequest = async (userId) => {
    try {
      const response = await api.get(
        `/due-diligence/findOneGasRequest/${userId}`
      );
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
            project_type: response.data.project_type,
          })
        );
      }
      return { data: response.data };
    } catch (error) {
      handleError(error, false);

      return { error: error?.response?.data?.message };
    }
  };

  const createGasRequest = async (values) => {
    try {
      const response = await api.post(
        "/due-diligence/createGasRequest",
        values
      );

      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
            project_type: response.data.project_type,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const scheduleMeeting = async (id) => {
    try {
      const response = await api.post(`/calendly/scheduled-events/${id}`);
      if (response?.data) {
        dispatch(
          setMeetingDetails({
            meetingDetails: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const cancelMeeting = async (id) => {
    try {
      const response = await api.delete(`/calendly/cancel-schedule/${id}`);
      if (response?.data) {
        dispatch(
          setMeetingDetails({
            meetingDetails: null,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const getMeetingDetails = async (id) => {
    try {
      const response = await api.get(`/calendly/scheduled-events/${id}`);
      if (response?.data) {
        dispatch(
          setMeetingDetails({
            meetingDetails: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, false);

      return false;
    }
  };

  const markMeetingAsHeld = async (id) => {
    try {
      const response = await api.post(`due-diligence/update-status/${id}`, {
        status: "meeting_held",
        admin_note: "",
      });
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
          })
        );
        dispatch(
          setMeetingDetails({
            meetingDetails: {
              ...meetingDetails,
              status: response.data.status,
            },
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const acceptGasRequestForm = async (id) => {
    try {
      const response = await api.post(`due-diligence/update-status/${id}`, {
        status: "accepted",
        admin_note: "",
      });
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const rejectGasRequestForm = async (id, note) => {
    try {
      const response = await api.post(`due-diligence/update-status/${id}`, {
        status: "rejected",
        admin_note: note,
      });
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const restartGasRequest = async () => {
    try {
      const response = await api.put("/due-diligence/restartGasRequest");

      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: null,
            project_type: "",
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const createPaymentAccounts = async () => {
    try {
      const response = await api.post(`monnify/create_bacf_pay_account`);
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const getPaymentHistory = async () => {
    try {
      const response = await api.get(`/monnify/getUserHistory`);
      if (response?.data) {
        dispatch(
          setBacfHistory({
            bacfHistory: response.data.length === 0 ? null : response.data[0],
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const getLastPaymentHistory = async (userId) => {
    try {
      const response = await api.get(`/monnify/getLastPayment/${userId}`);
      if (response?.data) {
        dispatch(
          setBacfHistory({
            bacfHistory: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const getBacfPrice = async () => {
    try {
      const response = await api.get(`/monnify/getPrice`);
      if (response?.data) {
        dispatch(
          setBacfPrice({
            bacfPrice: response.data.bacf_price,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const confirmPayment = async (id) => {
    try {
      const response = await api.post(
        `due-diligence/confirm-bacf-payment/${id}`
      );
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  // ============== NDA
  const uploadNdaFile = async (id, values) => {
    try {
      const response = await api.post(
        `/due-diligence/nda/upload?userId=${id}`,
        values
      );
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const updateNdaStatus = async (id, values) => {
    try {
      const response = await api.put(
        `/due-diligence/nda/updateStatus?userId=${id}`,
        values
      );
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const rejectNda = async (id, values) => {
    try {
      const response = await api.put(
        `/due-diligence/nda/comment?userId=${id}`,
        values
      );
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const resetNda = async (id) => {
    try {
      const response = await api.put(`/due-diligence/nda/reset/${id}`);
      if (response?.data) {
        dispatch(
          setUserRequest({
            userRequest: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  // ============== Questionnaire
  const getQuestionnaireDetails = async (id) => {
    try {
      const response = await api.get(`/request/questionnaire/${id}`);

      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: response.data?.result
              ? {
                  ...response.data?.result,
                  request_detail: response.data?.request_detail,
                }
              : {
                  __v: response.data?.request_detail?.__v,
                  _id: response.data?.request_detail?._id,
                  userId: response.data?.request_detail?.userId,
                  status: "pending",
                  project_type: response.data?.request_detail?.natureOfBusiness,
                  request_detail: response.data?.request_detail,
                  CommercialDueDiligence: null,
                  EconomicDueDiligence: null,
                  Extras: null,
                  ReputationDueDiligence: null,
                  TechnicalDueDiligence: null,
                },
          })
        );

        return {
          data: response?.data?.status
            ? response.data
            : { ...response.data, status: "pending" },
        };
      }
    } catch (error) {
      handleError(error, false);

      return { error: error?.response?.data?.message };
    }
  };

  const uploadFile = async (formData) => {
    try {
      const response = await api.post("/cloudinary/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response?.data) {
        return { data: response.data };
      }
    } catch (error) {
      if (error?.message === "Network Error") {
        try {
          await refreshToken();
          error.config.headers["Content-Type"] = "multipart/form-data";

          // Retry the original request
          const res = await api(error.config);
          return { data: res.data };
        } catch (refreshError) {
          dispatch(setDisplaySessionTimeoutDialog(true));
        }
      } else {
        const errorMessage = error?.response?.data?.message;
        handleError(error, true, errorMessage);
        return { error: errorMessage };
      }
    }
  };

  const request = async (values) => {
    try {
      const response = await api.post(
        `/due-diligence/createRequestDetail`,
        values
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: questionnaire?.ReputationDueDiligence
              ? { ...questionnaire, request_detail: response.data }
              : {
                  ...questionnaire,
                  request_detail: response.data,
                  __v: response.data?.__v,
                  _id: response.data?._id,
                  userId: response.data?.userId,
                  status: "pending",
                  project_type: response.data?.natureOfBusiness,
                  CommercialDueDiligence: null,
                  EconomicDueDiligence: null,
                  Extras: null,
                  ReputationDueDiligence: null,
                  TechnicalDueDiligence: null,
                },
          })
        );
        // add toast
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const reputation = async (project_type, values, type) => {
    try {
      const response = await api.post(
        `questionnaire/${project_type}/createReputation${
          type ? `?type=${type}` : ""
        }`,
        values
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, ...response.data },
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const commercial = async (project_type, values, type) => {
    try {
      const response = await api.post(
        `/questionnaire/${project_type}/createCommercial${
          type ? `?type=${type}` : ""
        }`,
        values
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, ...response.data },
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const technical = async (project_type, values, type) => {
    try {
      const response = await api.post(
        `/questionnaire/${project_type}/createTechnical${
          type ? `?type=${type}` : ""
        }`,
        values
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, ...response.data },
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const economic = async (project_type, values, type) => {
    try {
      const response = await api.post(
        `/questionnaire/${project_type}/createEconomic${
          type ? `?type=${type}` : ""
        }`,
        values
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, ...response.data },
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const extra = async (project_type, values, type) => {
    try {
      const response = await api.post(
        `/questionnaire/${project_type}/createExtra${
          type ? `?type=${type}` : ""
        }`,
        values
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, ...response.data },
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const submitQuestionnaire = async (status, project_type, type) => {
    try {
      const response = await api.post(
        project_type === PROJECT_TYPE.POWER
          ? "/questionnaire/power/submitPower"
          : `/questionnaire/commgas/submit?type=${type}`,
        { status: status }
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, ...response.data },
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const approveQuestionnaire = async (values, project_type, type, id) => {
    try {
      const response = await api.post(
        project_type === PROJECT_TYPE.POWER
          ? `/questionnaire/power/updateStatus/${id}`
          : `/questionnaire/commgas/updateStatus/${id}?type=${type}`,
        values
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, ...response.data },
          })
        );
        toast.success("Questionnaire has been approved!");
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const submitQuestionnaireAdminNote = async (project_type, type, id, name) => {
    try {
      const response = await api.post(
        project_type === PROJECT_TYPE.POWER
          ? `/questionnaire/power/${name}/${id}`
          : `/questionnaire/commgas/${name}/${id}?type=${type}`
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, ...response.data },
          })
        );
        toast.success(
          "Feedback has been sent to buyer to update their questionnaire"
        );
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        toast.error(error?.response?.data?.message);
      }
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const questionnaireAdminNote = async (
    values,
    project_type,
    type,
    id,
    name
  ) => {
    try {
      const response = await api.put(
        project_type === PROJECT_TYPE.POWER
          ? `/questionnaire/power/${name}/${id}`
          : `/questionnaire/commgas/${name}/${id}?type=${type}`,
        values
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, ...response.data },
          })
        );
        return true;
      }
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const requestDetailAdminNote = async (values, id) => {
    try {
      const response = await api.post(
        `/due-diligence/adminCommentRequestDetail/${id}`,
        values
      );
      if (response?.data) {
        dispatch(
          setQuestionnaire({
            questionnaire: { ...questionnaire, request_detail: response.data },
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  // ============== Site visit
  const createSiteVisit = async (values) => {
    try {
      const response = await api.post("/site-visit", values);

      if (response?.data) {
        dispatch(
          setSiteVisit({
            siteVisit: response.data,
          })
        );
        dispatch(
          setUserRequest({
            userRequest: { ...userRequest, site_visit_status: "pending" },
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const updateSiteVisit = async (values, id) => {
    try {
      const response = await api.put(`/site-visit/update/${id}`, values);

      if (response?.data) {
        dispatch(
          setSiteVisit({
            siteVisit: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const acceptSiteVisit = async (values, id) => {
    try {
      const response = await api.put(`/site-visit/${id}`, values);

      if (response?.data) {
        dispatch(
          setSiteVisit({
            siteVisit: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const siteVisitHeld = async (values, id) => {
    try {
      const response = await api.put(`/site-visit/upload/${id}`, values);

      if (response?.data) {
        dispatch(
          setSiteVisit({
            siteVisit: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const getSiteVisitDetails = async (id) => {
    try {
      const response = await api.get(`/site-visit/${id}`);

      if (response?.data?.status) {
        dispatch(
          setSiteVisit({
            siteVisit: response.data,
          })
        );
      }

      return { data: response?.data };
    } catch (error) {
      handleError(error, false);

      return false;
    }
  };

  // ============== Site visit Questionnaire
  const getSiteVisitQuestionnaireDetails = async (id) => {
    try {
      const response = await api.get(`/site-visit/siteQues/${id}`);

      if (response?.data) {
        dispatch(
          setSiteVisitQuestionnaire({
            siteVisitQuestionnaire: response?.data?.status
              ? response.data
              : null,
          })
        );

        return { data: response.data };
      }
    } catch (error) {
      handleError(error, false);

      return { error: error?.response?.data?.message };
    }
  };

  const submitSiteVisitQuestionnaire = async (id, values) => {
    try {
      const response = await api.put(
        `/site-visit/siteQues/submit/${id}`,
        values
      );
      if (response?.data?.status) {
        dispatch(
          setSiteVisitQuestionnaire({
            siteVisitQuestionnaire: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const saveSiteVisitQuestionnaire = async (project_type, values) => {
    try {
      const response = await api.post(
        `/site-visit/siteQues/${project_type}`,
        values
      );
      if (response?.data) {
        dispatch(
          setSiteVisitQuestionnaire({
            siteVisitQuestionnaire: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const approveSiteVisitQuestionnaire = async (id) => {
    try {
      const response = await api.put(
        `/site-visit/siteQues/updateStatus/${id}`,
        {
          status: "approved",
        }
      );
      if (response?.data) {
        dispatch(
          setSiteVisitQuestionnaire({
            siteVisitQuestionnaire: response.data,
          })
        );
        toast.success("Site visit questionnaire has been approved!");
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const siteVisitQuestionnaireAdminNote = async (values, name, id) => {
    try {
      const response = await api.put(`/site-visit/${name}/${id}`, values);
      if (response?.data) {
        dispatch(
          setSiteVisitQuestionnaire({
            siteVisitQuestionnaire: response.data,
          })
        );
        toast.success(
          "Feedback has been sent to buyer to update their site visit questionnaire"
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  // ============== Finalize
  const getFinalizeDetails = async (id) => {
    try {
      const response = await api.get(`/due-diligence/finalize/${id}`);

      if (response?.data) {
        dispatch(
          setFinalize({
            finalize: response.data,
          })
        );
      }

      return { data: response?.data };
    } catch (error) {
      handleError(error, false);

      return false;
    }
  };

  const createAdditionalDetail = async (values, id) => {
    try {
      const response = await api.post(
        `/due-diligence/finalizeAddtional/${id}`,
        values
      );

      if (response?.data) {
        dispatch(
          setFinalize({
            finalize: response.data,
          })
        );
        toast.success("Request documents have been sent to buyer");
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const updateAdditionalDetail = async (values, id) => {
    try {
      const response = await api.put(
        `/due-diligence/finalize/userUpdate?detailId=${id}`,
        values
      );

      if (response?.data) {
        dispatch(
          setFinalize({
            finalize: response.data,
          })
        );
        toast.success("Request documents have been sent to buyer");
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const addFinalizeAdminComment = async (values, userId) => {
    try {
      const response = await api.put(
        `/due-diligence/finalize/adminUpdate/${userId}`,
        values
      );

      if (response?.data) {
        dispatch(
          setFinalize({
            finalize: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const acceptFinalizeDocument = async (id) => {
    try {
      const response = await api.put(
        `/due-diligence/finalize/approveDocument?userId=${id}`
      );

      if (response?.data) {
        dispatch(
          setFinalize({
            finalize: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  const approveFinalize = async (values, id) => {
    try {
      const response = await api.put(
        `/due-diligence/finalize/approve?userId=${id}`,
        values
      );

      if (response?.data) {
        dispatch(
          setFinalize({
            finalize: response.data,
          })
        );
      }

      return true;
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      return false;
    }
  };

  return {
    fetchUserData,
    updateUser,
    verifyPassword,
    changePassword,
    createAdmin,
    resendAdminInvite,
    createProxyUser,
    getAllProxyUsers,

    getAllAdmins,
    getUserById,
    deleteUser,
    deleteTeamMember,

    getAllRequests,
    getUserRequest,
    createGasRequest,
    acceptGasRequestForm,
    rejectGasRequestForm,
    restartGasRequest,

    scheduleMeeting,
    cancelMeeting,
    getMeetingDetails,
    markMeetingAsHeld,

    createPaymentAccounts,
    getBacfPrice,
    getPaymentHistory,
    getLastPaymentHistory,
    confirmPayment,

    uploadNdaFile,
    updateNdaStatus,
    rejectNda,
    resetNda,

    getQuestionnaireDetails,
    uploadFile,
    request,
    reputation,
    commercial,
    technical,
    economic,
    extra,
    submitQuestionnaire,
    approveQuestionnaire,
    submitQuestionnaireAdminNote,
    questionnaireAdminNote,
    requestDetailAdminNote,

    createSiteVisit,
    updateSiteVisit,
    acceptSiteVisit,
    siteVisitHeld,
    getSiteVisitDetails,

    getSiteVisitQuestionnaireDetails,
    saveSiteVisitQuestionnaire,
    submitSiteVisitQuestionnaire,
    approveSiteVisitQuestionnaire,
    siteVisitQuestionnaireAdminNote,

    getFinalizeDetails,
    createAdditionalDetail,
    updateAdditionalDetail,
    addFinalizeAdminComment,
    acceptFinalizeDocument,
    approveFinalize,
  };
}
