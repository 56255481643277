import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  PROJECT_TYPE,
  STAGE_FOUR_STATUS,
  USER_TYPE,
} from "../../../../utils/constants";
import { countNonNullValues } from "../../../../utils/functions";

export default function QuestionnareTab({ tabs, activeTab, handleTabClick }) {
  const { user } = useSelector((state) => state.auth);
  const { questionnaire, project_type } = useSelector((state) => state.user);

  const [commentCount, setCommentCount] = useState([]);

  const countAdminComments = () => {
    const adminComments = {
      request: {
        company_name:
          questionnaire?.request_detail?.adminNote?.company_name || null,
        company_address:
          questionnaire?.request_detail?.adminNote?.company_address || null,
        natureOfBusiness:
          questionnaire?.request_detail?.adminNote?.natureOfBusiness || null,
        endProducts:
          questionnaire?.request_detail?.adminNote?.endProducts || null,
        franchiseOrDistributionArea:
          questionnaire?.request_detail?.adminNote
            ?.franchiseOrDistributionArea || null,
        likelyDateOfFirstGasDelivery:
          questionnaire?.request_detail?.adminNote
            ?.likelyDateOfFirstGasDelivery || null,
        likelyDateOfFirstDeliveryOfNGLOrLPG:
          questionnaire?.request_detail?.adminNote
            ?.likelyDateOfFirstDeliveryOfNGLOrLPG || null,
        proposedDurationOfContract:
          questionnaire?.request_detail?.adminNote
            ?.proposedDurationOfContract || null,
        totalContractVolume:
          questionnaire?.request_detail?.adminNote?.totalContractVolume || null,
        totalContractVolumeForNGLOrLPG:
          questionnaire?.request_detail?.adminNote
            ?.totalContractVolumeForNGLOrLPG || null,
        proposedLocationOfProject:
          questionnaire?.request_detail?.adminNote?.proposedLocationOfProject ||
          null,
        gasContractQuantity:
          questionnaire?.request_detail?.adminNote?.gasContractQuantity || null,
        nglContractQuantity:
          questionnaire?.request_detail?.adminNote?.nglContractQuantity || null,
        lpgContractQuantity:
          questionnaire?.request_detail?.adminNote?.lpgContractQuantity || null,
      },
      reputation: {
        companyName:
          questionnaire?.ReputationDueDiligence?.companyName?.adminNote || null,
        registeredOfficeAddress:
          questionnaire?.ReputationDueDiligence?.registeredOfficeAddress
            ?.adminNote || null,
        dateOfIncorporation:
          questionnaire?.ReputationDueDiligence?.dateOfIncorporation
            ?.adminNote || null,
        companyRegistrationNumber:
          questionnaire?.ReputationDueDiligence?.companyRegistrationNumber
            ?.adminNote || null,
        equityDistribution:
          questionnaire?.ReputationDueDiligence?.equityDistribution
            ?.adminNote || null,
        partnerContributions:
          questionnaire?.ReputationDueDiligence?.partnerContributions
            ?.adminNote || null,
        companyExperienceSummary:
          questionnaire?.ReputationDueDiligence?.companyExperienceSummary
            ?.adminNote || null,

        ethicsRequirement: {
          politicallyExposedPerson:
            questionnaire?.ReputationDueDiligence?.ethicsRequirement
              ?.politicallyExposedPerson?.adminNote || null,
          politicallyExposedPersonDetails:
            questionnaire?.ReputationDueDiligence?.ethicsRequirement
              ?.politicallyExposedPersonDetails?.adminNote || null,
          criminalConviction:
            questionnaire?.ReputationDueDiligence?.ethicsRequirement
              ?.criminalConviction?.adminNote || null,
          criminalConvictionDetails:
            questionnaire?.ReputationDueDiligence?.ethicsRequirement
              ?.criminalConvictionDetails?.adminNote || null,
        },

        holdValidShipperLicense:
          questionnaire?.ReputationDueDiligence.holdValidShipperLicense
            ?.adminNote || null,
        willingToCollaborateForShipperLicense:
          questionnaire?.ReputationDueDiligence
            .willingToCollaborateForShipperLicense?.adminNote || null,
        planForSecuringShipperLicense:
          questionnaire?.ReputationDueDiligence.planForSecuringShipperLicense
            ?.adminNote || null,
        willingToAppointGACNAsShipperAgent:
          questionnaire?.ReputationDueDiligence
            .willingToAppointGACNAsShipperAgent?.adminNote || null,
        planForSecuringShipperAgent:
          questionnaire?.ReputationDueDiligence.planForSecuringShipperAgent
            ?.adminNote || null,

        cacCertificate:
          questionnaire?.ReputationDueDiligence?.cacCertificate?.adminNote ||
          null,

        auditedFinancialReportsYear1:
          questionnaire?.ReputationDueDiligence?.auditedFinancialReportsYear1
            ?.adminNote || null,
        auditedFinancialReportsYear2:
          questionnaire?.ReputationDueDiligence?.auditedFinancialReportsYear2
            ?.adminNote || null,
        auditedFinancialReportsYear3:
          questionnaire?.ReputationDueDiligence?.auditedFinancialReportsYear3
            ?.adminNote || null,

        deedOfAdherenceDeclaration:
          questionnaire?.ReputationDueDiligence?.deedOfAdherenceDeclaration
            ?.adminNote || null,
      },
      commercialPower: {
        projectDescription:
          questionnaire?.CommercialDueDiligence?.projectDescription
            ?.adminNote || null,
        projectedProductStreams:
          questionnaire?.CommercialDueDiligence?.projectedProductStreams
            ?.adminNote || null,
        plannedGenerationCapacity:
          questionnaire?.CommercialDueDiligence?.plannedGenerationCapacity
            ?.adminNote || null,
        feedstockRequirements:
          questionnaire?.CommercialDueDiligence?.feedstockRequirements
            ?.adminNote || null,
        offTakePlanOverview:
          questionnaire?.CommercialDueDiligence?.offTakePlanOverview
            ?.adminNote || null,
        evidenceOfOffTakeAgreements:
          questionnaire?.CommercialDueDiligence?.evidenceOfOffTakeAgreements
            ?.adminNote || null,
        otherSupportingDocument: [
          questionnaire?.CommercialDueDiligence?.otherSupportingDocument?.["0"]
            ?.adminNote || null,
        ],
        projectDeliveryTimescales:
          questionnaire?.CommercialDueDiligence?.projectDeliveryTimescales
            ?.adminNote || null,
        financingPlan:
          questionnaire?.CommercialDueDiligence?.financingPlan?.adminNote ||
          null,
        financialPlanningDocument:
          questionnaire?.CommercialDueDiligence?.financialPlanningDocument
            ?.adminNote || null,

        nbetPowerPurchaseAgreement:
          questionnaire?.CommercialDueDiligence?.statutoryPermits
            ?.nbetPowerPurchaseAgreement?.adminNote || null,

        commitToPaymentAssuranceFramework:
          questionnaire?.CommercialDueDiligence
            ?.commitToPaymentAssuranceFramework?.adminNote || null,
        reasonsForNotCommitting:
          questionnaire?.CommercialDueDiligence?.reasonsForNotCommitting
            ?.adminNote || null,

        letterOfCreditworthiness:
          questionnaire?.CommercialDueDiligence?.letterOfCreditworthiness
            ?.adminNote || null,

        statutoryPermits: {
          nercLicensing:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.nercLicensing?.adminNote || null,
          tcnEvacuationApplication:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.tcnEvacuationApplication?.adminNote || null,
          environmentalPermits:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.environmentalPermits?.adminNote || null,
          fedMinOfHousingAndEnvStudy:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.fedMinOfHousingAndEnvStudy?.adminNote || null,
          taxRegistration:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.taxRegistration?.adminNote || null,
          gasUseLicense:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.gasUseLicense?.adminNote || null,
          riskAssessmentSafetyHazop:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.riskAssessmentSafetyHazop?.adminNote || null,
          simulationStudies:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.simulationStudies?.adminNote || null,
          pollutionControlEffluentTreatment:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.pollutionControlEffluentTreatment?.adminNote || null,
          constructionOperatingPermits:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.constructionOperatingPermits?.adminNote || null,
          localGovernmentApprovals:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.localGovernmentApprovals?.adminNote || null,
          otherPermits: [
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.otherPermits?.["0"]?.adminNote || null,
          ],
        },
      },
      commercialCommgas: {
        projectDescription:
          questionnaire?.CommercialDueDiligence?.projectDescription
            ?.adminNote || null,
        projectedProductStreams:
          questionnaire?.CommercialDueDiligence?.projectedProductStreams
            ?.adminNote || null,
        plannedGenerationCapacity:
          questionnaire?.CommercialDueDiligence?.plannedGenerationCapacity
            ?.adminNote || null,
        feedstockRequirements:
          questionnaire?.CommercialDueDiligence?.feedstockRequirements
            ?.adminNote || null,
        offTakePlanOverview:
          questionnaire?.CommercialDueDiligence?.offTakePlanOverview
            ?.adminNote || null,
        evidenceOfOffTakeAgreements:
          questionnaire?.CommercialDueDiligence?.evidenceOfOffTakeAgreements
            ?.adminNote || null,
        otherSupportingDocument: [
          questionnaire?.CommercialDueDiligence?.otherSupportingDocument?.["0"]
            ?.adminNote || "",
        ],
        projectDeliveryTimescales:
          questionnaire?.CommercialDueDiligence?.projectDeliveryTimescales
            ?.adminNote || null,
        financingPlan:
          questionnaire?.CommercialDueDiligence?.financingPlan?.adminNote ||
          null,
        financialPlanningDocument:
          questionnaire?.CommercialDueDiligence?.financialPlanningDocument
            ?.adminNote || null,

        commitToPaymentAssuranceFramework:
          questionnaire?.CommercialDueDiligence
            ?.commitToPaymentAssuranceFramework?.adminNote || null,
        reasonsForNotCommitting:
          questionnaire?.CommercialDueDiligence?.reasonsForNotCommitting
            ?.adminNote || null,

        letterOfCreditworthiness:
          questionnaire?.CommercialDueDiligence?.letterOfCreditworthiness
            ?.adminNote || null,

        statutoryPermits: {
          incorporationCertificate:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.incorporationCertificate?.adminNote || null,
          nmdpraLicense: {
            nmdpraGasDistributionLicense:
              questionnaire?.CommercialDueDiligence?.statutoryPermits
                ?.nmdpraLicense?.nmdpraGasDistributionLicense || null,
            nmdpraGasProcessingLicense:
              questionnaire?.CommercialDueDiligence?.statutoryPermits
                ?.nmdpraLicense?.nmdpraGasProcessingLicense || null,
            nmdpraGasTransportationLicense:
              questionnaire?.CommercialDueDiligence?.statutoryPermits
                ?.nmdpraLicense?.nmdpraGasTransportationLicense || null,
          },
          nigerianContentCompliancePlan:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.nigerianContentCompliancePlan?.adminNote || null,
          environmentalPermit:
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.environmentalPermit?.adminNote || null,
          otherPermits: [
            questionnaire?.CommercialDueDiligence?.statutoryPermits
              ?.otherPermits?.["0"]?.adminNote || "",
          ],
        },
      },
      technicalPower: {
        plantNominalInstalledCapacity:
          questionnaire?.TechnicalDueDiligence?.plantNominalInstalledCapacity
            ?.adminNote || null,
        locationAndLandAcquisitionStatus:
          questionnaire?.TechnicalDueDiligence?.locationAndLandAcquisitionStatus
            ?.adminNote || null,
        technologyDeployed:
          questionnaire?.TechnicalDueDiligence?.technologyDeployed?.adminNote ||
          null,

        gasRequirement: {
          volumeRequirement:
            questionnaire?.TechnicalDueDiligence?.gasRequirement
              ?.volumeRequirement?.adminNote || null,
          deliveryPressures:
            questionnaire?.TechnicalDueDiligence?.gasRequirement
              ?.deliveryPressures?.adminNote || null,
          deliveryDate:
            questionnaire?.TechnicalDueDiligence?.gasRequirement?.deliveryDate
              ?.adminNote || null,
          anticipatedDemandSwing:
            questionnaire?.TechnicalDueDiligence?.gasRequirement
              ?.anticipatedDemandSwing?.adminNote || null,
          plantAvailability:
            questionnaire?.TechnicalDueDiligence?.gasRequirement
              ?.plantAvailability?.adminNote || null,
        },

        gasInfrastructureArrangement:
          questionnaire?.TechnicalDueDiligence?.gasInfrastructureArrangement
            ?.adminNote || null,
        gasInfrastructureArrangementDocument:
          questionnaire?.TechnicalDueDiligence
            ?.gasInfrastructureArrangementDocument?.adminNote || null,

        electricityEvacuation: {
          mode:
            questionnaire?.TechnicalDueDiligence?.electricityEvacuation?.mode
              ?.adminNote || null,
          offGridDetails: {
            marketAssessmentStudy:
              questionnaire?.TechnicalDueDiligence?.electricityEvacuation
                ?.offGridDetails?.marketAssessmentStudy?.adminNote || null,
            supplyNicheDescription:
              questionnaire?.TechnicalDueDiligence?.electricityEvacuation
                ?.offGridDetails?.supplyNicheDescription?.adminNote || null,
          },
          onGridDetails: {
            evacuationArrangement:
              questionnaire?.TechnicalDueDiligence?.electricityEvacuation
                ?.onGridDetails?.evacuationArrangement?.adminNote || null,
            tcnApprovedStudy:
              questionnaire?.TechnicalDueDiligence?.electricityEvacuation
                ?.onGridDetails?.tcnApprovedStudy?.adminNote || null,
          },
        },

        projectEngineeringStatus:
          questionnaire?.TechnicalDueDiligence?.projectEngineeringStatus
            ?.adminNote || null,
        projectEngineeringStatusDocument:
          questionnaire?.TechnicalDueDiligence?.projectEngineeringStatusDocument
            ?.adminNote || null,

        statusOfEpcContracts:
          questionnaire?.TechnicalDueDiligence?.statusOfEpcContracts
            ?.adminNote || null,
        statusOfEpcContractsDocument:
          questionnaire?.TechnicalDueDiligence?.statusOfEpcContractsDocument
            ?.adminNote || null,

        evidenceOfTechnicalPartners:
          questionnaire?.TechnicalDueDiligence?.evidenceOfTechnicalPartners
            ?.adminNote || null,
        nigerianContentCompliancePlan:
          questionnaire?.TechnicalDueDiligence?.nigerianContentCompliancePlan
            ?.adminNote || null,

        operationsAndMaintenancePlan:
          questionnaire?.TechnicalDueDiligence?.operationsAndMaintenancePlan
            ?.adminNote || null,
        operationsAndMaintenancePlanDocument:
          questionnaire?.TechnicalDueDiligence
            ?.operationsAndMaintenancePlanDocument?.adminNote || null,

        uptimePerformanceExpectation:
          questionnaire?.TechnicalDueDiligence?.uptimePerformanceExpectation
            ?.adminNote || null,
        uptimePerformanceExpectationDocument:
          questionnaire?.TechnicalDueDiligence
            ?.uptimePerformanceExpectationDocument?.adminNote || null,

        technicalMaintenanceAgreementsStatus:
          questionnaire?.TechnicalDueDiligence
            ?.technicalMaintenanceAgreementsStatus?.adminNote || null,
        technicalMaintenanceAgreementsStatusDocument:
          questionnaire?.TechnicalDueDiligence
            ?.technicalMaintenanceAgreementsStatusDocument?.adminNote || null,

        staffingAndRecruitingPlans:
          questionnaire?.TechnicalDueDiligence?.staffingAndRecruitingPlans
            ?.adminNote || null,
        staffingAndRecruitingPlansDocument:
          questionnaire?.TechnicalDueDiligence
            ?.staffingAndRecruitingPlansDocument?.adminNote || null,

        hsePlans:
          questionnaire?.TechnicalDueDiligence?.hsePlans?.adminNote || null,
        hsePlansDocument:
          questionnaire?.TechnicalDueDiligence?.hsePlansDocument?.adminNote ||
          null,

        existingAndExpansionFacilities:
          questionnaire?.TechnicalDueDiligence?.existingAndExpansionFacilities
            ?.adminNote || null,
        existingAndExpansionFacilitiesDocument:
          questionnaire?.TechnicalDueDiligence
            ?.existingAndExpansionFacilitiesDocument?.adminNote || null,

        operatingManuals:
          questionnaire?.TechnicalDueDiligence?.operatingManuals?.adminNote ||
          null,
        operatingManualsDocument:
          questionnaire?.TechnicalDueDiligence?.operatingManualsDocument
            ?.adminNote || null,

        sustainableDevelopmentAndCommunityEngagementPlans:
          questionnaire?.TechnicalDueDiligence
            ?.sustainableDevelopmentAndCommunityEngagementPlans?.adminNote ||
          null,
        sustainableDevelopmentAndCommunityEngagementPlansDocument:
          questionnaire?.TechnicalDueDiligence
            ?.sustainableDevelopmentAndCommunityEngagementPlansDocument
            ?.adminNote || null,

        constructionSchedule:
          questionnaire?.TechnicalDueDiligence?.constructionSchedule
            ?.adminNote || null,
        constructionScheduleDocument:
          questionnaire?.TechnicalDueDiligence?.constructionScheduleDocument
            ?.adminNote || null,
      },
      technicalCommgas: {
        proposedLocation:
          questionnaire?.TechnicalDueDiligence?.proposedLocation?.adminNote ||
          null,
        plantCapacity:
          questionnaire?.TechnicalDueDiligence?.plantCapacity?.adminNote ||
          null,
        gasRequirement: {
          volumeRequirement:
            questionnaire?.TechnicalDueDiligence?.gasRequirement
              ?.volumeRequirement?.adminNote || null,
          deliveryPressures:
            questionnaire?.TechnicalDueDiligence?.gasRequirement
              ?.deliveryPressures?.adminNote || null,
          deliveryDate:
            questionnaire?.TechnicalDueDiligence?.gasRequirement?.deliveryDate
              ?.adminNote || null,
        },

        technologyStatement:
          questionnaire?.TechnicalDueDiligence?.technologyStatement
            ?.adminNote || null,
        technologyStatementDocument:
          questionnaire?.TechnicalDueDiligence?.technologyStatementDocument
            ?.adminNote || null,

        engineeringDesignStatus:
          questionnaire?.TechnicalDueDiligence?.engineeringDesignStatus
            ?.adminNote || null,
        engineeringDesignStatusDocument:
          questionnaire?.TechnicalDueDiligence?.engineeringDesignStatusDocument
            ?.adminNote || null,

        landAcquisition:
          questionnaire?.TechnicalDueDiligence?.landAcquisition?.adminNote ||
          null,
        landAcquisitionDocument:
          questionnaire?.TechnicalDueDiligence?.landAcquisitionDocument
            ?.adminNote || null,

        statusOfEpcContracts:
          questionnaire?.TechnicalDueDiligence?.statusOfEpcContracts
            ?.adminNote || null,
        statusOfEpcContractsDocument:
          questionnaire?.TechnicalDueDiligence?.statusOfEpcContractsDocument
            ?.adminNote || null,

        evidenceOfTechnicalPartners:
          questionnaire?.TechnicalDueDiligence?.evidenceOfTechnicalPartners
            ?.adminNote || null,

        operationsAndMaintenancePlan:
          questionnaire?.TechnicalDueDiligence?.operationsAndMaintenancePlan
            ?.adminNote || null,
        operationsAndMaintenancePlanDocument:
          questionnaire?.TechnicalDueDiligence
            ?.operationsAndMaintenancePlanDocument?.adminNote || null,
      },
      economicPower: {
        businessPlan:
          questionnaire?.EconomicDueDiligence?.businessPlan?.adminNote || null,

        projectEconomicsAndFinancialRatios:
          questionnaire?.EconomicDueDiligence
            ?.projectEconomicsAndFinancialRatios?.adminNote || null,

        financialRatios:
          questionnaire?.EconomicDueDiligence?.financialRatios?.adminNote ||
          null,

        guaranteesCreditworthiness:
          questionnaire?.EconomicDueDiligence?.guaranteesCreditworthiness
            ?.adminNote || null,

        competitiveAdvantage:
          questionnaire?.EconomicDueDiligence?.competitiveAdvantage
            ?.adminNote || null,

        competitiveAdvantageDocument:
          questionnaire?.EconomicDueDiligence?.competitiveAdvantageDocument
            ?.adminNote || null,

        riskAssessmentStudy:
          questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.adminNote ||
          null,

        riskAssessmentDocument:
          questionnaire?.EconomicDueDiligence?.riskAssessmentDocument
            ?.adminNote || null,

        marketViability:
          questionnaire?.EconomicDueDiligence?.marketViability?.adminNote ||
          null,

        marketViabilityDocument:
          questionnaire?.EconomicDueDiligence?.marketViabilityDocument
            ?.adminNote || null,

        commitmentByInvestors:
          questionnaire?.EconomicDueDiligence?.commitmentByInvestors
            ?.adminNote || null,

        evidenceOfFederalOrStateGovernmentSupport:
          questionnaire?.EconomicDueDiligence
            ?.evidenceOfFederalOrStateGovernmentSupport?.adminNote || null,

        involvementOfNGO:
          questionnaire?.EconomicDueDiligence?.involvementOfNGO?.adminNote ||
          null,
      },
      economicCommgas: {
        businessPlan:
          questionnaire?.EconomicDueDiligence?.businessPlan?.adminNote || null,

        projectEconomics: {
          assumptions:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.assumptions
              ?.adminNote || null,
          duration:
            questionnaire?.EconomicDueDiligence?.projectEconomics?.duration
              ?.adminNote || null,
          indicators: {
            npv:
              questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
                ?.npv?.adminNote || null,
            irr:
              questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
                ?.irr?.adminNote || null,
            roe:
              questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
                ?.roe?.adminNote || null,
            roce:
              questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
                ?.roce?.adminNote || null,
            paybackPeriod:
              questionnaire?.EconomicDueDiligence?.projectEconomics?.indicators
                ?.paybackPeriod?.adminNote || null,
          },
        },

        riskAssessmentStudy: {
          demandRisk:
            questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.demandRisk
              ?.adminNote || null,
          priceRisks:
            questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.priceRisks
              ?.adminNote || null,
          countryPoliticalRisk:
            questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
              ?.countryPoliticalRisk?.adminNote || null,
          marketRisk:
            questionnaire?.EconomicDueDiligence?.riskAssessmentStudy?.marketRisk
              ?.adminNote || null,
          operationalRisk:
            questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
              ?.operationalRisk?.adminNote || null,
          constructionCompletionRisk:
            questionnaire?.EconomicDueDiligence?.riskAssessmentStudy
              ?.constructionCompletionRisk?.adminNote || null,
        },
      },
      extras: {
        additionalNotes:
          questionnaire?.Extras?.additionalNotes?.adminNote || null,
        additionalDocumentation:
          questionnaire?.Extras?.additionalDocumentation?.adminNote || null,
      },
    };
    let requestCommentCount = 0;
    let reputationCommentCount = 0;
    let commercialCommentCount = 0;
    let technicalCommentCount = 0;
    let economicCommentCount = 0;
    let extrasCommentCount = 0;

    requestCommentCount = countNonNullValues(adminComments.request);
    reputationCommentCount = countNonNullValues(adminComments.reputation);
    extrasCommentCount = countNonNullValues(adminComments.extras);

    if (project_type === PROJECT_TYPE.POWER) {
      commercialCommentCount = countNonNullValues(
        adminComments.commercialPower
      );
      technicalCommentCount = countNonNullValues(adminComments.technicalPower);
      economicCommentCount = countNonNullValues(adminComments.economicPower);
    } else {
      commercialCommentCount = countNonNullValues(
        adminComments.commercialCommgas
      );
      technicalCommentCount = countNonNullValues(
        adminComments.technicalCommgas
      );
      economicCommentCount = countNonNullValues(adminComments.economicCommgas);
    }

    setCommentCount([
      requestCommentCount,
      reputationCommentCount,
      commercialCommentCount,
      technicalCommentCount,
      economicCommentCount,
      extrasCommentCount,
    ]);
  };

  useEffect(() => {
    if (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) {
      countAdminComments();
    } else {
      setCommentCount([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire]);

  return (
    <>
      <div className="overflow-auto !border-none">
        <div className="flex w-fit mx-auto">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`min-w-fit border border-grey-200 text-sm text-center font-medium !leading-[20.3px] py-[10px] px-4 cursor-pointer first:rounded-l-[10px] last:rounded-r-[10px] space-x-2 
            ${
              (activeTab === index &&
                questionnaire?.status !== STAGE_FOUR_STATUS.PENDING) ||
              (activeTab === index &&
                (!questionnaire ||
                  questionnaire?.status === STAGE_FOUR_STATUS.PENDING))
                ? "text-black bg-grey-10"
                : index > activeTab && !questionnaire
                ? "text-grey-400 bg-white"
                : activeTab > index ||
                  questionnaire?.status !== STAGE_FOUR_STATUS.PENDING
                ? "text-primary-1"
                : "text-grey-400 bg-white"
            }            
                `}
              onClick={() => {
                if (
                  (user?.role !== USER_TYPE.USER &&
                    user?.role !== USER_TYPE.PROXY) ||
                  !questionnaire ||
                  questionnaire?.status !== STAGE_FOUR_STATUS.PENDING
                )
                  handleTabClick(index);
              }}
            >
              <span
                className={`${
                  commentCount.length > 0 && commentCount[index] > 0
                    ? "inline-block max-w-[105px] truncate align-middle"
                    : ""
                }`}
              >
                {tab}
              </span>
              {commentCount.length > 0 && commentCount[index] > 0 && (
                <span className="bg-error-1 text-white text-xs inline-block w-[22px] h-[17px] rounded-xl">
                  {commentCount[index]}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
