import { useSelector } from "react-redux";
import SignupForm from "../../components/AuthForm/signup";
import AuthLayout from "../../components/Layout/authLayout";

const SignupPage = () => {
  const { isPasswordStep } = useSelector((state) => state.loginForm);

  return (
    <AuthLayout
      type="user"
      page="signup"
      title={isPasswordStep ? "Create a password" : "Create an account"}
      description={
        isPasswordStep
          ? "Create a password to access your account"
          : "Get started by creating your own account"
      }
    >
      <SignupForm />
    </AuthLayout>
  );
};

export default SignupPage;
