import React, { useState } from "react";
import Button from "../ui/button";
import Dialog from "../ui/dialog";
import useAuthentication from "../../hooks/useAuthentication";

function LogoutDialog({ toggleLogoutDialog, isLogoutDialogOpen, type }) {
  const [loading, setLoading] = useState(false);
  const { logUserout } = useAuthentication();

  return (
    <Dialog isDialogModalOpen={isLogoutDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[448px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Log out</h2>
          <p>
            Are you sure you want to log out? Please confirm to proceed with
            logging out of your account.
          </p>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleLogoutDialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              try {
                await logUserout(type);
                toggleLogoutDialog(false);
                setLoading(false);
              } catch (e) {
                setLoading(false);
              }
            }}
            disabled={loading}
            className="!bg-error-1"
            loading={loading}
          >
            Log out
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default LogoutDialog;
