import { useNavigate, useParams } from "react-router-dom";

import Icons from "../../assets/icons";
import Divider from "../ui/divider";
import useAuthentication from "../../hooks/useAuthentication";
import Loader from "../ui/loader";

const CheckEmail = ({ type }) => {
  const { email } = useParams();
  const navigate = useNavigate();
  const { resendConfirmationLink, isLoading } = useAuthentication();

  const loginRoute = type === "admin" ? "/admin/login" : "/login";

  if (!email) navigate(loginRoute);

  return (
    <div className="space-y-6 flex flex-col items-center">
      <img src={Icons.ic_no_email} alt="" />
      <div className="space-y-4">
        <h2 className="text-h2 text-black">Check your mailbox</h2>
        <p className="text-base">
          Tap on the confirmation link sent to{" "}
          <span className="text-black">{email}</span> to verify your account.
        </p>
      </div>
      <Divider text="Didn’t receive any email?" />
      {isLoading ? (
        <Loader />
      ) : (
        <button onClick={() => resendConfirmationLink(email)}>
          <p className="link-underline font-medium">
            Resend confirmation email
          </p>
        </button>
      )}
    </div>
  );
};

export default CheckEmail;
