import React from "react";
import InnerPageLayout from "../../../../../components/Layout/innerPageLayout";
import useAdmin from "../../../../../hooks/useAdmin";
import Loader from "../../../../../components/ui/loader";
import StageThree from "../../../../../components/DueDiligence/Admin/stages/stageThree";

export default function StageThreePage() {
  const { loading, id } = useAdmin();

  return (
    <InnerPageLayout
      type="admin"
      title="Non-Disclosure Agreement"
      back={`/admin/company-info/${id}`}
    >
      {loading ? <Loader /> : <StageThree />}
    </InnerPageLayout>
  );
}
