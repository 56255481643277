import React from "react";
import { useSelector } from "react-redux";

import Badge from "../../../ui/badge";
import Card from "../Card";
import PowerSiteVisitQuestionnaire from "./Power";
import GasSiteVisitQuestionnaire from "./Gas";
import CommercialSiteVisitQuestionnaire from "./Commercial";
import { getProjectName } from "../../../../utils/functions";
import { PROJECT_TYPE } from "../../../../utils/constants";

export default function SiteVisitQuestionnaire({ handleStageSixStep }) {
  const { project_type, siteVisitQuestionnaire } = useSelector(
    (state) => state.user
  );

  return (
    <Card
      title={`Site Visit Questionnaire - ${getProjectName(project_type)}`}
      description="Complete the following questionnaire provided below"
      badge={<Badge status={siteVisitQuestionnaire?.status} />}
    >
      {project_type === PROJECT_TYPE.POWER? (
        <PowerSiteVisitQuestionnaire handleStageSixStep={handleStageSixStep} />
      ) : project_type === PROJECT_TYPE.GAS? (
        <GasSiteVisitQuestionnaire handleStageSixStep={handleStageSixStep} />
      ) : (
        <CommercialSiteVisitQuestionnaire
          handleStageSixStep={handleStageSixStep}
        />
      )}
    </Card>
  );
}
