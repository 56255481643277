import React, { useState } from "react";
import useUser from "../../../../../hooks/useUser";
import Button from "../../../../ui/button";
import Dialog from "../../../../ui/dialog";
import SwitchUI from "../../../../ui/switch";
import FileUpload from "../../../../ui/fileUpload";
import { Form, Formik } from "formik";
import { mixed, object } from "yup";

export default function SiteVisitHeldDialog({
  toggleIsSiteVisitHeldDialog,
  isSiteVisitHeldDialogOpen,
  companyId,
}) {
  const { siteVisitHeld } = useUser();
  const [uploadWithoutReport, setUploadWithoutReport] = useState(false);

  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      isDialogModalOpen={isSiteVisitHeldDialogOpen}
      maxWidth="max-w-[480px]"
    >
      <div className="bg-white rounded-lg w-full max-w-[520px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Confirm Site Visit Completion</h2>
          <p>Are you sure you want to mark the site visit as completed?</p>
          <p>
            This action will update the due diligence status to 'Site Visit
            Held' for the buyer.
          </p>

          <Formik
            initialValues={{
              visit_report: "",
            }}
            onSubmit={() => {}}
            validationSchema={object().shape({
              visit_report: mixed().test(
                "requiredWithoutReport",
                "File is required",
                (value) => {
                  if (uploadWithoutReport) {
                    return false;
                  }

                  return true;
                }
              ),
            })}
            initialErrors={{
              visit_report: "File is required",
            }}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {(props) => {
              const {
                values,
                isValid,
                handleBlur,
                setFieldTouched,
                validateForm,
                setFieldValue,
              } = props;

              return (
                <Form className="space-y-4">
                  {!uploadWithoutReport && (
                    <div className="bg-grey-10 border border-grey-200 rounded-lg py-1 px-2">
                      <FileUpload
                        title="Upload Site Visit Report"
                        name="visit_report"
                        fileType=".pdf, .xls, .xlsx"
                        format="PDF or Excel format"
                        onBlur={handleBlur}
                        onChange={(fieldName, file) => {
                          setFieldTouched(fieldName, true);
                          setFieldValue(fieldName, file);
                        }}
                        required
                      />
                    </div>
                  )}
                  <div className="pt-2 flex items-center justify-between">
                    <p className="text-black font-semibold">
                      Approve Without Report
                    </p>
                    <SwitchUI
                      enabled={uploadWithoutReport}
                      setEnabled={setUploadWithoutReport}
                      title="Confirm without upload"
                      type="site"
                      validate={validateForm}
                    />
                  </div>
                  <div className="flex gap-6">
                    <Button
                      onClick={() => toggleIsSiteVisitHeldDialog(false)}
                      className="bg-transparent border border-grey-200 !text-black"
                    >
                      cancel
                    </Button>
                    <Button
                      onClick={async () => {
                        setLoading(true);
                        const response = await siteVisitHeld(values, companyId);
                        if (response) {
                          toggleIsSiteVisitHeldDialog(false);
                        }
                        setLoading(false);
                      }}
                      disabled={!isValid || loading}
                      type="button"
                      loading={loading}
                    >
                      Confirm
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Dialog>
  );
}
