import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, parse } from "date-fns";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import { Info } from "../../../General/MeetingInfo";
import { setCurrentStep } from "../../../../../config/reducers/userSlice";

export default function SiteInfo({ details }) {
  const dispatch = useDispatch();
  const { siteVisitQuestionnaire } = useSelector((state) => state.user);
  return (
    <div className="space-y-12 pt-4">
      <div>
        <Info
          title="Site Detailed location"
          content={details?.site_location}
          icon={Icons.ic_location}
        />
        <Info
          title="Proposed Date Period"
          content={
            details?.date_of_visit
              ? format(new Date(details?.date_of_visit), "EEEE, LLL dd, yyyy")
              : ""
          }
          icon={Icons.ic_calendar}
        />
        <Info
          title="Time"
          content={
            details?.time_of_visit
              ? format(
                  parse(details?.time_of_visit, "HH:mm", new Date()),
                  "h:mm a"
                )
              : ""
          }
          icon={Icons.ic_clock}
        />
        <Info
          title="Name of Contact person for Site Visit"
          content={details?.full_name}
          icon={Icons.ic_user}
        />
        <Info
          title="Phone Number of Contact Person for Site Visit"
          content={details?.phone_number}
          icon={Icons.ic_phone}
        />
        <Info
          title="Email of Contact Person for Site Visit"
          content={details?.email}
          icon={Icons.ic_email}
        />
      </div>
      <Button
        fullWidth
        onClick={() =>
          dispatch(
            setCurrentStep({
              currentStep: 6,
              userStep: 6,
            })
          )
        }
      >
        {!siteVisitQuestionnaire?.status ||
        siteVisitQuestionnaire?.status === "pending"
          ? "Complete Site Questionnaire"
          : "Site Questionnaire"}
      </Button>
    </div>
  );
}
