import React from "react";

export default function Radio(props) {
  const { className, name, title, options, errors, disabled, value, ...rest } =
    props;
  
  return (
    <div className="space-y-6 text-sm font-semibold text-black">
      {title && <p className="">{title}</p>}
      <div className="space-x-6">
        {options.map((option) => (
          <label className="w-20 relative" key={option.value}>
            <input
              type="radio"
              name={name}
              value={option.value}
              defaultValue={value}
              checked={option.value === value ? true : false}
              className={`appearance-none border border-grey-300 rounded-full w-5 h-5 mb-[-5px] mr-3 checked:outline-none checked:border checked:border-primary-1 transition duration-150 ease-in-out
          ${disabled ? "bg-grey-10" : "bg-white"}
          `}
              {...rest}
              disabled={disabled ? true : false}
            />
            {option.title}
            <span className="checkmark"></span>
          </label>
        ))}

        {errors && (
          <p className="mt-2 text-xs text-error-500 text-left font-normal">
            {errors}
          </p>
        )}
      </div>
    </div>
  );
}
