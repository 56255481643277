import React, { useState } from "react";
import { useSelector } from "react-redux";
import { format, parse } from "date-fns";
import Badge from "../../../../ui/badge";
import Button from "../../../../ui/button";
import AcceptDateDialog from "./acceptDateDialog";
import SiteVisitHeldDialog from "./siteVisitHeldDialog";
import UpdateDateDialog from "./updateDateDialog";
import PdfDownload from "../../../../ui/pdfDownload";
import SiteInfo from "./siteInfo";
import Card from "../../../General/Card";
import ConfirmDateUpdateDialog from "./confirmDateUpdateDialog";
import {
  getDownloadFileLink,
  getFileNameFromLink,
  getFileType,
} from "../../../../../utils/functions";

export default function SiteVisitDetails() {
  const { siteVisit, userRequest } = useSelector((state) => state.user);

  const [updatedSiteInformation, setUpdatedSiteInformation] = useState("");
  const [isAcceptDateDialogOpen, setIsAcceptDateDialogOpen] = useState(false);
  const [isUpdateDateDialogOpen, setIsUpdateDateDialogOpen] = useState(false);
  const [isConfirmDateUpdateDialogOpen, setIsConfirmDateUpdateDialogOpen] =
    useState(false);
  const [isSiteVisitHeldDialogOpen, setIsSiteVisitHeldDialogOpen] =
    useState(false);

  const toggleAcceptDateDialog = (value) => setIsAcceptDateDialogOpen(value);
  const toggleUpdateDateDialog = (value) => setIsUpdateDateDialogOpen(value);
  const toggleConfirmDateUpdateDialog = (value) =>
    setIsConfirmDateUpdateDialogOpen(value);
  const toggleIsSiteVisitHeldDialog = (value) =>
    setIsSiteVisitHeldDialogOpen(value);

  return (
    <div className="flex flex-col md:flex-row gap-6 justify-center">
      <div className=" w-full md:max-w-[432px]">
        <Card
          title="Visit Information"
          badge={
            <Badge
              status={
                siteVisit?.status === "pending"
                  ? "awaiting_acceptance"
                  : siteVisit?.status === "awaiting"
                  ? "awaiting_visit"
                  : siteVisit?.status
              }
            />
          }
        >
          <SiteInfo details={siteVisit} />
          <div
            className={`flex justify-end w-full gap-6 py-4 mt-7  ${
              siteVisit?.visit_report && "!border-none"
            }`}
          >
            {siteVisit?.status === "meeting_held" ? (
              siteVisit?.visit_report && (
                <div className="w-full space-y-4 pt-4 border-t border-grey-200">
                  <p className="text-black leading-[23.2px] font-semibold">
                    Site Visit Report
                  </p>
                  <PdfDownload
                    type={getFileType(siteVisit?.visit_report)}
                    title={getFileNameFromLink(siteVisit?.visit_report)}
                    darkBg
                    pdfFile={getDownloadFileLink(siteVisit?.visit_report)}
                    download
                  />
                </div>
              )
            ) : siteVisit?.status === "awaiting" ? (
              <Button
                className="py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal "
                onClick={() => toggleIsSiteVisitHeldDialog(true)}
              >
                Mark as held
              </Button>
            ) : (
              <>
                <Button
                  className="py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-black"
                  onClick={() => toggleUpdateDateDialog(true)}
                >
                  Update date
                </Button>
                <Button
                  className="py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal "
                  onClick={() => toggleAcceptDateDialog(true)}
                >
                  Accept date
                </Button>
              </>
            )}
          </div>
          {isAcceptDateDialogOpen && (
            <AcceptDateDialog
              isAcceptDateDialogOpen={isAcceptDateDialogOpen}
              toggleAcceptDateDialog={toggleAcceptDateDialog}
              companyId={userRequest?.userId}
              date={
                siteVisit?.date_of_visit
                  ? format(new Date(siteVisit?.date_of_visit), "dd LLLL, yyyy")
                  : ""
              }
              time={
                siteVisit?.time_of_visit
                  ? format(
                      parse(siteVisit?.time_of_visit, "HH:mm", new Date()),
                      "h:mm a"
                    )
                  : ""
              }
            />
          )}
          {isSiteVisitHeldDialogOpen && (
            <SiteVisitHeldDialog
              isSiteVisitHeldDialogOpen={isSiteVisitHeldDialogOpen}
              toggleIsSiteVisitHeldDialog={toggleIsSiteVisitHeldDialog}
              companyId={userRequest?.userId}
            />
          )}
          {isUpdateDateDialogOpen && (
            <UpdateDateDialog
              toggleUpdateDateDialog={toggleUpdateDateDialog}
              isUpdateDateDialogOpen={isUpdateDateDialogOpen}
              toggleConfirmDateUpdateDialog={toggleConfirmDateUpdateDialog}
              setUpdatedSiteInformation={setUpdatedSiteInformation}
            />
          )}

          {isConfirmDateUpdateDialogOpen && (
            <ConfirmDateUpdateDialog
              isConfirmDateUpdateDialogOpen={isConfirmDateUpdateDialogOpen}
              toggleConfirmDateUpdateDialog={toggleConfirmDateUpdateDialog}
              updatedSiteInformation={updatedSiteInformation}
              companyId={userRequest?.userId}
              date={
                updatedSiteInformation?.date_of_visit
                  ? format(
                      new Date(updatedSiteInformation?.date_of_visit),
                      "dd LLLL, yyyy"
                    )
                  : ""
              }
              time={
                updatedSiteInformation?.time_of_visit
                  ? format(
                      parse(
                        updatedSiteInformation?.time_of_visit,
                        "HH:mm",
                        new Date()
                      ),
                      "h:mm a"
                    )
                  : ""
              }
            />
          )}
        </Card>
      </div>
    </div>
  );
}
