import { useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import { format } from "date-fns";
import { contactInformation } from "../../utils/constants";
import { addCommasToNumber } from "../../utils/functions";
import Loader from "./loader";
import logo from "../../assets/images/logo.png";
import facebook from "../../assets/icons/facebook.png";
import instagram from "../../assets/icons/instagram.png";
import linkedin from "../../assets/icons/linkedin.png";
import twitter from "../../assets/icons/twitter.png";

const SocialLinks = ({ icon, link }) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    className="bg-primary-1 h-6 w-6 flex items-center justify-center rounded-full"
  >
    <img src={icon} width="13" alt="" />
  </a>
);

export default function PaymentReceipt({ paymentDetails }) {
  const options = {
    filename: "bacf-receipt.pdf",
    margin: 1,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  };

  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const convertToPdf = async () => {
    setLoading(true);
    const content = contentRef.current;

    await html2pdf().set(options).from(content).save();
    setLoading(false);
  };

  return (
    <>
      <div className="hidden">
        <div
          ref={contentRef}
          className="bg-[#f1f1f1] color-[#1d191e] text-sm p-8 leading-[20.3px] space-y-6 w-full h-full"
        >
          <div className="w-full max-w-[536px] mx-auto ">
            <img src={logo} alt="" className="block w-[160px] mx-auto" />
          </div>
          <div className="w-full max-w-[536px] mx-auto bg-white p-8 space-y-6">
            <p className="font-bold text-black">
              Payment Receipt - {paymentDetails?.business_name}
            </p>
            <p>
              Date Executed:{" "}
              <span className="font-bold">
                {paymentDetails?.date_of_payment
                  ? format(
                      new Date(paymentDetails?.date_of_payment),
                      "do MMM, yyyy"
                    )
                  : "---"}
              </span>
            </p>
            <p>
              Company Name:
              <span className="font-bold">
                {" "}
                {paymentDetails?.business_name}
              </span>
            </p>
            <p>
              Amount Paid:{" "}
              <span className="font-bold">{`NGN ${addCommasToNumber(
                paymentDetails?.new_bal
              )}`}</span>
            </p>
            <p>
              Method: <span className="font-bold">Bank transfer</span>
            </p>

            <p>
              Purpose: <span className="font-bold"> BACF Payment</span>
            </p>
          </div>
          <div className="w-full max-w-[536px] mx-auto space-y-6 ">
            <div className="text-center space-y-6">
              <p>
                <span className="text-primary-1">Address: </span> 1B Malcom
                Fraser Street Asokoro, Abuja, Nigeria
              </p>
              <p>
                <span className="text-primary-1">Call: </span>
                <a
                  href={`tel:${contactInformation.phone}`}
                  className="underline text-grey-900"
                >
                  {contactInformation.phone}
                </a>
                ,<span className="text-primary-1">Email: </span>
                <a
                  href={`mailto:${contactInformation.salesEmail}`}
                  className="underline text-grey-900"
                >
                  {contactInformation.salesEmail}
                </a>
              </p>
            </div>
            <div className="w-full flex justify-between items-center border-t border-[#cccbcc] pt-6">
              <img src={logo} alt="" className="block w-[120px]" />
              <div className="flex gap-6">
                <SocialLinks
                  link="https://facebook.com/people/Gas-Aggregation-Company-Nigeria-Ltd/100069027182947/"
                  icon={facebook}
                />
                <SocialLinks link="https://x.com/Gacn_LtdGte" icon={twitter} />
                <SocialLinks
                  link="https://www.instagram.com/gacn_ltdgte/"
                  icon={instagram}
                />
                <SocialLinks
                  link="https://www.linkedin.com/company/gas-aggregation-company-nigeria/"
                  icon={linkedin}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        className="border-[1.5px] border-primary-1 rounded-lg text-primary-1 py-[7px] px-4 text-sm font-semibold"
        onClick={convertToPdf}
        disabled={loading}
      >
        {loading ? <Loader /> : "Download"}
      </button>
    </>
  );
}
