import { useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import Icons from "../../../assets/icons";
import Button from "../../ui/button";
import DeleteUserDialog from "../../Account/deleteUserDialog";

const Info = ({ title, content, icon }) => (
  <div className="flex gap-6 items-center py-4">
    <span className="grey-400"> {icon}</span>
    <div className="space-y-1">
      <p className="text-xs text-grey-500">{title}</p>
      <p className="text-sm font-medium text-grey-900">{content}</p>
    </div>
  </div>
);

export default function UserInfo({ request }) {
  const { team } = useSelector((state) => state.admin);

  const [userTobeDeleted, setUserToBeDeleted] = useState("");
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);

  const toggleDeleteUserDialog = (value) => setIsDeleteUserDialogOpen(value);

  const handleDeleteUser = (id) => {
    setUserToBeDeleted(id);
    toggleDeleteUserDialog(true);
  };

  return (
    <div className="space-y-6 pt-4">
      <div>
        <Info
          title="Company"
          content={request?.company_name}
          icon={Icons.ic_company}
        />
        <Info
          title="Contact person"
          content={request?.full_name}
          icon={Icons.ic_user}
        />
        <Info
          title="Contact email"
          content={request?.email}
          icon={Icons.ic_email}
        />
        <Info
          title="Phone number"
          content={request?.phone_number}
          icon={Icons.ic_phone}
        />
        <Info
          title="Date joined"
          content={
            request?.createdAt &&
            format(new Date(request?.createdAt), "LLL dd, yyyy")
          }
          icon={Icons.ic_company}
        />
      </div>
      <Button
        onClick={() => handleDeleteUser(request?.userId ?? request?._id)}
        className="bg-transparent border-[1.5px] border-error-1 !text-error-1 w-full"
      >
        Delete company
      </Button>

      <DeleteUserDialog
        toggleDeleteUserDialog={toggleDeleteUserDialog}
        isDeleteUserDialogOpen={isDeleteUserDialogOpen}
        userTobeDeleted={userTobeDeleted}
        team={team}
        role="company"
        from="/due-diligence"
      />
    </div>
  );
}
