import { useSelector } from "react-redux";
import { setCurrentTeamPage } from "../../../../config/reducers/userSlice";
import useUser from "../../../../hooks/useUser";
import Table from "../../../ui/table";


export default function UserTable({ items }) {
  const { resendAdminInvite } = useUser();
  const { searchUserText, totalTeam, currentTeamPage } = useSelector(
    (state) => state.user
  );

  return (
    <Table
      items={items}
      role="user"
      type="proxy"
      resendInvite={resendAdminInvite}
      searchUserText={searchUserText}
      totalTeam={totalTeam}
      currentTeamPage={currentTeamPage}
      setCurrentTeamPage={setCurrentTeamPage}
    />
  );
}
