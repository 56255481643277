import React from "react";
import useStageSix from "./useStageSix";
import Loader from "../../../../ui/loader";
import SiteVisitQuestionnaire from "../../../General/SiteVisitQuestionnaire";
import SiteVisitQuestionnaireStatus from "./status";

export default function StageSix() {
  const { loading, stageSixStep, handleStageSixStep } = useStageSix();

  return loading ? (
    <Loader />
  ) : (
    <div>
      {stageSixStep === 1 && (
        <SiteVisitQuestionnaire handleStageSixStep={handleStageSixStep} />
      )}
      {stageSixStep === 2 && <SiteVisitQuestionnaireStatus />}
    </div>
  );
}
