import { Link, useNavigate, useParams } from "react-router-dom";
import Icons from "../../assets/icons";
import Button from "../../components/ui/button";
import { useEffect } from "react";
import useAuthentication from "../../hooks/useAuthentication";
import Loader from "../ui/loader";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { error, isLoading, verifyUserEmail } = useAuthentication();

  useEffect(() => {
    if (!token) navigate("/login", { replace: true });
    else {
      // save token to storage
      verifyUserEmail(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return isLoading ? (
    <Loader fullpage />
  ) : error ? (
    <div>{error}</div>
  ) : (
    <div className="space-y-12 flex flex-col items-center">
      <div className="space-y-6 flex flex-col items-center">
        <img src={Icons.ic_success} alt="" />
        <div className="space-y-4">
          <h2 className="text-h2 text-black">Verification successful</h2>
          <p className="text-base">
            Your account has been successfully created.
          </p>
        </div>
      </div>
      <Link to="/login">
        <Button>LOg in to account</Button>
      </Link>
    </div>
  );
};

export default VerifyEmail;
