import React from "react";

const Input = (props) => {
  const {
    className,
    name,
    title,
    icon,
    errors,
    toggleHiddenPassword,
    reqError,
    disabled,
    required,
    info,
    ...rest
  } = props;

  return (
    <div className={className}>
      {title && (
        <label className="text-left text-grey-900 text-sm font-medium mb-1 block">
          {title} {required && "*"}
        </label>
      )}

      <div className="relative">
        <input
          className={`w-full appearance-none border focus:outline-none focus:border focus:border-primary-1 text-sm rounded-md transition duration-150 ease-in-out
          ${errors || reqError ? "!border-error-200" : "border-grey-300"}
          ${name === "search" ? "pl-10 p-2" : "p-4"}
          ${disabled ? "bg-grey-10 text-grey-400" : "bg-white text-grey-900"}
          `}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          {...rest}
          disabled={disabled ? true : false}
        />

        {name === "search" ? (
          <div className="absolute left-3 bottom-[10px] text-grey-500 ">
            {icon}
          </div>
        ) : name === "password" ||
          name === "confirmPassword" ||
          name === "currentPassword" ? (
          <div
            className="absolute right-4 bottom-[19px] text-grey-500 cursor-pointer"
            onClick={toggleHiddenPassword}
          >
            {icon}
          </div>
        ) : (
          icon && (
            <div
              className={`absolute right-4 bottom-[19px] bg-white
              ${errors || reqError ? "!text-error-500" : "text-grey-500"}`}
            >
              {icon}
            </div>
          )
        )}
      </div>
      {errors && (
        <p className="mt-2 text-xs text-error-500 text-left">{errors}</p>
      )}
      {info && <p className="mt-2 text-xs">{info}</p>}
    </div>
  );
};

export default Input;
