import React from "react";
import Button from "../../../../ui/button";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentStep } from "../../../../../config/reducers/userSlice";
import DueDiligenceStatus from "../../../General/Status";

export default function QuestionnaireStatus({ handleStageFourStep }) {
  const { questionnaire } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  return questionnaire?.status === "awaiting" ? (
    <DueDiligenceStatus
      type="success"
      title="Submission Successful"
      description="Our Due Diligence team will review your submission and provide comments, feedback, or final approval within 24 to 72 hours. We appreciate your patience during this review period."
    >
      <div className="flex gap-4">
        <Button
          onClick={() => handleStageFourStep(1)}
          className="bg-transparent border-[1.5px] border-primary-1 !text-primary-1"
        >
          View Responses
        </Button>
      </div>
    </DueDiligenceStatus>
  ) : questionnaire?.status === "accepted" ? (
    <DueDiligenceStatus
      type="success"
      title="Questionnaire Approved"
      description="Your Due Diligence Questionnaire has been reviewed and approved. You can now proceed with the Site Visit process. Thank you for your cooperation and prompt submission"
    >
      <div className="flex gap-4">
        <Button
          onClick={() => handleStageFourStep(1)}
          className="bg-transparent border-[1.5px] border-primary-1 !text-primary-1"
        >
          View Response
        </Button>
        <Button
          onClick={() =>
            dispatch(
              setCurrentStep({
                currentStep: 5,
              })
            )
          }
          className="w-fit"
        >
          Proceed to Site Visit
        </Button>
      </div>
    </DueDiligenceStatus>
  ) : questionnaire?.status === "feedback" ? (
    <DueDiligenceStatus
      type="feedback"
      title="Feedback on Your Submission"
      description={
        <>
          We have reviewed your Due Diligence Questionnaire and found some areas
          that require further information or clarification.
          <br />
          Please review our comments and update the relevant sections
          accordingly
        </>
      }
    >
      <div className="flex gap-4">
        <Button
          onClick={() => handleStageFourStep(1)}
          className="bg-transparent border-[1.5px] border-primary-1 !text-primary-1"
        >
          view Comments
        </Button>
      </div>
    </DueDiligenceStatus>
  ) : null;
}
