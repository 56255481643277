import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const usePagination = (
  items,
  numberPerPage,
  searchText,
  totalItems,
  setCurrentTeamPage
) => {
  const dispatch = useDispatch();
  const itemsPerPage = numberPerPage ?? 6;

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const currentItems = items?.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    dispatch(
      setCurrentTeamPage({
        currentTeamPage: event.selected + 1,
      })
    );
    window.scrollTo(0, 0);
  };

  return { currentItems, pageCount, handlePageClick };
};

export default usePagination;
