import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../../../../config/reducers/userSlice";
import Button from "../../../../ui/button";
import DueDiligenceStatus from "../../../General/Status";

export default function SiteVisitQuestionnaireStatus({ handleStageSixStep }) {
  const dispatch = useDispatch();

  const { siteVisitQuestionnaire } = useSelector((state) => state.user);

  return siteVisitQuestionnaire?.status === "approved" ? (
    <DueDiligenceStatus
      type="success"
      title="Questionnaire Approved"
      description="Your Site Visit Questionnaire has been reviewed and approved. You can now proceed to finalise your due diligence."
    >
      <div className="flex gap-4">
        <Button
          onClick={() => handleStageSixStep(1)}
          className="bg-transparent border-[1.5px] border-primary-1 !text-primary-1"
        >
          Review response
        </Button>
        <Button
          onClick={() =>
            dispatch(
              setCurrentStep({
                currentStep: 7,
              })
            )
          }
          className="w-fit"
        >
          Proceed to finalize
        </Button>
      </div>
    </DueDiligenceStatus>
  ) : siteVisitQuestionnaire?.status === "feedback" ? (
    <DueDiligenceStatus
      type="feedback"
      title="Feedback on Your Submission"
      description={
        <>
          We have reviewed your Site Visit Questionnaire and found some areas
          that require further information or clarification.
          <br />
          Please review our comments and update the relevant sections
          accordingly
        </>
      }
    >
      <div className="flex gap-4">
        <Button
          onClick={() => handleStageSixStep(1)}
          className="bg-transparent border-[1.5px] border-primary-1 !text-primary-1"
        >
          view Comments
        </Button>
      </div>
    </DueDiligenceStatus>
  ) : (
    <DueDiligenceStatus
      type="success"
      title="Submission Successful"
      description="Our Due Diligence team will review your submission and provide comments, feedback, or final approval within 24 to 72 hours. We appreciate your patience during this review period."
    >
      <div className="flex gap-4">
        <Button
          onClick={() => handleStageSixStep(1)}
          className="bg-transparent border-[1.5px] border-primary-1 !text-primary-1"
        >
          View Responses
        </Button>
      </div>
    </DueDiligenceStatus>
  );
}
