import React, { useState } from "react";
import { Form, Formik } from "formik";
import Input from "./input";
import Icons from "../../assets/icons";
import Button from "./button";

const AdminComment = ({
  name,
  adminComment,
  allComments,
  isAdminCommentAvailable,
  canAdminComment,
  setAdminComments,
  setIsCommentNonNull,
  marginTop,
  type,
  children,
  itemIndex,
}) => {
  const [isAdminCommenting, setIsAdminCommenting] = useState(false);

  const onSubmitQuestionnaire = async (values) => {
    if (name.includes(".")) {
      const parts = name.split(".");

      if (parts.length === 3) {
        const objectKey1 = parts[0];
        const objectKey2 = parts[1];

        let nested1 = {
          ...allComments[type][`${objectKey1}`][`${objectKey2}`],
          ...values[`${objectKey1}`][`${objectKey2}`],
        };

        let nested2 = {
          ...allComments[type][`${objectKey1}`],
          [`${objectKey2}`]: nested1,
        };

        const newComments = {
          ...allComments[type],
          [`${objectKey1}`]: nested2,
        };

        setAdminComments({ ...allComments, [type]: newComments });
      } else {
        const objectKey = parts[0];
        let nested = {
          ...allComments[type][`${objectKey}`],
          ...values[`${objectKey}`],
        };
        const newComments = { ...allComments[type], [`${objectKey}`]: nested };
        setAdminComments({ ...allComments, [type]: newComments });
      }
    } else {
      const newComments = { ...allComments[type], ...values };
      setAdminComments({ ...allComments, [type]: newComments });
    }
    setIsCommentNonNull(true);
    setIsAdminCommenting(false);
  };

  const onSubmitFinalize = async (values) => {
    const detailToEdit = allComments.filter(
      (value, index) => index === itemIndex
    );

    const valuesToUse = values.details.filter(
      (value, index) => index === itemIndex
    );

    const newComments = { ...detailToEdit[0], ...valuesToUse[0] };

    const allCommentsCopy = [...allComments];
    allCommentsCopy[itemIndex] = newComments;

    setAdminComments(allCommentsCopy);
    setIsCommentNonNull(true);
    setIsAdminCommenting(false);
  };
  return (
    <div className="relative space-y-6">
      {canAdminComment ? (
        <div className="flex gap-2 items-start">
          <div className="w-full">{children}</div>
          <button
            type="button"
            className={`bg-white border border-grey-200 rounded-lg p-2 
            ${marginTop ? "mt-6" : ""}
            `}
            onClick={() => setIsAdminCommenting(true)}
          >
            {Icons.ic_comment}
          </button>
        </div>
      ) : (
        children
      )}

      {isAdminCommentAvailable && (
        <div className="px-3 py-2 border border-error-400 bg-error-50 rounded-md">
          <p className="text-grey-600 text-sm">
            <span className="text-black font-medium">Comment:</span>{" "}
            {adminComment}
          </p>
        </div>
      )}

      {isAdminCommenting && (
        <Formik
          initialValues={{}}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isValid,
              dirty,
              handleChange,
              handleBlur,
              isSubmitting,
              setSubmitting,
            } = props;

            return (
              <Form className="space-y-3 border border-error-400 bg-error-50 rounded-md py-2 px-3">
                <Input
                  placeholder="Enter comment"
                  type="text"
                  name={name}
                  id={name}
                  value={values[name]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors[name] && touched[name] ? errors[name] : ""}
                />

                <div className="flex gap-4 justify-end ">
                  <Button
                    onClick={() => setIsAdminCommenting(false)}
                    className="bg-transparent border-[1.5px] border-error-1 !text-black px-3 py-2 h-fit capitalize w-fit !tracking-[0]"
                  >
                    cancel
                  </Button>
                  <Button
                    className="px-3 py-2 h-fit capitalize w-fit !tracking-[0] !bg-error-1"
                    disabled={!isValid || !dirty || isSubmitting}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (type === "finalize")
                        onSubmitFinalize(values, setSubmitting);
                      else onSubmitQuestionnaire(values);
                    }}
                    loading={isSubmitting}
                  >
                    comment
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default AdminComment;
