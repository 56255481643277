import React from "react";
import DashboardLayout from "../../components/Layout/dashboardLayout";
import UserDueDiligence from "../../components/DueDiligence/User";

export default function DueDiligence() {
  return (
    <DashboardLayout
      type="user"
      title="Due Diligence"
      description="Below, you'll find an outline of the steps involved in the gas purchase process"
    >
      <UserDueDiligence />
    </DashboardLayout>
  );
}
