import React, { useState } from "react";
import Dialog from "../../../../ui/dialog";
import Button from "../../../../ui/button";
import useUser from "../../../../../hooks/useUser";
import { toast } from "react-toastify";

export default function AcceptFormDialog({
  toggleAcceptFormDialog,
  isAcceptFormDialogOpen,
  companyName,
  companyId,
}) {
  const { acceptGasRequestForm } = useUser();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isDialogModalOpen={isAcceptFormDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Confirm acceptance</h2>
          <p>
            Confirm accepting {companyName} Gas Request Form to proceed to BACF
            Payment
          </p>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleAcceptFormDialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              const response = await acceptGasRequestForm(companyId);
              if (response) {
                toast.success("Buyer has been approved for next process!");
                toggleAcceptFormDialog(false);
              }
              setLoading(false);
            }}
            disabled={loading}
            loading={loading}
          >
            Yes, Accept
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
