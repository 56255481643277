import React, { useState } from "react";
import ScheduleMeeting from "./scheduleMeeting";
import RequestStatus from "./requestStatus";
import { useSelector } from "react-redux";
import MeetingDetails from "../../../General/Meeting/details";
import RequestForm from "../../../General/GasRequestForm";
import { BackButton } from "../../../../ui/goback";
import { STAGE_ONE_STATUS } from "../../../../../utils/constants";

export default function StageOne() {
  const userRequest = useSelector((state) => state.user.userRequest);

  const [stageOneStep, setStageOneStep] = useState(
    userRequest?.status === STAGE_ONE_STATUS.ACCEPTED ||
      userRequest?.status === STAGE_ONE_STATUS.REJECTED
      ? 4
      : userRequest?.meeting_set
      ? 3
      : userRequest?.status === STAGE_ONE_STATUS.PENDING
      ? 2
      : 1
  );

  const handleStageOneStep = (value) => setStageOneStep(value);

  return (
    <div>
      {stageOneStep === 1 && (
        <>
          {userRequest &&
            (userRequest?.status === STAGE_ONE_STATUS.ACCEPTED ||
              userRequest?.status === STAGE_ONE_STATUS.REJECTED) && (
              <div className="mb-4">
                <BackButton onClick={() => handleStageOneStep(4)} />
              </div>
            )}
          <RequestForm handleStageOneStep={handleStageOneStep} type="user" />
        </>
      )}
      {stageOneStep === 2 && (
        <ScheduleMeeting handleStageOneStep={handleStageOneStep} />
      )}
      {stageOneStep === 3 && (
        <MeetingDetails handleStageOneStep={handleStageOneStep} type="user" />
      )}

      {stageOneStep === 4 && (
        <RequestStatus handleStageOneStep={handleStageOneStep} />
      )}
    </div>
  );
}
