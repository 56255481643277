import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { comapnyInfoValidation } from "../../../utils/validation";
import Button from "../../ui/button";
import Input from "../../ui/input";
import Icons from "../../../assets/icons";
import useUser from "../../../hooks/useUser";

export default function CompanyInfo() {
  const user = useSelector((state) => state.auth.user);
  const { updateUser } = useUser();
  return (
    <>
      <p className="text-sm">
        Keep your company's information up-to-date for seamless communication
        and service delivery.
      </p>
      <Formik
        initialValues={{
          company_name: user?.company_name || "",
          company_address: user?.company_address || "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await updateUser({
            company_name: values.company_name,
            company_address: values.company_address,
          });
          setSubmitting(false);
        }}
        validationSchema={comapnyInfoValidation}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isValid,
            dirty,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props;

          return (
            <Form className="flex flex-col gap-12">
              <div className="flex flex-col gap-6">
                <Input
                  title="COMPANY NAME"
                  icon={Icons.ic_company}
                  placeholder="Your job title"
                  type="text"
                  name="company_name"
                  id="company_name"
                  value={values.company_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.company_name && touched.company_name
                      ? errors.company_name
                      : ""
                  }
                />
                <Input
                  title="COMPANY ADDRESS"
                  icon={Icons.ic_location}
                  placeholder="Enter company location"
                  type="text"
                  name="company_address"
                  id="company_address"
                  value={values.company_address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.company_address && touched.company_address
                      ? errors.company_address
                      : ""
                  }
                />
              </div>
              <Button
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                className="py-4 px-6"
                fullWidth
                loading={isSubmitting}
              >
                Save Changes
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
