import React from "react";
import { subDays } from "date-fns";
import { Form, Formik } from "formik";
import { gasRequestFormValidation } from "../../../../utils/validation";
import useGasRequestForm from "./useGasRequestForm";
import Icons from "../../../../assets/icons";
import Button from "../../../ui/button";
import Input from "../../../ui/input";
import Select from "../../../ui/select";
import Textarea from "../../../ui/textarea";
import DateInput from "../../../ui/datePicker";
import FormContainer from "../FormContainer";
import Card from "../Card";
import Badge from "../../../ui/badge";
import { PROJECT_TYPE } from "../../../../utils/constants";
import PhoneNumberInput from "../../../ui/PhoneNumberInput";

export default function RequestForm({ handleStageOneStep, type }) {
  const { initialValues, handleSubmit, disableForm, userRequest } =
    useGasRequestForm(handleStageOneStep, type);

  return (
    <Card
      title="Request Form"
      description="Complete the following gas request to proceed with your due diligence"
      badge={
        <Badge
          status={
            userRequest?.status === "awaiting"
              ? "awaitng_review"
              : userRequest?.status
          }
        />
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={gasRequestFormValidation}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isValid,
            dirty,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldTouched,
            setFieldValue,
          } = props;

          return (
            <Form className="flex flex-col divide-y divide-grey-200">
              <FormContainer title="Company Info" nowhitespace type={type}>
                <Input
                  required
                  title="COMPANY NAME"
                  icon={Icons.ic_company}
                  placeholder="Your job title"
                  type="text"
                  name="company_name"
                  id="company_name"
                  value={values.company_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.company_name && touched.company_name
                      ? errors.company_name
                      : ""
                  }
                  disabled={disableForm}
                />
                <Input
                  required
                  title="COMPANY ADDRESS"
                  icon={Icons.ic_location}
                  placeholder="Enter company location"
                  type="text"
                  name="company_address"
                  id="company_address"
                  value={values.company_address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.company_address && touched.company_address
                      ? errors.company_address
                      : ""
                  }
                  disabled={disableForm}
                />
              </FormContainer>

              <FormContainer title="Personal Info" nowhitespace type={type}>
                <Input
                  required
                  title="FULL NAME"
                  icon={Icons.ic_user}
                  placeholder="First and last name"
                  type="text"
                  name="full_name"
                  id="full_name"
                  value={values.full_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.full_name && touched.full_name
                      ? errors.full_name
                      : ""
                  }
                  disabled={disableForm}
                />
                <PhoneNumberInput
                  required
                  title="PHONE NUMBER"
                  icon={Icons.ic_phone}
                  placeholder="07000000000"
                  name="phone_number"
                  id="phone_number"
                  value={values.phone_number}
                  onChange={(number) => {
                    setFieldTouched("phoneNumber", true);
                    setFieldValue("phoneNumber", number);
                  }}
                  onBlur={handleBlur}
                  errors={
                    errors.phone_number && touched.phone_number
                      ? errors.phone_number
                      : ""
                  }
                  disabled={disableForm}
                />
                <Input
                  required
                  title="EMAIL"
                  icon={Icons.ic_email}
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                />
              </FormContainer>

              <FormContainer title="Project Details" nowhitespace type={type}>
                <Select
                  required
                  title="PROJECT TYPE"
                  name="project_type"
                  id="project_type"
                  value={values.project_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.project_type && touched.project_type
                      ? errors.project_type
                      : ""
                  }
                  options={[
                    { title: "Power", value: PROJECT_TYPE.POWER },
                    {
                      title: "Gas Based Industry",
                      value: PROJECT_TYPE.GAS,
                    },
                    {
                      title: "Commercial (Mini - LNG, CNG]",
                      value: PROJECT_TYPE.COMM,
                    },
                  ]}
                  disabled={disableForm}
                />
                <Input
                  title="PROJECT LOCATION"
                  icon={Icons.ic_location}
                  placeholder="Enter location of project"
                  type="text"
                  name="project_location"
                  id="project_location"
                  value={values.project_location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.project_location && touched.project_location
                      ? errors.project_location
                      : ""
                  }
                  disabled={disableForm}
                />
                <Textarea
                  title="DESCRIPTION"
                  placeholder="Brief description of the project"
                  type="text"
                  name="project_description"
                  id="project_description"
                  value={values.project_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.project_description && touched.project_description
                      ? errors.project_description
                      : ""
                  }
                  disabled={disableForm}
                />
                <Textarea
                  title="DAILY CONTRACT VOLUME (MMCF/D)"
                  placeholder="Enter volume"
                  type="text"
                  name="daily_contract_volume"
                  id="daily_contract_volume"
                  value={values.daily_contract_volume}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.daily_contract_volume &&
                    touched.daily_contract_volume
                      ? errors.daily_contract_volume
                      : ""
                  }
                  disabled={disableForm}
                  info="Please specify the proposed volume required daily (if the project is in phases please indicate the required volume for each phase)"
                />

                <DateInput
                  title="DATE OF FIRST GAS DELIVERY"
                  icon={Icons.ic_calendar}
                  name="first_gas_delivery_date"
                  id="first_gas_delivery_date"
                  selected={values.first_gas_delivery_date}
                  minDate={subDays(new Date(), 0)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(date) => {
                    setFieldTouched("first_gas_delivery_date", true);
                    setFieldValue("first_gas_delivery_date", date);
                  }}
                  onBlur={handleBlur}
                  errors={
                    errors.first_gas_delivery_date &&
                    touched.first_gas_delivery_date
                      ? errors.first_gas_delivery_date
                      : ""
                  }
                  info="Please specify the proposed Commercial Operation Date"
                  disabled={disableForm}
                />
              </FormContainer>

              {type === "user" && (
                <div className="flex flex-col gap-6 w-full pt-16">
                  {disableForm ? (
                    <Button
                      className="py-4 px-6 self-center sm:self-end"
                      onClick={() => handleStageOneStep(3)}
                    >
                      View meeting info
                    </Button>
                  ) : (
                    <Button
                      disabled={!isValid || !dirty || isSubmitting}
                      type="submit"
                      className="py-4 px-6 self-center sm:self-end"
                      loading={isSubmitting}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
}
