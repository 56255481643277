import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../../../../hooks/useUser";
import { USER_TYPE } from "../../../../utils/constants";
import { setCurrentStep } from "../../../../config/reducers/userSlice";

export default function useMeeting(handleStageOneStep, type) {
  const dispatch = useDispatch();

  const { getMeetingDetails, createPaymentAccounts } = useUser();

  const { user } = useSelector((state) => state.auth);
  const { userRequest, meetingDetails } = useSelector((state) => state.user);
  const { currentUserId } = useSelector((state) => state.admin);

  const [loading, setLoading] = useState(false);
  const [loadingAccountDetails, setLoadingAccountDetails] = useState(false);

  const userId =
    user?.role === USER_TYPE.PROXY
      ? user?.superuser_id
      : user?.role === USER_TYPE.USER
      ? user?._id
      : currentUserId;

  const getDetails = async () => {
    setLoading(true);
    const response = await getMeetingDetails(userId);
    if (!response) {
      type === "user" && handleStageOneStep(2);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userId) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRequest, userId]);

  const getAccountDetails = async () => {
    if (userRequest?.bankAccounts?.length === 0) {
      setLoadingAccountDetails(true);

      const response = await createPaymentAccounts();
      if (response) {
        dispatch(
          setCurrentStep({
            currentStep: 2,
          })
        );
      }
      setLoadingAccountDetails(false);
    } else {
      dispatch(
        setCurrentStep({
          currentStep: 2,
        })
      );
    }
  };

  return {
    user,
    meetingDetails,
    userRequest,
    loading,
    loadingAccountDetails,
    getAccountDetails,
  };
}
