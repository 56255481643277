import React from "react";
import { showFileIconByType } from "../../utils/functions";

export default function PdfDownload({
  type,
  download,
  pdfFile,
  title,
  darkBg,
  fullWidth,
  btnClassName,
  paymentBtn,
}) {
  return (
    <div
      className={`flex items-center justify-between gap-4 border border-grey-200 rounded-lg w-full 
      ${darkBg ? "bg-grey-50 py-[18px] px-4" : "bg-white p-3"}
      ${fullWidth ? "w-full" : "max-w-[470px]"} 
      `}
    >
      <div className="flex gap-4">
        <img src={showFileIconByType(type)} alt="" />
        <div className="leading-[23.2px]">
          <p className="font-semibold text-black h-[23px] line-clamp-1 break-all">
            {title}{" "}
          </p>
          <p className="divide-x divide-dotted text-sm text-grey-400">
            <span>{type?.toUpperCase()} format</span>{" "}
            {/* <span className="font-medium">Size. 5MB</span> */}
          </p>
        </div>
      </div>
      {paymentBtn ? (
        paymentBtn
      ) : download && pdfFile ? (
        <a
          href={pdfFile}
          className={`border-[1.5px] border-primary-1 rounded-lg text-primary-1 py-[7px] px-4 text-sm font-semibold
           ${btnClassName && btnClassName}
          `}
        >
          Download
        </a>
      ) : pdfFile ? (
        <a href={pdfFile} target="_blank" rel="noreferrer">
          <button
            className={`border-[1.5px] border-primary-1 rounded-lg text-primary-1 py-[7px] px-4 text-sm font-semibold
           ${btnClassName && btnClassName}
          `}
          >
            Download
          </button>
        </a>
      ) : (
        <button
          className={`border-[1.5px] border-primary-1 rounded-lg text-primary-1 py-[7px] px-4 text-sm font-semibold
           ${btnClassName && btnClassName}
           `}
        >
          Download
        </button>
      )}
    </div>
  );
}
