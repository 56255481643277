import React from "react";
import Loader from "../../ui/loader";
import Icons from "../../../assets/icons";
import RequestsTable from "../../ui/requestsTable";
import RequestHeader from "./header";
import useRequests from "./useRequests";

export default function AdminGasRequests({ activeTab }) {
  const { loading, requests, searchRequestText, handleSearch } = useRequests();

  return (
    <div>
      <RequestHeader
        searchText={searchRequestText}
        handleSearch={handleSearch}
        activeTab={activeTab}
      />
      {loading ? (
        <div className=" p-4">
          <Loader />
        </div>
      ) : requests?.length === 0 ? (
        <div className="space-y-4 flex flex-col justify-center items-center px-4 py-8 bg-white md:bg-transaprent border md:border-none rounded-t-lg md:rounded-none ">
          <img src={Icons.ic_empty_state} alt="" />
          <p className="text-black text-lg text-center font-semibold !leading-[26.1px]">
            {searchRequestText
              ? "No search results"
              : "Currently, no company matches this status. Please check again later."}
          </p>
        </div>
      ) : (
        <RequestsTable items={requests} />
      )}
    </div>
  );
}
