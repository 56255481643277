import React from "react";
import { Form, Formik, getIn } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { siteVisitGasValidationSchema } from "../../../../../utils/validation";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import useSiteVisitQuestionnaire from "../useSiteVisitQuestionnaire";
import Radio from "../../../../ui/radio";
import AdminComment from "../../../../ui/adminComment";
import { USER_TYPE } from "../../../../../utils/constants";

export default function CommercialSiteVisitQuestionnaire({
  handleStageSixStep,
}) {
  const { user } = useSelector((state) => state.auth);
  const { currentUserId } = useSelector((state) => state.admin);

  const {
    disableForm,
    loading,
    initialValues,
    handleSaveSiteVisitQuestionnaire,
    handleApproveSiteVisitQuestionnaire,
    status,
    siteVisitQuestionnaire,
    siteVisit,

    canAdminComment,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useSiteVisitQuestionnaire(handleStageSixStep);

  return (
    <Formik
      initialValues={initialValues.gas}
      validationSchema={siteVisitGasValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <FormContainer
              type="user"
              width
              title="1. Site Specification and Proximity Inquiry"
            >
              <>
                <SectionHeader title="Layout map" />
                <AdminComment
                  name="layoutMap"
                  adminComment={adminComments?.gas.layoutMap || ""}
                  isAdminCommentAvailable={
                    adminComments?.gas.layoutMap ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <FileUpload
                    name="layoutMap"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={values.layoutMap}
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.layoutMap && touched.layoutMap
                        ? getIn(errors, `layoutMap`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.layoutMap &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader border title="Plot plan" />
                <AdminComment
                  name="plotPlan"
                  adminComment={adminComments?.gas.plotPlan || ""}
                  isAdminCommentAvailable={
                    adminComments?.gas.plotPlan ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <FileUpload
                    name="plotPlan"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={values.plotPlan}
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.plotPlan && touched.plotPlan
                        ? getIn(errors, `plotPlan`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.plotPlan &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Land size showing your coordinates"
                />
                <AdminComment
                  name="landSizeDoc"
                  adminComment={adminComments?.gas.landSizeDoc || ""}
                  isAdminCommentAvailable={
                    adminComments?.gas.landSizeDoc ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <FileUpload
                    name="landSizeDoc"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={values.landSizeDoc}
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.landSizeDoc && touched.landSizeDoc
                        ? getIn(errors, `landSizeDoc`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.landSizeDoc &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Proximity to gas suppliers (processing plant, pipeline, or metering station)*"
                />
                <AdminComment
                  name="proximityToGasSupplier"
                  adminComment={adminComments?.gas.proximityToGasSupplier || ""}
                  isAdminCommentAvailable={
                    adminComments?.gas.proximityToGasSupplier ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="proximityToGasSupplier"
                    id="proximityToGasSupplier"
                    value={values.proximityToGasSupplier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.proximityToGasSupplier &&
                      touched.proximityToGasSupplier
                        ? errors.proximityToGasSupplier
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.proximityToGasSupplier &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              type="user"
              width
              title="2. Project Infrastructure and Planning"
            >
              <>
                <SectionHeader title="Can you show us your gas inlet point, indicating the distance to the pipeline tie-in location? Also, provide detailed gas Transportation arrangements." />
                <AdminComment
                  name="projectPlan.gasInletPoint"
                  adminComment={
                    adminComments?.gas.projectPlan.gasInletPoint || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.projectPlan.gasInletPoint ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.gasInletPoint"
                    id="projectPlan.gasInletPoint"
                    value={values.projectPlan.gasInletPoint}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.gasInletPoint") &&
                          getIn(touched, "projectPlan.gasInletPoint")
                      )
                        ? getIn(errors, "projectPlan.gasInletPoint")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.projectPlan.gasInletPoint &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
                <AdminComment
                  name="projectPlan.gasInletPointFile"
                  adminComment={
                    adminComments?.gas.projectPlan.gasInletPointFile || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.projectPlan.gasInletPointFile
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <FileUpload
                    name="projectPlan.gasInletPointFile"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      siteVisitQuestionnaire
                        ? values.projectPlan.gasInletPointFile
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.gasInletPointFile") &&
                          getIn(touched, "projectPlan.gasInletPointFile")
                      )
                        ? getIn(errors, `projectPlan.gasInletPointFile`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.projectPlan.gasInletPointFile &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Road Access: Can you confirm your road network (Internal and External access roads)*"
                />
                <AdminComment
                  name="projectPlan.roadAccess"
                  adminComment={adminComments?.gas.projectPlan.roadAccess || ""}
                  isAdminCommentAvailable={
                    adminComments?.gas.projectPlan.roadAccess ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.roadAccess"
                    id="projectPlan.roadAccess"
                    value={values.projectPlan.roadAccess}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.roadAccess") &&
                          getIn(touched, "projectPlan.roadAccess")
                      )
                        ? getIn(errors, "projectPlan.roadAccess")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.projectPlan.roadAccess &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Where will your meter be located and what type of metering system will you use for the project?*"
                />
                <AdminComment
                  name="projectPlan.meterLocation"
                  adminComment={
                    adminComments?.gas.projectPlan.meterLocation || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.projectPlan.meterLocation ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.meterLocation"
                    id="projectPlan.meterLocation"
                    value={values.projectPlan.meterLocation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.meterLocation") &&
                          getIn(touched, "projectPlan.meterLocation")
                      )
                        ? getIn(errors, "projectPlan.meterLocation")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.projectPlan.meterLocation &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Is your project phased, and what is your construction/completion timeline?*"
                />
                <AdminComment
                  name="projectPlan.constructionTimeline"
                  adminComment={
                    adminComments?.gas.projectPlan.constructionTimeline || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.projectPlan.constructionTimeline
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.constructionTimeline"
                    id="projectPlan.constructionTimeline"
                    value={values.projectPlan.constructionTimeline}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.constructionTimeline") &&
                          getIn(touched, "projectPlan.constructionTimeline")
                      )
                        ? getIn(errors, "projectPlan.constructionTimeline")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.projectPlan.constructionTimeline &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="What is your mode of evacuation?*"
                />
                <AdminComment
                  name="projectPlan.modeOfEvacuation"
                  adminComment={
                    adminComments?.gas.projectPlan.modeOfEvacuation || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.projectPlan.modeOfEvacuation
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.modeOfEvacuation"
                    id="projectPlan.modeOfEvacuation"
                    value={values.projectPlan.modeOfEvacuation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.modeOfEvacuation") &&
                          getIn(touched, "projectPlan.modeOfEvacuation")
                      )
                        ? getIn(errors, "projectPlan.modeOfEvacuation")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.projectPlan.modeOfEvacuation &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="What Technology type are you looking at for your proposed project?*"
                />
                <AdminComment
                  name="projectPlan.technologyType"
                  adminComment={
                    adminComments?.gas.projectPlan.technologyType || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.projectPlan.technologyType ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.technologyType"
                    id="projectPlan.technologyType"
                    value={values.projectPlan.technologyType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.technologyType") &&
                          getIn(touched, "projectPlan.technologyType")
                      )
                        ? getIn(errors, "projectPlan.technologyType")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.projectPlan.technologyType &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              type="user"
              width
              title="3. Environmental and Safety Considerations"
            >
              <>
                <SectionHeader title="In case of gas unavailability issues, what alternative fuel are you considering and what is the storage plan of the alternative Fuel? (In-facility storage) How would you switch from gas to an alternative?*" />
                <AdminComment
                  name="safetyConsiderations.alternativeFuel"
                  adminComment={
                    adminComments?.gas.safetyConsiderations.alternativeFuel ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.safetyConsiderations.alternativeFuel
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="safetyConsiderations.alternativeFuel"
                    id="safetyConsiderations.alternativeFuel"
                    value={values.safetyConsiderations.alternativeFuel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "safetyConsiderations.alternativeFuel") &&
                          getIn(touched, "safetyConsiderations.alternativeFuel")
                      )
                        ? getIn(errors, "safetyConsiderations.alternativeFuel")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.safetyConsiderations
                        .alternativeFuel &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="How would you switch from gas to an alternative?*"
                />
                <AdminComment
                  name="safetyConsiderations.howToSwitch"
                  adminComment={
                    adminComments?.gas.safetyConsiderations.howToSwitch || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.safetyConsiderations.howToSwitch
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="safetyConsiderations.howToSwitch"
                    id="safetyConsiderations.howToSwitch"
                    value={values.safetyConsiderations.howToSwitch}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "safetyConsiderations.howToSwitch") &&
                          getIn(touched, "safetyConsiderations.howToSwitch")
                      )
                        ? getIn(errors, "safetyConsiderations.howToSwitch")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.safetyConsiderations.howToSwitch &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border />
                <AdminComment
                  name="safetyConsiderations.workCommenced"
                  adminComment={
                    adminComments?.gas.safetyConsiderations.workCommenced || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.safetyConsiderations.workCommenced
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.safetyConsiderations.workCommenced}
                    onChange={handleChange}
                    name="safetyConsiderations.workCommenced"
                    title="Observe if any early work has commenced on the project site?*"
                    errors={
                      Boolean(
                        getIn(errors, "safetyConsiderations.workCommenced") &&
                          getIn(touched, "safetyConsiderations.workCommenced")
                      )
                        ? getIn(errors, "safetyConsiderations.workCommenced")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.safetyConsiderations.workCommenced &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              type="user"
              width
              title="4. Market Engagement and Employment"
            >
              <>
                <SectionHeader title="What is your ancillary system that might require gas for your proposed project?*" />
                <AdminComment
                  name="marketExperience.ancillarySystem"
                  adminComment={
                    adminComments?.gas.marketExperience.ancillarySystem || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.marketExperience.ancillarySystem
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.ancillarySystem"
                    id="marketExperience.ancillarySystem"
                    value={values.marketExperience.ancillarySystem}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "marketExperience.ancillarySystem") &&
                          getIn(touched, "marketExperience.ancillarySystem")
                      )
                        ? getIn(errors, "marketExperience.ancillarySystem")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.marketExperience.ancillarySystem &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Do you have an expansion plan? If yes, what is the plan and when will you commence the expansion?*"
                />
                <AdminComment
                  name="marketExperience.expansionPlan"
                  adminComment={
                    adminComments?.gas.marketExperience.expansionPlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.marketExperience.expansionPlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.expansionPlan"
                    id="marketExperience.expansionPlan"
                    value={values.marketExperience.expansionPlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "marketExperience.expansionPlan") &&
                          getIn(touched, "marketExperience.expansionPlan")
                      )
                        ? getIn(errors, "marketExperience.expansionPlan")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.marketExperience.expansionPlan &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Can you confirm the employment opportunities your project will generate for locals and others and the category of the Jobs?*"
                />
                <AdminComment
                  name="marketExperience.employmentOpportunities"
                  adminComment={
                    adminComments?.gas.marketExperience
                      .employmentOpportunities || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.marketExperience.employmentOpportunities
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.employmentOpportunities"
                    id="marketExperience.employmentOpportunities"
                    value={values.marketExperience.employmentOpportunities}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "marketExperience.employmentOpportunities"
                        ) &&
                          getIn(
                            touched,
                            "marketExperience.employmentOpportunities"
                          )
                      )
                        ? getIn(
                            errors,
                            "marketExperience.employmentOpportunities"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.marketExperience
                        .employmentOpportunities &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Will you be delivering the Product to the customer’s facility or will the customer be providing their own transportation?*"
                />
                <AdminComment
                  name="marketExperience.productDistribution"
                  adminComment={
                    adminComments?.gas.marketExperience.productDistribution ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.marketExperience.productDistribution
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.productDistribution"
                    id="marketExperience.productDistribution"
                    value={values.marketExperience.productDistribution}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "marketExperience.productDistribution") &&
                          getIn(touched, "marketExperience.productDistribution")
                      )
                        ? getIn(errors, "marketExperience.productDistribution")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.marketExperience
                        .productDistribution &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="What is your market track record? Have you done this type of project before?*" />
                <AdminComment
                  name="marketExperience.marketTrackRecord"
                  adminComment={
                    adminComments?.gas.marketExperience.marketTrackRecord || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.gas.marketExperience.marketTrackRecord
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="gas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.marketTrackRecord"
                    id="marketExperience.marketTrackRecord"
                    value={values.marketExperience.marketTrackRecord}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "marketExperience.marketTrackRecord") &&
                          getIn(touched, "marketExperience.marketTrackRecord")
                      )
                        ? getIn(errors, "marketExperience.marketTrackRecord")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.gas.marketExperience.marketTrackRecord &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            {user?.role === USER_TYPE.USER || user?.role === USER_TYPE.PROXY
              ? (!siteVisitQuestionnaire ||
                  siteVisitQuestionnaire?.status === "pending" ||
                  siteVisitQuestionnaire?.status === "feedback") && (
                  <div className="flex justify-end gap-6 pt-16">
                    {/* <Button
                      disabled={isSubmitting}
                      onClick={() => {
                        setErrors({});
                        handleSaveSiteVisitQuestionnaire(
                          "gas",
                          values,
                          setSubmitting
                        );
                        const res = validateForm();
                        setErrors({ ...res });
                      }}
                      loading={isSubmitting}
                      className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                    >
                      Save progress
                    </Button> */}
                    <Button
                      onClick={async () => {
                        setSubmitting(false);
                        const res = await validateForm();
                        if (Object.keys(res).length !== 0)
                          toast.error("Please fill all the required fields");
                        else
                          handleSaveSiteVisitQuestionnaire(
                            "gas",
                            values,
                            setSubmitting
                          );
                      }}
                      disabled={!isValid || isSubmitting}
                      className="py-4 px-6 self-end"
                      loading={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                )
              : status !== "approved" && (
                  <div className="flex justify-end gap-6 w-full pt-6">
                    <Button
                      onClick={() =>
                        handleAddAdminComment(
                          adminComments.gas,
                          "adminUpdateGas"
                        )
                      }
                      disabled={loadingAddComments || !isCommentNonNull}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700"
                      loading={loadingAddComments}
                    >
                      Submit Comment
                    </Button>
                    <Button
                      onClick={() =>
                        handleApproveSiteVisitQuestionnaire(currentUserId)
                      }
                      disabled={
                        loading ||
                        status === "feedback" ||
                        isCommentNonNull ||
                        siteVisit?.status !== "meeting_held"
                      }
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                      loading={loading}
                    >
                      Approve Questionnaire
                    </Button>
                  </div>
                )}
          </Form>
        );
      }}
    </Formik>
  );
}
