import React from "react";
import { PopupModal } from "react-calendly";
import { useSelector } from "react-redux";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import useScheduleMeeting from "../../../../../hooks/useScheduleMeeting";

const ScheduleMeeting = ({ handleStageOneStep }) => {
  const { loading, openCalendly, handleOpenCalendly } =
    useScheduleMeeting(handleStageOneStep);

  const user = useSelector((state) => state.auth.user);

  return (
    <div className="space-y-12 flex flex-col items-center py-6 max-w-[465px] mx-auto">
      <div className="space-y-6 flex flex-col items-center">
        <img src={Icons.ic_meeting} alt="" />
        <div className="space-y-4 text-center">
          <h2 className="text-lg text-grey-800 font-semibold">
            Set up an Introductory Meeting with the GACN Team
          </h2>
          <p className="text-base text-grey-500">
            Please use the "Schedule" button below to schedule a meeting based
            on your availability
          </p>
        </div>
      </div>

      <Button loading={loading} onClick={() => handleOpenCalendly(true)}>
        Schedule meeting
      </Button>

      <PopupModal
        url="https://calendly.com/gacnltdgte/gas-request-meeting"
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        textColor="#ffffff"
        color="#00a2ff"
        open={openCalendly}
        onModalClose={() => handleOpenCalendly(false)}
        prefill={{
          email: user?.email ?? "",
          name: user?.fullName ?? "",
        }}
      />
    </div>
  );
};

export default ScheduleMeeting;
