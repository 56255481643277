import React from "react";

const Textarea = (props) => {
  const {
    className,
    name,
    title,
    errors,
    reqError,
    disabled,
    required,
    info,
    ...rest
  } = props;

  return (
    <div className={className}>
      {title && (
        <label className="text-left text-grey-900 text-sm font-medium mb-1 block">
          {title} {required && "*"}
        </label>
      )}

      <div className="relative">
        <textarea
          className={`p-4 min-h-10 w-full appearance-none border focus:outline-none focus:border focus:border-primary-1 text-sm rounded-md transition duration-150 ease-in-out
          ${errors || reqError ? "!border-error-200" : "border-grey-300"}
          ${disabled ? "bg-grey-10 text-grey-400" : "bg-white text-grey-900"}
          `}
          spellCheck="false"
          {...rest}
          disabled={disabled ? true : false}
        />
      </div>
      {errors && (
        <p className="mt-2 text-xs text-error-500 text-left">{errors}</p>
      )}
      {info && <p className="mt-2 text-xs">{info}</p>}
    </div>
  );
};

export default Textarea;
