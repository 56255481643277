import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimePicker = (props) => {
  const {
    className,
    name,
    title,
    icon,
    errors,
    reqError,
    disabled,
    required,
    ...rest
  } = props;

  return (
    <div className={className}>
      {title && (
        <label className="text-left text-grey-900 text-sm font-medium mb-1 block">
          {title} {required && "*"}
        </label>
      )}

      <div className="relative">
        <DatePicker
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="h:mm aa"
          className={`w-full p-4 appearance-none border focus:outline-none focus:border focus:border-primary-1 text-sm rounded-md transition duration-150 ease-in-out
          ${errors || reqError ? "!border-error-200" : "border-grey-300"}
          ${disabled ? "bg-grey-10 text-grey-400" : "bg-white text-grey-900"}
          `}
          {...rest}
          disabled={disabled ? true : false}
        />
        {
          <div
            className={`absolute right-4 bottom-[19px] bg-white
              ${errors || reqError ? "!text-error-500" : "text-grey-500"}`}
          >
            {icon}
          </div>
        }
      </div>
      {errors && (
        <p className="mt-2 text-xs text-error-500 text-left">{errors}</p>
      )}
    </div>
  );
};

export default TimePicker;
