import AuthLayout from "../../components/Layout/authLayout";
import CheckEmail from "../../components/AuthForm/checkEmail";

const ConfirmationPage = () => {
  return (
    <AuthLayout type="user" page="confirmation">
      <CheckEmail />
    </AuthLayout>
  );
};

export default ConfirmationPage;
