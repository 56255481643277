import ReactPaginate from "react-paginate";
import Icons from "../../assets/icons";

export default function Pagination({
  curentPage,
  pageCount,
  handlePageClick,
  children,
}) {
  return (
    <>
      {children}
      <ReactPaginate
        previousLabel={
          <div className="flex gap-2 items-center text-black">
            {" "}
            {Icons.ic_previous}
            Previous
          </div>
        }
        nextLabel={
          <div className="flex gap-2 items-center text-black">
            Next {Icons.ic_next}
          </div>
        }
        forcePage={curentPage - 1}
        breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="py-4 flex items-center justify-center gap-[2px] !leading-[20.3px] text-grey-400 text-sm relative"
        pageClassName="h-9 w-9 bg-white rounded-lg flex items-center justify-center"
        previousClassName="mr-[6px] rounded-lg border border-grey-200 px-6 py-2"
        nextClassName="ml-[6px] rounded-lg border border-grey-200 px-6 py-2"
        breakClassName="h-9 w-9 bg-white rounded-full flex items-center justify-center"
        activeClassName="border border-primary-1"
        activeLinkClassName="text-black"
        disabledClassName="hidden"
      />
    </>
  );
}
