import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setProxyUsers,
  setSearchUserText,
} from "../../../../config/reducers/userSlice";
import useUser from "../../../../hooks/useUser";

export default function useUserTable() {
  const dispatch = useDispatch();
  const { getAllProxyUsers, createProxyUser } = useUser();

  const { user } = useSelector((state) => state.auth);
  const { currentUserId } = useSelector((state) => state.admin);
  const { proxyUsers, loadingProxyUser, searchUserText, currentTeamPage } =
    useSelector((state) => state.user);

  const handleSearch = (value) =>
    dispatch(setSearchUserText({ searchUserText: value }));

  useEffect(() => {
    dispatch(setProxyUsers({ loadingProxyUser: true }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getAllProxyUsers,
    createProxyUser,
    user,
    currentUserId,
    proxyUsers,
    loadingProxyUser,
    searchUserText,
    currentTeamPage,
    handleSearch,
  };
}
