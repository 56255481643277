import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addCommasToNumber } from "../../utils/functions";

export default function Progress({ amountPaid }) {
  const { bacfPrice } = useSelector((state) => state.user);

  const [width, setWidth] = useState("w-0");

  // Function to calculate the percentage of amount paid
  const calculatePercentagePaid = () => {
    const totalAmount = bacfPrice;
    return ((amountPaid / totalAmount) * 100).toFixed(0);
  };

  // Function to convert number to comma-separated string
  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    //   calculate width in percentage here
    const widthInPercentage = `${calculatePercentagePaid(amountPaid)}%`;
    setWidth(widthInPercentage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountPaid]);

  return (
    <div className="text-sm text-black leading-[20.3px] font-medium space-y-1">
      <p>Amount Received</p>
      <div className="h-3 w-full bg-grey-10 rounded-[20px]">
        <div
          className={`h-full bg-primary-1 rounded-s-[20px] ${
            amountPaid === bacfPrice ? "rounded-e-[20px]" : ""
          } `}
          style={{ width: amountPaid === bacfPrice ? "100%" : width }}
        />
      </div>
      <p className="flex justify-between">
        <span>NGN {numberWithCommas(amountPaid ?? "")}</span>
        <span>NGN {addCommasToNumber(bacfPrice)}</span>
      </p>
    </div>
  );
}
