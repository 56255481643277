import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPasswordStep: false,
};

export const loginFormSlice = createSlice({
  name: "loginForm",
  initialState,
  reducers: {
    setIsPasswordStep(state, action) {
      state.isPasswordStep = action.payload;
    },
  },
});

export const { setIsPasswordStep } = loginFormSlice.actions;
export default loginFormSlice.reducer;
