export const USER_TYPE = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  USER: "user",
  PROXY: "proxy",
};

export const PROJECT_TYPE = {
  COMM: "comm",
  GAS: "gas",
  POWER: "power",
  COMMGAS: "commgas",
};

export const PROJECT_FULL_NAME = {
  COMM: "Wholesale/Local Distribution Company",
  GAS: "Gas-based Industry",
  POWER: "Power Generation",
};

export const STAGE_ONE_STATUS = {
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  AWAITING: "awaiting",
  PENDING: "pending",
};
export const STAGE_TWO_STATUS = {};
export const STAGE_THREE_STATUS = {};
export const STAGE_FOUR_STATUS = {
  ACCEPTED: "accepted",
  FEEDBACK: "feedback",
  AWAITING: "awaiting",
  PENDING: "pending",
};
export const STAGE_FIVE_STATUS = {};
export const STAGE_SIX_STATUS = {
  APPROVED: "approved",
  FEEDBACK: "feedback",
  REVIEW: "review",
  PENDING: "pending",
};
export const STAGE_SEVEN_STATUS = {
  APPROVED: "approved",
  FEEDBACK: "feedback",
  AWAITING: "awaiting",
  PENDING: "pending",
};

export const STATUS = {
  NOT_PAID: "not_paid",
  PENDING: "pending",
  PENDING_DETAILS: "pending_details",
  PENDING_REVIEW: "pending_review",
  PENDING_FINAL_APPROVAL: "pending_final_approval",

  NDA_REVIEW: "review",
  AWAITING_MEETING: "awaiting_meeting",
  AWAITING_REVIEW: "awaiting_review",
  AWAITING_ACCEPTANCE: "awaiting_acceptance",
  AWAITING_VISIT: "awaiting_visit",

  FEEDBACK: "feedback",
  UPDATE_REQUIRED: "update_required",

  ACCEPTED: "accepted",
  APPROVED: "approved",
  MEETING_HELD: "meeting_held",
  PAID: "paid",

  REJECTED: "rejected",
};

export const DOCUMENT_ICON_TYPE = {
  DOC: "doc",
  DOCX: "docx",
  DOTX: "dotx",
  PDF: "pdf",
  XLS: "xlsx",
};

export const maxSize = 5 * 1024 * 1024;

export const contactInformation = {
  adminEmail: "support@gacn.com ",
  paymentEmail: "support@gacn.com ",
  salesEmail: "support@gacn.com ",
  phone: "02094605830",
};

export const fullNameRegEx = /^[A-Za-z'-]+\s[A-Za-z'-]+(?:\s[A-Za-z'-]+)*\s*$/;

export const phoneNumberRegEx = /^(\d{11})$/;

export const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const itemsPerPage = 10;

export const tableHeadItems = [
  "Name",
  "Email",
  "Role",
  "Date added",
  "Status",
  "Action",
];
