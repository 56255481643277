import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { setIsPasswordStep } from "../../config/reducers/loginFormSlice";
import { emailValidation } from "../../utils/validation";
import useAuthentication from "../../hooks/useAuthentication";
import Input from "../ui/input";
import Icons from "../../assets/icons";
import Button from "../ui/button";

export default function ForgotPasswordForm({ type }) {
  const dispatch = useDispatch();
  const { forgotPassword } = useAuthentication();

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        dispatch(setIsPasswordStep(false));
        await forgotPassword(values, type);
        setSubmitting(false);
      }}
      validationSchema={emailValidation}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          dirty,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;

        return (
          <Form className="flex flex-col gap-16">
            <div className="flex flex-col gap-6 max-h-[392px] md:max-h-[344px] overflow-auto scrollbar ">
              <Input
                title="EMAIL"
                icon={Icons.ic_email}
                placeholder="Eample@email.com"
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email ? true : false}
                errors={errors.email && touched.email ? errors.email : ""}
              />
            </div>
            <Button
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              className="py-4 px-6"
              fullWidth
              loading={isSubmitting}
            >
              CONTINUE
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
