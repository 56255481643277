import React from "react";

function Tab({ tabs, activeTab, handleTabClick }) {
  return (
    <div className="overflow-auto">
      <div className="flex gap-3 border-b border-grey-200 w-full">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`p-4 text-center text-sm font-medium leading-[20.3px] cursor-pointer w-fit whitespace-nowrap 
            ${
              tab === activeTab
                ? "border-b text-primary-1 border-primary-1"
                : "text-grey-700"
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tab;
