import React from "react";
import Dialog from "../../../ui/dialog";
import Button from "../../../ui/button";

function RescheduleMeetingDialog({
  toggleRescheduleMeetingDialog,
  isRescheduleMeetingDialogOpen,
  handleOpenCalendly,
}) {
  return (
    <Dialog isDialogModalOpen={isRescheduleMeetingDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Reshedule Meeting</h2>
          <p>Are you sure you want reschedule the meeting?</p>
          <p>This action cannot be undone.</p>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleRescheduleMeetingDialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={() => {
              handleOpenCalendly(true);
              toggleRescheduleMeetingDialog(false);
            }}
            className="!bg-error-1"
          >
            Reschedule
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default RescheduleMeetingDialog;
