import React from "react";

import AuthLayout from "../../../components/Layout/authLayout";
import ForgotPasswordForm from "../../../components/AuthForm/forgotPassword";

const AdminForgotPasswordPage = () => {
  return (
    <AuthLayout
      type="admin"
      page="forgotPassword"
      title="Reset password"
      description="Provide the email address linked to your account to  receive a reset password link."
    >
      <ForgotPasswordForm type="admin" />
    </AuthLayout>
  );
};

export default AdminForgotPasswordPage;
