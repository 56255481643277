import React from "react";
import DueDiligenceStatus from "../../../General/Status";

export default function SiteVisitQuestionnaireStatus() {
  return (
    <DueDiligenceStatus
      type="pending"
      title="Site Visit Questionnaire is pending"
      description="Buyer is yet to fill the provided questionnaire, you will be notified when the questionnaire is submitted"
    />
  );
}
