import React from "react";
import { Switch } from "@headlessui/react";

export default function SwitchUI({
  enabled,
  setEnabled,
  title,
  toggleDialog,
  isDialogOpen,
  type,
  name,
  validate,
}) {
  return (
    <Switch
      checked={enabled}
      onChange={(checked) => {
        if (type === "meeting") {
          if (!enabled) toggleDialog(!isDialogOpen);
        } else if (type === "finalize") {
          setEnabled(name, checked);
        } else if (type === "site") {
          setEnabled(checked);
          validate();
        } else {
          setEnabled(checked);
        }
      }}
      className={`${
        enabled ? "bg-primary-1" : "bg-grey-200"
      } relative inline-flex h-5 w-[32.5px] items-center rounded-full`}
    >
      <span className="sr-only">{title}</span>
      <span
        className={`${
          enabled ? "translate-x-[11.25px]" : "translate-x-[1.25px]"
        } inline-block h-[17.5px] w-[17.5px] transform rounded-full bg-white transition`}
      />
    </Switch>
  );
}
