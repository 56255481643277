import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Badge from "../../../../ui/badge";
import Loader from "../../../../ui/loader";
import Card from "../../../General/Card";
import useUser from "../../../../../hooks/useUser";
import { CompanyInfoNotPaid, CompanyInfoPaid } from "./companyInfo";
import { addCommasToNumber } from "../../../../../utils/functions";

export default function StageTwo() {
  const { userRequest, bacfPrice } = useSelector((state) => state.user);

  const { getBacfPrice } = useUser();

  const [loading, setLoading] = useState(false);

  const getPrice = async () => {
    setLoading(true);
    await getBacfPrice();
    setLoading(false);
  };

  useEffect(() => {
    getPrice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <div className="py-4">
      <Loader />
    </div>
  ) : (
    <div className="max-w-[632px]">
      <Card
        title={
          userRequest?.bacf_payment_confirmed !== true
            ? "BACF Payment Update"
            : "Payment Confirmation Success"
        }
        description={
          userRequest?.bacf_payment_confirmed !== true ? (
            <>
              <span>
                We've successfully sent the payment instructions, including the
                required ₦{addCommasToNumber(bacfPrice)}, to the buyer. The
                system is now awaiting payment confirmation.
              </span>
              <br /> <br />
              <span>
                Upon successful verification, which occurs automatically via our
                platform, the buyer will be seamlessly moved to the subsequent
                stage of the process.
              </span>
            </>
          ) : (
            <>
              <span>Payment Successfully Confirmed!</span>
              <br /> <br />
              <span>
                The buyer's status has been updated to 'Payment Confirmed,' and
                they have been advanced to the next stage.
              </span>
            </>
          )
        }
        badge={
          <Badge
            status={
              userRequest?.bacf_payment_confirmed !== true ? "not_paid" : "paid"
            }
          />
        }
      >
        {userRequest?.bacf_payment_confirmed !== true ? (
          <CompanyInfoNotPaid details={userRequest} />
        ) : (
          <CompanyInfoPaid details={userRequest} />
        )}
      </Card>
    </div>
  );
}
