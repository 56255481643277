import React, { useState } from "react";
import { Form, Formik } from "formik";
import { changePasswordValidation } from "../../../utils/validation";
import Button from "../../ui/button";
import Input from "../../ui/input";
import Icons from "../../../assets/icons";
import PasswordModal from "./passwordModal";
import useUser from "../../../hooks/useUser";

export default function ChangePassword({ type }) {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [password, setPassword] = useState({});

  const togglePasswordModal = (value) => setIsPasswordModalOpen(value);
  const toggleHiddenPassword = () => setIsPasswordHidden(!isPasswordHidden);

  const { verifyPassword } = useUser();

  return (
    <>
      <p className="text-sm ">
        Enhance your account's security by updating your password regularly.
      </p>
      <Formik
        initialValues={{
          currentPassword: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const isverified = await verifyPassword(values);
          if (isverified) {
            setPassword(values);
            togglePasswordModal(true);
          }
          setSubmitting(false);
        }}
        validationSchema={changePasswordValidation}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isValid,
            dirty,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props;

          return (
            <Form className="flex flex-col gap-12">
              <div className="flex flex-col gap-6">
                <Input
                  title="CURRENT PASSWORD"
                  icon={
                    isPasswordHidden ? Icons.ic_eye_closed : Icons.ic_eye_open
                  }
                  placeholder="Your current password"
                  type={isPasswordHidden ? "password" : "text"}
                  name="currentPassword"
                  id="currentPassword"
                  value={values.currentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.currentPassword && touched.currentPassword
                      ? errors.currentPassword
                      : ""
                  }
                  toggleHiddenPassword={toggleHiddenPassword}
                />
              </div>
              <Button
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                className="py-4 px-6"
                fullWidth
                loading={isSubmitting}
              >
                CHANGE PASSWORD
              </Button>
            </Form>
          );
        }}
      </Formik>
      <PasswordModal
        togglePasswordModal={togglePasswordModal}
        isPasswordModalOpen={isPasswordModalOpen}
        password={password}
        type={type}
      />
    </>
  );
}
