import { Formik, Form } from "formik";
import React from "react";
import { object, string } from "yup";
import { setHours, setMinutes, subDays } from "date-fns";
import Input from "../../../../ui/input";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import Badge from "../../../../ui/badge";
import useUser from "../../../../../hooks/useUser";
import SectionHeader from "../../../General/SectionHeader";
import TimePicker from "../../../../ui/timePicker";
import DateInput from "../../../../ui/datePicker";
import { convertTime } from "../../../../../utils/functions";

export default function SiteVisitForm({ handleStageFourStep }) {
  return (
    <div className="flex flex-col md:flex-row gap-6 justify-center">
      <div className="border border-grey-200 rounded-lg w-full md:max-w-[560px] bg-white p-6 space-y-6">
        <SectionHeader
          title="Site visit"
          badge={<Badge status="pending_details" />}
        />

        <p className="text-sm">
          Visit involves a detailed inspection of the buyer's facility pertinent
          to the procurement process. This visit enables GACN to evaluate the
          site's condition, observe (planned) operations, and address any
          pertinent issues, fostering informed decision-making by all involved
          parties.
        </p>
        <SiteForm handleStageFourStep={handleStageFourStep} />
      </div>
    </div>
  );
}

const SiteForm = ({ handleStageFourStep }) => {
  const { createSiteVisit } = useUser();
  return (
    <Formik
      initialValues={{
        site_location: "",
        date_of_visit: "",
        time_of_visit: "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const formattedTime = convertTime(values.time_of_visit);
        setSubmitting(true);
        const response = await createSiteVisit({
          ...values,
          time_of_visit: formattedTime,
        });
        if (response) {
          handleStageFourStep(2);
        }
        setSubmitting(false);
      }}
      validationSchema={object().shape({
        site_location: string().required("Please enter your site location"),
        date_of_visit: string().required("Please enter the visit date"),
        time_of_visit: string().required("Please enter the visit time"),
      })}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          dirty,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <Form className="flex flex-col gap-12">
            <div className="flex flex-col gap-6">
              <Input
                title="SITE DETAILED LOCATION"
                icon={Icons.ic_location}
                placeholder="Enter company address"
                type="text"
                name="site_location"
                id="site_location"
                value={values.site_location}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={
                  errors.site_location && touched.site_location
                    ? errors.site_location
                    : ""
                }
                required
              />

              <DateInput
                required
                title="PROPOSED DATE PERIOD"
                icon={Icons.ic_calendar}
                name="date_of_visit"
                id="date_of_visit"
                selected={values.date_of_visit}
                minDate={subDays(new Date(), 0)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date) => {
                  setFieldTouched("date_of_visit", true);
                  setFieldValue("date_of_visit", date);
                }}
                onBlur={handleBlur}
                errors={
                  errors.date_of_visit && touched.date_of_visit
                    ? errors.date_of_visit
                    : ""
                }
              />

              <TimePicker
                title="TIME OF AVAILABILITY"
                type="time"
                placeholder="00:00"
                name="time_of_visit"
                icon={Icons.ic_clock}
                id="time_of_visit"
                value={values.time_of_visit}
                onChange={(date) => {
                  setFieldTouched("time_of_visit", true);
                  setFieldValue("time_of_visit", date);
                }}
                onBlur={handleBlur}
                selected={values.time_of_visit}
                minTime={setHours(setMinutes(new Date(), 0), 7)}
                maxTime={setHours(setMinutes(new Date(), 30), 17)}
                errors={
                  errors.time_of_visit && touched.time_of_visit
                    ? errors.time_of_visit
                    : ""
                }
                required
              />
            </div>

            <Button
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              className="py-4 px-6"
              fullWidth
              loading={isSubmitting}
            >
              Submit details
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
