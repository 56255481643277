import { Form, Formik, getIn } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { technicalPowerValidationSchema } from "../../../../../utils/validation";
import useQuestionnaire from "../useQuestionnaire";
import Icons from "../../../../../assets/icons";
import DateInput from "../../../../ui/datePicker";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import Radio from "../../../../ui/radio";
import AdminComment from "../../../../ui/adminComment";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import { subDays } from "date-fns";
import { STAGE_FOUR_STATUS, USER_TYPE } from "../../../../../utils/constants";

export default function PowerTechnical({ handleStageFourTabClick }) {
  const { user } = useSelector((state) => state.auth);
  const { questionnaire } = useSelector((state) => state.user);

  const {
    disableForm,
    loading,
    canAdminComment,
    initialValues,
    handleSubmitPowerTechnical,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useQuestionnaire(handleStageFourTabClick);

  return (
    <Formik
      initialValues={initialValues.technicalPower}
      validationSchema={technicalPowerValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setErrors,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <div className="py-8 space-y-3">
              <h6 className="font-semibold text-black">
                Section 4: Technical Due Diligence
              </h6>
              <p className="text-sm max-w-[324px]">
                Please provide more Key Information and Supporting Documents for
                the Current or Proposed Project.
              </p>
            </div>
            <FormContainer width type="user" title="1. Project Description">
              <>
                <SectionHeader title="Plant Nominal Installed Capacity*" />
                <AdminComment
                  name="plantNominalInstalledCapacity"
                  adminComment={
                    adminComments?.technicalPower
                      ?.plantNominalInstalledCapacity || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.plantNominalInstalledCapacity
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="plantNominalInstalledCapacity"
                    id="plantNominalInstalledCapacity"
                    value={values.plantNominalInstalledCapacity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.plantNominalInstalledCapacity &&
                      touched.plantNominalInstalledCapacity
                        ? errors.plantNominalInstalledCapacity
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.plantNominalInstalledCapacity?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Location and land acquisition status*" />
                <AdminComment
                  name="locationAndLandAcquisitionStatus"
                  adminComment={
                    adminComments?.technicalPower
                      ?.locationAndLandAcquisitionStatus || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.locationAndLandAcquisitionStatus
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="locationAndLandAcquisitionStatus"
                    id="locationAndLandAcquisitionStatus"
                    value={values.locationAndLandAcquisitionStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.locationAndLandAcquisitionStatus &&
                      touched.locationAndLandAcquisitionStatus
                        ? errors.locationAndLandAcquisitionStatus
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.locationAndLandAcquisitionStatus?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <AdminComment
                  name="technologyDeployed"
                  adminComment={
                    adminComments?.technicalPower?.technologyDeployed || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.technologyDeployed
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Radio
                    options={[
                      {
                        title: "Open Cycle Turbines",
                        value: "Open Cycle Turbines",
                      },
                      {
                        title: "Combined Cycle Turbines",
                        value: "Combined Cycle Turbines",
                      },
                    ]}
                    value={values.technologyDeployed}
                    onChange={handleChange}
                    name="technologyDeployed"
                    title="Technology deployed"
                    errors={
                      Boolean(
                        errors.technologyDeployed && touched.technologyDeployed
                      )
                        ? errors.technologyDeployed
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence?.technologyDeployed
                        ?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer width type="user" title="2. Gas Requirement">
              <>
                <SectionHeader title="Volume requirement (daily, annual quantity) (Mmscf/d) and duration" />
                <AdminComment
                  name="gasRequirement.volumeRequirement"
                  adminComment={
                    adminComments?.technicalPower?.gasRequirement
                      .volumeRequirement || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.gasRequirement
                      .volumeRequirement
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="gasRequirement.volumeRequirement"
                    id="gasRequirement.volumeRequirement"
                    value={values.gasRequirement.volumeRequirement}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "gasRequirement.volumeRequirement") &&
                          getIn(touched, "gasRequirement.volumeRequirement")
                      )
                        ? getIn(errors, "gasRequirement.volumeRequirement")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      questionnaire?.TechnicalDueDiligence
                        ?.plantNominalInstalledCapacity?.adminNote
                    }
                  />
                </AdminComment>
              </>
              {/* <>
                <SectionHeader title="Gross Heating Values (minimum/maximum MMbtu/d)" />
                <AdminComment
                  name="gasRequirement.grossHeatingValues"
                  adminComment={
                    adminComments?.technicalPower?.gasRequirement
                      ?.grossHeatingValues || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.gasRequirement
                      ?.grossHeatingValues
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="gasRequirement.grossHeatingValues"
                    id="gasRequirement.grossHeatingValues"
                    value={values.gasRequirement.grossHeatingValues}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "gasRequirement.grossHeatingValues") &&
                          getIn(touched, "gasRequirement.grossHeatingValues")
                      )
                        ? getIn(errors, "gasRequirement.grossHeatingValues")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence?.gasRequirement
                        ?.grossHeatingValues?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </> */}
              <>
                <SectionHeader title="Delivery Pressures" />
                <AdminComment
                  name="gasRequirement.deliveryPressures"
                  adminComment={
                    adminComments?.technicalPower?.gasRequirement
                      ?.deliveryPressures || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.gasRequirement
                      ?.deliveryPressures
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="gasRequirement.deliveryPressures"
                    id="gasRequirement.deliveryPressures"
                    value={values.gasRequirement.deliveryPressures}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "gasRequirement.deliveryPressures") &&
                          getIn(touched, "gasRequirement.deliveryPressures")
                      )
                        ? getIn(errors, "gasRequirement.deliveryPressures")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence?.gasRequirement
                        ?.deliveryPressures?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Delivery Date" />
                <AdminComment
                  name="gasRequirement.deliveryDate"
                  adminComment={
                    adminComments?.technicalPower?.gasRequirement
                      ?.deliveryDate || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.gasRequirement?.deliveryDate
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <DateInput
                    name="gasRequirement.deliveryDate"
                    id="gasRequirement.deliveryDate"
                    icon={Icons.ic_calendar}
                    selected={values.gasRequirement.deliveryDate}
                    minDate={subDays(new Date(), 0)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                      setFieldTouched("gasRequirement.deliveryDate", true);
                      setFieldValue("gasRequirement.deliveryDate", date);
                    }}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "gasRequirement.deliveryDate") &&
                          getIn(touched, "gasRequirement.deliveryDate")
                      )
                        ? getIn(errors, "gasRequirement.deliveryDate")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence?.gasRequirement
                        ?.deliveryDate?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <>
                  <SectionHeader title="Anticipated demand swing, limitations, or restrictions" />
                  <AdminComment
                    name="gasRequirement.anticipatedDemandSwing"
                    adminComment={
                      adminComments?.technicalPower?.gasRequirement
                        ?.anticipatedDemandSwing || ""
                    }
                    isAdminCommentAvailable={
                      adminComments?.technicalPower?.gasRequirement
                        ?.anticipatedDemandSwing
                        ? true
                        : false
                    }
                    canAdminComment={canAdminComment}
                    allComments={adminComments}
                    setAdminComments={setAdminComments}
                    setIsCommentNonNull={setIsCommentNonNull}
                    type="technicalPower"
                  >
                    <Textarea
                      placeholder="Enter description here"
                      name="gasRequirement.anticipatedDemandSwing"
                      id="gasRequirement.anticipatedDemandSwing"
                      value={values.gasRequirement.anticipatedDemandSwing}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={
                        Boolean(
                          getIn(
                            errors,
                            "gasRequirement.anticipatedDemandSwing"
                          ) &&
                            getIn(
                              touched,
                              "gasRequirement.anticipatedDemandSwing"
                            )
                        )
                          ? getIn(
                              errors,
                              "gasRequirement.anticipatedDemandSwing"
                            )
                          : ""
                      }
                      disabled={
                        disableForm ||
                        (!questionnaire?.TechnicalDueDiligence?.gasRequirement
                          ?.anticipatedDemandSwing?.adminNote &&
                          questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                      }
                    />
                  </AdminComment>
                </>
                <SectionHeader title="Indication of Plant availability factor, Load Factor, Operational" />
                <AdminComment
                  name="gasRequirement.plantAvailability"
                  adminComment={
                    adminComments?.technicalPower?.gasRequirement
                      ?.plantAvailability || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.gasRequirement
                      ?.plantAvailability
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="gasRequirement.plantAvailability"
                    id="gasRequirement.plantAvailability"
                    value={values.gasRequirement.plantAvailability}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "gasRequirement.plantAvailability") &&
                          getIn(touched, "gasRequirement.plantAvailability")
                      )
                        ? getIn(errors, "gasRequirement.plantAvailability")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence?.gasRequirement
                        ?.plantAvailability?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="3. Gas Infrastructure arrangement"
            >
              <>
                <SectionHeader title="Describe the gas transportation arrangement to the site location. This should include; information on the pipeline (diameter, volume capability, pressure) to the facility, plans &amp; maps, the status of the pipeline(s), etc.*" />
                <AdminComment
                  name="gasInfrastructureArrangement"
                  adminComment={
                    adminComments?.technicalPower
                      ?.gasInfrastructureArrangement || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.gasInfrastructureArrangement
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="gasInfrastructureArrangement"
                    id="gasInfrastructureArrangement"
                    value={values.gasInfrastructureArrangement}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.gasInfrastructureArrangement &&
                      touched.gasInfrastructureArrangement
                        ? errors.gasInfrastructureArrangement
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.gasInfrastructureArrangement?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="gasInfrastructureArrangementDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.gasInfrastructureArrangementDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.gasInfrastructureArrangementDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="gasInfrastructureArrangementDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.gasInfrastructureArrangementDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.gasInfrastructureArrangementDocument?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer width type="user" title="4. Electricity evacuation">
              <Radio
                options={[
                  { title: "On-grid", value: "On-grid" },
                  { title: "Off-grid", value: "Off-grid" },
                ]}
                value={values.electricityEvacuation.mode}
                onChange={handleChange}
                name="electricityEvacuation.mode"
                title="Indicate power evacuation modal"
                errors={
                  Boolean(
                    getIn(errors, "electricityEvacuation.mode") &&
                      getIn(touched, "electricityEvacuation.mode")
                  )
                    ? getIn(errors, "electricityEvacuation.mode")
                    : ""
                }
                disabled={disableForm}
              />
              {values.electricityEvacuation?.mode === "On-grid" && (
                <>
                  <>
                    <SectionHeader title="Describe the electricity evacuation arrangement from your site to the proposed TCN substation" />
                    <AdminComment
                      name="electricityEvacuation.onGridDetails.evacuationArrangement"
                      adminComment={
                        adminComments?.technicalPower?.electricityEvacuation
                          ?.onGridDetails?.evacuationArrangement || ""
                      }
                      isAdminCommentAvailable={
                        adminComments?.technicalPower?.electricityEvacuation
                          ?.onGridDetails?.evacuationArrangement
                          ? true
                          : false
                      }
                      canAdminComment={canAdminComment}
                      allComments={adminComments}
                      setAdminComments={setAdminComments}
                      setIsCommentNonNull={setIsCommentNonNull}
                      type="technicalPower"
                    >
                      <Textarea
                        placeholder="Enter description here"
                        name="electricityEvacuation.onGridDetails.evacuationArrangement"
                        id="electricityEvacuation.onGridDetails.evacuationArrangement"
                        value={
                          values.electricityEvacuation.onGridDetails
                            .evacuationArrangement
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={
                          Boolean(
                            getIn(
                              errors,
                              "electricityEvacuation.onGridDetails.evacuationArrangement"
                            ) &&
                              getIn(
                                touched,
                                "electricityEvacuation.onGridDetails.evacuationArrangement"
                              )
                          )
                            ? getIn(
                                errors,
                                "electricityEvacuation.onGridDetails.evacuationArrangement"
                              )
                            : ""
                        }
                        disabled={
                          disableForm ||
                          (!questionnaire?.TechnicalDueDiligence
                            ?.electricityEvacuation?.onGridDetails
                            ?.evacuationArrangement?.adminNote &&
                            questionnaire?.status ===
                              STAGE_FOUR_STATUS.FEEDBACK)
                        }
                      />
                    </AdminComment>
                  </>
                  <>
                    <SectionHeader title="Provide TCN approved Transmission/Evacuation study showing evidence that capacity is available" />
                    <AdminComment
                      name="electricityEvacuation.onGridDetails.tcnApprovedStudy"
                      adminComment={
                        adminComments?.technicalPower?.electricityEvacuation
                          ?.onGridDetails?.tcnApprovedStudy || ""
                      }
                      isAdminCommentAvailable={
                        adminComments?.technicalPower?.electricityEvacuation
                          ?.onGridDetails?.tcnApprovedStudy
                          ? true
                          : false
                      }
                      canAdminComment={canAdminComment}
                      allComments={adminComments}
                      setAdminComments={setAdminComments}
                      setIsCommentNonNull={setIsCommentNonNull}
                      type="technicalPower"
                    >
                      <FileUpload
                        name="electricityEvacuation.onGridDetails.tcnApprovedStudy"
                        fileType=".pdf, .xls, .xlsx"
                        format="PDF or Excel format"
                        value={
                          questionnaire?.TechnicalDueDiligence
                            ? values.electricityEvacuation.onGridDetails
                                .tcnApprovedStudy
                            : ""
                        }
                        onChange={(fieldName, file) => {
                          setFieldValue(fieldName, file);
                        }}
                        disabled={
                          disableForm ||
                          (!questionnaire?.TechnicalDueDiligence
                            ?.electricityEvacuation?.onGridDetails
                            ?.tcnApprovedStudy?.adminNote &&
                            questionnaire?.status ===
                              STAGE_FOUR_STATUS.FEEDBACK)
                        }
                      />
                    </AdminComment>
                  </>
                </>
              )}
              {values.electricityEvacuation.mode === "Off-grid" && (
                <>
                  <>
                    <SectionHeader title="Provide evidence of a market assessment study showing the demand Target market where electricity will be supplied and Identify the supply niche i.e., captive market" />
                    <AdminComment
                      name="electricityEvacuation.offGridDetails.marketAssessmentStudy"
                      adminComment={
                        adminComments?.technicalPower?.electricityEvacuation
                          ?.offGridDetails?.marketAssessmentStudy || ""
                      }
                      isAdminCommentAvailable={
                        adminComments?.technicalPower?.electricityEvacuation
                          ?.offGridDetails?.marketAssessmentStudy
                          ? true
                          : false
                      }
                      canAdminComment={canAdminComment}
                      allComments={adminComments}
                      setAdminComments={setAdminComments}
                      setIsCommentNonNull={setIsCommentNonNull}
                      type="technicalPower"
                    >
                      <Textarea
                        placeholder="Enter description here"
                        name="electricityEvacuation.offGridDetails.marketAssessmentStudy"
                        id="electricityEvacuation.offGridDetails.marketAssessmentStudy"
                        value={
                          values.electricityEvacuation.offGridDetails
                            .marketAssessmentStudy
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={
                          Boolean(
                            getIn(
                              errors,
                              "electricityEvacuation.offGridDetails.marketAssessmentStudy"
                            ) &&
                              getIn(
                                touched,
                                "electricityEvacuation.offGridDetails.marketAssessmentStudy"
                              )
                          )
                            ? getIn(
                                errors,
                                "electricityEvacuation.offGridDetails.marketAssessmentStudy"
                              )
                            : ""
                        }
                        disabled={
                          disableForm ||
                          (!questionnaire?.TechnicalDueDiligence
                            ?.electricityEvacuation?.offGridDetails
                            ?.marketAssessmentStudy?.adminNote &&
                            questionnaire?.status ===
                              STAGE_FOUR_STATUS.FEEDBACK)
                        }
                      />
                    </AdminComment>
                  </>
                  <>
                    <SectionHeader title="Describe the electricity evacuation arrangement from your site to the proposed captive market" />
                    <AdminComment
                      name="electricityEvacuation.offGridDetails.supplyNicheDescription"
                      adminComment={
                        adminComments?.technicalPower?.electricityEvacuation
                          ?.offGridDetails?.supplyNicheDescription || ""
                      }
                      isAdminCommentAvailable={
                        adminComments?.technicalPower?.electricityEvacuation
                          ?.offGridDetails?.supplyNicheDescription
                          ? true
                          : false
                      }
                      canAdminComment={canAdminComment}
                      allComments={adminComments}
                      setAdminComments={setAdminComments}
                      setIsCommentNonNull={setIsCommentNonNull}
                      type="technicalPower"
                    >
                      <Textarea
                        placeholder="Enter description here"
                        name="electricityEvacuation.offGridDetails.supplyNicheDescription"
                        id="electricityEvacuation.offGridDetails.supplyNicheDescription"
                        value={
                          values.electricityEvacuation.offGridDetails
                            .supplyNicheDescription
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={
                          Boolean(
                            getIn(
                              errors,
                              "electricityEvacuation.offGridDetails.supplyNicheDescription"
                            ) &&
                              getIn(
                                touched,
                                "electricityEvacuation.offGridDetails.supplyNicheDescription"
                              )
                          )
                            ? getIn(
                                errors,
                                "electricityEvacuation.offGridDetails.supplyNicheDescription"
                              )
                            : ""
                        }
                        disabled={
                          disableForm ||
                          (!questionnaire?.TechnicalDueDiligence
                            ?.electricityEvacuation?.offGridDetails
                            ?.supplyNicheDescription?.adminNote &&
                            questionnaire?.status ===
                              STAGE_FOUR_STATUS.FEEDBACK)
                        }
                      />
                    </AdminComment>
                  </>
                </>
              )}
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="5. Statement on technologyDeployed to be deployed and evidence of license to apply Such."
            >
              <>
                <SectionHeader title="Status of project engineering designs e.g, Feasibility Report, Pre-FEED and FEED activities, Detailed Design etc., including the name of the engineering firm being used (if applicable) or plan for the development of same*" />
                <AdminComment
                  name="projectEngineeringStatus"
                  adminComment={
                    adminComments?.technicalPower?.projectEngineeringStatus ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.projectEngineeringStatus
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectEngineeringStatus"
                    id="projectEngineeringStatus"
                    value={values.projectEngineeringStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.projectEngineeringStatus &&
                      touched.projectEngineeringStatus
                        ? errors.projectEngineeringStatus
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.projectEngineeringStatus?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="projectEngineeringStatusDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.projectEngineeringStatusDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.projectEngineeringStatusDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="projectEngineeringStatusDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.projectEngineeringStatusDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.projectEngineeringStatusDocument?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Status of EPC Contracts: Award Process, Expression of Interest, Request for Proposal - Technical and Commercial*" />

                <AdminComment
                  name="statusOfEpcContracts"
                  adminComment={
                    adminComments?.technicalPower?.statusOfEpcContracts || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.statusOfEpcContracts
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="statusOfEpcContracts"
                    id="statusOfEpcContracts"
                    value={values.statusOfEpcContracts}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.statusOfEpcContracts &&
                      touched.statusOfEpcContracts
                        ? errors.statusOfEpcContracts
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.statusOfEpcContracts?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="statusOfEpcContractsDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.statusOfEpcContractsDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.statusOfEpcContractsDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="statusOfEpcContractsDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.statusOfEpcContractsDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.statusOfEpcContractsDocument?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Evidence of Technical Partners (if applicable)"
                />
                <AdminComment
                  name="evidenceOfTechnicalPartners"
                  adminComment={
                    adminComments?.technicalPower
                      ?.evidenceOfTechnicalPartners || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.evidenceOfTechnicalPartners
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="evidenceOfTechnicalPartners"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    onBlur={handleBlur}
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.evidenceOfTechnicalPartners
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    errors={
                      errors.evidenceOfTechnicalPartners &&
                      touched.evidenceOfTechnicalPartners
                        ? errors.evidenceOfTechnicalPartners
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.evidenceOfTechnicalPartners?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Plan for Nigerian Content law compliance as applicable"
                />
                <AdminComment
                  name="nigerianContentCompliancePlan"
                  adminComment={
                    adminComments?.technicalPower
                      ?.nigerianContentCompliancePlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.nigerianContentCompliancePlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="nigerianContentCompliancePlan"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.nigerianContentCompliancePlan
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    errors={
                      errors.nigerianContentCompliancePlan &&
                      touched.nigerianContentCompliancePlan
                        ? errors.nigerianContentCompliancePlan
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.nigerianContentCompliancePlan?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Operations and Maintenance Plan" />
                <AdminComment
                  name="operationsAndMaintenancePlan"
                  adminComment={
                    adminComments?.technicalPower
                      ?.operationsAndMaintenancePlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.operationsAndMaintenancePlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="operationsAndMaintenancePlan"
                    id="operationsAndMaintenancePlan"
                    value={values.operationsAndMaintenancePlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.operationsAndMaintenancePlan &&
                      touched.operationsAndMaintenancePlan
                        ? errors.operationsAndMaintenancePlan
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.operationsAndMaintenancePlan?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="operationsAndMaintenancePlanDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.operationsAndMaintenancePlanDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.operationsAndMaintenancePlanDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="operationsAndMaintenancePlanDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.operationsAndMaintenancePlanDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.operationsAndMaintenancePlanDocument?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Uptime performance expectation and basis" />
                <AdminComment
                  name="uptimePerformanceExpectation"
                  adminComment={
                    adminComments?.technicalPower
                      ?.uptimePerformanceExpectation || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.uptimePerformanceExpectation
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="uptimePerformanceExpectation"
                    id="uptimePerformanceExpectation"
                    value={values.uptimePerformanceExpectation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.uptimePerformanceExpectation &&
                      touched.uptimePerformanceExpectation
                        ? errors.uptimePerformanceExpectation
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.uptimePerformanceExpectation?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="uptimePerformanceExpectationDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.uptimePerformanceExpectationDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.uptimePerformanceExpectationDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="uptimePerformanceExpectationDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.uptimePerformanceExpectationDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.uptimePerformanceExpectationDocument?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Status of Technical / Maintenance Agreements" />
                <AdminComment
                  name="technicalMaintenanceAgreementsStatus"
                  adminComment={
                    adminComments?.technicalPower
                      ?.technicalMaintenanceAgreementsStatus || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.technicalMaintenanceAgreementsStatus
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="technicalMaintenanceAgreementsStatus"
                    id="technicalMaintenanceAgreementsStatus"
                    value={values.technicalMaintenanceAgreementsStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.technicalMaintenanceAgreementsStatus &&
                      touched.technicalMaintenanceAgreementsStatus
                        ? errors.technicalMaintenanceAgreementsStatus
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.technicalMaintenanceAgreementsStatus?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="technicalMaintenanceAgreementsStatusDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.technicalMaintenanceAgreementsStatusDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.technicalMaintenanceAgreementsStatusDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="technicalMaintenanceAgreementsStatusDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.technicalMaintenanceAgreementsStatusDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.technicalMaintenanceAgreementsStatusDocument
                        ?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Staffing and recruiting Plans" />
                <AdminComment
                  name="staffingAndRecruitingPlans"
                  adminComment={
                    adminComments?.technicalPower?.staffingAndRecruitingPlans ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.staffingAndRecruitingPlans
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="staffingAndRecruitingPlans"
                    id="staffingAndRecruitingPlans"
                    value={values.staffingAndRecruitingPlans}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.staffingAndRecruitingPlans &&
                      touched.staffingAndRecruitingPlans
                        ? errors.staffingAndRecruitingPlans
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.staffingAndRecruitingPlans?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="staffingAndRecruitingPlansDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.staffingAndRecruitingPlansDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.staffingAndRecruitingPlansDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="staffingAndRecruitingPlansDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.staffingAndRecruitingPlansDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.staffingAndRecruitingPlansDocument?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Health, Safety &amp; Environment (HSE) Plans and measuring systems." />
                <AdminComment
                  name="hsePlans"
                  adminComment={adminComments?.technicalPower?.hsePlans || ""}
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.hsePlans ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="hsePlans"
                    id="hsePlans"
                    value={values.hsePlans}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.hsePlans && touched.hsePlans ? errors.hsePlans : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence?.hsePlans
                        ?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="hsePlansDocument"
                  adminComment={
                    adminComments?.technicalPower?.hsePlansDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.hsePlansDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="hsePlansDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.hsePlansDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence?.hsePlansDocument
                        ?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Existing &amp; expansion facilities" />
                <AdminComment
                  name="existingAndExpansionFacilities"
                  adminComment={
                    adminComments?.technicalPower
                      ?.existingAndExpansionFacilities || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.existingAndExpansionFacilities
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="existingAndExpansionFacilities"
                    id="existingAndExpansionFacilities"
                    value={values.existingAndExpansionFacilities}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.existingAndExpansionFacilities &&
                      touched.existingAndExpansionFacilities
                        ? errors.existingAndExpansionFacilities
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.existingAndExpansionFacilities?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="existingAndExpansionFacilitiesDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.existingAndExpansionFacilitiesDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.existingAndExpansionFacilitiesDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="existingAndExpansionFacilitiesDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.existingAndExpansionFacilitiesDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.existingAndExpansionFacilitiesDocument?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Operating Manuals" />
                <AdminComment
                  name="operatingManuals"
                  adminComment={
                    adminComments?.technicalPower?.operatingManuals || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.operatingManuals
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="operatingManuals"
                    id="operatingManuals"
                    value={values.operatingManuals}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.operatingManuals && touched.operatingManuals
                        ? errors.operatingManuals
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence?.operatingManuals
                        ?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="operatingManualsDocument"
                  adminComment={
                    adminComments?.technicalPower?.operatingManualsDocument ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.operatingManualsDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="operatingManualsDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.operatingManualsDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.operatingManualsDocument?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Sustainable Development &amp; Community Engagement and Relations Plans" />
                <AdminComment
                  name="sustainableDevelopmentAndCommunityEngagementPlans"
                  adminComment={
                    adminComments?.technicalPower
                      ?.sustainableDevelopmentAndCommunityEngagementPlans || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.sustainableDevelopmentAndCommunityEngagementPlans
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="sustainableDevelopmentAndCommunityEngagementPlans"
                    id="sustainableDevelopmentAndCommunityEngagementPlans"
                    value={
                      values.sustainableDevelopmentAndCommunityEngagementPlans
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.sustainableDevelopmentAndCommunityEngagementPlans &&
                      touched.sustainableDevelopmentAndCommunityEngagementPlans
                        ? errors.sustainableDevelopmentAndCommunityEngagementPlans
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.sustainableDevelopmentAndCommunityEngagementPlans
                        ?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="sustainableDevelopmentAndCommunityEngagementPlansDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.sustainableDevelopmentAndCommunityEngagementPlansDocument ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower
                      ?.sustainableDevelopmentAndCommunityEngagementPlansDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="sustainableDevelopmentAndCommunityEngagementPlansDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.sustainableDevelopmentAndCommunityEngagementPlansDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.sustainableDevelopmentAndCommunityEngagementPlansDocument
                        ?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  title={
                    <>
                      <span>Construction Schedule</span>
                      <br />
                      <ul className="ml-5 list-decimal">
                        <li>Project subcontracting system</li>
                        <li>Construction execution plan overview</li>
                        <li>
                          Integration plan with existing facilities in case of
                          expansion
                        </li>
                        <li>Process Documentation</li>
                        <li>
                          Project Master Schedules and Critical-Path activities
                        </li>
                      </ul>
                    </>
                  }
                />
                <AdminComment
                  name="constructionSchedule"
                  adminComment={
                    adminComments?.technicalPower?.constructionSchedule || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.constructionSchedule
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="constructionSchedule"
                    id="constructionSchedule"
                    value={values.constructionSchedule}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.constructionSchedule &&
                      touched.constructionSchedule
                        ? errors.constructionSchedule
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.constructionSchedule?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="constructionScheduleDocument"
                  adminComment={
                    adminComments?.technicalPower
                      ?.constructionScheduleDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalPower?.constructionScheduleDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalPower"
                >
                  <FileUpload
                    name="constructionScheduleDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.constructionScheduleDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!questionnaire?.TechnicalDueDiligence
                        ?.constructionScheduleDocument?.adminNote &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            {(user?.role === USER_TYPE.USER ||
              user?.role === USER_TYPE.PROXY) &&
            (!questionnaire ||
              questionnaire?.status === STAGE_FOUR_STATUS.PENDING ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) ? (
              <div className="flex justify-between w-full pt-16">
                <button
                  onClick={() => handleStageFourTabClick(2)}
                  className="text-primary-1 uppercase text-sm font-bold leading-[16.8px]"
                >
                  Previous
                </button>
                <div className="flex justify-end gap-6 ">
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      setErrors({});
                      handleSubmitPowerTechnical(values, "save", setSubmitting);
                      const res = validateForm();
                      setErrors({ ...res });
                    }}
                    loading={isSubmitting}
                    className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                  >
                    Save progress
                  </Button>
                  <Button
                    onClick={async () => {
                      setSubmitting(false);
                      const res = await validateForm();
                      setErrors({ ...res });

                      if (Object.keys(res).length !== 0)
                        toast.error("Please fill all the required fields");
                      else
                        handleSubmitPowerTechnical(
                          values,
                          "next",
                          setSubmitting
                        );
                    }}
                    disabled={!isValid || loading}
                    className="py-4 px-6 self-end"
                    loading={loading}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end gap-6 w-full pt-16">
                <div className="flex justify-end gap-6">
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(2)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    {Icons.ic_arrow_left} Previous
                  </Button>
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(4)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    Next {Icons.ic_arrow_right}
                  </Button>
                </div>
                {(user?.role === USER_TYPE.ADMIN ||
                  user?.role === USER_TYPE.SUPER_ADMIN) &&
                  (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
                    questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
                    <Button
                      disabled={loadingAddComments || !isCommentNonNull}
                      onClick={() => {
                        handleAddAdminComment(
                          adminComments?.technicalPower,
                          "adminUpdateTechnical"
                        );
                      }}
                      loading={loadingAddComments}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                    >
                      Save Comment
                    </Button>
                  )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
