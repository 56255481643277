import LoginForm from "../../components/AuthForm/login";
import AuthLayout from "../../components/Layout/authLayout";

const LoginPage = () => {
  return (
    <AuthLayout
      type="user"
      page="login"
      title="Log in"
      description="Enter your credentials to access your account"
    >
      <LoginForm type='user' />
    </AuthLayout>
  );
};

export default LoginPage;
