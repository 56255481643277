import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUser from "../../../../../hooks/useUser";

export default function useStageFour() {
  const { getQuestionnaireDetails } = useUser();

  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [stageFourStep, setStageFourStep] = useState(1);

  const handleStageFourStep = (value) => setStageFourStep(value);

  const fetchQuestionnaire = async () => {
    setLoading(true);
    const response = await getQuestionnaireDetails(
      user?.superuser_id ?? user?._id
    );
    if (response?.data && response?.data?.status !== "pending")
      setStageFourStep(2);
    setLoading(false);
  };

  useEffect(() => {
    if (user?.superuser_id ?? user?._id) {
      fetchQuestionnaire();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.superuser_id, user?._id]);

  return { loading, stageFourStep, handleStageFourStep };
}
