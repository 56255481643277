import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { subDays } from "date-fns";
import { requestValidationSchema } from "../../../../../utils/validation";
import useQuestionnaire from "../useQuestionnaire";
import Icons from "../../../../../assets/icons";
import Input from "../../../../ui/input";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import AdminComment from "../../../../ui/adminComment";
import FormContainer from "../../FormContainer";
import Select from "../../../../ui/select";
import RequestModal from "./requestModal";
import {
  countNonNullValues,
  parseAndHandleChange,
} from "../../../../../utils/functions";
import GasSpecificationModal from "./gasSpecificationModal";
import DateInput from "../../../../ui/datePicker";
import { setUserRequest } from "../../../../../config/reducers/userSlice";
import {
  PROJECT_FULL_NAME,
  PROJECT_TYPE,
  STAGE_FOUR_STATUS,
  USER_TYPE,
} from "../../../../../utils/constants";

export default function Request({ handleStageFourTabClick }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { questionnaire } = useSelector((state) => state.user);

  const {
    disableForm,
    loading,
    canAdminComment,
    initialValues,
    handleSubmitRequest,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddRequestDetailAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useQuestionnaire(handleStageFourTabClick);

  const [isGasSpecificationModalOpen, setIsGasSpecificationModalOpen] =
    useState(false);
  const [isGasContractModalOpen, setIsGasContractModalOpen] = useState(false);
  const [isNglContractModalOpen, setIsNglContractModalOpen] = useState(false);
  const [isLpgContractModalOpen, setIsLpgContractModalOpen] = useState(false);

  const toggleGasSpecificationModal = (value) =>
    setIsGasSpecificationModalOpen(value);

  const toggleGasContractModal = (value) => setIsGasContractModalOpen(value);
  const toggleNglContractModal = (value) => setIsNglContractModalOpen(value);
  const toggleLpgContractModal = (value) => setIsLpgContractModalOpen(value);

  useEffect(() => {
    countNonNullValues(adminComments?.request);
  }, [adminComments]);

  return (
    <Formik
      initialValues={initialValues.request}
      onSubmit={(values, { setSubmitting }) => setSubmitting(false)}
      validationSchema={requestValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setErrors,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <div className="py-8 space-y-3">
              <h6 className="font-semibold text-black">
                Section 1: Request Details
              </h6>
              <p className="text-sm max-w-[324px] flex gap-2 items-center">
                Fill in the following details{" "}
                <span
                  className="cursor-pointer"
                  onClick={() => toggleGasSpecificationModal(true)}
                >
                  {Icons.ic_info}
                </span>
                <GasSpecificationModal
                  toggleGasSpecificationModal={toggleGasSpecificationModal}
                  isGasSpecificationModalOpen={isGasSpecificationModalOpen}
                />
              </p>
            </div>
            <FormContainer width type="user" title="1. Project Company Details">
              <Input
                required
                title="COMPANY NAME"
                icon={Icons.ic_company}
                placeholder="Your job title"
                type="text"
                name="company_name"
                id="company_name"
                value={values.company_name}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={
                  errors.company_name && touched.company_name
                    ? errors.company_name
                    : ""
                }
                disabled
              />

              <AdminComment
                name="company_address"
                adminComment={adminComments?.request?.company_address || ""}
                isAdminCommentAvailable={
                  adminComments?.request?.company_address ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <Input
                  required
                  title="REGISTERED OFFICE"
                  icon={Icons.ic_location}
                  placeholder="Enter company address"
                  type="text"
                  name="company_address"
                  id="company_address"
                  value={values.company_address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.company_address && touched.company_address
                      ? errors.company_address
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.request?.company_address &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>

              <Select
                required
                title="NATURE OF BUSINESS"
                name="natureOfBusiness"
                id="natureOfBusiness"
                value={values.natureOfBusiness}
                onChange={(event) => {
                  setFieldTouched("natureOfBusiness", true);
                  setFieldValue("natureOfBusiness", event.target.value);

                  dispatch(
                    setUserRequest({
                      project_type: event.target.value,
                    })
                  );
                }}
                onBlur={handleBlur}
                errors={
                  errors.natureOfBusiness && touched.natureOfBusiness
                    ? errors.natureOfBusiness
                    : ""
                }
                options={[
                  {
                    title: PROJECT_FULL_NAME.POWER,
                    value: PROJECT_TYPE.POWER,
                  },
                  { title: PROJECT_FULL_NAME.GAS, value: PROJECT_TYPE.GAS },
                  {
                    title: PROJECT_FULL_NAME.COMM,
                    value: PROJECT_TYPE.COMM,
                  },
                ]}
                disabled={questionnaire && questionnaire?.status !== "pending"}
              />

              {values.natureOfBusiness === "power" && (
                <AdminComment
                  name="projectDesc"
                  adminComment={adminComments?.request?.projectDesc || ""}
                  isAdminCommentAvailable={
                    adminComments?.request?.projectDesc ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="request"
                  marginTop={true}
                >
                  <Textarea
                    required
                    title="Please describe your project type"
                    placeholder="Enter description here"
                    name="projectDesc"
                    id="projectDesc"
                    value={values.projectDesc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.projectDesc && touched.projectDesc
                        ? errors.projectDesc
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.request?.projectDesc &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              )}

              {values.natureOfBusiness === "gas" && (
                <AdminComment
                  name="endProducts"
                  adminComment={adminComments?.request?.endProducts || ""}
                  isAdminCommentAvailable={
                    adminComments?.request?.endProducts ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="request"
                  marginTop={true}
                >
                  <Textarea
                    required
                    title="PLEASE SPECIFY END PRODUCT(S)"
                    placeholder="Enter description here"
                    name="endProducts"
                    id="endProducts"
                    value={values.endProducts}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.endProducts && touched.endProducts
                        ? errors.endProducts
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.request?.endProducts &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              )}

              {values.natureOfBusiness === "comm" && (
                <AdminComment
                  name="franchiseOrDistributionArea"
                  adminComment={
                    adminComments?.request?.franchiseOrDistributionArea || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.request?.franchiseOrDistributionArea
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="request"
                  marginTop={true}
                >
                  <Textarea
                    required
                    title="PLEASE SPECIFY THE FRANCHISE/DISTRIBUTION/LOCATION AREA"
                    placeholder="Enter description here"
                    name="franchiseOrDistributionArea"
                    id="franchiseOrDistributionArea"
                    value={values.franchiseOrDistributionArea}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.franchiseOrDistributionArea &&
                      touched.franchiseOrDistributionArea
                        ? errors.franchiseOrDistributionArea
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.request?.franchiseOrDistributionArea &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              )}

              <AdminComment
                name="likelyDateOfFirstGasDelivery"
                adminComment={
                  adminComments?.request?.likelyDateOfFirstGasDelivery || ""
                }
                isAdminCommentAvailable={
                  adminComments?.request?.likelyDateOfFirstGasDelivery
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <DateInput
                  required
                  title="DATE OF FIRST GAS DELIVERY"
                  icon={Icons.ic_calendar}
                  name="likelyDateOfFirstGasDelivery"
                  id="likelyDateOfFirstGasDelivery"
                  selected={values.likelyDateOfFirstGasDelivery}
                  minDate={subDays(new Date(), 0)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(date) => {
                    setFieldTouched("likelyDateOfFirstGasDelivery", true);
                    setFieldValue("likelyDateOfFirstGasDelivery", date);
                  }}
                  onBlur={handleBlur}
                  errors={
                    errors.likelyDateOfFirstGasDelivery &&
                    touched.likelyDateOfFirstGasDelivery
                      ? errors.likelyDateOfFirstGasDelivery
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.request?.likelyDateOfFirstGasDelivery &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>

              <AdminComment
                name="likelyDateOfFirstDeliveryOfNGLOrLPG"
                adminComment={
                  adminComments?.request?.likelyDateOfFirstDeliveryOfNGLOrLPG ||
                  ""
                }
                isAdminCommentAvailable={
                  adminComments?.request?.likelyDateOfFirstDeliveryOfNGLOrLPG
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <DateInput
                  title="DATE OF FIRST DELIVERY OF NGL OR LPG"
                  icon={Icons.ic_calendar}
                  name="likelyDateOfFirstDeliveryOfNGLOrLPG"
                  id="likelyDateOfFirstDeliveryOfNGLOrLPG"
                  selected={values.likelyDateOfFirstDeliveryOfNGLOrLPG}
                  minDate={subDays(new Date(), 0)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(date) => {
                    setFieldTouched(
                      "likelyDateOfFirstDeliveryOfNGLOrLPG",
                      true
                    );
                    setFieldValue("likelyDateOfFirstDeliveryOfNGLOrLPG", date);
                  }}
                  onBlur={handleBlur}
                  errors={
                    errors.likelyDateOfFirstDeliveryOfNGLOrLPG &&
                    touched.likelyDateOfFirstDeliveryOfNGLOrLPG
                      ? errors.likelyDateOfFirstDeliveryOfNGLOrLPG
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.request
                      ?.likelyDateOfFirstDeliveryOfNGLOrLPG &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>

              <AdminComment
                name="proposedDurationOfContract"
                adminComment={
                  adminComments?.request?.proposedDurationOfContract || ""
                }
                isAdminCommentAvailable={
                  adminComments?.request?.proposedDurationOfContract
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <Input
                  required
                  title="PROPOSED DURATION OF CONTRACT"
                  type="number"
                  min={0}
                  name="proposedDurationOfContract"
                  id="proposedDurationOfContract"
                  value={values.proposedDurationOfContract}
                  onChange={(event) =>
                    parseAndHandleChange(
                      event.target.value,
                      setFieldValue,
                      "proposedDurationOfContract"
                    )
                  }
                  onBlur={handleBlur}
                  errors={
                    errors.proposedDurationOfContract &&
                    touched.proposedDurationOfContract
                      ? errors.proposedDurationOfContract
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.request?.proposedDurationOfContract &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>

              <AdminComment
                name="totalContractVolume"
                adminComment={adminComments?.request?.totalContractVolume || ""}
                isAdminCommentAvailable={
                  adminComments?.request?.totalContractVolume ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <Input
                  required
                  title="TOTAL CONTRACT VOLUME (BCF)"
                  type="number"
                  min={0}
                  placeholder="10"
                  name="totalContractVolume"
                  id="totalContractVolume"
                  value={values.totalContractVolume}
                  onChange={(event) =>
                    parseAndHandleChange(
                      event.target.value,
                      setFieldValue,
                      "totalContractVolume"
                    )
                  }
                  onBlur={handleBlur}
                  errors={
                    errors.totalContractVolume && touched.totalContractVolume
                      ? errors.totalContractVolume
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.request?.totalContractVolume &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>

              <AdminComment
                name="totalContractVolumeForNGLOrLPG"
                adminComment={
                  adminComments?.request?.totalContractVolumeForNGLOrLPG || ""
                }
                isAdminCommentAvailable={
                  adminComments?.request?.totalContractVolumeForNGLOrLPG
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <Input
                  title="Total Contract Volume for NGL or LPG (BBL)"
                  type="number"
                  min={0}
                  placeholder="10"
                  name="totalContractVolumeForNGLOrLPG"
                  id="totalContractVolumeForNGLOrLPG"
                  value={values.totalContractVolumeForNGLOrLPG}
                  onChange={(event) =>
                    parseAndHandleChange(
                      event.target.value,
                      setFieldValue,
                      "totalContractVolumeForNGLOrLPG"
                    )
                  }
                  onBlur={handleBlur}
                  errors={
                    errors.totalContractVolumeForNGLOrLPG &&
                    touched.totalContractVolumeForNGLOrLPG
                      ? errors.totalContractVolumeForNGLOrLPG
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.request?.totalContractVolumeForNGLOrLPG &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>

              {/* <AdminComment
                name="proposedPaymentGuarantee"
                adminComment={
                  adminComments?.request?.proposedPaymentGuarantee || ""
                }
                isAdminCommentAvailable={
                  adminComments?.request?.proposedPaymentGuarantee
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <Textarea
                  required
                  title="PROPOSED PAYMENT GUARANTEE (Parent Company, Bank, or Any Other Financial Institution)"
                  placeholder="Enter description here"
                  name="proposedPaymentGuarantee"
                  id="proposedPaymentGuarantee"
                  value={values.proposedPaymentGuarantee}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.proposedPaymentGuarantee &&
                    touched.proposedPaymentGuarantee
                      ? errors.proposedPaymentGuarantee
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.request?.proposedPaymentGuarantee &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment> */}

              <AdminComment
                name="proposedLocationOfProject"
                adminComment={
                  adminComments?.request?.proposedLocationOfProject || ""
                }
                isAdminCommentAvailable={
                  adminComments?.request?.proposedLocationOfProject
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <Input
                  required
                  title="PROPOSED/EXISTING LOCATION OF PROJECT FACILITY"
                  icon={Icons.ic_location}
                  placeholder="Enter location here"
                  type="text"
                  name="proposedLocationOfProject"
                  id="proposedLocationOfProject"
                  value={values.proposedLocationOfProject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.proposedLocationOfProject &&
                    touched.proposedLocationOfProject
                      ? errors.proposedLocationOfProject
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.request?.proposedLocationOfProject &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer width type="user" title="2. Gas Contract Quantity">
              <AdminComment
                name="gasContractQuantity"
                adminComment={adminComments?.request?.gasContractQuantity || ""}
                isAdminCommentAvailable={
                  adminComments?.request?.gasContractQuantity ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <div>
                  <Select
                    required
                    title="SELECT NUMBER OF YEARS"
                    name="gasContractQuantityYear"
                    id="gasContractQuantityYear"
                    value={values.gasContractQuantityYear}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.gasContractQuantityYear &&
                      touched.gasContractQuantityYear
                        ? errors.gasContractQuantityYear
                        : errors.gasContractQuantity &&
                          touched.gasContractQuantity
                        ? errors.gasContractQuantity
                        : ""
                    }
                    options={[
                      { title: "1 year", value: 1 },
                      { title: "2 year", value: 2 },
                      { title: "3 year", value: 3 },
                      { title: "4 year", value: 4 },
                      { title: "5 year", value: 5 },
                      { title: "6 year", value: 6 },
                      { title: "7 year", value: 7 },
                      { title: "8 year", value: 8 },
                      { title: "9 year", value: 9 },
                      { title: "10 year", value: 10 },
                    ]}
                    disabled={
                      disableForm ||
                      (!adminComments?.request?.gasContractQuantity &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />

                  <div className="pt-2">
                    {values.gasContractQuantityYear ? (
                      <>
                        Please complete the annual contract qty spreadsheet{" "}
                        <span
                          className="text-primary-1 underline font-semibold cursor-pointer"
                          onClick={() => toggleGasContractModal(true)}
                        >
                          Click Here
                        </span>
                        <RequestModal
                          years={values.gasContractQuantityYear}
                          isRequestModalOpen={isGasContractModalOpen}
                          toggleRequestModal={toggleGasContractModal}
                          value={values.gasContractQuantity}
                          name="gasContractQuantity"
                          setValue={setFieldValue}
                          setTouched={setFieldTouched}
                          disabled={
                            disableForm ||
                            (!adminComments?.request?.gasContractQuantity &&
                              questionnaire?.status ===
                                STAGE_FOUR_STATUS.FEEDBACK)
                          }
                        />
                      </>
                    ) : (
                      "Total Contract Volume(Bcf): X yrs"
                    )}
                  </div>
                </div>
                {values.gasContractQuantity.years && (
                  <p>Total Contract Volume(BBL): -</p>
                )}
              </AdminComment>
            </FormContainer>
            <FormContainer
              width
              type="user"
              title="3. Natural Gas Liquids (NGL)"
            >
              <AdminComment
                name="nglContractQuantity"
                adminComment={adminComments?.request?.nglContractQuantity || ""}
                isAdminCommentAvailable={
                  adminComments?.request?.nglContractQuantity ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <div>
                  <Select
                    title="SELECT NUMBER OF YEARS"
                    name="nglContractQuantityYear"
                    id="nglContractQuantityYear"
                    value={values.nglContractQuantityYear}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.nglContractQuantityYear &&
                      touched.nglContractQuantityYear
                        ? errors.nglContractQuantityYear
                        : errors.nglContractQuantity &&
                          touched.nglContractQuantity
                        ? errors.nglContractQuantity
                        : ""
                    }
                    options={[
                      { title: "1 year", value: "1" },
                      { title: "2 year", value: "2" },
                      { title: "3 year", value: "3" },
                      { title: "4 year", value: "4" },
                      { title: "5 year", value: "5" },
                      { title: "6 year", value: 6 },
                      { title: "7 year", value: 7 },
                      { title: "8 year", value: 8 },
                      { title: "9 year", value: 9 },
                      { title: "10 year", value: 10 },
                    ]}
                    disabled={
                      disableForm ||
                      (!adminComments?.request?.nglContractQuantity &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />

                  <div className="pt-2">
                    {values.nglContractQuantityYear ? (
                      <>
                        Please complete the annual contract qty spreadsheet{" "}
                        <span
                          className="text-primary-1 underline font-semibold cursor-pointer"
                          onClick={() => toggleNglContractModal(true)}
                        >
                          Click Here
                        </span>
                        <RequestModal
                          years={values.nglContractQuantityYear}
                          isRequestModalOpen={isNglContractModalOpen}
                          toggleRequestModal={toggleNglContractModal}
                          value={values.nglContractQuantity}
                          name="nglContractQuantity"
                          setValue={setFieldValue}
                          setTouched={setFieldTouched}
                          disabled={
                            disableForm ||
                            (!adminComments?.request?.nglContractQuantity &&
                              questionnaire?.status ===
                                STAGE_FOUR_STATUS.FEEDBACK)
                          }
                        />
                      </>
                    ) : (
                      "Total Contract Volume(Bcf): X yrs"
                    )}
                  </div>
                </div>
                {values.gasContractQuantity.years && (
                  <p>Total Contract Volume(BBL): -</p>
                )}
              </AdminComment>
            </FormContainer>
            <FormContainer
              width
              type="user"
              title="4. Liquified Petroleum Gas (LPG)"
            >
              <AdminComment
                name="lpgContractQuantity"
                adminComment={adminComments?.request?.lpgContractQuantity || ""}
                isAdminCommentAvailable={
                  adminComments?.request?.lpgContractQuantity ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="request"
                marginTop={true}
              >
                <div>
                  <Select
                    title="SELECT NUMBER OF YEARS"
                    name="lpgContractQuantityYear"
                    id="lpgContractQuantityYear"
                    value={values.lpgContractQuantityYear}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.lpgContractQuantityYear &&
                      touched.lpgContractQuantityYear
                        ? errors.lpgContractQuantityYear
                        : errors.lpgContractQuantity &&
                          touched.lpgContractQuantity
                        ? errors.lpgContractQuantity
                        : ""
                    }
                    options={[
                      { title: "1 year", value: 1 },
                      { title: "2 year", value: 2 },
                      { title: "3 year", value: 3 },
                      { title: "4 year", value: 4 },
                      { title: "5 year", value: 5 },
                      { title: "6 year", value: 6 },
                      { title: "7 year", value: 7 },
                      { title: "8 year", value: 8 },
                      { title: "9 year", value: 9 },
                      { title: "10 year", value: 10 },
                    ]}
                    disabled={
                      disableForm ||
                      (!adminComments?.request?.lpgContractQuantity &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />

                  <div className="pt-2">
                    {values.lpgContractQuantityYear ? (
                      <>
                        Please complete the annual contract qty spreadsheet{" "}
                        <span
                          className="text-primary-1 underline font-semibold cursor-pointer"
                          onClick={() => toggleLpgContractModal(true)}
                        >
                          Click Here
                        </span>
                        <RequestModal
                          years={values.lpgContractQuantityYear}
                          isRequestModalOpen={isLpgContractModalOpen}
                          toggleRequestModal={toggleLpgContractModal}
                          value={values.lpgContractQuantity}
                          name="lpgContractQuantity"
                          setValue={setFieldValue}
                          setTouched={setFieldTouched}
                          disabled={
                            disableForm ||
                            (!adminComments?.request?.lpgContractQuantity &&
                              questionnaire?.status ===
                                STAGE_FOUR_STATUS.FEEDBACK)
                          }
                        />
                      </>
                    ) : (
                      "Total Contract Volume(Bcf): X yrs"
                    )}
                  </div>
                </div>
                {values.gasContractQuantity.years && (
                  <p>Total Contract Volume(BBL): -</p>
                )}
              </AdminComment>
            </FormContainer>

            {(user?.role === USER_TYPE.USER ||
              user?.role === USER_TYPE.PROXY) &&
            (!questionnaire ||
              questionnaire?.status === STAGE_FOUR_STATUS.PENDING ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) ? (
              <div className="flex justify-end gap-6 w-full pt-16">
                <Button
                  disabled={isSubmitting || loading}
                  onClick={() => {
                    setErrors({});
                    handleSubmitRequest(values, "save", setSubmitting);
                    const res = validateForm();
                    setErrors({ ...res });
                  }}
                  loading={isSubmitting}
                  className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                >
                  Save progress
                </Button>
                <Button
                  onClick={async () => {
                    setSubmitting(false);
                    const res = validateForm();
                    setErrors({ ...res });

                    if (Object.keys(res).length !== 0)
                      toast.error("Please fill all the required fields");
                    else handleSubmitRequest(values, "next", setSubmitting);
                  }}
                  disabled={!isValid || loading || isSubmitting}
                  className="py-4 px-6 self-end"
                  loading={loading}
                >
                  Next
                </Button>
              </div>
            ) : (
              <div className="flex justify-end gap-6 w-full pt-16">
                <div className="flex justify-end gap-6">
                  <Button
                    disabled
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    {Icons.ic_arrow_left} Previous
                  </Button>
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(1)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    Next {Icons.ic_arrow_right}
                  </Button>
                </div>
                {(user?.role === USER_TYPE.ADMIN ||
                  user?.role === USER_TYPE.SUPER_ADMIN) &&
                  (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
                    questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
                    <Button
                      disabled={loadingAddComments || !isCommentNonNull}
                      onClick={() => {
                        handleAddRequestDetailAdminComment(
                          adminComments?.request
                        );
                      }}
                      loading={loadingAddComments}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                    >
                      Save Comment
                    </Button>
                  )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
