import React, { Fragment } from "react";

import { Popover, Transition } from "@headlessui/react";
import Icons from "../../../assets/icons";
import Loader from "../loader";

const moreItems = [
  {
    name: "Resend Invite email",
    type: "resend",
  },
  {
    name: "Remove",
    type: "remove",
  },
];

const MoreButton = ({ removeUser, resendInvite, resendInviteLoading }) => (
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button className="">{Icons.ic_more}</Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute -left-9 md:-left-10 z-[99] mt-3 w-[155px] md:w-fit -translate-x-1/2 transform px-4 sm:px-0 popover">
            <div className="overflow-hidden rounded-lg border border-grey-200">
              <div className="divide-y divide-grey-200 bg-white py-1">
                {moreItems.map((item) => (
                  <button
                    key={item.name}
                    disabled={item.type === "resend" && resendInviteLoading}
                    onClick={item.type === "remove" ? removeUser : resendInvite}
                    className="block w-full text-left text-black last:text-error-1 text-sm font-semibold underline pt-1 last:pt-4 pb-4 last:pb-1 px-4"
                  >
                    {item.type === "resend" && resendInviteLoading ? (
                      <Loader />
                    ) : (
                      item.name
                    )}
                  </button>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export default MoreButton;
