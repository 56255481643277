import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../../../../../hooks/useUser";
import { setCurrentUserId } from "../../../../../config/reducers/adminSlice";
import { useNavigate, useParams } from "react-router-dom";

export default function useStageSix() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { getSiteVisitQuestionnaireDetails } = useUser();

  const [loading, setLoading] = useState(false);

  const fetchSiteVisitQuestionnaire = async () => {
    setLoading(true);
    const response = await getSiteVisitQuestionnaireDetails(id);
    if (response?.data) setLoading(false);
  };

  if (!id) navigate("/admin/due-diligence", { replace: true });

  useEffect(() => {
    if (id) {
      dispatch(
        setCurrentUserId({
          currentUserId: id,
        })
      );
      fetchSiteVisitQuestionnaire();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { siteVisitQuestionnaire } = useSelector((state) => state.user);
  const [stageSixStep, setStageSixStep] = useState(
    !siteVisitQuestionnaire?.status ||
      siteVisitQuestionnaire?.status === "pending"
      ? 2
      : 1
  );

  const handleStageSixStep = (value) => setStageSixStep(value);

  return { loading, stageSixStep, handleStageSixStep };
}
