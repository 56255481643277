import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUser from "../../../../../hooks/useUser";

export default function useStageSeven() {
  const [loading, setLoading] = useState(false);
  const [stageSevenStep, setStageSevenStep] = useState(1);
  const handleStageSeven = (value) => setStageSevenStep(value);

  const { getFinalizeDetails } = useUser();

  const user = useSelector((state) => state.auth.user);
  const { finalize } = useSelector((state) => state.user);

  const fetchFinalize = async () => {
    setLoading(true);
      await getFinalizeDetails(user?.superuser_id ?? user?._id);
    setLoading(false);
  };

  useEffect(() => {
    if (user?.superuser_id ?? user?._id) {
      fetchFinalize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.superuser_id, user?._id]);

  return { loading, stageSevenStep, handleStageSeven, finalize };
}
