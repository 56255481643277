import QuestionnaireStatus from "./status";
import Loader from "../../../../ui/loader";
import { BackButton } from "../../../../ui/goback";
import useStageFour from "./useStageFour";
import Questionnaire from "../../../General/Questionnaire";
import { useSelector } from "react-redux";
import { STAGE_FOUR_STATUS } from "../../../../../utils/constants";

export default function StageFour() {
  const { loading, stageFourStep, handleStageFourStep } = useStageFour();
  const { questionnaire } = useSelector((state) => state.user);

  return loading ? (
    <Loader />
  ) : (
    <div>
      {stageFourStep === 1 && (
        <>
          {questionnaire &&
            (questionnaire?.status === STAGE_FOUR_STATUS.ACCEPTED ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
              questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
              <div className="mb-4">
                <BackButton onClick={() => handleStageFourStep(2)} />
              </div>
            )}
          <Questionnaire handleStageFourStep={handleStageFourStep} />
        </>
      )}
      {stageFourStep === 2 && (
        <QuestionnaireStatus handleStageFourStep={handleStageFourStep} />
      )}
    </div>
  );
}
