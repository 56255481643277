import React from "react";
import { useSelector } from "react-redux";

import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import PdfDownload from "../../../../ui/pdfDownload";
import { Phone, SalesEmail } from "../../../../ui/contact";
import {
  getDownloadFileLink,
  getFileType,
} from "../../../../../utils/functions";

export default function Status({ handleStageSeven }) {
  const { finalize } = useSelector((state) => state.user);

  return finalize?.status === "approved" ? (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <img src={Icons.ic_success} alt="" />
      <div className="space-y-4 text-center flex flex-col items-center">
        <h2 className="text-h2 text-grey-800">You have been approved!</h2>
        {finalize?.report && (
          <>
            <p className="text-base max-w-[512px]">
              Proceed to download your Due Diligence Certificate
            </p>
            <PdfDownload
              type={getFileType(finalize?.report)}
              title="Due Diligence Certificate"
              pdfFile={getDownloadFileLink(finalize?.report)}
              download
            />
          </>
        )}

        <p className=" max-w-[512px]">
          For more inquiries, contact our sales team at{" "}
          <SalesEmail className="text-primary-1 underline" /> or call:{" "}
          <Phone className="text-primary-1" />
        </p>
        {finalize?.details?.length > 0 && (
          <Button
            onClick={() => handleStageSeven(2)}
            className="bg-transparent border border-primary-1 !text-primary-1 mx-auto !mt-10"
          >
            View Responses
          </Button>
        )}
      </div>
    </div>
  ) : finalize?.status === "feedback" ? (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <img src={Icons.ic_error} alt="" />
      <div className="space-y-4 text-center">
        <h2 className="text-h2 text-grey-800">Feedback on Your Submission</h2>
        <p className="text-base max-w-[512px]">
          We have reviewed your final submission and found some areas that
          require further information or clarification.
        </p>
        <p>
          Please review our comments and update the relevant sections
          accordingly
        </p>
        <Button
          className="bg-transparent border border-primary-1 !text-primary-1"
          onClick={() => handleStageSeven(2)}
        >
          view Comments
        </Button>
      </div>
    </div>
  ) : finalize?.status === "awaiting" ? (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <img src={Icons.ic_success} alt="" />
      <div className="space-y-4 text-center">
        <h2 className="text-h2 text-grey-800">Submission Successful</h2>
        <p className="text-base max-w-[512px]">
          Our Due Diligence team will review your submission and provide
          comments, feedback, or final approval within 24 to 72 hours. We
          appreciate your patience during this review period."
        </p>
        <Button onClick={() => handleStageSeven(2)}>View Responses</Button>
      </div>
    </div>
  ) : finalize?.status === "pending" ? (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <img src={Icons.ic_warning} alt="" />
      <div className="space-y-4 text-center">
        <h2 className="text-h2 text-grey-800">Additional Details Requested</h2>
        <p className="text-base max-w-[512px]">
          We have reviewed your final submission and would require further
          information or clarification.
        </p>
        <Button
          className="bg-transparent border border-primary-1 !text-primary-1"
          onClick={() => handleStageSeven(2)}
        >
          view request
        </Button>
      </div>
    </div>
  ) : (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <img src={Icons.ic_pending} alt="" />
      <div className="space-y-4 text-center">
        <h2 className="text-h2 text-grey-800">
          Your Application is Pending Final Approval
        </h2>
        <p className="text-base max-w-[512px]">
          For more inquiries, contact our sales team at{" "}
          <SalesEmail className="text-primary-1 underline" /> or call:{" "}
          <Phone className="text-primary-1" />
        </p>
      </div>
    </div>
  );
}
