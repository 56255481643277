import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

const PhoneNumberInput = (props) => {
  const {
    className,
    name,
    title,
    icon,
    errors,
    toggleHiddenPassword,
    reqError,
    disabled,
    required,
    info,
    ...rest
  } = props;

  return (
    <div className={className}>
      {title && (
        <label className="text-left text-grey-900 text-sm font-medium mb-1 block">
          {title} {required && "*"}
        </label>
      )}

      <div className="relative">
        <PhoneInput
          {...rest}
          className={`w-full appearance-none border has-[:focus-visible]:border has-[:focus-visible]:border-primary-1 text-sm rounded-md transition duration-150 ease-in-out
          ${errors || reqError ? "!border-error-200" : "border-grey-300"}
          ${name === "search" ? "pl-10 p-2" : "p-4"}
          ${disabled ? "bg-grey-10 text-grey-400" : "bg-white text-grey-900"}
          `}
          numberInputProps={{
            className: "max-w-[86%] focus-visible:outline-none",
          }}
          international
          defaultCountry="NG"
          flags={flags}
          disabled={disabled ? true : false}
        />

        {icon && (
          <div
            className={`absolute right-4 bottom-[19px] bg-white
              ${errors || reqError ? "!text-error-500" : "text-grey-500"}`}
          >
            {icon}
          </div>
        )}
      </div>
      {errors && (
        <p className="mt-2 ml-9 text-xs text-error-500 text-left">{errors}</p>
      )}
      {info && <p className="mt-2 text-xs">{info}</p>}
    </div>
  );
};

export default PhoneNumberInput;
