import { useSelector } from "react-redux";
import { Form, Formik, getIn } from "formik";
import { toast } from "react-toastify";
import { economicCommgasValidationSchema } from "../../../../../utils/validation";
import useQuestionnaire from "../useQuestionnaire";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import AdminComment from "../../../../ui/adminComment";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import { STAGE_FOUR_STATUS, USER_TYPE } from "../../../../../utils/constants";

export default function Economic({ handleStageFourTabClick }) {
  const { user } = useSelector((state) => state.auth);
  const { questionnaire } = useSelector((state) => state.user);

  const {
    disableForm,
    loading,
    canAdminComment,
    initialValues,
    handleSubmitCommgasEconomic,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useQuestionnaire(handleStageFourTabClick);

  return (
    <Formik
      initialValues={initialValues.economicCommgas}
      validationSchema={economicCommgasValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setErrors,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <div className="py-8 space-y-3">
              <h6 className="font-semibold text-black">
                Section 5: Economic Due Diligence
              </h6>
              <p className="text-sm max-w-[324px]">
                Please provide detailed Economic Information and Supporting
                Documents for the Proposed Project.
              </p>
            </div>
            <FormContainer width type="user" title="1. Business Plan">
              <AdminComment
                name="businessPlan"
                adminComment={
                  adminComments?.economicCommgas?.businessPlan || ""
                }
                isAdminCommentAvailable={
                  adminComments?.economicCommgas?.businessPlan ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="economicCommgas"
              >
                <FileUpload
                  name="businessPlan"
                  fileType=".pdf, .xls, .xlsx"
                  format="PDF or Excel format"
                  value={
                    questionnaire?.EconomicDueDiligence
                      ? values.businessPlan
                      : ""
                  }
                  onBlur={handleBlur}
                  onChange={(fieldName, file) => {
                    setFieldTouched(fieldName, true);
                    setFieldValue(fieldName, file);
                  }}
                  required
                  errors={
                    errors.businessPlan && touched.businessPlan
                      ? getIn(errors, `businessPlan`)
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.economicCommgas?.businessPlan &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>
            <FormContainer
              width
              type="user"
              title="2. Project Assumptions and Economics"
            >
              <>
                <SectionHeader title="Kindly specify the foundational assumptions for project evaluation.*" />
                <AdminComment
                  name="projectEconomics.assumptions"
                  adminComment={
                    adminComments?.economicCommgas?.projectEconomics
                      ?.assumptions || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.projectEconomics
                      ?.assumptions
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectEconomics.assumptions"
                    id="projectEconomics.assumptions"
                    value={values.projectEconomics.assumptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectEconomics.assumptions") &&
                          getIn(touched, "projectEconomics.assumptions")
                      )
                        ? getIn(errors, "projectEconomics.assumptions")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.projectEconomics
                        ?.assumptions &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Kindly Indicate the project lifespan. *"
                />
                <AdminComment
                  name="projectEconomics.duration"
                  adminComment={
                    adminComments?.economicCommgas?.projectEconomics
                      ?.duration || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.projectEconomics?.duration
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectEconomics.duration"
                    id="projectEconomics.duration"
                    value={values.projectEconomics.duration}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectEconomics.duration") &&
                          getIn(touched, "projectEconomics.duration")
                      )
                        ? getIn(errors, "projectEconomics.duration")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.projectEconomics
                        ?.duration &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Kindly provide values for the following economic indicators:"
                />
                <AdminComment
                  name="projectEconomics.indicators.npv"
                  adminComment={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.npv || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.npv
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                  marginTop
                >
                  <Textarea
                    title="NPV (Net Present Value)*"
                    placeholder="Enter description here"
                    name="projectEconomics.indicators.npv"
                    id="projectEconomics.indicators.npv"
                    value={values.projectEconomics.indicators.npv}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectEconomics.indicators.npv") &&
                          getIn(touched, "projectEconomics.indicators.npv")
                      )
                        ? getIn(errors, "projectEconomics.indicators.npv")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.projectEconomics
                        ?.indicators?.npv &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="projectEconomics.indicators.irr"
                  adminComment={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.irr || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.irr
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                  marginTop
                >
                  <Textarea
                    title="Project IRR (Internal Rate of Return)*"
                    placeholder="Enter description here"
                    name="projectEconomics.indicators.irr"
                    id="projectEconomics.indicators.irr"
                    value={values.projectEconomics.indicators.irr}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectEconomics.indicators.irr") &&
                          getIn(touched, "projectEconomics.indicators.irr")
                      )
                        ? getIn(errors, "projectEconomics.indicators.irr")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.projectEconomics
                        ?.indicators?.irr &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="projectEconomics.indicators.roe"
                  adminComment={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.roe || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.roe
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                  marginTop
                >
                  <Textarea
                    title="ROE (Return on Equity)*"
                    placeholder="Enter description here"
                    name="projectEconomics.indicators.roe"
                    id="projectEconomics.indicators.roe"
                    value={values.projectEconomics.indicators.roe}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectEconomics.indicators.roe") &&
                          getIn(touched, "projectEconomics.indicators.roe")
                      )
                        ? getIn(errors, "projectEconomics.indicators.roe")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.projectEconomics
                        ?.indicators?.roe &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="projectEconomics.indicators.roce"
                  adminComment={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.roce || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.roce
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                  marginTop
                >
                  <Textarea
                    title="ROCE (Return on Capital Employed)*"
                    placeholder="Enter description here"
                    name="projectEconomics.indicators.roce"
                    id="projectEconomics.indicators.roce"
                    value={values.projectEconomics.indicators.roce}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectEconomics.indicators.roce") &&
                          getIn(touched, "projectEconomics.indicators.roce")
                      )
                        ? getIn(errors, "projectEconomics.indicators.roce")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.projectEconomics
                        ?.indicators?.roce &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="projectEconomics.indicators.paybackPeriod"
                  adminComment={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.paybackPeriod || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.projectEconomics?.indicators
                      ?.paybackPeriod
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                  marginTop
                >
                  <Textarea
                    title="Payback Period*"
                    placeholder="Enter description here"
                    name="projectEconomics.indicators.paybackPeriod"
                    id="projectEconomics.indicators.paybackPeriod"
                    value={values.projectEconomics.indicators.paybackPeriod}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "projectEconomics.indicators.paybackPeriod"
                        ) &&
                          getIn(
                            touched,
                            "projectEconomics.indicators.paybackPeriod"
                          )
                      )
                        ? getIn(
                            errors,
                            "projectEconomics.indicators.paybackPeriod"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.projectEconomics
                        ?.indicators?.paybackPeriod &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>
            <FormContainer width type="user" title="3. Risk Assessment Study">
              <>
                <SectionHeader
                  title="Demand Risk:*"
                  description="Evaluate the stability and growth potential of demand for the project's output."
                />
                <AdminComment
                  name="riskAssessmentStudy.demandRisk"
                  adminComment={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.demandRisk || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.demandRisk
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="riskAssessmentStudy.demandRisk"
                    id="riskAssessmentStudy.demandRisk"
                    value={values.riskAssessmentStudy.demandRisk}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "riskAssessmentStudy.demandRisk") &&
                          getIn(touched, "riskAssessmentStudy.demandRisk")
                      )
                        ? getIn(errors, "riskAssessmentStudy.demandRisk")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.riskAssessmentStudy
                        ?.demandRisk &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Price Risks: Analyze the degree of control over pricing and its impact on the project. *" />
                <AdminComment
                  name="riskAssessmentStudy.priceRisks"
                  adminComment={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.priceRisks || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.priceRisks
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="riskAssessmentStudy.priceRisks"
                    id="riskAssessmentStudy.priceRisks"
                    value={values.riskAssessmentStudy.priceRisks}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "riskAssessmentStudy.priceRisks") &&
                          getIn(touched, "riskAssessmentStudy.priceRisks")
                      )
                        ? getIn(errors, "riskAssessmentStudy.priceRisks")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.riskAssessmentStudy
                        ?.priceRisks &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Country/Political Risk: Assess risks related to political stability and regulatory changes.*" />
                <AdminComment
                  name="riskAssessmentStudy.countryPoliticalRisk"
                  adminComment={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.countryPoliticalRisk || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.countryPoliticalRisk
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="riskAssessmentStudy.countryPoliticalRisk"
                    id="riskAssessmentStudy.countryPoliticalRisk"
                    value={values.riskAssessmentStudy.countryPoliticalRisk}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "riskAssessmentStudy.countryPoliticalRisk"
                        ) &&
                          getIn(
                            touched,
                            "riskAssessmentStudy.countryPoliticalRisk"
                          )
                      )
                        ? getIn(
                            errors,
                            "riskAssessmentStudy.countryPoliticalRisk"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.riskAssessmentStudy
                        ?.countryPoliticalRisk &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Market Risk: Identify and evaluate the risks associated with market demand and competition.*" />
                <AdminComment
                  name="riskAssessmentStudy.marketRisk"
                  adminComment={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.marketRisk || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.marketRisk
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="riskAssessmentStudy.marketRisk"
                    id="riskAssessmentStudy.marketRisk"
                    value={values.riskAssessmentStudy.marketRisk}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "riskAssessmentStudy.marketRisk") &&
                          getIn(touched, "riskAssessmentStudy.marketRisk")
                      )
                        ? getIn(errors, "riskAssessmentStudy.marketRisk")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.riskAssessmentStudy
                        ?.marketRisk &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Operational Risk: Discuss the operational challenges and risks the project might face.*" />
                <AdminComment
                  name="riskAssessmentStudy.operationalRisk"
                  adminComment={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.operationalRisk || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.operationalRisk
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="riskAssessmentStudy.operationalRisk"
                    id="riskAssessmentStudy.operationalRisk"
                    value={values.riskAssessmentStudy.operationalRisk}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "riskAssessmentStudy.operationalRisk") &&
                          getIn(touched, "riskAssessmentStudy.operationalRisk")
                      )
                        ? getIn(errors, "riskAssessmentStudy.operationalRisk")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.riskAssessmentStudy
                        ?.operationalRisk &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Construction/Completion Risk: Evaluate the risks related to project construction, timelines, and completion.*" />
                <AdminComment
                  name="riskAssessmentStudy.constructionCompletionRisk"
                  adminComment={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.constructionCompletionRisk || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicCommgas?.riskAssessmentStudy
                      ?.constructionCompletionRisk
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="riskAssessmentStudy.constructionCompletionRisk"
                    id="riskAssessmentStudy.constructionCompletionRisk"
                    value={
                      values.riskAssessmentStudy.constructionCompletionRisk
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "riskAssessmentStudy.constructionCompletionRisk"
                        ) &&
                          getIn(
                            touched,
                            "riskAssessmentStudy.constructionCompletionRisk"
                          )
                      )
                        ? getIn(
                            errors,
                            "riskAssessmentStudy.constructionCompletionRisk"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicCommgas?.riskAssessmentStudy
                        ?.constructionCompletionRisk &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            {(user?.role === USER_TYPE.USER ||
              user?.role === USER_TYPE.PROXY) &&
            (!questionnaire ||
              questionnaire?.status === STAGE_FOUR_STATUS.PENDING ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) ? (
              <div className="flex justify-between w-full pt-16">
                <button
                  onClick={() => handleStageFourTabClick(3)}
                  className="text-primary-1 uppercase text-sm font-bold leading-[16.8px]"
                >
                  Previous
                </button>
                <div className="flex justify-end gap-6 ">
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      setErrors({});
                      handleSubmitCommgasEconomic(
                        values,
                        "save",
                        setSubmitting
                      );
                      const res = validateForm();
                      setErrors({ ...res });
                    }}
                    loading={isSubmitting}
                    className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                  >
                    Save progress
                  </Button>
                  <Button
                    onClick={async () => {
                      setSubmitting(false);
                      const res = await validateForm();
                      setErrors({ ...res });

                      if (Object.keys(res).length !== 0)
                        toast.error("Please fill all the required fields");
                      else
                        handleSubmitCommgasEconomic(
                          values,
                          "next",
                          setSubmitting
                        );
                    }}
                    disabled={!isValid || loading}
                    className="py-4 px-6 self-end"
                    loading={loading}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end gap-6 w-full pt-16">
                <div className="flex justify-end gap-6">
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(3)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    {Icons.ic_arrow_left} Previous
                  </Button>
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(5)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    Next {Icons.ic_arrow_right}
                  </Button>
                </div>
                {(user?.role === USER_TYPE.ADMIN ||
                  user?.role === USER_TYPE.SUPER_ADMIN) &&
                  (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
                    questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
                    <Button
                      disabled={loadingAddComments || !isCommentNonNull}
                      onClick={() => {
                        handleAddAdminComment(
                          adminComments?.economicCommgas,
                          "adminUpdateEconomic"
                        );
                      }}
                      loading={loadingAddComments}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                    >
                      Save Comment
                    </Button>
                  )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
