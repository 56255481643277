import { useDispatch } from "react-redux";
import Button from "../../../../ui/button";
import { setCurrentStep } from "../../../../../config/reducers/userSlice";
import DueDiligenceStatus from "../../../General/Status";

const SiteVisitSuccess = () => {
  const dispatch = useDispatch();

  return (
    <DueDiligenceStatus
      type="success"
      title="Your site visit has been held"
      description="Congratulations, you’ve made it this far."
    >
      <Button
        className='w-[250px]'
        onClick={() => {
          dispatch(
            setCurrentStep({
              currentStep: 6,
            })
          );
        }}
      >
        Proceed to next stage
      </Button>
    </DueDiligenceStatus>
  );
};

export default SiteVisitSuccess;
