import React from "react";
import { BackButton } from "../../../../ui/goback";
import Loader from "../../../../ui/loader";
import Status from "./status";
import AdditionalDetails from "./additionalDetails";
import useStageSeven from "./useStageSeven";

export default function StageSeven() {
  const { loading, stageSevenStep, handleStageSeven, finalize } =
    useStageSeven();

  return loading ? (
    <Loader />
  ) : (
    <div>
      {stageSevenStep === 1 && <Status handleStageSeven={handleStageSeven} />}
      {stageSevenStep === 2 && (
        <>
          {finalize && (
            <div className="mb-4">
              <BackButton onClick={() => handleStageSeven(1)} />
            </div>
          )}
          <AdditionalDetails handleStageSeven={handleStageSeven} />
        </>
      )}
    </div>
  );
}
