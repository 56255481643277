import React from "react";

export default function Card({ title, description, badge, children }) {
  return (
    <div className="divide-y divide-grey-200 border border-grey-200 rounded-lg bg-white w-full p-6">
      <div className="space-y-4 pb-6">
        <div className="flex justify-between items-start">
          <h6 className="font-semibold text-black">{title}</h6>
          {badge && badge}
        </div>
        {description && <p className="text-sm text-grey-600">{description}</p>}
      </div>
      {children}
    </div>
  );
}
