import React, { Fragment, useEffect, useState } from "react";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import { FieldArray, Form, Formik, getIn } from "formik";
import { useSelector } from "react-redux";
import Button from "../../../../ui/button";
import useUser from "../../../../../hooks/useUser";
import Badge from "../../../../ui/badge";
import AdminComment from "../../../../ui/adminComment";
import Card from "../../../General/Card";
import { USER_TYPE } from "../../../../../utils/constants";
import { finalizeValidation } from "../../../../../utils/validation";

const SectionHeader = ({ title }) => (
  <p className="text-sm font-semibold text-black pt-8">{title}</p>
);

export default function AdditionalDetails({ handleStageSeven }) {
  const { finalize } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);

  const { updateAdditionalDetail } = useUser();

  const [disableForm, setDisableForm] = useState(false);

  useEffect(() => {
    if (
      (user?.role === USER_TYPE.USER || user?.role === USER_TYPE.PROXY) &&
      finalize?.status !== "feedback" &&
      finalize?.status !== "pending"
    )
      setDisableForm(true);
  }, [user?.role, finalize]);

  return (
    <div className="max-w-[560px]">
      <Card
        title="Additional details"
        description="The following details have been requested to proceed with finalizing your applications"
        badge={
          <Badge
            status={
              finalize?.status === "pending"
                ? "pending_details"
                : finalize?.status === "approved"
                ? "approved"
                : finalize?.status === "feedback"
                ? "feedback"
                : "pending_final_approval"
            }
          />
        }
      >
        <Formik
          initialValues={{ details: finalize?.details }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const response = await updateAdditionalDetail(
              values,
              user?.superuser_id ?? user?._id
            );
            if (response) {
              handleStageSeven(1);
            }
            setSubmitting(false);
          }}
          validationSchema={finalizeValidation}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isValid,
              dirty,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            } = props;

            return (
              <Form className="flex flex-col gap-6">
                <FieldArray
                  name="details"
                  render={(arrayHelpers) =>
                    values.details?.map((detail, index) => (
                      <Fragment key={index}>
                        <SectionHeader title={detail?.detailTitle} />
                        <AdminComment
                          tabName={detail?._id}
                          name={detail?.detailTitle}
                          adminComment={detail?.adminNote || ""}
                          isAdminCommentAvailable={
                            detail?.adminNote ? true : false
                          }
                          canAdminComment={
                            user?.role !== USER_TYPE.USER &&
                            user?.role !== USER_TYPE.PROXY
                              ? true
                              : false
                          }
                        >
                          {detail?.detailDocumentRequest ? (
                            <FileUpload
                              required
                              name={`details[${index}].userDocument`}
                              fileType=".pdf, .xls, .xlsx"
                              format="PDF or Excel format"
                              value={
                                detail?.userDocument ? detail?.userDocument : ""
                              }
                              onBlur={handleBlur}
                              onChange={(fieldName, file) => {
                                setFieldTouched(fieldName, true);
                                setFieldValue(fieldName, file);
                              }}
                              disabled={
                                disableForm ||
                                (!detail?.adminNote &&
                                  finalize?.status === "feedback")
                              }
                              errors={
                                Boolean(
                                  getIn(
                                    errors,
                                    `details[${index}].userDocument`
                                  ) &&
                                    getIn(
                                      touched,
                                      `details[${index}].userDocument`
                                    )
                                )
                                  ? getIn(
                                      errors,
                                      `details[${index}].userDocument`
                                    )
                                  : ""
                              }
                            />
                          ) : (
                            <Textarea
                              placeholder="Enter description here"
                              name={`details[${index}].userComment`}
                              id={`details[${index}].userComment`}
                              value={detail?.userComment}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errors={
                                Boolean(
                                  getIn(
                                    errors,
                                    `details[${index}].userComment`
                                  ) &&
                                    getIn(
                                      touched,
                                      `details[${index}].userComment`
                                    )
                                )
                                  ? getIn(
                                      errors,
                                      `details[${index}].userComment`
                                    )
                                  : ""
                              }
                              disabled={
                                disableForm ||
                                (!detail?.adminNote &&
                                  finalize?.status === "feedback")
                              }
                            />
                          )}
                        </AdminComment>
                      </Fragment>
                    ))
                  }
                />

                {!disableForm && (
                  <Button
                    disabled={
                      !isValid ||
                      (finalize?.status === "feedback" ? false : !dirty) ||
                      isSubmitting
                    }
                    type="submit"
                    fullWidth
                    className="mt-6"
                    loading={isSubmitting}
                  >
                    Submit details
                  </Button>
                )}
              </Form>
            );
          }}
        </Formik>
      </Card>
    </div>
  );
}
