export default function SectionHeader({ title, description, border }) {
  return (
    <>
      {title ? (
        <p
          className={`text-sm font-semibold text-black ${
            border ? "border-t border-grey-200 pt-8" : ""
          } `}
        >
          {title}
        </p>
      ) : (
        <div className="border-t border-grey-200" />
      )}
      {description && <p className="text-sm">{description}</p>}
    </>
  );
}
