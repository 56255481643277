import React, { useState } from "react";
import Dialog from "../../../../ui/dialog";
import Button from "../../../../ui/button";
import useUser from "../../../../../hooks/useUser";

export default function ConfirmPaymentDialog({
  toggleConfirmPaymentDialog,
  isConfirmPaymentDialogOpen,
  companyName,
  companyId,
}) {
  const { confirmPayment } = useUser();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isDialogModalOpen={isConfirmPaymentDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Confirm Payment Manually</h2>
          <p>
            Are you sure you want to manually confirm the payment for{" "}
            {companyName}?
          </p>
          <p>This action will move the user to the next stage</p>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleConfirmPaymentDialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              const response = await confirmPayment(companyId);
              if (response) toggleConfirmPaymentDialog(false);
              setLoading(false);
            }}
            disabled={loading}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
