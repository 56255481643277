import { useEffect, useState } from "react";
import { itemsPerPage } from "../../../utils/constants";

export default function useTeamHeader(
  searchText,
  fetchTeam,
  currentTeamPage,
  type,
  id
) {
  const [email, setEmail] = useState("");
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const [isInviteSentDialogOpen, setIsInviteSentDialogOpen] = useState(false);
  const toggleAddTeamModal = (value) => setIsAddTeamModalOpen(value);
  const toggleInviteSentDialog = (value) => setIsInviteSentDialogOpen(value);

  useEffect(() => {
    if (searchText && searchText.trim() !== "") {
      const debounceTimeout = setTimeout(() => {
        if (type === "user")
          fetchTeam(searchText, itemsPerPage, currentTeamPage, id);
        else fetchTeam(searchText, itemsPerPage, currentTeamPage);
      }, 500);

      return () => clearTimeout(debounceTimeout);
    } else {
      if (type === "user")
        fetchTeam(searchText, itemsPerPage, currentTeamPage, id);
      else fetchTeam(searchText, itemsPerPage, currentTeamPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentTeamPage]);

  return {
    email,
    setEmail,
    isAddTeamModalOpen,
    isInviteSentDialogOpen,
    toggleAddTeamModal,
    toggleInviteSentDialog,
  };
}
