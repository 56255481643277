import { object, string, array, number, ref } from "yup";
import { emailRegEx, fullNameRegEx } from "../constants";
import { isValidPhoneNumber } from "react-phone-number-input";

export const emailValidation = object().shape({
  email: string()
    .email("Invalid email format. Please enter a valid email address")
    .required("Please enter your email")
    .matches(
      emailRegEx,
      "Invalid email format. Please enter a valid email address"
    ),
});

export const passwordValidation = object().shape({
  password: string().required("Please enter your password"),
  confirmPassword: string()
    .required("Please confirm your password")
    .oneOf([ref("password"), null], "The passwords doesn’t match."),
});

export const signupValidation = object().shape({
  fullName: string()
    .required("Please enter your full name")
    .matches(fullNameRegEx, "Full name is incorrect"),
  email: string()
    .email("Invalid email format. Please enter a valid email address")
    .required("Please enter your email")
    .matches(
      emailRegEx,
      "Invalid email format. Please enter a valid email address"
    ),
  phoneNumber: string()
    .required("Please enter your phone number")
    .test("phoneNumber", "Invalid phone number", function (val) {
      return isValidPhoneNumber(val);
    }),
  company_name: string().required("Please enter your company name"),
});

export const loginValidation = object().shape({
  email: string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
  password: string().required("Please enter your password"),
});

export const personalInfoValidation = object().shape({
  fullName: string()
    .required("Please enter your full name")
    .matches(fullNameRegEx, "Full name is incorrect"),
  phoneNumber: string()
    .required("Please enter your phone number")
    .test("phoneNumber", "Invalid phone number", function (val) {
      return isValidPhoneNumber(val);
    }),
  designation: string("Please enter your role"),
});

export const comapnyInfoValidation = object().shape({
  company_name: string().required("Please enter your company name"),
  company_address: string().required("Please enter your company address"),
});

export const changePasswordValidation = object().shape({
  currentPassword: string().required("Please enter your current password"),
});

export const addTeamValidation = object().shape({
  fullName: string()
    .required("Please enter your full name")
    .matches(fullNameRegEx, "Full name is incorrect"),
  email: string()
    .email("Invalid email format. Please enter a valid email address")
    .required("Please enter your email")
    .matches(
      emailRegEx,
      "Invalid email format. Please enter a valid email address"
    ),
});

export const gasRequestFormValidation = object().shape({
  full_name: string()
    .required("Please enter your full name")
    .matches(fullNameRegEx, "Full name is incorrect"),
  phone_number: string()
    .required("Please enter your phone number")
    .test("phoneNumber", "Invalid phone number", function (val) {
      return isValidPhoneNumber(val);
    }),
  company_name: string().required("Please enter your company name"),
  company_address: string().required("Please enter your company address"),
  project_type: string().required("Please enter your project type"),
  daily_contract_volume: string().required("Please enter the volume"),
  first_gas_delivery_date: string().required(
    "Please enter the first gas delivery date"
  ),
});

export const requestValidationSchema = object().shape({
  company_name: string().required("Please enter the company name"),
  company_address: string().required("Please enter the company address"),
  natureOfBusiness: string().required("Please enter the field"),
  // endProducts: string().required("Please enter the field"),
  // franchiseOrDistributionArea: string().required("Please enter the field"),

  likelyDateOfFirstGasDelivery: string().required("Please enter the field"),
  // likelyDateOfFirstDeliveryOfNGLOrLPG: string().required(
  //   "Please enter the field"
  // ),
  proposedDurationOfContract: number()
    .typeError("Must be a number")
    .required("Please enter the field"),
  totalContractVolume: number()
    .typeError("Must be a number")
    .required("Please enter the field"),
  // totalContractVolumeForNGLOrLPG: number()
  //   .typeError("Must be a number")
  //   .required("Please enter the field"),

  // proposedPaymentGuarantee: string().required("Please enter the field"),
  proposedLocationOfProject: string().required("Please enter the field"),
  gasContractQuantityYear: number().min(1, "Please select the number of years"),
  gasContractQuantity: array()
    .required("Please select the number of years")
    .min(1, "Please complete the annual contract qty spreadsheet"),
  // nglContractQuantityYear: number().min(1, "Please select the number of years"),
  // nglContractQuantity: array()
  //   .required("Please select the number of years")
  //   .min(1, "Please complete the annual contract qty spreadsheet"),
  // lpgContractQuantityYear: number().min(1, "Please select the number of years"),
  // lpgContractQuantity: array()
  //   .required("Please select the number of years")
  //   .min(1, "Please complete the annual contract qty spreadsheet"),
});

export const reputationValidationSchema = object().shape(
  {
    companyName: string().required("Please enter the company name"),
    registeredOfficeAddress: string().required(
      "Please enter the registered office address"
    ),
    dateOfIncorporation: string().required(
      "Please enter the date of incorporation"
    ),
    companyRegistrationNumber: string().required(
      "Please enter the company registration number"
    ),
    equityDistribution: string().required(
      "Please enter the equity distribution"
    ),
    partnerContributions: string().required(
      "Please enter the partner contributions"
    ),
    companyExperienceSummary: string().required(
      "Please enter the company experience summary"
    ),
    auditedFinancialReportsYear1: string().required(
      "Upload the audited financial report file"
    ),
    auditedFinancialReportsYear2: string().required(
      "Upload the audited financial report file"
    ),
    auditedFinancialReportsYear3: string().required(
      "Upload the audited financial report file"
    ),
    cacCertificate: string().required("Upload the cac certificate file"),
    deedOfAdherenceDeclaration: string().required(
      "Upload the deed of adherence declaration file"
    ),

    ethicsRequirement: object().shape({
      politicallyExposedPerson: string().required(
        "Please enter the politically exposed person"
      ),
      politicallyExposedPersonDetails: string().when(
        ["politicallyExposedPerson"],
        {
          is: (politicallyExposedPerson) => politicallyExposedPerson === "Yes",
          then: () =>
            string().required(
              "Please enter the politically exposed person details"
            ),
        }
      ),

      criminalConviction: string().required(
        "Please enter the criminal conviction"
      ),
      criminalConvictionDetails: string().when(["criminalConviction"], {
        is: (criminalConviction) => criminalConviction === "Yes",
        then: () =>
          string().required("Please enter the criminal conviction details"),
      }),
    }),
    holdValidShipperLicense: string().required("Please select an option"),
    willingToCollaborateForShipperLicense: string().when(
      ["holdValidShipperLicense"],
      {
        is: (holdValidShipperLicense) => holdValidShipperLicense === "No",
        then: () => string().required("Please select an option"),
      }
    ),
    planForSecuringShipperLicense: string().when(
      ["willingToCollaborateForShipperLicense"],
      {
        is: (willingToCollaborateForShipperLicense) =>
          willingToCollaborateForShipperLicense === "No",
        then: () => string().required("Please enter your plan"),
      }
    ),

    willingToAppointGACNAsShipperAgent: string().required(
      "Please select an option"
    ),
    planForSecuringShipperAgent: string().when(
      ["willingToAppointGACNAsShipperAgent"],
      {
        is: (willingToAppointGACNAsShipperAgent) =>
          willingToAppointGACNAsShipperAgent === "No",
        then: () => string().required("Please enter your plan"),
      }
    ),
  },
  [
    ["politicallyExposedPerson", "politicallyExposedPersonDetails"],
    ["criminalConviction", "criminalConvictionDetails"],
    [
      "holdValidShipperLicense",
      "willingToCollaborateForShipperLicense",
      "planForSecuringShipperLicense",
    ],
    ["willingToAppointGACNAsShipperAgent", "planForSecuringShipperAgent"],
  ]
);

export const commercialPowerValidationSchema = object().shape(
  {
    projectDescription: string().required(
      "Please enter the project description"
    ),
    projectedProductStreams: string().required(
      "Please enter the projected product streams"
    ),
    plannedGenerationCapacity: string().required(
      "Please enter the planned generation capacity"
    ),
    feedstockRequirements: string().required(
      "Please enter the feedstock requirements"
    ),
    offTakePlanOverview: string().required(
      "Please enter the off-take plan overview"
    ),
    evidenceOfOffTakeAgreements: string().required(
      "Upload the evidence of off-take agreements file"
    ),
    otherSupportingDocument: array()
      .min(1, "Upload other supporting documents")
      .required("Upload other supporting documents")
      .of(string().trim().required("Upload supporting documents")),

    projectDeliveryTimescales: string().required(
      "Upload the project delivery timescales file"
    ),
    financingPlan: string().required("Please enter the financing plan"),
    commitToPaymentAssuranceFramework: string().required(
      "Please select an option"
    ),
    reasonsForNotCommitting: string().when(
      ["commitToPaymentAssuranceFramework"],
      {
        is: (commitToPaymentAssuranceFramework) =>
          commitToPaymentAssuranceFramework === "No",
        then: () => string().required("Please describe your reasons"),
      }
    ),
    // letterOfCreditworthiness: string().required(
    //   "Upload the acceptance of termination fee file"
    // ),
    // nbetPowerPurchaseAgreement: string().required(
    //   "Upload the NBET power purchase agreement"
    // ),
    statutoryPermits: object().shape({
      nercLicensing: string().required("Upload the NERC licensing file"),
      tcnEvacuationApplication: string().required(
        "Upload the tcn evacuation application file"
      ),
      environmentalPermits: string().required(
        "Upload the environmental permits file"
      ),
      fedMinOfHousingAndEnvStudy: string().required(
        "Upload the fed min of housing and env study file"
      ),
      taxRegistration: string().required("Upload the tax registration file"),
      gasUseLicense: string().required("Upload the gas use license file"),
      riskAssessmentSafetyHazop: string().required(
        "Upload the risk assessment safety hazop file"
      ),
      simulationStudies: string().required(
        "Upload the simulation studies file"
      ),
      pollutionControlEffluentTreatment: string().required(
        "Upload the pollution control effluent treatment file"
      ),
      constructionOperatingPermits: string().required(
        "Upload the construction operating permits file"
      ),
      localGovernmentApprovals: string().required(
        "Upload the local government approvals file"
      ),
    }),
  },
  ["commitToPaymentAssuranceFramework", "reasonsForNotCommitting"]
);

export const commercialCommgasValidationSchema = object().shape(
  {
    projectDescription: string().required(
      "Please enter the project description"
    ),
    projectedProductStreams: string().required(
      "Please enter the projected product streams"
    ),
    plannedGenerationCapacity: string().required(
      "Please enter the planned generation capacity"
    ),
    feedstockRequirements: string().required(
      "Please enter the feedstock requirements"
    ),
    offTakePlanOverview: string().required(
      "Please enter the off-take plan overview"
    ),
    evidenceOfOffTakeAgreements: string().required(
      "Upload the evidence of off-take agreements file"
    ),
    otherSupportingDocument: array()
      .min(1, "Upload other supporting documents")
      .required("Upload other supporting documents")
      .of(string().trim().required("Upload supporting documents")),

    projectDeliveryTimescales: string().required(
      "Upload the project delivery timescales file"
    ),
    financingPlan: string().required("Please enter the financing plan"),
    commitToPaymentAssuranceFramework: string().required(
      "Please select an option"
    ),
    reasonsForNotCommitting: string().when(
      ["commitToPaymentAssuranceFramework"],
      {
        is: (commitToPaymentAssuranceFramework) =>
          commitToPaymentAssuranceFramework === "No",
        then: () => string().required("Please describe your reasons"),
      }
    ),

    // acceptanceOfTerminationFee: string().required(
    //   "Upload the acceptance of termination fee file"
    // ),
    statutoryPermits: object().shape({
      incorporationCertificate: string().required(
        "Upload the incorporation certificate"
      ),
      nmdpraLicense: object().shape({
        nmdpraGasDistributionLicense: string().required(
          "Upload the NMPDRA gas distribution license"
        ),
        nmdpraGasProcessingLicense: string().required(
          "Upload the NMPDRA gas processing license"
        ),
        // nmdpraGasTransportationLicense: string().required(
        //   "Upload the NMPDRA gas transportation license"
        // ),
      }),
      nigerianContentCompliancePlan: string().required(
        "Upload the nigerian content compliance plan"
      ),
      environmentalPermit: string().required("Upload the environmental permit"),
    }),
  },
  ["commitToPaymentAssuranceFramework", "reasonsForNotCommitting"]
);

export const technicalPowerValidationSchema = object().shape({
  plantNominalInstalledCapacity: string().required(
    "Please enter the plant nominal installed capacity"
  ),
  locationAndLandAcquisitionStatus: string().required(
    "Please enter the location and land acquisition status"
  ),
  technologyDeployed: string().required("Please enter the technology deployed"),
  gasRequirement: object().shape({
    volumeRequirement: string().required("Please enter the volume requirement"),
    // grossHeatingValues: string().required(
    //   "Please enter the gross heating values"
    // ),
    deliveryPressures: string().required("Please enter the delivery pressures"),
    deliveryDate: string().required("Please enter the delivery date"),
    anticipatedDemandSwing: string().required(
      "Please enter the anticipated demand swing"
    ),
    plantAvailability: string().required("Please enter the plant availability"),
  }),
  electricityEvacuation: object().shape({
    mode: string().required("Please enter the mode"),
    offGridDetails: object().shape({
      // marketAssessmentStudy: string().required(
      //   "Please enter the market assessment study"
      // ),
      // supplyNicheDescription: string().required(
      //   "Please enter the supply niche description"
      // ),
    }),
    onGridDetails: object().shape({
      // evacuationArrangement: string().required(
      //   "Please enter the evacuation arrangement"
      // ),
      // tcnApprovedStudy: string().required(
      //   "Please enter the tcn approved study"
      // ),
    }),
  }),
  projectEngineeringStatus: string().required(
    "Please enter the project engineering status"
  ),
  statusOfEpcContracts: string().required(
    "Please enter the status of epc contracts"
  ),
  // evidenceOfTechnicalPartners: string().required(
  //   "Please enter the evidence of technical partners"
  // ),
  // nigerianContentCompliancePlan: string().required(
  //   "Please enter the nigerian content compliance plan"
  // ),
  operationsAndMaintenancePlan: string().required(
    "Please enter the operations"
  ),
  uptimePerformanceExpectation: string().required(
    "Please enter the uptime performance expectation"
  ),
  technicalMaintenanceAgreementsStatus: string().required(
    "Please enter the technical maintenance agreements status"
  ),
  staffingAndRecruitingPlans: string().required(
    "Please enter the staffing and recruiting plans"
  ),
  hsePlans: string().required("Please enter the hse plans"),
  existingAndExpansionFacilities: string().required(
    "Please enter the existing and expansion facilities"
  ),
  operatingManuals: string().required("Please enter the operating manuals"),
  sustainableDevelopmentAndCommunityEngagementPlans: string().required(
    "Please enter the sustainable development and community engagement plans"
  ),
  constructionSchedule: string().required("Please enter the plant"),
});

export const technicalCommgasValidationSchema = object().shape({
  proposedLocation: string().required("Please enter the propoased location"),
  plantCapacity: string().required("Please enter the plant capacity"),
  // landAcquisitionStatus: string().required(
  //   "Please enter the land acquisition status"
  // ),
  gasRequirement: object().shape({
    volumeRequirement: string().required("Please enter the volume requirement"),
    // grossHeatingValues: string().required(
    //   "Please enter the gross heating values"
    // ),
    deliveryPressures: string().required("Please enter the delivery pressures"),
    deliveryDate: string().required("Please enter the delivery date"),
  }),

  technologyStatement: string().required(
    "Please enter the technology statement"
  ),
  engineeringDesignStatus: string().required(
    "Please enter the engineering design status"
  ),
  engineeringDesignStatusDocument: string().required(
    "Please enter the engineering design status document"
  ),
  landAcquisition: string().required("Please enter the land acquisition"),
  landAcquisitionDocument: string().required(
    "Please enter the land acquisition document"
  ),

  statusOfEpcContracts: string().required(
    "Please enter the status of epc contracts"
  ),

  evidenceOfTechnicalPartners: string().required(
    "Please enter the status of evidence of technical partners"
  ),

  operationsAndMaintenancePlan: string().required(
    "Please enter the operations and maintenance plan"
  ),
});

export const economicPowerValidationSchema = object().shape({
  businessPlan: string().required("Upload the business plan file"),
  projectEconomicsAndFinancialRatios: string().required(
    "Please enter the project economics and financial ratios"
  ),
  financialRatios: string().required("Please enter the financial ratios"),
  guaranteesCreditworthiness: string().required(
    "Upload the guarantees/credit worthiness file"
  ),
  competitiveAdvantage: string().required(
    "Please enter the competitive advantage"
  ),
  riskAssessmentStudy: string().required(
    "Please enter the risk assessment study"
  ),
  marketViability: string().required("Please enter the market viability"),
  commitmentByInvestors: string().required(
    "Upload the commitment by investors file"
  ),
  evidenceOfFederalOrStateGovernmentSupport: string().required(
    "Upload the evidence of federal or state government support file"
  ),
  involvementOfNGO: string().required(
    "Please enter the plant involvement any of NGO"
  ),
});

export const economicCommgasValidationSchema = object().shape({
  businessPlan: string().required("Upload the business plan file"),

  projectEconomics: object().shape({
    assumptions: string().required("Please enter the assumptions"),
    duration: string().required("Please enter the duration"),
    indicators: object().shape({
      npv: string().required("Please enter the Net Present Value"),
      irr: string().required("Please enter the Project IRR"),
      roe: string().required("Please enter the Return on Equity"),
      roce: string().required("Please enter the return on capital employed"),
      paybackPeriod: string().required("Please enter the payback period"),
    }),
  }),
  riskAssessmentStudy: object().shape({
    demandRisk: string().required("Please enter the demand risk"),
    priceRisks: string().required("Please enter the price risks"),
    countryPoliticalRisk: string().required(
      "Please enter the political policy risks"
    ),
    marketRisk: string().required("Please enter the market risk"),
    operationalRisk: string().required("Please enter the operational risk"),
    constructionCompletionRisk: string().required(
      "Please enter the construction risk"
    ),
  }),
});

export const siteVisitPowerValidationSchema = object().shape(
  {
    coordinates: string().required("Please enter the coordinates"),
    layoutMap: string().required("Please enter the layout map"),
    plotPlan: string().required("Please enter the plot plan"),
    landSizeDoc: string().required("Please enter the land size"),
    proximityToGasSupplier: string().required("Please enter the field"),
    facilityLayout: string().required("Please enter the field"),
    roadAccess: string().required("Please enter the field"),
    evacuationPlan: object().shape({
      powerEvacuationPlan: string().required("Please enter the field"),
      alternativeEvacuationPlan: string().required("Please enter the field"),
      backupEvacuationStrategy: string().required("Please enter the field"),
    }),
    emergencyPlanning: object().shape({
      alternativeFuel: string().required("Please enter the field"),
      expansionPlan: string().required("Please enter the field"),
      expansionTimeline: string().when(["expansionPlan"], {
        is: (expansionPlan) => expansionPlan === "Yes",
        then: () => string().required("Please enter the field"),
      }),
      employmentOpportunities: string().required("Please enter the field"),
      powerDistribution: string().required("Please enter the field"),
      emergencyShutdownPlan: string().required("Please enter the field"),
      thirdPartyPower: string().required("Please enter the field"),
      thirdPartyPowerSpace: string().required("Please enter the field"),
    }),
    marketExperience: object().shape({
      meterLocation: string().required("Please enter the field"),
      unusedGridSales: string().required("Please enter the field"),
      marketTrackRecord: string().required("Please enter the field"),
      powerPurchaseAgreement: string().required("Please enter the field"),
      offtakeAgreement: string().required("Please enter the field"),
    }),
  },
  [["expansionPlan", "expansionTimeline"]]
);

export const siteVisitGasValidationSchema = object().shape({
  layoutMap: string().required("Please enter the field"),
  plotPlan: string().required("Please enter the field"),
  landSizeDoc: string().required("Please enter the field"),
  proximityToGasSupplier: string().required("Please enter the field"),
  projectPlan: object().shape({
    gasInletPoint: string().required("Please enter the field"),
    roadAccess: string().required("Please enter the field"),
    meterLocation: string().required("Please enter the field"),
    constructionTimeline: string().required("Please enter the field"),
    modeOfEvacuation: string().required("Please enter the field"),
    technologyType: string().required("Please enter the field"),
  }),
  safetyConsiderations: object().shape({
    alternativeFuel: string().required("Please enter the field"),
    howToSwitch: string().required("Please enter the field"),
    workCommenced: string().required("Please enter the field"),
  }),
  marketExperience: object().shape({
    ancillarySystem: string().required("Please enter the field"),
    expansionPlan: string().required("Please enter the field"),
    employmentOpportunities: string().required("Please enter the field"),
    productDistribution: string().required("Please enter the field"),
    marketTrackRecord: string().required("Please enter the field"),
  }),
});

export const siteVisitCommercialValidationSchema = object().shape(
  {
    layoutMap: string().required("Please enter the field"),
    landSizeDoc: string().required("Please enter the field"),
    proximityToGasSupplier: string().required("Please enter the field"),
    projectPlan: object().shape({
      gasInletPoint: string().required("Please enter the field"),
      gasGeneratorLocation: string().required("Please enter the field"),
      roadAccess: string().required("Please enter the field"),
      alternativeFuel: string().required("Please enter the field"),
      ancillarySystem: string().required("Please enter the field"),
      expansionPlan: string().required("Please enter the field"),
      meterLocation: string().required("Please enter the field"),
      constructionTimeline: string().required("Please enter the field"),
      modeOfEvacuation: string().required("Please enter the field"),
      productTransport: string().required("Please enter the field"),
    }),
    safetyConsiderations: object().shape({
      EIA: string().required("Please enter the field"),
      EIAReport: string().when(["EIA"], {
        is: (EIA) => EIA === "Yes",
        then: () => string().required("Please enter the field"),
      }),

      emergencyShutdownPlan: string().required("Please enter the field"),
      workCommenced: string().required("Please enter the field"),
    }),
    marketExperience: object().shape({
      ancillarySysmarketTrackRecordtem: string().required(
        "Please enter the field"
      ),
      workCommenced: string().required("Please enter the field"),
      employmentOpportunities: string().required("Please enter the field"),
      offtakeAgreement: string().required("Please enter the field"),
      gasSupplyCapcity: string().required("Please enter the field"),
    }),
  },
  [["EIA", "EIAReport"]]
);

export const finalizeValidation = object().shape({
  details: array().of(
    object().shape(
      {
        userComment: string().when(["detailDocumentRequest"], {
          is: (detailDocumentRequest) => detailDocumentRequest === false,
          then: () => string().required("Please enter the field"),
          otherwise: () => string().nullable(),
        }),

        userDocument: string().when(["detailDocumentRequest"], {
          is: (detailDocumentRequest) => detailDocumentRequest === true,
          then: () => string().required("Please enter the field"),
          otherwise: () => string().nullable(),
        }),
      },
      ["detailDocumentRequest", "userComment", "userDocument"]
    )
  ),
});
