import React from "react";
import { STATUS } from "../../utils/constants";

export default function Badge({ status }) {
  return (
    <div
      className={`rounded-xl py-0.5 px-3 text-sm font-medium capitalize
    ${
      status === "pending" ||
      status === "awaitng_review" ||
      status === "review" ||
      status === "awaiting_acceptance" ||
      status === "awaiting_visit" ||
      status === "update_required" ||
      status === "pending_final_approval"
        ? "bg-warning-50 text-warning-700"
        : status === "accepted" ||
          status === "approved" ||
          status === "paid" ||
          status === "meeting_held"
        ? "bg-primary-50 text-primary-700"
        : status === "rejected" || status === "feedback"
        ? "bg-error-50 text-error-700"
        : "bg-grey-10 text-grey-700"
    }`}
    >
      {status === "pending_final_approval"
        ? "Pending Final Approval"
        : status === "pending_details"
        ? "Pending Details Submission"
        : status === "pending_review" || status === "not_paid"
        ? "Pending"
        : status === "pending"
        ? "In progress"
        : status === "approved"
        ? "Approved"
        : status === "accepted"
        ? "Completed"
        : status === "feedback"
        ? "Feedback required"
        : status === "rejected"
        ? "rejected"
        : status === "paid"
        ? "Paid"
        : status === "update_required"
        ? "Update Required"
        : status === "awaiting" // change to awaiting_meeting
        ? "Awaiting Meeting"
        : status === "awaitng_review"
        ? "Awaiting Review"
        : status === STATUS.NDA_REVIEW
        ? "Under Review"
        : status === "awaiting_acceptance"
        ? "Awaiting Acceptance"
        : status === "awaiting_visit"
        ? "Awaiting Site Visit"
        : status === "meeting_held"
        ? "Meeting held"
        : "Not Started"}
    </div>
  );
}
