import { tableHeadItems } from "../../../utils/constants";


const TableHead = () => (
  <thead>
    <tr>
      {tableHeadItems.map((item) => (
        <th
          key={item}
          scope="col"
          className="px-6 py-[13.5px] bg-grey-50 text-start text-xs font-medium text-grey-700 capitalize"
        >
          {item}
        </th>
      ))}
    </tr>
  </thead>
);

export default TableHead;
