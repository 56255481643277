import React from "react";
import useQuestionnaire from "./useQuestionnaire";
import { useSelector } from "react-redux";
import Loader from "../../../../ui/loader";
import QuestionnaireStatus from "./status";
import Questionnaire from "../../../General/Questionnaire";

export default function StageFour() {
  const { loading } = useQuestionnaire();
  const { questionnaire } = useSelector((state) => state.user);

  return loading ? (
    <Loader />
  ) : questionnaire && questionnaire?.status !== "pending" ? (
    <Questionnaire />
  ) : (
    <QuestionnaireStatus />
  );
}
