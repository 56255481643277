import Modal from "../../../../ui/modal";
import gasSpecification from "../../../../../assets/images/gas_specification.png";

function GasSpecificationModal({
  toggleGasSpecificationModal,
  isGasSpecificationModalOpen,
}) {
  return (
    <Modal
      isModalOpen={isGasSpecificationModalOpen}
      onClick={() => toggleGasSpecificationModal(false)}
      width="max-w-[667px]"
    >
      <div className="bg-white rounded-lg w-full max-w-[667px] p-6 space-y-8 ">
        <div className="space-y-4 text-center">
          <h2 className="text-h2 text-black">NATIONAL GAS SPECIFICATION</h2>
          <p className="text-sm">
            Please note that the NGMP envisages a national pipeline gas spec.
            The proposed Spec. is as detailed below. Prospective buyers should
            expect gas within this ratio. Should you require another spec,
            Please complete ‘The Buyer’s Specific Gas Spec. Form’
          </p>
          <img src={gasSpecification} alt="national gas specification" />
        </div>
      </div>
    </Modal>
  );
}

export default GasSpecificationModal;
