import React from "react";

import AuthLayout from "../../../components/Layout/authLayout";
import ResetPasswordForm from "../../../components/AuthForm/resetPassword";

const AdminResetPasswordPage = () => {
  return (
    <AuthLayout
      page="resetPassword"
      title="Create new password"
      description="Create a new password to your account"
    >
      <ResetPasswordForm type="admin" />
    </AuthLayout>
  );
};

export default AdminResetPasswordPage;
