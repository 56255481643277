import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUser from "../../../../../hooks/useUser";

export default function useSiteVisit() {
  const [loading, setLoading] = useState(false);
  const [stageFourStep, setStageFourStep] = useState(1);
  const handleStageFourStep = (value) => setStageFourStep(value);

  const { getSiteVisitDetails } = useUser();

  const user = useSelector((state) => state.auth.user);

  const fetchSiteVisit = async () => {
    setLoading(true);
    // ====== for admin pass in the userRequest Id ====== //
    const response = await getSiteVisitDetails(user?.superuser_id ?? user?._id);
    if (response?.data) {
      if (response?.data?.status === "meeting_held") setStageFourStep(3);
      else if (!response?.data?.status) setStageFourStep(1);
      else setStageFourStep(2);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (user?.superuser_id ?? user?._id) {
      fetchSiteVisit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.superuser_id, user?._id]);
  return { loading, stageFourStep, handleStageFourStep };
}
