import { Form, Formik, getIn } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { reputationValidationSchema } from "../../../../../utils/validation";
import useQuestionnaire from "../useQuestionnaire";
import Icons from "../../../../../assets/icons";
import Input from "../../../../ui/input";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import Radio from "../../../../ui/radio";
import AdminComment from "../../../../ui/adminComment";
import FormContainer from "../../FormContainer";
import PdfDownload from "../../../../ui/pdfDownload";
import { addDays } from "date-fns";
import DateInput from "../../../../ui/datePicker";
import { STAGE_FOUR_STATUS, USER_TYPE } from "../../../../../utils/constants";

const SectionHeader = ({ title }) => (
  <p className="text-sm font-semibold text-black border-t border-grey-200 pt-8">
    {title}
  </p>
);

export default function Reputation({ handleStageFourTabClick }) {
  const { user } = useSelector((state) => state.auth);
  const { questionnaire } = useSelector((state) => state.user);

  const {
    disableForm,
    loading,
    canAdminComment,
    initialValues,
    adminComments,
    setAdminComments,
    handleSubmitReputation,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useQuestionnaire(handleStageFourTabClick);

  return (
    <Formik
      initialValues={initialValues.reputation}
      validationSchema={reputationValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setErrors,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <div className="py-8 space-y-3">
              <h6 className="font-semibold text-black">
                Section 2: Reputation Due Diligence
              </h6>
              <p className="text-sm max-w-[324px]">
                Provide the Corporate Information and Documentation for the
                Project Company
              </p>
            </div>
            <FormContainer width type="user" title="1. Project Company Details">
              <>
                <Input
                  required
                  title="COMPANY NAME"
                  icon={Icons.ic_company}
                  placeholder="Your job title"
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.companyName && touched.companyName
                      ? errors.companyName
                      : ""
                  }
                  disabled
                />

                <AdminComment
                  name="registeredOfficeAddress"
                  adminComment={
                    adminComments?.reputation?.registeredOfficeAddress || ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.registeredOfficeAddress
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  marginTop={true}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                >
                  <Input
                    required
                    title="REGISTERED OFFICE"
                    icon={Icons.ic_location}
                    placeholder="Enter company address"
                    type="text"
                    name="registeredOfficeAddress"
                    id="registeredOfficeAddress"
                    value={values.registeredOfficeAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.registeredOfficeAddress &&
                      touched.registeredOfficeAddress
                        ? errors.registeredOfficeAddress
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.registeredOfficeAddress &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader title="Incorporation Status" />
                <AdminComment
                  name="dateOfIncorporation"
                  adminComment={
                    adminComments?.reputation?.dateOfIncorporation || ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.dateOfIncorporation
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                  marginTop={true}
                >
                  <DateInput
                    required
                    title="DATE OF INCORPORATION"
                    icon={Icons.ic_calendar}
                    name="dateOfIncorporation"
                    id="dateOfIncorporation"
                    selected={values.dateOfIncorporation}
                    maxDate={addDays(new Date(), 0)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                      setFieldTouched("dateOfIncorporation", true);
                      setFieldValue("dateOfIncorporation", date);
                    }}
                    onBlur={handleBlur}
                    errors={
                      errors.dateOfIncorporation && touched.dateOfIncorporation
                        ? errors.dateOfIncorporation
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.dateOfIncorporation &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="companyRegistrationNumber"
                  adminComment={
                    adminComments?.reputation?.companyRegistrationNumber || ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.companyRegistrationNumber
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                  marginTop={true}
                >
                  <Input
                    required
                    title="REGISTRATION NUMBER"
                    icon={Icons.ic_file}
                    placeholder="Enter your registration number"
                    type="text"
                    name="companyRegistrationNumber"
                    id="companyRegistrationNumber"
                    value={values.companyRegistrationNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.companyRegistrationNumber &&
                      touched.companyRegistrationNumber
                        ? errors.companyRegistrationNumber
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.companyRegistrationNumber &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="cacCertificate"
                  adminComment={adminComments?.reputation?.cacCertificate || ""}
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.cacCertificate ? true : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                >
                  <FileUpload
                    required
                    name="cacCertificate"
                    title="Upload Company CAC Certificate"
                    fileType=".pdf"
                    format="PDF format"
                    value={
                      questionnaire?.ReputationDueDiligence
                        ? values.cacCertificate
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.cacCertificate &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                    errors={
                      errors.cacCertificate && touched.cacCertificate
                        ? errors.cacCertificate
                        : ""
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader title="Shareholders" />

                <AdminComment
                  name="equityDistribution"
                  adminComment={
                    adminComments?.reputation?.equityDistribution || ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.equityDistribution ? true : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                  marginTop={true}
                >
                  <Textarea
                    title="EQUITY DISTRIBUTION"
                    placeholder="Enter company shareholders"
                    name="equityDistribution"
                    id="equityDistribution"
                    value={values.equityDistribution}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.equityDistribution && touched.equityDistribution
                        ? errors.equityDistribution
                        : ""
                    }
                    info="Outline the equity split among shareholders and any notable conditions."
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.equityDistribution &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="partnerContributions"
                  adminComment={
                    adminComments?.reputation?.partnerContributions || ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.partnerContributions
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                  marginTop={true}
                >
                  <Textarea
                    title="PARTNER DISTRIBUTION"
                    placeholder="Enter company shareholders"
                    name="partnerContributions"
                    id="partnerContributions"
                    value={values.partnerContributions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.partnerContributions &&
                      touched.partnerContributions
                        ? errors.partnerContributions
                        : ""
                    }
                    info="Describe each partner's strategic role in development, operations, marketing, or finance."
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.partnerContributions &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader title="Provide a summary of your company's or sponsors' experience in delivering similar projects, including key outcomes and metrics that highlight your success*" />
                <AdminComment
                  name="companyExperienceSummary"
                  adminComment={
                    adminComments?.reputation?.companyExperienceSummary || ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.companyExperienceSummary
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                  marginTop={true}
                >
                  <Textarea
                    placeholder="Enter summary here"
                    name="companyExperienceSummary"
                    id="companyExperienceSummary"
                    value={values.companyExperienceSummary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.companyExperienceSummary &&
                      touched.companyExperienceSummary
                        ? errors.companyExperienceSummary
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.companyExperienceSummary &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader title="Audited Financial Reports for the last 3 years of the company's shareholders" />
                <AdminComment
                  name="auditedFinancialReportsYear1"
                  adminComment={
                    adminComments?.reputation?.auditedFinancialReportsYear1 ||
                    ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.auditedFinancialReportsYear1
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                >
                  <FileUpload
                    title="Year 1"
                    name="auditedFinancialReportsYear1"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.ReputationDueDiligence
                        ? values.auditedFinancialReportsYear1
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.auditedFinancialReportsYear1 &&
                      touched.auditedFinancialReportsYear1
                        ? getIn(errors, `auditedFinancialReportsYear1`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation
                        ?.auditedFinancialReportsYear1 &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
                <AdminComment
                  name="auditedFinancialReportsYear2"
                  adminComment={
                    adminComments?.reputation?.auditedFinancialReportsYear2 ||
                    ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.auditedFinancialReportsYear2
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                >
                  <FileUpload
                    title="Year 2"
                    name="auditedFinancialReportsYear2"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.ReputationDueDiligence
                        ? values.auditedFinancialReportsYear2
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.auditedFinancialReportsYear2 &&
                      touched.auditedFinancialReportsYear2
                        ? getIn(errors, `auditedFinancialReportsYear2`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation
                        ?.auditedFinancialReportsYear2 &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
                <AdminComment
                  name="auditedFinancialReportsYear3"
                  adminComment={
                    adminComments?.reputation?.auditedFinancialReportsYear3 ||
                    ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.auditedFinancialReportsYear3
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                >
                  <FileUpload
                    title="Year 3"
                    name="auditedFinancialReportsYear3"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.ReputationDueDiligence
                        ? values.auditedFinancialReportsYear3
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.auditedFinancialReportsYear3 &&
                      touched.auditedFinancialReportsYear3
                        ? getIn(errors, `auditedFinancialReportsYear3`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation
                        ?.auditedFinancialReportsYear3 &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="2. Provide a declaration to fulfil the Deed of Adherence to the Global Service Agreement with GACN"
            >
              <AdminComment
                name="deedOfAdherenceDeclaration"
                adminComment={
                  adminComments?.reputation?.deedOfAdherenceDeclaration || ""
                }
                allComments={adminComments}
                isAdminCommentAvailable={
                  adminComments?.reputation?.deedOfAdherenceDeclaration
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="reputation"
              >
                <PdfDownload
                  type="doc"
                  fullWidth
                  title="Deed of Adherence Template"
                  darkBg
                  pdfFile="https://res.cloudinary.com/duizbh2mk/raw/upload/fl_attachment/v1715583166/Deed_of_Adherence_Template_-_March_2023_joebje.dotx"
                  download
                />
                <>
                  <SectionHeader
                    border
                    title="Kindly upload the completed deed of adherence Document"
                  />

                  <FileUpload
                    name="deedOfAdherenceDeclaration"
                    fileType=".pdf, .doc, .docx, .dotx"
                    format="PDF or Doc format"
                    value={
                      questionnaire?.ReputationDueDiligence
                        ? values.deedOfAdherenceDeclaration
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.deedOfAdherenceDeclaration &&
                      touched.deedOfAdherenceDeclaration
                        ? getIn(errors, `deedOfAdherenceDeclaration`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.deedOfAdherenceDeclaration &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </>
              </AdminComment>
            </FormContainer>

            <FormContainer width type="user" title="3. Ethics Requirement">
              <Radio
                options={[
                  { title: "Yes", value: "Yes" },
                  { title: "No", value: "No" },
                ]}
                value={values.ethicsRequirement.politicallyExposedPerson}
                onChange={handleChange}
                name="ethicsRequirement.politicallyExposedPerson"
                title="Is any of the Company’s Directors or Shareholders a politically exposed person or has held a political or governmental position in the last 10 years?"
                errors={
                  Boolean(
                    getIn(
                      errors,
                      "ethicsRequirement.politicallyExposedPerson"
                    ) &&
                      getIn(
                        touched,
                        "ethicsRequirement.politicallyExposedPerson"
                      )
                  )
                    ? getIn(
                        errors,
                        "ethicsRequirement.politicallyExposedPerson"
                      )
                    : ""
                }
                disabled={
                  disableForm ||
                  (!adminComments?.reputation?.ethicsRequirement
                    ?.politicallyExposedPerson &&
                    questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                }
              />
              <AdminComment
                name="ethicsRequirement.politicallyExposedPersonDetails"
                adminComment={
                  adminComments?.reputation?.ethicsRequirement
                    ?.politicallyExposedPersonDetails || ""
                }
                allComments={adminComments}
                isAdminCommentAvailable={
                  adminComments?.reputation?.ethicsRequirement
                    ?.politicallyExposedPersonDetails
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="reputation"
              >
                {values.ethicsRequirement?.politicallyExposedPerson ===
                  "Yes" && (
                  <Textarea
                    placeholder="Share more details"
                    name="ethicsRequirement.politicallyExposedPersonDetails"
                    id="ethicsRequirement.politicallyExposedPersonDetails"
                    value={
                      values.ethicsRequirement.politicallyExposedPersonDetails
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "ethicsRequirement.politicallyExposedPersonDetails"
                        ) &&
                          getIn(
                            touched,
                            "ethicsRequirement.politicallyExposedPersonDetails"
                          )
                      )
                        ? getIn(
                            errors,
                            "ethicsRequirement.politicallyExposedPersonDetails"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.ethicsRequirement
                        ?.politicallyExposedPersonDetails &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                )}
              </AdminComment>

              <Radio
                options={[
                  { title: "Yes", value: "Yes" },
                  { title: "No", value: "No" },
                ]}
                value={values.ethicsRequirement.criminalConviction}
                onChange={handleChange}
                name="ethicsRequirement.criminalConviction"
                title="Does any of the Directors have a criminal conviction or record, an allegation of financial crime from EFCC or has been tried or indicted?"
                errors={
                  Boolean(
                    getIn(errors, "ethicsRequirement.criminalConviction") &&
                      getIn(touched, "ethicsRequirement.criminalConviction")
                  )
                    ? getIn(errors, "ethicsRequirement.criminalConviction")
                    : ""
                }
                disabled={
                  disableForm ||
                  (!adminComments?.reputation?.ethicsRequirement
                    ?.criminalConviction &&
                    questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                }
              />
              {values.ethicsRequirement?.criminalConviction === "Yes" && (
                <AdminComment
                  name="ethicsRequirement.criminalConvictionDetails"
                  adminComment={
                    adminComments?.reputation?.ethicsRequirement
                      ?.criminalConvictionDetails || ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.ethicsRequirement
                      ?.criminalConvictionDetails
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                >
                  <Textarea
                    placeholder="Share more details"
                    name="ethicsRequirement.criminalConvictionDetails"
                    id="ethicsRequirement.criminalConvictionDetails"
                    value={values.ethicsRequirement.criminalConvictionDetails}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "ethicsRequirement.criminalConvictionDetails"
                        ) &&
                          getIn(
                            touched,
                            "ethicsRequirement.criminalConvictionDetails"
                          )
                      )
                        ? getIn(
                            errors,
                            "ethicsRequirement.criminalConvictionDetails"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.ethicsRequirement
                        ?.criminalConvictionDetails &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              )}
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="4. Additional Requirement for Gas Transportation (License as Shipper under Nigerian Gas Transportation Network Code – to the extent applicable)"
            >
              {/* =====NEW UPDATE===== */}
              <>
                <AdminComment
                  name="holdValidShipperLicense"
                  adminComment={
                    adminComments?.reputation?.holdValidShipperLicense || ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.holdValidShipperLicense
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.holdValidShipperLicense}
                    onChange={handleChange}
                    name="holdValidShipperLicense"
                    title="Do you hold a valid Shipper License on the NGTC (Nigerian Gas Transportation Network Code)"
                    errors={
                      Boolean(
                        getIn(errors, "holdValidShipperLicense") &&
                          getIn(touched, "holdValidShipperLicense")
                      )
                        ? getIn(errors, "holdValidShipperLicense")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation?.holdValidShipperLicense &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                {values.holdValidShipperLicense === "No" && (
                  <AdminComment
                    name="willingToCollaborateForShipperLicense"
                    adminComment={
                      adminComments?.reputation
                        ?.willingToCollaborateForShipperLicense || ""
                    }
                    allComments={adminComments}
                    isAdminCommentAvailable={
                      adminComments?.reputation
                        ?.willingToCollaborateForShipperLicense
                        ? true
                        : false
                    }
                    canAdminComment={canAdminComment}
                    setAdminComments={setAdminComments}
                    setIsCommentNonNull={setIsCommentNonNull}
                    type="reputation"
                  >
                    <Radio
                      options={[
                        { title: "Yes", value: "Yes" },
                        { title: "No", value: "No" },
                      ]}
                      value={values.willingToCollaborateForShipperLicense}
                      onChange={handleChange}
                      name="willingToCollaborateForShipperLicense"
                      title="Are you willing to collaborate with GACN to secure your Shipper’s License, subject to GACN terms and conditions?"
                      errors={
                        Boolean(
                          getIn(
                            errors,
                            "willingToCollaborateForShipperLicense"
                          ) &&
                            getIn(
                              touched,
                              "willingToCollaborateForShipperLicense"
                            )
                        )
                          ? getIn(
                              errors,
                              "willingToCollaborateForShipperLicense"
                            )
                          : ""
                      }
                      disabled={
                        disableForm ||
                        (!adminComments?.reputation
                          ?.willingToCollaborateForShipperLicense &&
                          questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                      }
                    />
                  </AdminComment>
                )}
                {values.willingToCollaborateForShipperLicense === "No" && (
                  <AdminComment
                    name="planForSecuringShipperLicense"
                    adminComment={
                      adminComments?.reputation
                        ?.planForSecuringShipperLicense || ""
                    }
                    allComments={adminComments}
                    isAdminCommentAvailable={
                      adminComments?.reputation?.planForSecuringShipperLicense
                        ? true
                        : false
                    }
                    canAdminComment={canAdminComment}
                    setAdminComments={setAdminComments}
                    setIsCommentNonNull={setIsCommentNonNull}
                    type="reputation"
                  >
                    <Textarea
                      title="Describe your plan to securing a Shippers License."
                      placeholder="Describe your plan to securing a Shippers License"
                      name="planForSecuringShipperLicense"
                      id="planForSecuringShipperLicense"
                      value={values.planForSecuringShipperLicense}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={
                        Boolean(
                          getIn(errors, "planForSecuringShipperLicense") &&
                            getIn(touched, "planForSecuringShipperLicense")
                        )
                          ? getIn(errors, "planForSecuringShipperLicense")
                          : ""
                      }
                      disabled={
                        disableForm ||
                        (!adminComments?.reputation
                          ?.planForSecuringShipperLicense &&
                          questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                      }
                    />
                  </AdminComment>
                )}
              </>

              <AdminComment
                name="willingToAppointGACNAsShipperAgent"
                adminComment={
                  adminComments?.reputation
                    ?.willingToAppointGACNAsShipperAgent || ""
                }
                allComments={adminComments}
                isAdminCommentAvailable={
                  adminComments?.reputation?.willingToAppointGACNAsShipperAgent
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="reputation"
              >
                <Radio
                  options={[
                    { title: "Yes", value: "Yes" },
                    { title: "No", value: "No" },
                  ]}
                  value={values.willingToAppointGACNAsShipperAgent}
                  onChange={handleChange}
                  name="willingToAppointGACNAsShipperAgent"
                  title="Are you willing to appoint GACN as your shipper agent for the NGTNC?"
                  disabled={
                    disableForm ||
                    (!adminComments?.reputation
                      ?.willingToAppointGACNAsShipperAgent &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
              {values.willingToAppointGACNAsShipperAgent === "No" && (
                <AdminComment
                  name="planForSecuringShipperAgent"
                  adminComment={
                    adminComments?.reputation?.planForSecuringShipperAgent || ""
                  }
                  allComments={adminComments}
                  isAdminCommentAvailable={
                    adminComments?.reputation?.planForSecuringShipperAgent
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="reputation"
                >
                  <Textarea
                    title="Describe your plan to securing a Shipper Agent"
                    placeholder="Describe your plan to securing a Shippers License"
                    name="planForSecuringShipperAgent"
                    id="planForSecuringShipperAgent"
                    value={values.planForSecuringShipperAgent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "planForSecuringShipperAgent") &&
                          getIn(touched, "planForSecuringShipperAgent")
                      )
                        ? getIn(errors, "planForSecuringShipperAgent")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.reputation
                        ?.planForSecuringShipperAgent &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              )}

              {/* <AdminComment
                name="additionalGasTransportationRequirement.executeGacnShipperAgencyAndAllocationAgreement"
                adminComment={
                  adminComments?.reputation
                    ?.additionalGasTransportationRequirement
                    ?.executeGacnShipperAgencyAndAllocationAgreement || ""
                }
                allComments={adminComments}
                isAdminCommentAvailable={
                  adminComments?.reputation
                    ?.additionalGasTransportationRequirement
                    ?.executeGacnShipperAgencyAndAllocationAgreement
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="reputation"
              >
                <Radio
                  options={[
                    { title: "Yes", value: "Yes" },
                    { title: "No", value: "No" },
                  ]}
                  value={
                    values.additionalGasTransportationRequirement
                      .executeGacnShipperAgencyAndAllocationAgreement
                  }
                  onChange={handleChange}
                  name="additionalGasTransportationRequirement.executeGacnShipperAgencyAndAllocationAgreement"
                  title="Upon acceptance of GACN as Shipper Agent and notification to Nigerian Gas Infrastructure Company (NGIC), execute GACN Shipper Agency and Allocation Agreement."
                  disabled={
                    disableForm ||
                    (!adminComments?.reputation
                      ?.additionalGasTransportationRequirement
                      ?.executeGacnShipperAgencyAndAllocationAgreement &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment> */}
            </FormContainer>

            {(user?.role === USER_TYPE.USER ||
              user?.role === USER_TYPE.PROXY) &&
            (!questionnaire ||
              questionnaire?.status === STAGE_FOUR_STATUS.PENDING ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) ? (
              <div className="flex justify-between w-full pt-16">
                <button
                  onClick={() => handleStageFourTabClick(0)}
                  className="text-primary-1 uppercase text-sm font-bold leading-[16.8px]"
                >
                  Previous
                </button>
                <div className="flex justify-end gap-6 ">
                  <Button
                    disabled={isSubmitting || loading}
                    onClick={() => {
                      setErrors({});
                      handleSubmitReputation(values, "save", setSubmitting);
                      const res = validateForm();
                      setErrors({ ...res });
                    }}
                    loading={isSubmitting}
                    className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                  >
                    Save progress
                  </Button>
                  <Button
                    onClick={async () => {
                      setSubmitting(false);
                      const res = await validateForm();
                      setErrors({ ...res });

                      if (Object.keys(res).length !== 0)
                        toast.error("Please fill all the required fields");
                      else
                        handleSubmitReputation(values, "next", setSubmitting);
                    }}
                    disabled={!isValid || loading || isSubmitting}
                    className="py-4 px-6 self-end"
                    loading={loading}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end gap-6 w-full pt-16">
                <div className="flex justify-end gap-6">
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(0)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    {Icons.ic_arrow_left} Previous
                  </Button>
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(2)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    Next {Icons.ic_arrow_right}
                  </Button>
                </div>
                {(user?.role === USER_TYPE.ADMIN ||
                  user?.role === USER_TYPE.SUPER_ADMIN) &&
                  (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
                    questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
                    <Button
                      disabled={loadingAddComments || !isCommentNonNull}
                      onClick={() => {
                        handleAddAdminComment(
                          adminComments.reputation,
                          "adminUpdateReputation"
                        );
                      }}
                      loading={loadingAddComments}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                    >
                      Save Comment
                    </Button>
                  )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
