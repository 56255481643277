import React from "react";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { setHours, setMinutes, subDays } from "date-fns";
import Icons from "../../../../../assets/icons";
import TimePicker from "../../../../ui/timePicker";
import DateInput from "../../../../ui/datePicker";
import Button from "../../../../ui/button";
import Dialog from "../../../../ui/dialog";
import { convertTime } from "../../../../../utils/functions";

export default function UpdateDateDialog({
  toggleUpdateDateDialog,
  isUpdateDateDialogOpen,
  toggleConfirmDateUpdateDialog,
  setUpdatedSiteInformation,
}) {
  return (
    <Dialog isDialogModalOpen={isUpdateDateDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Review Site Visit Schedule</h2>
          <p>Please review the requested site visit date and time</p>
          <Formik
            initialValues={{
              date_of_visit: "",
              time_of_visit: new Date(),
            }}
            onSubmit={async (values) => {
              const formattedTime = convertTime(values.time_of_visit);

              setUpdatedSiteInformation({
                ...values,
                time_of_visit: formattedTime,
              });
              toggleConfirmDateUpdateDialog(true);
              toggleUpdateDateDialog(false);
            }}
            validationSchema={object().shape({
              date_of_visit: string().required("Please enter the visit date"),
              time_of_visit: string().required("Please enter the visit time"),
            })}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isValid,
                dirty,
                handleBlur,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
              } = props;

              return (
                <Form className="flex flex-col gap-12">
                  <div className="flex flex-col gap-6">
                    <DateInput
                      title="DATE"
                      icon={Icons.ic_calendar}
                      name="date_of_visit"
                      id="date_of_visit"
                      selected={values.date_of_visit}
                      minDate={subDays(new Date(), 0)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={(date) => {
                        setFieldTouched("date_of_visit", true);
                        setFieldValue("date_of_visit", date);
                      }}
                      onBlur={handleBlur}
                      errors={
                        errors.date_of_visit && touched.date_of_visit
                          ? errors.date_of_visit
                          : ""
                      }
                    />

                    <TimePicker
                      title="TIME"
                      type="time"
                      name="time_of_visit"
                      icon={Icons.ic_clock}
                      id="time_of_visit"
                      value={values.time_of_visit}
                      onChange={(date) => {
                        setFieldTouched("time_of_visit", true);
                        setFieldValue("time_of_visit", date);
                      }}
                      minTime={setHours(setMinutes(new Date(), 0), 7)}
                      maxTime={setHours(setMinutes(new Date(), 30), 17)}
                      onBlur={handleBlur}
                      selected={values.time_of_visit}
                      errors={
                        errors.time_of_visit && touched.time_of_visit
                          ? errors.time_of_visit
                          : ""
                      }
                      required
                    />
                  </div>

                  <div className="flex gap-6">
                    <Button
                      onClick={() => toggleUpdateDateDialog(false)}
                      className="bg-transparent border border-grey-200 !text-black"
                    >
                      cancel
                    </Button>
                    <Button
                      disabled={!isValid || !dirty}
                      type="submit"
                      className="py-4 px-6"
                      fullWidth
                      loading={isSubmitting}
                    >
                      Proceed
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Dialog>
  );
}
