import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../../../config/reducers/userSlice";

export default function useSteps() {
  const { currentStep, userStep } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { userRequest } = useSelector((state) => state.user);

  useEffect(() => {
    // ======= if site visit questionnaire
    if (userRequest?.site_ques_status === "approved") {
      dispatch(
        setCurrentStep({
          currentStep: 7,
          userStep: 7,
        })
      );
    }

    // ======= if site visit
    else if (
      userRequest?.site_visit_status === "meeting_held" ||
      userRequest?.site_visit_status === "pending"
    ) {
      dispatch(
        setCurrentStep({
          currentStep: 6,
          userStep: 6,
        })
      );
    }

    // ======= if questionnaire
    else if (userRequest?.questionnaire_status === "accepted") {
      dispatch(
        setCurrentStep({
          currentStep: 5,
          userStep: 5,
        })
      );
    }

    // ======= if nda agreement
    else if (userRequest?.nda_status === "accepted") {
      dispatch(
        setCurrentStep({
          currentStep: 4,
          userStep: 4,
        })
      );
    }

    // ======= if payment is confirmed
    else if (userRequest?.bacf_payment_confirmed) {
      dispatch(
        setCurrentStep({
          currentStep: 3,
          userStep: 3,
        })
      );
    }

    // ======= gas request has been accepted and user has accounts
    else if (
      userRequest?.status === "accepted" &&
      userRequest?.bankAccounts?.length > 0
    ) {
      dispatch(
        setCurrentStep({
          currentStep: 2,
          userStep: 2,
        })
      );
    }

    // if no status
    else {
      dispatch(
        setCurrentStep({
          currentStep: 1,
          userStep: 1,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRequest, userStep]);

  return { currentStep, userStep };
}
