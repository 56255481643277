import React from "react";
import { useSelector } from "react-redux";
import { STATUS } from "../../../utils/constants";
import DueDiligenceProcess from "../dueDiligenceProcess";
import UserInfo from "./userInfo";
import Badge from "../../ui/badge";
import SectionHeader from "../General/SectionHeader";
import CardHeader from "../General/CardHeader";
import ProxyUserManagement from "../../Account/teamManagement/user";

export default function RequestInfo({ request }) {
  const { currentUserId } = useSelector((state) => state.admin);

  return (
    <>
      <div className="flex flex-col md:flex-row gap-6">
        <div className="border border-grey-200 rounded-lg w-full md:max-w-[396px] bg-white p-6">
          <SectionHeader title="Company Info" />
          <UserInfo request={request} />
        </div>
        <div className="border border-grey-200 rounded-lg w-full md:max-w-[528px] bg-white p-6">
          <CardHeader
            title="Due diligence process"
            badge={<Badge status={request?.due_dligence_status} />}
          />

          <DueDiligenceProcess
            status={[
              request?.status === STATUS.ACCEPTED
                ? "Completed"
                : request?.project_type
                ? "Active"
                : "Pending",
              request?.bacf_payment_confirmed
                ? "Completed"
                : request?.status === STATUS.ACCEPTED &&
                  request?.bankAccounts?.length > 0
                ? "Active"
                : "Pending",
              request?.nda_status === STATUS.ACCEPTED
                ? "Completed"
                : request?.bacf_payment_confirmed
                ? "Active"
                : "Pending",
              request?.questionnaire_status === STATUS.ACCEPTED
                ? "Completed"
                : request?.nda_status === STATUS.ACCEPTED
                ? "Active"
                : "Pending",
              request?.site_visit_status === "meeting_held"
                ? "Completed"
                : request?.questionnaire_status === STATUS.ACCEPTED
                ? "Active"
                : "Pending",
              request?.site_ques_status === "approved"
                ? "Completed"
                : request?.site_visit_status === "meeting_held" ||
                  request?.site_visit_status === "pending"
                ? "Active"
                : "Pending",
              request?.finalize_status === "approved"
                ? "Completed"
                : request?.site_ques_status === "approved" &&
                  request?.site_visit_status === "meeting_held"
                ? "Active"
                : "Pending",
            ]}
            link={[
              `/admin/company-info/${currentUserId}/stageOne`,
              `/admin/company-info/${currentUserId}/stageTwo`,
              `/admin/company-info/${currentUserId}/stageThree`,
              `/admin/company-info/${currentUserId}/stageFour`,
              `/admin/company-info/${currentUserId}/stageFive`,
              `/admin/company-info/${currentUserId}/stageSix`,
              `/admin/company-info/${currentUserId}/stageSeven`,
            ]}
          />
        </div>
      </div>
      <ProxyUserManagement role="admin" />
    </>
  );
}
