import React from "react";
import Icons from "../../../../../assets/icons";

export default function QuestionnaireStatus() {
  return (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <img src={Icons.ic_pending} alt="" />
      <div className="space-y-4 text-center">
        <h2 className="text-h2 text-grey-800">Questionnaire is pending</h2>
        <p className="text-base max-w-[455px]">
          Buyer is yet to fill the provided questionnaire, you will be notified
          when the questionnaire is submitted
        </p>
      </div>
    </div>
  );
}
