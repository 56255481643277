import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { personalInfoValidation } from "../../../utils/validation";
import Button from "../../ui/button";
import Input from "../../ui/input";
import Icons from "../../../assets/icons";
import useUser from "../../../hooks/useUser";
import PhoneNumberInput from "../../ui/PhoneNumberInput";

export default function PersonalInfo() {
  const user = useSelector((state) => state.auth.user);
  const { updateUser } = useUser();

  return (
    <>
      <p className="text-sm ">
        Update your basic personal details to ensure your profile remains
        current.
      </p>
      <Formik
        initialValues={{
          fullName: user?.fullName || "",
          email: user?.email,
          phoneNumber: user?.phoneNumber || "",
          designation: user?.designation || "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await updateUser({
            fullName: values.fullName.trimEnd(),
            phoneNumber: values.phoneNumber,
            designation: values.designation,
          });
          setSubmitting(false);
        }}
        validationSchema={personalInfoValidation}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isValid,
            dirty,
            handleBlur,
            handleChange,
            setFieldTouched,
            setFieldValue,
            isSubmitting,
          } = props;

          return (
            <Form className="flex flex-col gap-12">
              <div className="flex flex-col gap-6">
                <Input
                  title="FULL NAME"
                  icon={Icons.ic_user}
                  placeholder="First and last name"
                  type="text"
                  name="fullName"
                  id="fullName"
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.fullName && touched.fullName ? errors.fullName : ""
                  }
                />

                <Input
                  title="EMAIL"
                  icon={Icons.ic_email}
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                />
                <PhoneNumberInput
                  title="PHONE NUMBER"
                  icon={Icons.ic_phone}
                  placeholder="07000000000"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={values.phoneNumber}
                  onChange={(number) => {
                    setFieldTouched("phoneNumber", true);
                    setFieldValue("phoneNumber", number);
                  }}
                  onBlur={handleBlur}
                  errors={
                    errors.phoneNumber && touched.phoneNumber
                      ? errors.phoneNumber
                      : ""
                  }
                />
                <Input
                  title="DESIGNATION"
                  icon={Icons.ic_role}
                  placeholder="Your job title"
                  type="text"
                  name="designation"
                  id="designation"
                  value={values.designation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.designation && touched.designation
                      ? errors.designation
                      : ""
                  }
                />
              </div>
              <Button
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                className="py-4 px-6"
                fullWidth
                loading={isSubmitting}
              >
                Save Changes
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
