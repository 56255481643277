import React, { useState } from "react";
import Dialog from "../../../../ui/dialog";
import Button from "../../../../ui/button";
import useUser from "../../../../../hooks/useUser";

export default function ApproveNDADialog({
  toggleApproveNDADialog,
  isApproveNDADialogOpen,
  companyId,
  ndafile,
  setNdaFile,
  isNdaDocFromUser,
}) {
  const { updateNdaStatus } = useUser();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isDialogModalOpen={isApproveNDADialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Confirm NDA Approval</h2>
          {!isNdaDocFromUser && (
            <p>
              Are you ready to approve the Non-Disclosure Agreement signed
              offline?
            </p>
          )}
          <p>
            By confirming, you acknowledge that the document has been reviewed
            and meets our standards. This action will update the due diligence
            status to reflect the signed NDA.
          </p>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleApproveNDADialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              const response = await updateNdaStatus(
                companyId,
                ndafile
                  ? { userResponse: ndafile, status: "accepted" }
                  : { status: "accepted" }
              );
              if (response) {
                setNdaFile("");
                toggleApproveNDADialog(false);
              }
              setLoading(false);
            }}
            disabled={loading}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
