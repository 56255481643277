import React from "react";
import Icons from "../../assets/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const stages = [
  {
    title: "Gas Request Form and Introductory Meeting",
    description:
      "Submit your gas request form and schedule an introductory meeting with the GACN team.",
  },
  {
    title: "BACF Payment",
    description:
      "Make payment for the Buyer Assurance and Commitment Fee (BACF) to proceed further.",
  },
  {
    title: "Non-Disclosure Agreement",
    description:
      "Complete a Non-Disclosure Agreement (NDA) to ensure confidentiality.",
  },
  {
    title: "Questionnaire",
    description:
      "Fill out a detailed questionnaire providing essential information about your project.",
  },
  {
    title: "Site visit",
    description:
      "Arrange for a site visit to enable GACN to evaluate your facility and operations.",
  },
  {
    title: "Site visit questionnaire",
    description: "Fill out a detailed questionnaire about site details",
  },
  {
    title: "Finalize",
    description:
      "Complete necessary formalities and obtain approval to start making gas purchases",
  },
];

const Stage = ({ status, index, step, description, link }) => (
  <div className="flex gap-4">
    <div
      className={`w-11 h-10 rounded-full flex items-center justify-center ${
        status && status[index] === "Completed"
          ? "bg-primary-1"
          : "border border-grey-400"
      }`}
    >
      <p className="text-primary-1 text-xl">
        {status && status[index] === "Completed" ? Icons.ic_tick : index + 1}
      </p>
    </div>
    <div className="flex justify-between items-center w-full">
      <div>
        <p className="text-black text-base leading-[23.2px] font-medium mb-1">
          {step.title}
        </p>
        {status && (
          <p
            className={`text-xs ${
              status[index] !== "Pending" ? "text-grey-600" : "text-grey-400"
            }`}
          >
            {status[index]}
          </p>
        )}
        {description && (
          <p className="text-xs text-grey-600">{step.description}</p>
        )}
      </div>
      {status && status[index] !== "Pending" && (
        <Link className="bloack text-black p-2 text-black" to={link[index]}>
          {Icons.ic_chevron_right}
        </Link>
      )}
    </div>
  </div>
);

export default function DueDiligenceProcess({ status, description, link }) {
  const userRequest = useSelector((state) => state.user.userRequest);

  return (
    <div className="space-y-[26px] pt-6">
      {stages.map((step, index) =>
        userRequest?.status === "rejected" ? (
          index === 0 && (
            <Stage
              key={index}
              status={status}
              link={link}
              step={step}
              description={description}
              index={index}
            />
          )
        ) : (
          <Stage
            key={index}
            status={status}
            link={link}
            step={step}
            description={description}
            index={index}
          />
        )
      )}
      {userRequest?.status === "rejected" && (
        <div className="py-4 px-3 bg-grey-50 border border-grey-200 rounded-lg text-sm leading-[24px]">
          <p className="pb-4">
            <span className="text-black font-medium">Disclaimer: </span>
            {userRequest?.admin_note}
          </p>
          <p className="border-t border-grey-200 pt-4">
            <span className="text-black font-medium">Date of Rejection: </span>
            {format(new Date(userRequest?.admin_note_date), "MMMM dd, yyyy")}
          </p>
        </div>
      )}
    </div>
  );
}
