import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import Badge from "../../../../ui/badge";
import useUser from "../../../../../hooks/useUser";
import CopyText from "../../../../ui/copy";
import Progress from "../../../../ui/progress";
import { PaymentEmail, Phone } from "../../../../ui/contact";
import { addCommasToNumber } from "../../../../../utils/functions";
import Loader from "../../../../ui/loader";

const Info = ({ title, content, icon }) => (
  <div className="flex gap-6 items-center justify-between py-4">
    <div className="flex gap-6 items-center">
      <span className="text-grey-400"> {icon}</span>
      <div className="space-y-1">
        <p className="text-xs text-grey-500">{title}</p>
        <p className="text-sm font-medium text-grey-900">{content}</p>
      </div>
    </div>
    <CopyText value={content} />
  </div>
);

const AccountInfo = ({
  details,
  handleStageTwoStep,
  amountPaid,
  bacfPrice,
  userId,
}) => {
  const { confirmPayment } = useUser();
  const [loading, setLoading] = useState(false);
  return (
    <div className="space-y-12 pt-4">
      <div>
        <Info
          title="Bank Name"
          content={details?.bankName}
          icon={Icons.ic_bank}
        />
        <Info
          title="Account Number "
          content={details?.accountNumber}
          icon={Icons.ic_pencil}
        />
        <Info
          title="Account Name"
          content={details?.accountName}
          icon={Icons.ic_user}
        />
        <Info
          title="Amount"
          content={`NGN ${addCommasToNumber(bacfPrice)}`}
          icon={Icons.ic_money}
        />
      </div>
      <Progress amountPaid={amountPaid} />
      <Button
        disabled={amountPaid !== bacfPrice}
        loading={loading}
        onClick={async () => {
          setLoading(true);
          const response = await confirmPayment(userId);
          if (response) {
            handleStageTwoStep(2);
          }
          setLoading(false);
        }}
        className="bg-transparent w-full border-[1.5px] border-primary-1 disabled:border-grey-10 !text-primary-1 disabled:!text-grey-400"
      >
        Confirm payment
      </Button>
    </div>
  );
};

const Header = ({ title, decription, badge }) => (
  <div className="border-b border-grey-10 pb-4">
    <div className="flex justify-between">
      <p className="text-black leading-[23.2px] font-semibold">{title}</p>
      {badge && badge}
    </div>
    {decription && decription}
  </div>
);

const ListItem = ({ title, content, breakText }) => (
  <li className="list-disc text-xs leading-[17.4px] ml-[10px]">
    <span className="font-medium text-black">
      {title} {breakText && <br />}
    </span>
    {content}
  </li>
);

export default function MakePayment({ handleStageTwoStep }) {
  const { userRequest, bacfPrice, bacfHistory } = useSelector(
    (state) => state.user
  );

  const { getPaymentHistory } = useUser();
  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    await getPaymentHistory();
    setLoading(false);
  };

  useEffect(() => {
    getDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <div className="py-4">
      <Loader />
    </div>
  ) : (
    <div className="flex flex-col md:flex-row gap-6 justify-center">
      <div className="border border-grey-200 rounded-lg w-full md:max-w-[574px] bg-white p-6">
        <Header
          title="Payment Information"
          decription={
            <p className="text-sm leading-[20.3px] pt-4">
              You are required to pay a non-refundable fee of{" "}
              <span className="font-semibold text-black">
                NGN {addCommasToNumber(bacfPrice)}
              </span>{" "}
              for BACF
            </p>
          }
          badge={
            <Badge
              status={
                userRequest?.bacf_payment_confirmed !== true
                  ? "not_paid"
                  : "paid"
              }
            />
          }
        />

        <AccountInfo
          details={userRequest?.bankAccounts[0]}
          handleStageTwoStep={handleStageTwoStep}
          amountPaid={bacfHistory?.amount || 0}
          bacfPrice={bacfPrice}
          userId={userRequest?.userId}
        />
      </div>
      <div className="border border-grey-200 rounded-lg w-full md:max-w-[342px] bg-white p-6 space-y-8">
        <div>
          <Header title="Payment Instructions" />
          <ul className="space-y-3 pt-3">
            <ListItem
              title="Account Permanence:"
              content="The provided account details are permanent and do not expire."
              breakText
            />
            <ListItem
              title="Exact Amount:"
              content={`Please transfer exactly ${addCommasToNumber(
                bacfPrice
              )} Naira in a single payment.`}
              breakText
            />
            <ListItem
              title="Prompt Confirmation:"
              content="Adhering to these guidelines will avoid delays in payment confirmation."
              breakText
            />
          </ul>
        </div>
        <div className="py-3 px-4 border border-grey-200 rounded-lg bg-grey-50">
          <ul className="space-y-4 text-xs leading-[17.4px]">
            <p>
              <span className="font-medium text-black">Payment support:</span>{" "}
              If you encounter any issues with your payment or require
              assistance, we are here to help.
            </p>
            <div>
              <ListItem
                title="Email Us:"
                content={
                  <>
                    For support, send an email to <PaymentEmail />.
                  </>
                }
              />
              <ListItem
                title="Call Us:"
                content={
                  <>
                    Alternatively, you can reach us by phone at <Phone />.
                  </>
                }
              />
            </div>
            <p>
              Our team is dedicated to resolving your payment queries promptly.
            </p>
          </ul>
        </div>
      </div>
    </div>
  );
}
