import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { setIsPasswordStep } from "../../config/reducers/loginFormSlice";
import { checkIfInputIsNum } from "../../utils/functions";
import { signupValidation } from "../../utils/validation";
import useAuthentication from "../../hooks/useAuthentication";
import Input from "../ui/input";
import Icons from "../../assets/icons";
import Button from "../ui/button";
import PasswordForm from "./passwordForm";
import PhoneNumberInput from "../ui/PhoneNumberInput";

export default function SignupForm() {
  const dispatch = useDispatch();
  const { isPasswordStep } = useSelector((state) => state.loginForm);

  const [values, setValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    company_name: "",
  });

  const showPassWordForm = async (value) => {
    setValues(value);
    dispatch(setIsPasswordStep(true));
  };

  return (
    <div>
      {isPasswordStep ? (
        <PasswordForm otherValues={values} type="creatAccount" />
      ) : (
        <UserDetailsForm values={values} showPassWordForm={showPassWordForm} />
      )}
    </div>
  );
}

const UserDetailsForm = ({ values, showPassWordForm }) => {
  const { checkIfUserEmailExists } = useAuthentication();
  return (
    <Formik
      initialValues={values}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        const res = await checkIfUserEmailExists(values.email);
        if (res) {
          showPassWordForm({ ...values, fullName: values.fullName.trimEnd() });
        }
        setSubmitting(false);
      }}
      validationSchema={signupValidation}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          handleBlur,
          handleChange,
          setFieldTouched,
          setFieldValue,
          isSubmitting,
        } = props;

        return (
          <Form className="flex flex-col gap-16">
            <div className="flex flex-col gap-6 ">
              <Input
                title="FULL NAME"
                icon={Icons.ic_user}
                placeholder="First and last name"
                type="text"
                name="fullName"
                id="fullName"
                value={values.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={
                  errors.fullName && touched.fullName ? errors.fullName : ""
                }
              />
              <Input
                title="COMPANY NAME"
                icon={Icons.ic_company}
                placeholder="Name of your organisation"
                type="text"
                name="company_name"
                id="company_name"
                value={values.company_name}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={
                  errors.company_name && touched.company_name
                    ? errors.company_name
                    : ""
                }
              />
              <Input
                title="EMAIL"
                icon={Icons.ic_email}
                placeholder="Eample@email.com"
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.email && touched.email ? errors.email : ""}
              />
              <PhoneNumberInput
                title="PHONE NUMBER"
                icon={Icons.ic_phone}
                placeholder="07000000000"
                name="phoneNumber"
                id="phoneNumber"
                value={values.phoneNumber}
                onChange={(number) => {
                  setFieldTouched("phoneNumber", true);
                  setFieldValue("phoneNumber", number);
                }}
                onBlur={handleBlur}
                errors={
                  errors.phoneNumber && touched.phoneNumber
                    ? errors.phoneNumber
                    : ""
                }
                onKeyDown={checkIfInputIsNum}
              />
            </div>
            <Button
              disabled={!isValid || isSubmitting}
              type="submit"
              className="py-4 px-6"
              fullWidth
              loading={isSubmitting}
            >
              CONTINUE
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
