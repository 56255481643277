import { Link, useLocation, useNavigate } from "react-router-dom";
import Icons from "../../assets/icons";
import Button from "../ui/button";
import { useEffect } from "react";

const ResetPasswordSuccess = ({type}) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const loginRoute = type === "admin" ? "/admin/login" : "/login";

  useEffect(() => {
    if (!state) navigate(loginRoute, { replace: true });
  }, []);

  return (
    <div className="space-y-12 flex flex-col items-center">
      <div className="space-y-6 flex flex-col items-center">
        <img src={Icons.ic_success} alt="" />
        <div className="space-y-4">
          <h2 className="text-h2 text-black">Password Reset successful</h2>
          <p className="text-base">
            You can now log in to your account with your new password
          </p>
        </div>
      </div>
      <Link to={loginRoute}>
        <Button>LOg in to account</Button>
      </Link>
    </div>
  );
};

export default ResetPasswordSuccess;
