import React from "react";
import DashboardLayout from "../../../components/Layout/dashboardLayout";
import Tab from "../../../components/ui/tab";
import useTab from "../../../hooks/useTab";
import PersonalInfo from "../../../components/Account/personalInfo";
import ChangePassword from "../../../components/Account/changePassword";
import AdminManagement from "../../../components/Account/teamManagement/admin";

export default function AdminAccountSettings() {
  const tabsItems = ["Personal Info", "Team Management", "Change Password"];
  const { tabs, activeTab, handleTabClick } = useTab(tabsItems);

  return (
    <DashboardLayout
      type="admin"
      title="Account settings"
      description="Manage Your Personal and Company Information"
    >
      <Tab tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
      <div
        className={`border border-grey-200 rounded-lg  w-full space-y-6 ${
          activeTab === tabs[1]
            ? "bg-transaprent md:bg-white border-none md:border md:border-grey-200"
            : "max-w-[448px] px-4 py-6 bg-white border border-grey-200"
        }`}
      >
        {activeTab === tabs[0] && <PersonalInfo />}
        {activeTab === tabs[1] && <AdminManagement />}
        {activeTab === tabs[2] && <ChangePassword type="admin" />}
      </div>
    </DashboardLayout>
  );
}
