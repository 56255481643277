import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../../../../config/reducers/userSlice";
import useSteps from "./useSteps";
import StageOne from "./StageOne";
import StageTwo from "./StageTwo";
import StageThree from "./StageThree";
import StageFour from "./StageFour";
import StageFive from "./StageFive";
import StageSix from "./StageSix";
import StageSeven from "./StageSeven";

const steps = [
  "Gas Request Form",
  "BACF Payment",
  "NDA Agreement",
  "Questionnaire",
  "Site visit",
  "Site Visit Questionnaire",
  "Finalize",
];

const RequestSteps = () => {
  const dispatch = useDispatch();
  const { currentStep, userStep } = useSteps();

  return (
    <div className="max-w-[940px]">
      <div>
        <div className="flex items-center px-10 overflow-auto">
          {steps.map((step, index) => (
            <Fragment key={index}>
              <div
                onClick={() => {
                  if (index + 1 <= userStep) {
                    dispatch(
                      setCurrentStep({
                        currentStep: index + 1,
                      })
                    );
                  }
                }}
                className={`flex flex-col w-11 min-w-[50px] lg:min-w-[auto] items-center space-y-4 relative
                 ${
                   userStep === index + 1 || userStep > index + 1
                     ? "font-semibold cursor-pointer"
                     : "font-medium"
                 }
                 `}
              >
                <div
                  className={`flex items-center justify-center rounded-full transition duration-500 ease-in-out h-8 w-8 p-1 border
                  ${
                    userStep === index + 1 || userStep > index + 1
                      ? "bg-primary-1 border-primary-1 text-white"
                      : "border-grey-400 text-grey-400"
                  }
                  `}
                >
                  {index + 1}
                </div>
                <p className="text-sm  leading-[20.3px] whitespace-nowrap ">
                  {step}
                </p>
              </div>
              {index + 1 !== steps.length && (
                <div
                  className={`-mt-9 flex-auto min-w-[80px] border-t-[1.5px] transition duration-500 ease-in-out
                  ${
                    currentStep === index + 1
                      ? "border-dashed border-primary-1"
                      : currentStep > index + 1
                      ? "border-primary-1"
                      : "border-dashed border-primary-75"
                  }
                  `}
                ></div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
      <div className="py-8">
        {currentStep === 1 && <StageOne />}
        {currentStep === 2 && <StageTwo />}
        {currentStep === 3 && <StageThree />}
        {currentStep === 4 && <StageFour />}
        {currentStep === 5 && <StageFive />}
        {currentStep === 6 && <StageSix />}
        {currentStep === 7 && <StageSeven />}
      </div>
    </div>
  );
};
export default RequestSteps;
