import LoginForm from "../../../components/AuthForm/login";
import AuthLayout from "../../../components/Layout/authLayout";

const AdminLoginPage = () => {
  return (
    <AuthLayout
      type="admin"
      page="login"
      title="Admin Log in"
      description="Enter your credentials to access the admin dashboard"
    >
      <LoginForm type="admin" />
    </AuthLayout>
  );
};

export default AdminLoginPage;
