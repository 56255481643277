import axios from "axios";
import { toast } from "react-toastify";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshToken } from "./refreshAuth";
import { store } from "../../config/store";
import { setDisplayNoInternetDialog } from "../../config/reducers/authSlice";
import { setDisplaySessionTimeoutDialog } from "../../config/reducers/authSlice";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

// Request interceptor
api.interceptors.request.use((config) => {
  return config;
});

async function handleError(error) {
  if (error?.message === "Network Error") {
    if (error?.config?.url === "/cloudinary/upload") {
    } else {
      return store.dispatch(setDisplayNoInternetDialog());
    }
  }
  switch (error?.response?.status) {
    case 400:
      break;
    case 401:
      if (error?.response?.data?.message === "Insufficient permissions") {
        toast.error(error?.response?.data?.message);
      }
      if (
        error?.response?.data?.message ===
        "Session expired, please login again."
      )
        store.dispatch(setDisplaySessionTimeoutDialog(true));
      break;
    case 403:
      // toast.error(error?.response?.data.message);
      break;
    case 404:
      // toast.error(error?.response?.data?.message);
      break;
    case 500:
      toast.error("Internal Server Error");
      break;
    case 503:
      toast.error("Service Unavailable. Please try again later.");
      break;
    default:
      console.log(error?.response?.data?.message);
      break;
  }
}

createAuthRefreshInterceptor(api, refreshToken, {
  statusCodes: [401],
  pauseInstanceWhileRefreshing: true,
  shouldRefresh: (error) => error?.response?.data?.message === "Unauthorized",
});

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    handleError(error);
    return Promise.reject(error);
  }
);

export default api;
