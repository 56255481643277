import Icons from "../../assets/icons";
import {
  DOCUMENT_ICON_TYPE,
  PROJECT_FULL_NAME,
  PROJECT_TYPE,
} from "../constants";

// convert bytes to MB
export const bytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

// parse string value to int and handle input change
export const parseAndHandleChange = (value, setFieldValue, name) => {
  const parsed = parseInt(value, 10);
  return setFieldValue(name, parsed);
};

export const generateYearArray = (numYears) => {
  const currentYear = new Date().getFullYear();
  const yearArray = [];
  for (let i = 0; i < numYears; i++) {
    yearArray.push(currentYear + i);
  }
  return yearArray;
};

export const generateIndexArray = (numYears) => {
  const initialValue = 0;
  const indexArray = [];
  for (let i = 0; i < numYears; i++) {
    indexArray.push(initialValue + i);
  }
  return indexArray;
};

export const checkIfInputIsNum = (event) => {
  if (
    event.key === "Backspace" ||
    event.key === "Clear" ||
    event.key === "Tab" ||
    event.key === "Delete" ||
    event.key === "ArrowRight" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowUp" ||
    event.key === "ArrowDown" ||
    event.key === "Control"
  ) {
    return;
  }

  if (!/\d/.test(event.key)) {
    event.preventDefault();
  }
};

export const getFileType = (fileName) => {
  const parts = fileName?.split(".");
  const extension = parts ? parts[parts.length - 1] : "";
  return extension.toLowerCase();
};

export const showFileIconByType = (fileName) => {
  const fileType = getFileType(fileName);
  let iconType = Icons.ic_pdf;

  if (fileType === DOCUMENT_ICON_TYPE.XLS) {
    iconType = Icons.ic_xls;
  } else if (
    fileType === DOCUMENT_ICON_TYPE.DOC ||
    fileType === DOCUMENT_ICON_TYPE.DOCX ||
    fileType === DOCUMENT_ICON_TYPE.DOTX
  )
    iconType = Icons.ic_doc;

  return iconType;
};

export const getFileNameFromLink = (link) => {
  const parts = link?.split("/");
  let fileNameWithExtension = parts ? parts[parts?.length - 1] : "";
  if (
    fileNameWithExtension.includes("%20") ||
    fileNameWithExtension.includes("%29") ||
    fileNameWithExtension.includes("%28")
  ) {
    fileNameWithExtension = fileNameWithExtension.replaceAll("%20", " ");
    fileNameWithExtension = fileNameWithExtension.replaceAll("%29", " ");
    fileNameWithExtension = fileNameWithExtension.replaceAll("%28", " ");
  }
  return fileNameWithExtension;
};

export const getFileNameWithoutExtensionFromLink = (link) => {
  const parts = link?.split("/");
  let fileNameWithExtension = parts ? parts[parts?.length - 1] : "";

  fileNameWithExtension = fileNameWithExtension.replaceAll("%20", "_");
  fileNameWithExtension = fileNameWithExtension.replaceAll("%29", "_");
  fileNameWithExtension = fileNameWithExtension.replaceAll("%28", "_");
  fileNameWithExtension = fileNameWithExtension.replaceAll(" ", "_");

  return fileNameWithExtension.split(".")[0];
};

export const getDownloadFileLink = (link) => {
  const fileName = getFileNameWithoutExtensionFromLink(link);
  if (link?.includes("fl_attachment")) {
    return link;
  }

  const parts = link?.split("/");
  const updatedLink = parts
    ?.slice(0, -3)
    .concat([`fl_attachment:${fileName}`])
    .concat(parts?.slice(-3))
    .join("/");

  return updatedLink;
};

export const getProjectName = (project_type) => {
  const projectName =
    project_type === PROJECT_TYPE.COMM
      ? PROJECT_FULL_NAME.COMM
      : project_type === PROJECT_TYPE.GAS
      ? PROJECT_FULL_NAME.GAS
      : PROJECT_FULL_NAME.POWER;

  return projectName;
};

export const convertTime = (time) => {
  const hours = time.getHours();
  const minutes = time.getMinutes();

  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  const formattedTime = `${formattedHours}:${formattedMinutes}`;

  return formattedTime;
};

export const addCommasToNumber = (amount) => {
  return parseInt(amount)?.toLocaleString();
};

export const hasNonNullValue = (obj) => {
  if (obj === null || obj === undefined || obj === "") return false;
  if (typeof obj !== "object") return true;
  if (Array.isArray(obj)) return obj.some(hasNonNullValue);
  return Object.values(obj).some(hasNonNullValue);
};

export const countNonNullValues = (obj) => {
  let nonNullCount = 0;

  const countNonNulls = (item) => {
    if (item !== null && item !== undefined && item !== "") {
      if (typeof item !== "object") {
        nonNullCount += 1;
      } else if (Array.isArray(item)) {
        item.forEach(countNonNulls);
      } else {
        Object.values(item).forEach(countNonNulls);
      }
    }
  };

  countNonNulls(obj);

  return nonNullCount;
};
