import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GetStarted from "./getStarted";
import RequestSteps from "./Steps";
import useUser from "../../../hooks/useUser";
import Loader from "../../ui/loader";

export default function UserDueDiligence() {
  const { getUserRequest } = useUser();
  const user = useSelector((state) => state.auth.user);

  const [hasUserStarted, setUserHasStarted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getRequest = async () => {
      setLoading(true);
      const response = await getUserRequest(user?.superuser_id ?? user?._id);
      if (response?.data) {
        setUserHasStarted(true);
      } else {
        setUserHasStarted(false);
      }
      setLoading(false);
    };
    if (user?.superuser_id ?? user?._id) {
      getRequest();
    }
  }, [user?.superuser_id, user?._id]);

  const handleUserHasStarted = () => setUserHasStarted(true);
  return loading ? (
    <Loader />
  ) : hasUserStarted ? (
    <RequestSteps />
  ) : (
    <GetStarted handleUserHasStarted={handleUserHasStarted} />
  );
}
