import { Suspense, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./config";
import Loader from "../components/ui/loader";

const Router = () => {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  return (
    <Suspense fallback={<Loader fullpage />}>
      <RouterProvider router={routes}></RouterProvider>
    </Suspense>
  );
};

export default Router;
