import React from "react";
import Icons from "../../../../assets/icons";
import { format } from "date-fns";
import { contactInformation } from "../../../../utils/constants";
import { Phone, SalesEmail } from "../../../ui/contact";

export const STATUS = {
  PENDING: "pending",
  AWAITING: "awaiting",
  FEEDBACK: "feedback",
  UPDATE: "update",
  SUCCESS: "success",
  REJECTED: "rejected",
};

const Icon = ({ type }) =>
  STATUS.PENDING === type ? (
    <img src={Icons.ic_pending} alt="" />
  ) : STATUS.FEEDBACK === type || STATUS.REJECTED === type ? (
    <img src={Icons.ic_error} alt="" />
  ) : STATUS.SUCCESS === type ? (
    <img src={Icons.ic_success} alt="" />
  ) : (
    <img src={Icons.ic_success} alt="" />
  );

export default function DueDiligenceStatus({
  type,
  title,
  description,
  date,
  note,
  children,
}) {
  return type === STATUS.REJECTED ? (
    <div className="space-y-12 flex flex-col items-center py-6 max-w-[584px] mx-auto">
      <div className="space-y-6 flex flex-col items-center">
        <img src={Icons.ic_error} alt="" />
        <div className="space-y-4 text-center">
          <h2 className="text-lg text-grey-800 font-semibold">{title}</h2>
          <p className="text-base text-grey-500">{description}</p>
        </div>
        <div className="border border-grey-200 rounded-lg w-full md:max-w-[584px] bg-white py-3 px-4 space-y-4 text-sm leading-[24px]">
          {note && (
            <p>
              <span className="text-black font-medium">Reason: </span>
              {note}
            </p>
          )}
          {date && (
            <p>
              <span className="text-black font-medium">
                Date of Rejection:{" "}
              </span>
              {format(new Date(date), "LLLL dd, yyyy")}
            </p>
          )}
          <div className="border-t border-grey-200 w-full" />
          <p>
            For inquiries, contact our sales team at{" "}
            <SalesEmail className="text-black font-medium" /> or call:{" "}
            <Phone className="text-black font-medium" />
          </p>
        </div>
      </div>

      {children}
    </div>
  ) : type === STATUS.PENDING ? (
    <div className="space-y-6 flex flex-col items-center justify-center py-6 min-h-[59vh]">
      <Icon type={type} />
      <div className="space-y-4 text-center">
        <h2 className="text-h2 text-grey-800">{title}</h2>
        <p className="text-base max-w-[455px]">{description}</p>
      </div>
    </div>
  ) : (
    <div className="space-y-12 flex flex-col items-center py-6 max-w-[496px] mx-auto">
      <div className="space-y-6 flex flex-col items-center">
        <Icon type={type} />
        <div className="space-y-4 text-center">
          <h2 className="text-lg text-grey-800 font-semibold">{title}</h2>
          <p className="text-base text-grey-500">{description}</p>
        </div>
      </div>

      {children}
    </div>
  );
}
