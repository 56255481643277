import React, { useEffect, useState } from "react";
import PaymentReceived from "./paymentReceived";
import MakePayment from "./makePayment";
import { useSelector } from "react-redux";
import useUser from "../../../../../hooks/useUser";
import Loader from "../../../../ui/loader";

export default function StageTwo() {
  const { getBacfPrice } = useUser();

  const { userRequest } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [stageTwoStep, setStageTwoStep] = useState(
    userRequest?.bacf_payment_confirmed ? 2 : 1
  );
  const handleStageTwoStep = (value) => setStageTwoStep(value);

  const getPrice = async () => {
    setLoading(true);
    await getBacfPrice();
    setLoading(false);
  };

  useEffect(() => {
    getPrice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <div className="py-4">
      <Loader />
    </div>
  ) : (
    <div>
      {stageTwoStep === 1 && (
        <MakePayment handleStageTwoStep={handleStageTwoStep} />
      )}
      {stageTwoStep === 2 && <PaymentReceived />}
    </div>
  );
}
