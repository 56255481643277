import { useSelector } from "react-redux";
import { setCurrentTeamPage } from "../../../../config/reducers/adminSlice";
import useUser from "../../../../hooks/useUser";
import Table from "../../../ui/table";

export default function AdminTable({ items }) {
  const { resendAdminInvite } = useUser();
  const { searchUserText, totalTeam, currentTeamPage } = useSelector(
    (state) => state.admin
  );

  return (
    <Table
      items={items}
      role="admin"
      resendInvite={resendAdminInvite}
      searchUserText={searchUserText}
      totalTeam={totalTeam}
      currentTeamPage={currentTeamPage}
      setCurrentTeamPage={setCurrentTeamPage}
    />
  );
}
