import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUser from "../../../../../hooks/useUser";

export default function useStageSix() {
  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [stageSixStep, setStageSixStep] = useState(1);

  const handleStageSixStep = (value) => setStageSixStep(value);

  const { getSiteVisitQuestionnaireDetails } = useUser();

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      setLoading(true);
      const response = await getSiteVisitQuestionnaireDetails(
        user?.superuser_id ?? user?._id
      );
      if (response?.data) {
        setStageSixStep(
          response?.data?.status && response?.data?.status !== "pending" ? 2 : 1
        );
        setLoading(false);
      }
    };
    if (user?.superuser_id ?? user?._id) {
      fetchQuestionnaire();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.superuser_id, user?._id]);

  return { loading, stageSixStep, handleStageSixStep };
}
