import Loader from "../../../ui/loader";
import Icons from "../../../../assets/icons";
import UserTable from "./userTable";
import TeamHeader from "../header";
import useUserTable from "./useUserTable";

export default function ProxyUserManagement({ role }) {
  const {
    getAllProxyUsers,
    createProxyUser,
    user,
    currentUserId,
    proxyUsers,
    loadingProxyUser,
    searchUserText,
    currentTeamPage,
    handleSearch,
  } = useUserTable();

  return (
    <div className="max-w-[950px] md:border md:border-grey-200 md:rounded-lg md:bg-white ">
      <TeamHeader
        searchText={searchUserText}
        handleSearch={handleSearch}
        role={role}
        currentTeamPage={currentTeamPage}
        fetchTeam={getAllProxyUsers}
        createTeamMember={createProxyUser}
        id={currentUserId ? currentUserId : user?._id}
        type="user"
      />
      {loadingProxyUser ? (
        <div className=" p-4">
          <Loader />
        </div>
      ) : proxyUsers?.length === 0 ? (
        <div className="space-y-4 flex flex-col justify-center items-center px-4 py-8 bg-white md:bg-transaprent border md:border-none rounded-t-lg md:rounded-none ">
          <img src={Icons.ic_empty_state} alt="" />
          <p className="text-black text-lg font-semibold !leading-[26.1px]">
            {searchUserText ? "No search results" : "No User Found"}
          </p>
        </div>
      ) : (
        <UserTable items={proxyUsers} />
      )}
    </div>
  );
}
