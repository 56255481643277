import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { initialize } from "../../config/reducers/authSlice";
import Loader from "../ui/loader";
import { USER_TYPE } from "../../utils/constants";

function AdminAuthSessionProvider({ children }) {
  const { isAuthenticated, isInitialized, user } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const token = localStorage.getItem("refresh-token");

  useEffect(() => {
    if (!token) dispatch(initialize({ isAuthenticated: false, user: null }));

    if (token) {
      dispatch(initialize({ isAuthenticated: true, refreshToken: token }));
    }
  }, [dispatch, token]);

  if (!isInitialized) {
    return <Loader fullpage />;
  }

  if (
    isAuthenticated &&
    token &&
    user &&
    user.role !== USER_TYPE.USER &&
    user.role !== USER_TYPE.PROXY
  ) {
    return <Navigate to={"/admin/due-diligence"} replace />;
  }

  if (!isAuthenticated && !user && !!token) {
    return <Navigate to={"/admin/login"} replace />;
  }

  return <>{children}</>;
}

export default AdminAuthSessionProvider;
