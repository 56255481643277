import React, { useEffect, useState } from "react";
import Button from "../../../../ui/button";
import Icons from "../../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../../../../config/reducers/userSlice";
import PdfDownload from "../../../../ui/pdfDownload";
import { addCommasToNumber } from "../../../../../utils/functions";
import PaymentReceipt from "../../../../ui/paymentReceipt";
import useUser from "../../../../../hooks/useUser";
import Loader from "../../../../ui/loader";

export default function PaymentReceived() {
  const dispatch = useDispatch();
  const { bacfPrice, userRequest, bacfHistory } = useSelector(
    (state) => state.user
  );

  const { getLastPaymentHistory } = useUser();
  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    await getLastPaymentHistory(userRequest?.userId);
    setLoading(false);
  };

  useEffect(() => {
    getDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="space-y-12 flex flex-col items-center py-6 max-w-[496px] mx-auto">
      <div className="space-y-8 flex flex-col items-center w-full">
        <div className="space-y-6 flex flex-col items-center">
          <img src={Icons.ic_success} alt="" />
          <div className="space-y-4 text-center">
            <h2 className="text-lg text-grey-800 font-semibold">
              Payment Received
            </h2>
            <p className="text-base text-grey-500">
              Payment of{" "}
              <span className="font-semibold text-black">
                NGN {addCommasToNumber(bacfPrice)}
              </span>{" "}
              successful
            </p>
          </div>
        </div>

        <PdfDownload
          type="pdf"
          title="Payment receipt"
          paymentBtn={<PaymentReceipt paymentDetails={bacfHistory} />}
        />
      </div>

      <Button
        onClick={() =>
          dispatch(
            setCurrentStep({
              currentStep: 3,
            })
          )
        }
      >
        Proceed
      </Button>
    </div>
  );
}
