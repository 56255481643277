import React from "react";

const Select = (props) => {
  const {
    className,
    name,
    title,
    icon,
    errors,
    toggleHiddenPassword,
    reqError,
    disabled,
    options,
    required,
    ...rest
  } = props;

  return (
    <div className={className}>
      {title && (
        <label className="text-left text-grey-900 text-sm font-medium mb-1 block">
          {title}
          {required && "*"}
        </label>
      )}

      <div className="relative">
        <select
          className={` p-4 w-full appearance-none border focus:outline-none focus:border focus:border-primary-1 text-sm rounded-md transition duration-150 ease-in-out
          ${errors || reqError ? "!border-error-200" : "border-grey-300"}
          ${disabled ? "bg-grey-10 text-grey-400" : "bg-white text-grey-900"}
          `}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          {...rest}
          disabled={disabled ? true : false}
        >
          <option selected>Select</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
      {errors && (
        <p className="mt-2 text-xs text-error-500 text-left">{errors}</p>
      )}
    </div>
  );
};

export default Select;
