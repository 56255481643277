import React, { useState } from "react";
import useUser from "../../../../../hooks/useUser";
import Button from "../../../../ui/button";
import Dialog from "../../../../ui/dialog";

import { toast } from "react-toastify";

export default function AcceptDateDialog({
  toggleAcceptDateDialog,
  isAcceptDateDialogOpen,
  companyId,
  time,
  date,
}) {
  const { acceptSiteVisit } = useUser();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isDialogModalOpen={isAcceptDateDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Confirm Site Visit Schedule</h2>
          <p>
            Are you sure you want to confirm the site visit for
            <span className="text-black font-semibold">
              {" "}
              {date} at {time}{" "}
            </span>
            as requested by the buyer?
          </p>
          <p>
            By accepting, you agree to be available for conducting the site
            visit
          </p>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleAcceptDateDialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              const response = await acceptSiteVisit(
                { status: "awaiting" },
                companyId
              );
              if (response) {
                toast.success("Site Visit date has been accepted");
                toggleAcceptDateDialog(false);
              }
              setLoading(false);
            }}
            disabled={loading}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
