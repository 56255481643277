import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import useUser from "../../../../../hooks/useUser";
import { setCurrentUserId } from "../../../../../config/reducers/adminSlice";

export default function useFinalize() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { getFinalizeDetails } = useUser();

  const [loading, setLoading] = useState(false);

  if (!id) navigate("/admin/due-diligence", { replace: true });

  const fetchFinalize = async () => {
    setLoading(true);
    await getFinalizeDetails(id);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(
        setCurrentUserId({
          currentUserId: id,
        })
      );
      fetchFinalize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { loading, id };
}
