import React, { useState } from "react";
import Badge from "../../../../ui/badge";
import PdfDownload from "../../../../ui/pdfDownload";
import SectionHeader from "../../../General/SectionHeader";
import DragAndDrop from "../../../../ui/dragAndDrop";
import Button from "../../../../ui/button";
import useUser from "../../../../../hooks/useUser";
import { useSelector } from "react-redux";
import { STATUS } from "../../../../../utils/constants";
import { AdminEmail, Phone } from "../../../../ui/contact";

const Header = ({ title, decription, badge }) => (
  <div className="pb-4 space-y-4">
    <div className="flex justify-between items-center">
      <p className="text-black leading-[23.2px] font-semibold">{title}</p>
      {badge && badge}
    </div>
    {decription && <p>{decription}</p>}
  </div>
);

export default function NDAForm({ handleStageThreeStep }) {
  const { user } = useSelector((state) => state.auth);
  const { userRequest } = useSelector((state) => state.user);

  const [ndafile, setNdaFile] = useState("");
  const [loading, setLoading] = useState(false);

  const { uploadNdaFile } = useUser();

  return (
    <div className="md:max-w-[605px] border border-grey-200 rounded-lg w-full bg-white p-6">
      <Header
        title={
          userRequest?.nda_status === "pending"
            ? "Non-Disclosure Agreement Completion"
            : userRequest?.nda_status === STATUS.ACCEPTED
            ? "NDA Approval Confirmed"
            : userRequest?.nda_status === STATUS.NDA_REVIEW
            ? "NDA Submission Received"
            : "Non-Disclosure Agreement"
        }
        decription={
          userRequest?.nda_status === "pending"
            ? "Please download the NDA Agreement, fill in the required details, and sign it. This step is crucial for proceeding with the due diligence process."
            : userRequest?.nda_status === STATUS.ACCEPTED
            ? "This Non-Disclosure Agreement has been successfully approved."
            : userRequest?.nda_status === STATUS.NDA_REVIEW
            ? "Your NDA is under review. You'll be notified and can proceed to the questionnaire once approved. Thank you for your patience."
            : ""
        }
        badge={
          <Badge
            status={
              !userRequest?.nda_status || userRequest?.nda_status === "pending"
                ? "pending_review"
                : userRequest?.nda_status === STATUS.ACCEPTED
                ? STATUS.APPROVED
                : userRequest?.nda_status
            }
          />
        }
      />
      <div className="space-y-6">
        <PdfDownload
          type="doc"
          fullWidth
          title="NDA Agreement Form"
          download
          pdfFile="https://res.cloudinary.com/duizbh2mk/raw/upload/fl_attachment/v1715076902/Mutual_NDA_Due_Diligence_Template_-_January_2024_lk12fg.dotx"
        />
        <SectionHeader />
        <p>
          Upload the completed and signed copy of the NDA here to proceed to the
          questionnaire.
        </p>
        <DragAndDrop
          name="userResponse"
          onChange={(file) => setNdaFile(file)}
        />
        <SectionHeader />
        <p>
          For inquiries, contact our sales team at{" "}
          <AdminEmail className="text-black font-medium" /> or call:{" "}
          <Phone className="text-black font-medium" />
        </p>
        <div className="pt-6">
          <Button
            disabled={loading || !ndafile}
            onClick={async () => {
              if (ndafile) {
                setLoading(true);
                const res = await uploadNdaFile(
                  user?.superuser_id ?? user?._id,
                  {
                    userResponse: ndafile,
                  }
                );
                if (res) {
                  setLoading(false);
                  handleStageThreeStep(2);
                }
              }
            }}
            fullWidth
            loading={loading}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  );
}
