import React from "react";
import Header from "../Header";
import { BackLink } from "../ui/goback";
import globalRouter from "../../router/globalRouter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SessionTimeoutDialog from "../AuthForm/sessionTimeOutModal";
import NoInternetDialog from "../AuthForm/noInternet";

export default function InnerPageLayout({ title, back, id, children, type }) {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  const userRequest = useSelector((state) => state.user.userRequest);

  const { displaySessionTimeoutDialog, displayNoInternet } = useSelector(
    (state) => state.auth
  );

  return (
    <>
      <SessionTimeoutDialog
        isSessionTimeoutDialogOpen={displaySessionTimeoutDialog}
      />
      <NoInternetDialog isNoInternetDialogOpen={displayNoInternet} />

      <Header type={type} />
      <div className="w-full md:w-[calc(100%-272px)] md:ml-[272px] mt-[72px] md:mt-0 px-4 md:px-8 py-10 md:py-14">
        {/* if no user data then show something went wrong here */}

        <div className="space-y-[32px]">
          <div className="flex flex-col sm:flex-row justify-between gap-4">
            <BackLink link={back} />
            {id && (
              <p className="text-sm">
                Application ID:{" "}
                <span className="text-black font-semibold">
                  {userRequest?.userId ? id : "Pending"}
                </span>
              </p>
            )}
          </div>
          {title && (
            <h2 className="text-black text-lg leading-[26.1px] font-medium">
              {title}
            </h2>
          )}
        </div>
        <div className="space-y-6 pt-8"> {children}</div>
      </div>
    </>
  );
}
