import React from "react";
import DueDiligenceProcess from "../dueDiligenceProcess";
import Button from "../../ui/button";
import { Phone, SalesEmail } from "../../ui/contact";

export default function GetStarted({ handleUserHasStarted }) {
  return (
    <div className="border border-grey-200 rounded-lg w-full md:max-w-[623px] bg-white p-6 space-y-12">
      <div>
        <p className="text-black text-base leading-[23.2px] font-medium">
          Steps
        </p>
        <DueDiligenceProcess description={true} />
      </div>
      <div className="space-y-4 border border-grey-200 bg-grey-50 rounded-lg py-3 px-4 text-grey-600 text-sm leading-[24px]">
        <p>
          <span className="font-medium">Disclaimer:</span> The due diligence
          process outlined above is essential for ensuring transparency and
          reliability in our gas procurement transactions.
          <br /> Your cooperation and promptness in completing each stage are
          highly appreciated.
        </p>
        <p className="border-t border-grey-10 pt-4">
          For inquiries, contact our sales team at{" "}
          <SalesEmail className="font-medium text-black" /> or call:{" "}
          <Phone className="font-medium text-black" />
        </p>
      </div>
      <Button className="w-full" onClick={() => handleUserHasStarted()}>
        GET STARTED
      </Button>
    </div>
  );
}
