import api from ".";
import { setDisplaySessionTimeoutDialog } from "../../config/reducers/authSlice";
import { store } from "../../config/store";

const fetchNewToken = async () => {
  try {
    const token = await api.get("/auth/refresh").then((res) => res.data);
    return token;
  } catch (error) {
    return null;
  }
};

export const refreshToken = async (failedRequest) => {
  const data = await fetchNewToken();

  if (data) {
    return Promise.resolve(data);
  } else {
    store.dispatch(setDisplaySessionTimeoutDialog(true));
    return Promise.reject();
  }
};
