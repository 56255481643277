import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  team: [],
  totalTeam: 0,
  currentTeamPage: 1,
  searchUserText: "",

  requests: [],
  totalRequest: 0,
  currentRequestPage: 1,
  searchRequestText: "",

  loading: false,
  error: "",

  currentUserId: "",
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setError(state, action) {
      // Handle error action
      return { ...state, ...action.payload };
    },

    setLoading(state, action) {
      // Handle loading action
      return {
        ...state,
        ...action.payload, //loading
      };
    },

    setTeam(state, action) {
      // Handle set team action

      return {
        ...state,
        ...action.payload, // action.payload.team
      };
    },
    setSearchUserText(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.searchUserText
      };
    },
    setSearchRequestText(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.searchRequestText
      };
    },
    setRequests(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.requests
      };
    },
    setCurrentUserId(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.currentUserId
      };
    },

    setCurrentTeamPage(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.currentTeamPage
      };
    },
    setCurrentRequestPage(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.currentRequestPage
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setError,
  setLoading,
  setTeam,
  setRequests,
  setSearchUserText,
  setSearchRequestText,
  setCurrentUserId,
  setCurrentTeamPage,
  setCurrentRequestPage,
} = adminSlice.actions;

export default adminSlice.reducer;
