import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setIsPasswordStep } from "../../config/reducers/loginFormSlice";

import logo from "../../assets/images/logo.png";
import Divider from "../ui/divider";
import { BackFloatButton } from "../ui/goback";

export default function AuthLayout({
  page,
  title,
  description,
  children,
  type,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isPasswordStep } = useSelector((state) => state.loginForm);

  return (
    <div className="px-4 ">
      <div className="container !py-6">
        <img src={logo} width={200} alt="" />
      </div>
      <div className="max-w-[400px] mx-auto py-6 md:py-10 text-center text-sm space-y-8 relative">
        {(isPasswordStep || page === "forgotPassword") && (
          <BackFloatButton
            onClick={() => {
              if (page === "forgotPassword") {
                dispatch(setIsPasswordStep(false));
                navigate(type === "admin" ? "/admin/login" : "/login", {
                  replace: true,
                });
              } else dispatch(setIsPasswordStep(false));
            }}
          />
        )}

        {page === "verification" || page === "confirmation" ? (
          <></>
        ) : (
          <div className="space-y-4">
            <h2 className="text-h2 text-black">{title}</h2>
            <p className="text-base">{description}</p>
          </div>
        )}

        {children}

        {(page === "signup" || page === "login") &&
          !isPasswordStep &&
          type === "user" && (
            <>
              <Divider text="Or" />

              <p>
                Are you new here?{" "}
                {page === "signup" ? (
                  <Link to="/login" className="link-underline">
                    Log in
                  </Link>
                ) : (
                  <Link to="/signup" className="link-underline">
                    Create Account
                  </Link>
                )}
              </p>
            </>
          )}
      </div>
    </div>
  );
}
