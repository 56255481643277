import { Link } from "react-router-dom";
import Icons from "../../assets/icons";

export const BackLink = ({ link }) => (
  <Link className="flex gap-2 items-center text-grey-900" to={link}>
    {Icons.ic_chevron_left}
    <p className="uppercase text-sm font-semibold leading-[16.8px]">Back</p>
  </Link>
);

export const BackFloatButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="flex gap-2 items-center mt-8 relative md:absolute top-12 left-[-100px]"
  >
    {Icons.ic_chevron_left}
    <p className="uppercase text-grey-900 text-sm font-semibold leading-[16.8px]">
      Back
    </p>
  </button>
);

export const BackButton = ({ onClick }) => (
  <button onClick={onClick} className="flex gap-2 items-center">
    {Icons.ic_chevron_left}
    <p className="uppercase text-grey-900 text-sm font-semibold leading-[16.8px]">
      Back
    </p>
  </button>
);
