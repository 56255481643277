import { Form, Formik, getIn } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { technicalCommgasValidationSchema } from "../../../../../utils/validation";
import useQuestionnaire from "../useQuestionnaire";
import Icons from "../../../../../assets/icons";
import DateInput from "../../../../ui/datePicker";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import AdminComment from "../../../../ui/adminComment";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import { subDays } from "date-fns";
import { STAGE_FOUR_STATUS, USER_TYPE } from "../../../../../utils/constants";

export default function Technical({ handleStageFourTabClick }) {
  const { user } = useSelector((state) => state.auth);
  const { questionnaire } = useSelector((state) => state.user);

  const {
    disableForm,
    loading,
    canAdminComment,
    initialValues,
    handleSubmitCommgasTechnical,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useQuestionnaire(handleStageFourTabClick);

  return (
    <Formik
      initialValues={initialValues.technicalCommgas}
      validationSchema={technicalCommgasValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setErrors,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <div className="py-8 space-y-3">
              <h6 className="font-semibold text-black">
                Section 4: Technical Capability Due Diligence
              </h6>
              <p className="text-sm max-w-[324px]">
                Please provide the following information and supporting
                documents for the proposed project:
              </p>
            </div>
            <FormContainer width type="user" title="1. Project Overview">
              <>
                <SectionHeader
                  title="Proposed Location*"
                  description="Specify the geographical location of the project."
                />
                <AdminComment
                  name="proposedLocation"
                  adminComment={
                    adminComments?.technicalCommgas?.proposedLocation || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.proposedLocation
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="proposedLocation"
                    id="proposedLocation"
                    value={values.proposedLocation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.proposedLocation && touched.proposedLocation
                        ? errors.proposedLocation
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas?.proposedLocation &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Plant Capacity*"
                  description="Define the nominal installed capacity of the plant."
                />
                <AdminComment
                  name="plantCapacity"
                  adminComment={
                    adminComments?.technicalCommgas?.plantCapacity || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.plantCapacity
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="plantCapacity"
                    id="plantCapacity"
                    value={values.plantCapacity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.plantCapacity && touched.plantCapacity
                        ? errors.plantCapacity
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas?.plantCapacity &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              {/* <>
                <SectionHeader border title="Land acquisition status*" />
                <AdminComment
                  name="landAcquisitionStatus"
                  adminComment={
                    adminComments?.technicalCommgas?.landAcquisitionStatus || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.landAcquisitionStatus
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="landAcquisitionStatus"
                    id="landAcquisitionStatus"
                    value={values.landAcquisitionStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.landAcquisitionStatus &&
                      touched.landAcquisitionStatus
                        ? errors.landAcquisitionStatus
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas
                        ?.landAcquisitionStatus &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </> */}
              <>
                <SectionHeader
                  border
                  title="Volume Requirement*"
                  description="Specify the daily and annual gas volume requirements in MMScf/d, along with the contract duration."
                />
                <AdminComment
                  name="gasRequirement.volumeRequirement"
                  adminComment={
                    adminComments?.technicalCommgas?.gasRequirement
                      ?.volumeRequirement || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.gasRequirement
                      ?.volumeRequirement
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="gasRequirement.volumeRequirement"
                    id="gasRequirement.volumeRequirement"
                    value={values.gasRequirement.volumeRequirement}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "gasRequirement.volumeRequirement") &&
                          getIn(touched, "gasRequirement.volumeRequirement")
                      )
                        ? getIn(errors, "gasRequirement.volumeRequirement")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas?.gasRequirement
                        ?.volumeRequirement &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              {/* <>
                <SectionHeader
                  title="Gross Heating Values*"
                  description="Minimum and maximum gross heating values required in MMbtu/d"
                />

                <AdminComment
                  name="gasRequirement.grossHeatingValues"
                  adminComment={
                    adminComments?.technicalCommgas?.gasRequirement
                      ?.grossHeatingValues || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.gasRequirement
                      ?.grossHeatingValues
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="gasRequirement.grossHeatingValues"
                    id="gasRequirement.grossHeatingValues"
                    value={values.gasRequirement.grossHeatingValues}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "gasRequirement.grossHeatingValues") &&
                          getIn(touched, "gasRequirement.grossHeatingValues")
                      )
                        ? getIn(errors, "gasRequirement.grossHeatingValues")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas?.gasRequirement
                        ?.grossHeatingValues &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </> */}
              <>
                <SectionHeader border title="Delivery Pressures*" />
                <AdminComment
                  name="gasRequirement.deliveryPressures"
                  adminComment={
                    adminComments?.technicalCommgas?.gasRequirement
                      ?.deliveryPressures || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.gasRequirement
                      ?.deliveryPressures
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="gasRequirement.deliveryPressures"
                    id="gasRequirement.deliveryPressures"
                    value={values.gasRequirement.deliveryPressures}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "gasRequirement.deliveryPressures") &&
                          getIn(touched, "gasRequirement.deliveryPressures")
                      )
                        ? getIn(errors, "gasRequirement.deliveryPressures")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas?.gasRequirement
                        ?.deliveryPressures &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Delivery Date*" />
                <AdminComment
                  name="gasRequirement.deliveryDate"
                  adminComment={
                    adminComments?.technicalCommgas?.gasRequirement
                      ?.deliveryDate || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.gasRequirement
                      ?.deliveryDate
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <DateInput
                    name="gasRequirement.deliveryDate"
                    id="gasRequirement.deliveryDate"
                    icon={Icons.ic_calendar}
                    selected={values.gasRequirement.deliveryDate}
                    minDate={subDays(new Date(), 0)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                      setFieldTouched("gasRequirement.deliveryDate", true);
                      setFieldValue("gasRequirement.deliveryDate", date);
                    }}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "gasRequirement.deliveryDate") &&
                          getIn(touched, "gasRequirement.deliveryDate")
                      )
                        ? getIn(errors, "gasRequirement.deliveryDate")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas?.gasRequirement
                        ?.deliveryDate &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="2. Technology and Engineering"
            >
              <>
                <SectionHeader
                  title="Technology Statement:"
                  description="Statement on technology to be deployed and evidence of license to apply such.*"
                />
                <AdminComment
                  name="technologyStatement"
                  adminComment={
                    adminComments?.technicalCommgas?.technologyStatement || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.technologyStatement
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="technologyStatement"
                    id="technologyStatement"
                    value={values.technologyStatement}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.technologyStatement && touched.technologyStatement
                        ? errors.technologyStatement
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas?.technologyStatement &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="technologyStatementDocument"
                  adminComment={
                    adminComments?.technicalCommgas
                      ?.technologyStatementDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.technologyStatementDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <FileUpload
                    name="technologyStatementDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.technologyStatementDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas
                        ?.technologyStatementDocument &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  title="Engineering Design Status:"
                  description="Status of project engineering designs (e.g., FEED, Detailed Design), including the name of the engineering firm being used (if applicable) or plan for the development of the same.*"
                  border
                />

                <AdminComment
                  name="engineeringDesignStatus"
                  adminComment={
                    adminComments?.technicalCommgas?.engineeringDesignStatus ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.engineeringDesignStatus
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="engineeringDesignStatus"
                    id="engineeringDesignStatus"
                    value={values.engineeringDesignStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.engineeringDesignStatus &&
                      touched.engineeringDesignStatus
                        ? errors.engineeringDesignStatus
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas
                        ?.engineeringDesignStatus &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="engineeringDesignStatusDocument"
                  adminComment={
                    adminComments?.technicalCommgas
                      ?.engineeringDesignStatusDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas
                      ?.engineeringDesignStatusDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <FileUpload
                    required
                    name="engineeringDesignStatusDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.engineeringDesignStatusDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas
                        ?.engineeringDesignStatusDocument &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                    errors={
                      errors.engineeringDesignStatusDocument &&
                      touched.engineeringDesignStatusDocument
                        ? errors.engineeringDesignStatusDocument
                        : ""
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  title="Land Acquisition:"
                  description="Status of land acquisition or approval letter from relevant government body, land survey plan, and certificate of occupancy.*"
                  border
                />

                <AdminComment
                  name="landAcquisition"
                  adminComment={
                    adminComments?.technicalCommgas?.landAcquisition || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.landAcquisition
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="landAcquisition"
                    id="landAcquisition"
                    value={values.landAcquisition}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.landAcquisition && touched.landAcquisition
                        ? errors.landAcquisition
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas?.landAcquisition &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="landAcquisitionDocument"
                  adminComment={
                    adminComments?.technicalCommgas?.landAcquisitionDocument ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.landAcquisitionDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <FileUpload
                    required
                    name="landAcquisitionDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.landAcquisitionDocument
                        : ""
                    }
                    onChange={(fieldName, file) =>
                      setFieldValue(fieldName, file)
                    }
                    errors={
                      errors.landAcquisitionDocument &&
                      touched.landAcquisitionDocument
                        ? errors.landAcquisitionDocument
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas
                        ?.landAcquisitionDocument &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="3. Statement on technologyDeployed to be deployed and evidence of license to apply Such."
            >
              <>
                <SectionHeader
                  title="EPC Contracts Status"
                  description="Evidence of engagement (i.e., award process, expression of interest, or EPC contract if applicable)*"
                />
                <AdminComment
                  name="statusOfEpcContracts"
                  adminComment={
                    adminComments?.technicalCommgas?.statusOfEpcContracts || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.statusOfEpcContracts
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="statusOfEpcContracts"
                    id="statusOfEpcContracts"
                    value={values.statusOfEpcContracts}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.statusOfEpcContracts &&
                      touched.statusOfEpcContracts
                        ? errors.statusOfEpcContracts
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas?.statusOfEpcContracts &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="statusOfEpcContractsDocument"
                  adminComment={
                    adminComments?.technicalCommgas
                      ?.statusOfEpcContractsDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas
                      ?.statusOfEpcContractsDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <FileUpload
                    name="statusOfEpcContractsDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.statusOfEpcContractsDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas
                        ?.statusOfEpcContractsDocument &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="Technical Partners Evidence:" />
                <AdminComment
                  name="evidenceOfTechnicalPartners"
                  adminComment={
                    adminComments?.technicalCommgas
                      ?.evidenceOfTechnicalPartners || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas?.evidenceOfTechnicalPartners
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <FileUpload
                    name="evidenceOfTechnicalPartners"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    onBlur={handleBlur}
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.evidenceOfTechnicalPartners
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.evidenceOfTechnicalPartners &&
                      touched.evidenceOfTechnicalPartners
                        ? errors.evidenceOfTechnicalPartners
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas
                        ?.evidenceOfTechnicalPartners &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="4. Operations and Maintenance"
            >
              <>
                <SectionHeader
                  title="Operations and Maintenance Plan"
                  description="O&M contract or EOI from an O&M contractor.*"
                />
                <AdminComment
                  name="operationsAndMaintenancePlan"
                  adminComment={
                    adminComments?.technicalCommgas
                      ?.operationsAndMaintenancePlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas
                      ?.operationsAndMaintenancePlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="operationsAndMaintenancePlan"
                    id="operationsAndMaintenancePlan"
                    value={values.operationsAndMaintenancePlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.operationsAndMaintenancePlan &&
                      touched.operationsAndMaintenancePlan
                        ? errors.operationsAndMaintenancePlan
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas
                        ?.operationsAndMaintenancePlan &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="operationsAndMaintenancePlanDocument"
                  adminComment={
                    adminComments?.technicalCommgas
                      ?.operationsAndMaintenancePlanDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.technicalCommgas
                      ?.operationsAndMaintenancePlanDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="technicalCommgas"
                >
                  <FileUpload
                    name="operationsAndMaintenancePlanDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.TechnicalDueDiligence
                        ? values.operationsAndMaintenancePlanDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.technicalCommgas
                        ?.operationsAndMaintenancePlanDocument &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            {(user?.role === USER_TYPE.USER ||
              user?.role === USER_TYPE.PROXY) &&
            (!questionnaire ||
              questionnaire?.status === STAGE_FOUR_STATUS.PENDING ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) ? (
              <div className="flex justify-between w-full pt-16">
                <button
                  onClick={() => handleStageFourTabClick(2)}
                  className="text-primary-1 uppercase text-sm font-bold leading-[16.8px]"
                >
                  Previous
                </button>
                <div className="flex justify-end gap-6 ">
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      setErrors({});
                      handleSubmitCommgasTechnical(
                        values,
                        "save",
                        setSubmitting
                      );
                      const res = validateForm();
                      setErrors({ ...res });
                    }}
                    loading={isSubmitting}
                    className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                  >
                    Save progress
                  </Button>
                  <Button
                    onClick={async () => {
                      setSubmitting(false);
                      const res = await validateForm();
                      setErrors({ ...res });

                      if (Object.keys(res).length !== 0)
                        toast.error("Please fill all the required fields");
                      else
                        handleSubmitCommgasTechnical(
                          values,
                          "next",
                          setSubmitting
                        );
                    }}
                    disabled={!isValid || loading}
                    className="py-4 px-6 self-end"
                    loading={loading}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end gap-6 w-full pt-16">
                <div className="flex justify-end gap-6">
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(2)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    {Icons.ic_arrow_left} Previous
                  </Button>
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(4)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    Next {Icons.ic_arrow_right}
                  </Button>
                </div>
                {(user?.role === USER_TYPE.ADMIN ||
                  user?.role === USER_TYPE.SUPER_ADMIN) &&
                  (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
                    questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
                    <Button
                      disabled={loadingAddComments || !isCommentNonNull}
                      onClick={() => {
                        handleAddAdminComment(
                          adminComments.technicalCommgas,
                          "adminUpdateTechnical"
                        );
                      }}
                      loading={loadingAddComments}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                    >
                      Save Comment
                    </Button>
                  )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
