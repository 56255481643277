import React, { useState } from "react";
import { useSelector } from "react-redux";
import QuestionnareTab from "./tab";
import Reputation from "./Power/reputation";
import PowerCommercial from "./Power/commercial";
import Commercial from "./GasandCommercial/commercial";
import PowerTechnical from "./Power/technical";
import Technical from "./GasandCommercial/technical";
import PowerEconomic from "./Power/economic";
import Economic from "./GasandCommercial/economic";
import Extras from "./Power/extras";
import Badge from "../../../ui/badge";
import Card from "../Card";
import Request from "./Power/request";
import { getProjectName } from "../../../../utils/functions";
import { PROJECT_TYPE } from "../../../../utils/constants";

const tabs = [
  "Request Details",
  "Reputation Due Diligence",
  "Commercial Due Diligence",
  "Technical Due Diligence",
  "Economic Due Diligence",
  "Extras",
];

export default function Questionnaire({ handleStageFourStep }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleStageFourTabClick = (tab) => {
    setActiveTab(tab);
    window.scrollTo(0, 0);
  };

  const { questionnaire, project_type } = useSelector((state) => state.user);

  return (
    <Card
      title={`Questionnaire ${
        activeTab > 0 ? `- ${getProjectName(project_type)}` : ""
      }`}
      description="Complete the following questionnaire provided below"
      badge={
        <Badge
          status={
            questionnaire?.status === "pending"
              ? "pending_review"
              : questionnaire?.status === "awaiting"
              ? "awaitng_review"
              : questionnaire?.status
          }
        />
      }
    >
      <QuestionnareTab
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={handleStageFourTabClick}
      />
      {activeTab === 0 && (
        <Request handleStageFourTabClick={handleStageFourTabClick} />
      )}
      {activeTab === 1 && (
        <Reputation handleStageFourTabClick={handleStageFourTabClick} />
      )}
      {activeTab === 2 &&
        (project_type === PROJECT_TYPE.POWER? (
          <PowerCommercial handleStageFourTabClick={handleStageFourTabClick} />
        ) : (
          <Commercial handleStageFourTabClick={handleStageFourTabClick} />
        ))}
      {activeTab === 3 &&
        (project_type === PROJECT_TYPE.POWER? (
          <PowerTechnical handleStageFourTabClick={handleStageFourTabClick} />
        ) : (
          <Technical handleStageFourTabClick={handleStageFourTabClick} />
        ))}
      {activeTab === 4 &&
        (project_type === PROJECT_TYPE.POWER? (
          <PowerEconomic handleStageFourTabClick={handleStageFourTabClick} />
        ) : (
          <Economic handleStageFourTabClick={handleStageFourTabClick} />
        ))}
      {activeTab === 5 && (
        <Extras
          handleStageFourStep={handleStageFourStep}
          handleStageFourTabClick={handleStageFourTabClick}
        />
      )}
    </Card>
  );
}
