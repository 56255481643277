import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useUser from "./useUser";
import { setCurrentUserId } from "../config/reducers/adminSlice";
import { setUserRequest } from "../config/reducers/userSlice";

export default function useAdmin(state) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { getUserRequest } = useUser();

  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState({});

  if (!id) navigate("/admin/due-diligence", { replace: true });

  const getRequest = async () => {
    setLoading(true);
    const response = await getUserRequest(id);
    if (response?.data) {
      setRequest(response.data);
    } else {
      if (state) {
        setRequest(state);
        dispatch(
          setUserRequest({
            userRequest: state,
          })
        );
      } else navigate("/admin/due-diligence");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(
        setCurrentUserId({
          currentUserId: id,
        })
      );
      getRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { request, loading, id };
}
