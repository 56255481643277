import React, { useState } from "react";
import { useSelector } from "react-redux";
import SectionHeader from "../../../General/SectionHeader";
import Badge from "../../../../ui/badge";
import PdfDownload from "../../../../ui/pdfDownload";
import DragAndDrop from "../../../../ui/dragAndDrop";
import Button from "../../../../ui/button";
import ApproveNDADialog from "./approveNDADialog";
import RejectNDADialog from "./rejectNDADialog";
import { STATUS } from "../../../../../utils/constants";
import { format } from "date-fns";
import {
  getDownloadFileLink,
  getFileNameFromLink,
  getFileType,
} from "../../../../../utils/functions";

const Header = ({ title, decription, badge }) => (
  <div className="pb-4 space-y-4">
    <div className="flex justify-between items-center">
      <p className="text-black leading-[23.2px] font-semibold">{title}</p>
      {badge && badge}
    </div>
    {decription && <p>{decription}</p>}
  </div>
);

export default function StageThree() {
  const { userRequest } = useSelector((state) => state.user);

  const [ndafile, setNdaFile] = useState("");
  const [isApproveNDADialogOpen, setIsApproveNDADialogOpen] = useState(false);
  const [isRejectNDADialogOpen, setIsRejectNDADialogOpen] = useState(false);

  const toggleApproveNDADialog = (value) => setIsApproveNDADialogOpen(value);
  const toggleRejectNDADialog = (value) => setIsRejectNDADialogOpen(value);

  return (
    <div className="md:max-w-[605px] border border-grey-200 rounded-lg w-full bg-white">
      <div className="p-6">
        <Header
          title={
            userRequest?.nda_status === STATUS.ACCEPTED
              ? "NDA Approved"
              : "NDA Review"
          }
          decription={
            userRequest?.nda_status === STATUS.ACCEPTED
              ? "This Non-Disclosure Agreement has been successfully approved."
              : "Approve or review user NDA submissions efficiently on this page."
          }
          badge={
            <Badge
              status={
                !userRequest?.nda_status ||
                userRequest?.nda_status === "pending"
                  ? "pending_review"
                  : userRequest?.nda_status === STATUS.ACCEPTED
                  ? STATUS.APPROVED
                  : userRequest?.nda_status
              }
            />
          }
        />
        <div className="space-y-6">
          <p>
            <span className="text-black font-medium">Document upload: </span>
            {userRequest?.nda_status === "pending" ? "Pending upload" : "Done"}
          </p>
          <PdfDownload
            fullWidth
            type={getFileType(userRequest?.nda_doc?.userResponse)}
            title={
              userRequest?.nda_doc?.userResponse
                ? getFileNameFromLink(userRequest?.nda_doc?.userResponse)
                : "NDA Agreement Form"
            }
            btnClassName={
              !userRequest?.nda_doc?.userResponse &&
              "opacity-50 !border-grey-10 !text-grey-400"
            }
            download
            pdfFile={getDownloadFileLink(userRequest?.nda_doc?.userResponse)}
          />
          <SectionHeader />
          {userRequest?.nda_status === STATUS.ACCEPTED ? (
            <div className="border border-grey-200 rounded-lg w-full bg-grey-50 text-black py-3 px-4 space-y-4 text-sm leading-[24px]">
              <p>
                <span className="font-medium">Date of Approval: </span>

                {format(new Date(userRequest?.nda_date), "LLL dd, yyyy")}
              </p>
            </div>
          ) : userRequest?.nda_status === STATUS.REJECTED ? (
            <div className="border border-grey-200 rounded-lg w-full bg-grey-50 text-black py-3 px-4 space-y-4 text-sm leading-[24px]">
              <p>
                <span className="font-medium">Reason: </span>
                {userRequest?.nda_doc?.adminNote}
              </p>
              <p className="border-t border-grey-10 pt-4">
                <span className="font-medium">Date of Rejection: </span>

                {format(new Date(userRequest?.nda_date), "LLL dd, yyyy")}
              </p>
            </div>
          ) : (
            <>
              <p className="font-medium text-black">
                Manually Upload Signed NDA
              </p>
              <p>
                Confirm the NDA has been signed offline and upload the document
                here. This ensures the due diligence process is accurately
                updated.
              </p>
              <DragAndDrop
                name="userResponse"
                onChange={(file) => setNdaFile(file)}
                disabled={userRequest?.nda_status === STATUS.NDA_REVIEW}
              />
              <SectionHeader />

              <div
                className={`border border-grey-200 rounded-lg w-full md:max-w-[584px] bg-grey-50 text-black py-3 px-4 space-y-4 text-sm leading-[24px]
         ${userRequest?.nda_status === STATUS.NDA_REVIEW && "opacity-50"} 
         `}
              >
                <p>
                  <span className="font-medium">Note for Admin:</span> Please
                  verify the authenticity of the NDA before uploading. Once
                  uploaded, mark the NDA status as 'Completed' to reflect in the
                  user's due diligence progress
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      {(ndafile || userRequest?.nda_status === STATUS.NDA_REVIEW) && (
        <>
          <div className="p-6 border-t border-grey-200 flex justify-end gap-6 w-full">
            <Button
              onClick={() => toggleRejectNDADialog(true)}
              className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
            >
              Reject NDA
            </Button>
            <Button
              onClick={() => toggleApproveNDADialog(true)}
              className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
            >
              Approve NDA
            </Button>
          </div>
          <ApproveNDADialog
            isApproveNDADialogOpen={isApproveNDADialogOpen}
            toggleApproveNDADialog={toggleApproveNDADialog}
            companyId={userRequest?.userId}
            ndafile={ndafile}
            setNdaFile={setNdaFile}
            isNdaDocFromUser={userRequest?.nda_doc ? true : false}
          />
          <RejectNDADialog
            isRejectNDADialogOpen={isRejectNDADialogOpen}
            toggleRejectNDADialog={toggleRejectNDADialog}
            companyId={userRequest?.userId}
          />
        </>
      )}
    </div>
  );
}
