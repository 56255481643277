import React from "react";

import AuthLayout from "../../components/Layout/authLayout";
import ResetPasswordSuccess from "../../components/AuthForm/resetPasswordSuccess";

const ResetPasswordSuccessPage = () => {
  return (
    <AuthLayout type="user" page="confirmation">
      <ResetPasswordSuccess />
    </AuthLayout>
  );
};

export default ResetPasswordSuccessPage;
