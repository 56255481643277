import { useEffect } from "react";
import Icons from "../../assets/icons";

function Modal({ isModalOpen, width, onClick, children }) {
  useEffect(() => {
    isModalOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return (
    <section
      className={`bg-[#00000080] !m-0 flex w-full fixed top-0 left-0 z-[99999] transition-all ease-in-out duration-300  ${
        isModalOpen
          ? "visible min-h-screen h-full opacity-100"
          : "invisible min-h-0 opacity-0"
      }`}
    >
      <div className="flex justify-center items-center h-full w-full px-4 pt-[94px] pb-8 overflow-auto">
        <div
          className={`relative w-full h-full ${
            width ? width : "max-w-[448px]"
          }`}
        >
          {children}
          <div
            onClick={onClick}
            className="cursor-pointer bg-primary-500 border boder-solid border-primary-100 text-white h-10 w-10 rounded-full flex items-center justify-center absolute -top-[52px] right-0"
          >
            {Icons.ic_close}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Modal;
