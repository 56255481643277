import React, { useState } from "react";
import Icons from "../../assets/icons";
import useUser from "../../hooks/useUser";
import Loader from "./loader";
import { useSelector } from "react-redux";
import {
  bytesToMB,
  getDownloadFileLink,
  getFileNameFromLink,
  showFileIconByType,
} from "../../utils/functions";
import { maxSize } from "../../utils/constants";

export default function FileUpload(props) {
  const {
    name,
    title,
    errors,
    disabled,
    fileType,
    format,
    onChange,
    value,
    required = false,
    placeholder,
    ...rest
  } = props;

  const user = useSelector((state) => state.auth.user);

  const [fileDetails, setFileDetails] = useState(null);
  const [fileLink, setFileLink] = useState(value || "");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [fileSizeError, setFileSizeError] = useState("");

  const { uploadFile } = useUser();

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.size > maxSize) {
      setFileSizeError("maximum size is 5mb");
    } else {
      setFileUploadLoading(true);
      setFileSizeError("");

      const formData = new FormData();
      formData.append("doc", file);
      formData.append("folder", user?.superuser_id ?? user?._id);
      const { data } = await uploadFile(formData);

      if (data) {
        onChange(name, data.url);
        setFileDetails(file);
        setFileLink(data.url);
      }
      setFileUploadLoading(false);
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <div className="flex items-center gap-4">
          <img
            src={
              fileDetails || value
                ? showFileIconByType(fileLink)
                : Icons.ic_file_upload
            }
            alt=""
            className="h-12 w-11"
          />
          <div className="w-full leading-[23.2px] text-left">
            <p className="font-semibold text-black break-all">
              {value
                ? getFileNameFromLink(value)
                : fileDetails
                ? fileDetails.name
                : `${title ? title : "File Upload"} ${
                    required ? "(required)*" : "(optional)"
                  }`}
            </p>
            {value ? (
              ""
            ) : (
              <p className="divide-x divide-dotted text-sm text-grey-400">
                <span>{format}</span>{" "}
                <span className="font-medium">
                  {fileDetails
                    ? `${bytesToMB(fileDetails.size)}MB`
                    : "Max. 5MB"}
                </span>
              </p>
            )}
          </div>
          {fileDetails || value ? (
            <div className="flex gap-4">
              <a href={getDownloadFileLink(fileLink ? fileLink : value)}>
                {Icons.ic_download}
              </a>
              {!disabled && (
                <button
                  onClick={() => {
                    setFileLink("");
                    setFileDetails(null);
                    onChange(name, "");
                  }}
                >
                  {Icons.ic_delete}
                </button>
              )}
            </div>
          ) : (
            !placeholder && (
              <label className="border-[1.5px] border-primary-1 rounded-lg text-primary-1 py-[7px] px-4 text-sm font-semibold cursor-pointer">
                {fileUploadLoading ? (
                  <Loader />
                ) : (
                  <>
                    Upload
                    <input
                      type="file"
                      id="file"
                      name={name}
                      accept={fileType}
                      onChange={handleFileInputChange}
                      {...rest}
                      style={{ display: "none" }}
                      disabled={disabled}
                    />
                  </>
                )}
              </label>
            )
          )}
        </div>
        {(errors || fileSizeError) && (
          <p className="mt-2 text-xs text-error-500 text-left">
            {errors || fileSizeError}
          </p>
        )}
      </div>
    </div>
  );
}
