import React from "react";
import PasswordForm from "./passwordForm";
import { useNavigate, useParams } from "react-router-dom";

export default function ResetPasswordForm({ type }) {
  const navigate = useNavigate();
  const { token } = useParams();

  const loginRoute = type === "admin" ? "/admin/login" : "/login";

  if (!token) navigate(loginRoute, { replace: true });

  return (
    <PasswordForm
      type="resetPassword"
      accountType={type}
      otherValues={{ token }}
    />
  );
}
