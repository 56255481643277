import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUser from "../../../../hooks/useUser";
import { USER_TYPE } from "../../../../utils/constants";

export default function useGasRequestForm(handleStageOneStep, type) {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { userRequest } = useSelector((state) => state.user);

  const { createGasRequest } = useUser();

  const [disableForm, setDisableForm] = useState(false);

  useEffect(() => {
    if (
      user?.role !== USER_TYPE.USER &&
      user?.role !== USER_TYPE.PROXY &&
      !userRequest
    )
      navigate("/admin/due-diligence");
    if (user?.role !== USER_TYPE.USER && user?.role !== USER_TYPE.PROXY)
      setDisableForm(true);
    else if (
      (user?.role === USER_TYPE.USER || user?.role === USER_TYPE.PROXY) &&
      !userRequest
    )
      setDisableForm(false);
    else if (
      (user?.role === USER_TYPE.USER || user?.role === USER_TYPE.PROXY) &&
      userRequest?.status === "pending"
    )
      setDisableForm(false);
    else setDisableForm(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role, userRequest]);

  const initialValues = {
    full_name:
      userRequest?.full_name || (type === "user" && user?.fullName) || "",
    email: userRequest?.email || (type === "user" && user?.email),
    phone_number:
      userRequest?.phone_number || (type === "user" && user?.phoneNumber) || "",
    company_name:
      userRequest?.company_name ||
      (type === "user" && user?.company_name) ||
      "",
    company_address:
      userRequest?.company_address ||
      (type === "user" && user?.company_address) ||
      "",

    project_type: userRequest?.project_type || "",
    project_description: userRequest?.project_description || "",
    daily_contract_volume: userRequest?.daily_contract_volume || "",
    project_location: userRequest?.project_location || "",
    first_gas_delivery_date: userRequest?.first_gas_delivery_date || "",
  };

  const handleSubmit = async (values, setSubmitting) => {
    const response = await createGasRequest({
      ...values,
    });
    if (response) {
      handleStageOneStep(2);
    }
    setSubmitting(false);
  };

  return { initialValues, handleSubmit, disableForm, userRequest };
}
