import CopyText from "../../../ui/copy";

export const Info = ({ title, content, icon, type }) => (
  <div className="flex gap-6 items-center py-4">
    <span className="text-grey-400"> {icon}</span>
    <div className="space-y-1">
      <p className="text-xs text-grey-500">{title}</p>
      {type === "link" ? (
        <a
          href={content}
          target="_blank"
          rel="noreferrer"
          className="text-sm font-medium break-all text-primary-1"
        >
          {content}
        </a>
      ) : (
        <p className="text-sm font-medium break-all text-grey-900">{content}</p>
      )}
    </div>
  </div>
);

export const CopyInfo = ({ title, content, icon, type }) => (
  <div className="flex gap-6 items-center justify-between ">
    <Info title={title} content={content} icon={icon} type={type} />
    <CopyText value={content} />
  </div>
);
