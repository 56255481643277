import React from "react";
import { useSelector } from "react-redux";
import Badge from "../../../../ui/badge";
import SiteInfo from "./siteInfo";
import Card from "../../../General/Card";

export default function SiteVisitDetails() {
  const { siteVisit } = useSelector((state) => state.user);

  return (
    <div className="flex flex-col md:flex-row gap-6 justify-center">
      <div className="w-full md:max-w-[432px]">
        <Card
          title="Site visit"
          badge={
            <Badge
              status={
                siteVisit?.status === "pending"
                  ? "awaiting_acceptance"
                  : siteVisit?.status === "awaiting"
                  ? "awaiting_visit"
                  : siteVisit?.status
              }
            />
          }
        >
          <SiteInfo details={siteVisit} />
        </Card>
      </div>
    </div>
  );
}
