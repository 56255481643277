import React, { useState } from "react";
import { useSelector } from "react-redux";
import SwitchUI from "../../../../ui/switch";
import Button from "../../../../ui/button";
import AcceptFormDialog from "./acceptFormDialog";
import RejectFormDialog from "./rejectFormDialog";
import MarkMeetingAsHeldDialog from "./markMeetingAsHeldDialog";
import MeetingDetails from "../../../General/Meeting/details";
import RequestForm from "../../../General/GasRequestForm";

export default function StageOne() {
  const { userRequest, meetingDetails } = useSelector((state) => state.user);

  const [isAcceptFormDialogOpen, setIsAcceptFormDialogOpen] = useState(false);
  const [isRejectFormDialogOpen, setIsRejectFormDialogOpen] = useState(false);
  const [isMeetingHeldDialogOpen, setIsMeetingHeldDialogOpen] = useState(false);
  const [isMeetingHeld, setIsMeetingHeld] = useState(
    meetingDetails?.status === "meeting_held" ? true : false
  );

  const toggleAcceptFormDialog = (value) => setIsAcceptFormDialogOpen(value);
  const toggleRejectFormDialog = (value) => setIsRejectFormDialogOpen(value);
  const toggleIsMeetingHeldDialog = (value) =>
    setIsMeetingHeldDialogOpen(value);

  return (
    <div className="flex flex-col lg:flex-row items-start gap-6">
      <MeetingDetails
        type="admin"
        adminSwitch={
          <div className="border-t border-grey-200 pt-6 flex items-center justify-between">
            <p className="w-full text-black">Mark meeting as held</p>
            <SwitchUI
              enabled={isMeetingHeld}
              setEnabled={setIsMeetingHeld}
              title="Mark meeting as held"
              toggleDialog={toggleIsMeetingHeldDialog}
              isDialogOpen={isMeetingHeldDialogOpen}
              type="meeting"
            />
          </div>
        }
      />
      <div className="lg:max-w-[604px] lg:w-[604px] w-full">
        <RequestForm type="admin" />
        {userRequest?.status !== "accepted" &&
          userRequest?.status !== "rejected" && (
            <div className="flex justify-end gap-6 w-full pt-16">
              <Button
                className="py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-error-1 disabled:!text-grey-300"
                onClick={() => toggleRejectFormDialog(true)}
                disabled={
                  userRequest?.status === "pending" ||
                  userRequest?.status === "awaiting"
                    ? true
                    : false
                }
              >
                Reject form
              </Button>
              <Button
                className="py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal "
                onClick={() => toggleAcceptFormDialog(true)}
                disabled={
                  userRequest?.status === "pending" ||
                  userRequest?.status === "awaiting"
                    ? true
                    : false
                }
              >
                Accept form
              </Button>
              <AcceptFormDialog
                isAcceptFormDialogOpen={isAcceptFormDialogOpen}
                toggleAcceptFormDialog={toggleAcceptFormDialog}
                companyName={userRequest?.company_name}
                companyId={userRequest?.userId}
              />
              <RejectFormDialog
                isRejectFormDialogOpen={isRejectFormDialogOpen}
                toggleRejectFormDialog={toggleRejectFormDialog}
                companyName={userRequest?.company_name}
                companyId={userRequest?.userId}
              />
            </div>
          )}
      </div>

      {meetingDetails?.status !== "meeting_held" && (
        <MarkMeetingAsHeldDialog
          toggleIsMeetingHeldDialog={toggleIsMeetingHeldDialog}
          isMeetingHeldDialogOpen={isMeetingHeldDialogOpen}
          companyId={userRequest?.userId}
          setIsMeetingHeld={setIsMeetingHeld}
        />
      )}
    </div>
  );
}
