import React from "react";

export default function CardHeader({ title, badge }) {
  return (
    <div className="border-b border-grey-10 pb-4 flex justify-between">
      <p className="text-black leading-[23.2px] font-semibold">{title}</p>
      {badge && badge}
    </div>
  );
}
