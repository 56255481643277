import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useQuestionnaire from "../useQuestionnaire";
import useUser from "../../../../../hooks/useUser";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import AdminComment from "../../../../ui/adminComment";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import {
  PROJECT_TYPE,
  STAGE_FOUR_STATUS,
  USER_TYPE,
} from "../../../../../utils/constants";

export default function Extras({
  handleStageFourStep,
  handleStageFourTabClick,
}) {
  const [loading, setLoading] = useState(false);

  const { extra, submitQuestionnaire, approveQuestionnaire } = useUser();

  const { user } = useSelector((state) => state.auth);
  const { currentUserId } = useSelector((state) => state.admin);
  const { questionnaire, project_type } = useSelector((state) => state.user);

  const {
    disableForm,
    canAdminComment,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    handleSubmitAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useQuestionnaire();

  return (
    <Formik
      initialValues={{
        additionalNotes:
          questionnaire?.Extras?.additionalNotes?.userResponse || "",
        additionalDocumentation:
          questionnaire?.Extras?.additionalDocumentation?.userResponse || "",
      }}
      onSubmit={() => {}}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          dirty,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setSubmitting,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <div className="py-8 space-y-3">
              <h6 className="font-semibold text-black">Section 6: Extras</h6>
              <p className="text-sm">
                Use this section to provide any additional details
              </p>
            </div>
            <FormContainer width type="user" title="">
              <>
                <SectionHeader title="Additional Notes (optional)" />
                <AdminComment
                  tabName="adminUpdateExtra"
                  name="additionalNotes"
                  adminComment={adminComments?.extras?.additionalNotes || ""}
                  isAdminCommentAvailable={
                    adminComments?.extras?.additionalNotes ? true : false
                  }
                  canAdminComment={canAdminComment && questionnaire?.Extras}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="extras"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="additionalNotes"
                    id="additionalNotes"
                    value={values.additionalNotes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.additionalNotes && touched.additionalNotes
                        ? errors.additionalNotes
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.extras?.additionalNotes &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader title="Additional Documentation (optional)" />
                <AdminComment
                  tabName="adminUpdateExtra"
                  name="additionalDocumentation"
                  adminComment={
                    adminComments?.extras?.additionalDocumentation || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.extras?.additionalDocumentation
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment && questionnaire?.Extras}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="extras"
                >
                  <FileUpload
                    name="additionalDocumentation"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.Extras
                        ? values.additionalDocumentation
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.extras?.additionalDocumentation &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            {(user?.role === USER_TYPE.USER ||
              user?.role === USER_TYPE.PROXY) &&
            (!questionnaire ||
              questionnaire?.status === STAGE_FOUR_STATUS.PENDING ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) ? (
              <div className="flex justify-between w-full pt-16">
                <button
                  onClick={() => handleStageFourTabClick(4)}
                  className="text-primary-1 uppercase text-sm font-bold leading-[16.8px]"
                >
                  Previous
                </button>
                <div className="flex justify-end gap-6 ">
                  <Button
                    disabled={!isValid || isSubmitting || !dirty}
                    onClick={async () => {
                      const res = await extra(
                        project_type === PROJECT_TYPE.POWER
                          ? PROJECT_TYPE.POWER
                          : PROJECT_TYPE.COMMGAS,
                        values,
                        project_type === PROJECT_TYPE.GAS
                          ? PROJECT_TYPE.GAS
                          : PROJECT_TYPE.COMM
                      );
                      if (res) toast.success("Progress saved successfully");
                      setSubmitting(false);
                    }}
                    loading={isSubmitting}
                    className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                  >
                    Save progress
                  </Button>
                  <Button
                    onClick={async () => {
                      setLoading(true);
                      const res = await submitQuestionnaire(
                        STAGE_FOUR_STATUS.AWAITING,
                        project_type === PROJECT_TYPE.POWER
                          ? PROJECT_TYPE.POWER
                          : PROJECT_TYPE.COMMGAS,
                        project_type === PROJECT_TYPE.GAS
                          ? PROJECT_TYPE.GAS
                          : PROJECT_TYPE.COMM
                      );
                      if (res) handleStageFourStep(2);
                      setLoading(false);
                    }}
                    disabled={loading || dirty}
                    className="py-4 px-6 self-end"
                    loading={loading}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end gap-6 w-full pt-16">
                <Button
                  disabled={isCommentNonNull}
                  onClick={() => handleStageFourTabClick(4)}
                  className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                >
                  {Icons.ic_arrow_left} Previous
                </Button>
                {user?.role === USER_TYPE.USER ||
                user?.role === USER_TYPE.PROXY ? (
                  <Button
                    disabled
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    Next {Icons.ic_arrow_right}
                  </Button>
                ) : (
                  (user?.role === USER_TYPE.ADMIN ||
                    user?.role === USER_TYPE.SUPER_ADMIN) &&
                  (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
                    questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
                    <div className="flex gap-6">
                      {isCommentNonNull ? (
                        <Button
                          disabled={loadingAddComments}
                          onClick={() => {
                            handleAddAdminComment(
                              adminComments.extras,
                              "adminUpdateExtra"
                            );
                          }}
                          loading={loadingAddComments}
                          className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal"
                        >
                          Save Comment
                        </Button>
                      ) : (
                        <Button
                          disabled={loadingAddComments}
                          onClick={() =>
                            handleSubmitAdminComment(
                              project_type === PROJECT_TYPE.POWER
                                ? "submitAdminCommentPower"
                                : "submitAdminCommentCommGas"
                            )
                          }
                          loading={loadingAddComments}
                          className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                        >
                          Submit Comment
                        </Button>
                      )}
                      {questionnaire?.status === STAGE_FOUR_STATUS.AWAITING && (
                        <Button
                          onClick={async () => {
                            setLoading(true);
                            await approveQuestionnaire(
                              { status: STAGE_FOUR_STATUS.ACCEPTED },
                              project_type === PROJECT_TYPE.POWER
                                ? PROJECT_TYPE.POWER
                                : PROJECT_TYPE.COMMGAS,
                              project_type === PROJECT_TYPE.GAS
                                ? PROJECT_TYPE.GAS
                                : PROJECT_TYPE.COMM,
                              currentUserId
                            );

                            setLoading(false);
                          }}
                          loading={loading}
                          disabled={
                            loading ||
                            questionnaire?.status === "accepted" ||
                            isCommentNonNull
                          }
                          className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                        >
                          Approve Questionnaire
                        </Button>
                      )}
                    </div>
                  )
                )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
