import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../ui/button";
import Dialog from "../ui/dialog";
import {
  logout,
  setDisplaySessionTimeoutDialog,
} from "../../config/reducers/authSlice";
import { USER_TYPE } from "../../utils/constants";

function SessionTimeoutDialog({ isSessionTimeoutDialogOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user_type = localStorage.getItem("user_type");

  const type =
    user_type === USER_TYPE.USER || user_type === USER_TYPE.PROXY
      ? USER_TYPE.USER
      : USER_TYPE.ADMIN;

  const handleCloseDialog = () => {
    dispatch(logout({}));
    dispatch(setDisplaySessionTimeoutDialog(false));
    navigate(type === "admin" ? "/admin/login" : "/login");
  };

  return (
    <Dialog isDialogModalOpen={isSessionTimeoutDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[448px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Session Timeout</h2>
          <p>
            Your session has timed out due to inactivity. To continue, please
            log in again.
          </p>
        </div>
        <Button className="mx-auto block" onClick={handleCloseDialog}>
          Login
        </Button>
      </div>
    </Dialog>
  );
}

export default SessionTimeoutDialog;
