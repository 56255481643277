import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { initialize } from "../../config/reducers/authSlice";
import useUser from "../../hooks/useUser";
import Loader from "../ui/loader";
import { USER_TYPE } from "../../utils/constants";

function AdminDashboardSessionProvider({ children }) {
  const { isAuthenticated, isInitialized, user } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { fetchUserData } = useUser();
  const token = localStorage.getItem("refresh-token");
  const [requestedLocation, setRequestedLocation] = useState("");

  const initializeApp = async () => {
    if (!token) dispatch(initialize({ isAuthenticated: false, user: null }));
    else fetchUserData();
  };

  useEffect(() => {
    initializeApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);

  if (!isInitialized) {
    return <Loader fullpage />;
  }

  if (!isAuthenticated && !token) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={"/admin/login"} replace />;
  }

  if (
    isInitialized &&
    user &&
    user?.role === USER_TYPE.USER &&
    user?.role === USER_TYPE.PROXY
  ) {
    return <Navigate to={"/due-diligence"} replace />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);

    return <Navigate to={requestedLocation} replace />;
  }

  return <>{children}</>;
}

export default AdminDashboardSessionProvider;
