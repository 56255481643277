import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import FileUpload from "../../../../ui/fileUpload";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FinalizeBuyerDialog from "./finalizeBuyerDialog";
import Badge from "../../../../ui/badge";
import AdminComment from "../../../../ui/adminComment";
import Card from "../../../General/Card";
import { USER_TYPE } from "../../../../../utils/constants";
import useUser from "../../../../../hooks/useUser";

const SectionHeader = ({ title }) => (
  <p className="text-sm font-semibold text-black pt-8">{title}</p>
);

export default function UserResponse({ handleStageSevenStep, id }) {
  const { addFinalizeAdminComment, acceptFinalizeDocument } = useUser();

  const { currentUserId } = useSelector((state) => state.admin);
  const { userRequest, finalize } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);

  const detailsForComment = finalize?.details.map((obj) => {
    const { approved, userComment, userDocument, ...rest } = obj;
    return rest;
  });

  const [adminComments, setAdminComments] = useState(detailsForComment);
  const [isCommentNonNull, setIsCommentNonNull] = useState(false);
  const [loadingAddComments, setLoadingAddComments] = useState(false);
  const [loadingApproveDocument, setLoadingApproveDocument] = useState(false);

  const [isFinalizeBuyerDialogOpen, setIsFinalizeBuyerDialogOpen] =
    useState(false);

  const toggleIsFinalizeBuyerDialog = (value) =>
    setIsFinalizeBuyerDialogOpen(value);

  return (
    <Card
      title="Supplementary information"
      description="List out the questions and document you require this buyer to provider."
      badge={
        <Badge
          status={
            finalize?.status !== "approved"
              ? "update_required"
              : finalize?.status
          }
        />
      }
    >
      <div className="flex flex-col gap-6">
        {adminComments.map((detail, index) => (
          <Fragment key={index}>
            <SectionHeader title={finalize?.details[index].detailTitle} />

            {finalize?.details[index]?.detailDocumentRequest ? (
              <AdminComment
                detailId={finalize?.details[index]?._id}
                name={`details[${index}].adminNote`}
                adminComment={detail?.adminNote || ""}
                isAdminCommentAvailable={detail?.adminNote ? true : false}
                canAdminComment={
                  user?.role !== USER_TYPE.USER &&
                  user?.role !== USER_TYPE.PROXY
                    ? true
                    : false
                }
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="finalize"
                itemIndex={index}
              >
                <FileUpload
                  name={`details[${index}].adminNote`}
                  fileType=".pdf, .xls, .xlsx"
                  format="PDF or Excel format"
                  value={
                    finalize?.details[index]?.userDocument
                      ? finalize?.details[index]?.userDocument
                      : ""
                  }
                  disabled
                />
              </AdminComment>
            ) : (
              <AdminComment
                detailId={finalize?.details[index]?._id}
                name={`details[${index}].adminNote`}
                adminComment={detail?.adminNote || ""}
                isAdminCommentAvailable={detail?.adminNote ? true : false}
                canAdminComment={
                  user?.role !== USER_TYPE.USER &&
                  user?.role !== USER_TYPE.PROXY
                    ? true
                    : false
                }
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="finalize"
                itemIndex={index}
              >
                <Textarea
                  placeholder="Enter description here"
                  name={`details[${index}].userComment`}
                  id={`details[${index}].userComment`}
                  value={finalize?.details[index]?.userComment}
                  disabled
                />
              </AdminComment>
            )}
          </Fragment>
        ))}
        {finalize?.status === "awaiting" && (
          <div className="flex justify-end gap-6 p-4 mt-9 -mx-6 border-t border-grey-200">
            <Button
              onClick={async () => {
                setLoadingAddComments(true);
                await addFinalizeAdminComment(
                  { details: adminComments },
                  currentUserId
                );
                setLoadingAddComments(false);
                setIsCommentNonNull(false);
              }}
              loading={loadingAddComments}
              disabled={loadingAddComments || !isCommentNonNull}
              className="bg-transparent border border-grey-200 !text-black px-3 py-2 h-fit capitalize w-fit !tracking-[0] disabled:opacity-50"
            >
              submit comment
            </Button>
            <Button
              disabled={isCommentNonNull || loadingApproveDocument}
              className="px-3 py-2 h-fit capitalize w-fit !tracking-[0] disabled:opacity-50"
              onClick={async () => {
                setLoadingApproveDocument(true);
                const res = await acceptFinalizeDocument(currentUserId);
                if (res) handleStageSevenStep(1);
                setLoadingApproveDocument(false);
              }}
              loading={loadingApproveDocument}
            >
              Approve Document
            </Button>
          </div>
        )}
      </div>

      <FinalizeBuyerDialog
        handleStageSevenStep={handleStageSevenStep}
        isFinalizeBuyerDialogOpen={isFinalizeBuyerDialogOpen}
        toggleIsFinalizeBuyerDialog={toggleIsFinalizeBuyerDialog}
        companyId={userRequest?.userId}
      />
    </Card>
  );
}
