import React, { useEffect } from "react";
import useUser from "../../../hooks/useUser";
import Icons from "../../../assets/icons";
import Input from "../../ui/input";
import { itemsPerPage } from "../../../utils/constants";
import { useSelector } from "react-redux";

export default function RequestHeader({ activeTab, searchText, handleSearch }) {
  const { getAllRequests } = useUser();
  const { currentRequestPage } = useSelector((state) => state.admin);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getAllRequests(activeTab, searchText, itemsPerPage, currentRequestPage);
    }, 500);

    return () => clearTimeout(debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, activeTab]);

  return (
    <div className="p-4 md:p-6 flex flex-col lg:flex-row lg:justify-between lg:items-center gap-4 bg-white border md:border-none rounded-t-lg md:rounded-none mb-4 md:mb-0">
      <p className="text-base">Overview of Buyer Due Diligence Progress</p>
      <div className="flex items-center gap-6">
        <Input
          type="search"
          name="search"
          id="search"
          placeholder="Search here..."
          icon={Icons.ic_search}
          onChange={(e) => handleSearch(e.target.value)}
          className="lg:max-w-[240px]"
        />
      </div>
    </div>
  );
}
