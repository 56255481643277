import React from "react";
import { Form, Formik, getIn } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { siteVisitCommercialValidationSchema } from "../../../../../utils/validation";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import useSiteVisitQuestionnaire from "../useSiteVisitQuestionnaire";
import Radio from "../../../../ui/radio";
import AdminComment from "../../../../ui/adminComment";
import { USER_TYPE } from "../../../../../utils/constants";

export default function CommercialSiteVisitQuestionnaire({
  handleStageSixStep,
}) {
  const { user } = useSelector((state) => state.auth);
  const { currentUserId } = useSelector((state) => state.admin);

  const {
    disableForm,
    loading,
    initialValues,
    handleSaveSiteVisitQuestionnaire,
    handleApproveSiteVisitQuestionnaire,
    status,
    siteVisitQuestionnaire,
    siteVisit,

    canAdminComment,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useSiteVisitQuestionnaire(handleStageSixStep);

  return (
    <Formik
      initialValues={initialValues.commercial}
      validationSchema={siteVisitCommercialValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <FormContainer
              type="user"
              width
              title="1. Site Specification and Proximity Inquiry"
            >
              <>
                <SectionHeader title="Layout map" />
                <AdminComment
                  name="layoutMap"
                  adminComment={adminComments?.commercial.layoutMap || ""}
                  isAdminCommentAvailable={
                    adminComments?.commercial.layoutMap ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <FileUpload
                    name="layoutMap"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={values.layoutMap}
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.layoutMap && touched.layoutMap
                        ? errors.layoutMap
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.layoutMap &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Land size showing your coordinates"
                />
                <AdminComment
                  name="landSizeDoc"
                  adminComment={adminComments?.commercial.landSizeDoc || ""}
                  isAdminCommentAvailable={
                    adminComments?.commercial.landSizeDoc ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <FileUpload
                    name="landSizeDoc"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={values.landSizeDoc}
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.landSizeDoc && touched.landSizeDoc
                        ? errors.landSizeDoc
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.landSizeDoc &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Proximity to gas suppliers (processing plant, pipeline, or metering station)*"
                />
                <AdminComment
                  name="proximityToGasSupplier"
                  adminComment={
                    adminComments?.commercial.proximityToGasSupplier || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.proximityToGasSupplier
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="proximityToGasSupplier"
                    id="proximityToGasSupplier"
                    value={values.proximityToGasSupplier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.proximityToGasSupplier &&
                      touched.proximityToGasSupplier
                        ? errors.proximityToGasSupplier
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.proximityToGasSupplier &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              type="user"
              width
              title="2. Project Infrastructure and Planning"
            >
              <>
                <SectionHeader title="Can you show us your gas inlet point, indicating the distance to the pipeline tie-in location? Also, provide detailed gas Transportation arrangements." />
                <AdminComment
                  name="projectPlan.gasInletPoint"
                  adminComment={
                    adminComments?.commercial.projectPlan.gasInletPoint || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.gasInletPoint
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.gasInletPoint"
                    id="projectPlan.gasInletPoint"
                    value={values.projectPlan.gasInletPoint}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, `projectPlan.gasInletPoint`) &&
                          getIn(touched, `projectPlan.gasInletPoint`)
                      )
                        ? getIn(errors, `projectPlan.gasInletPoint`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan.gasInletPoint &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="projectPlan.gasInletPointFile"
                  adminComment={
                    adminComments?.commercial.projectPlan.gasInletPointFile ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.gasInletPointFile
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <FileUpload
                    name="projectPlan.gasInletPointFile"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={values.projectPlan.gasInletPointFile}
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    errors={
                      Boolean(
                        getIn(errors, `projectPlan.gasInletPointFile`) &&
                          getIn(touched, `projectPlan.gasInletPointFile`)
                      )
                        ? getIn(errors, `projectPlan.gasInletPointFile`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan
                        .gasInletPointFile &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Where will the gas generators and other facilities be located? If applicable?*"
                />
                <AdminComment
                  name="projectPlan.gasGeneratorLocation"
                  adminComment={
                    adminComments?.commercial.projectPlan
                      .gasGeneratorLocation || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.gasGeneratorLocation
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.gasGeneratorLocation"
                    id="projectPlan.gasGeneratorLocation"
                    value={values.projectPlan.gasGeneratorLocation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.gasGeneratorLocation") &&
                          getIn(touched, "projectPlan.gasGeneratorLocation")
                      )
                        ? getIn(errors, "projectPlan.gasGeneratorLocation")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan
                        .gasGeneratorLocation &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Road Access: Can you confirm your road network (Internal and External access roads)*"
                />
                <AdminComment
                  name="projectPlan.roadAccess"
                  adminComment={
                    adminComments?.commercial.projectPlan.roadAccess || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.roadAccess
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.roadAccess"
                    id="projectPlan.roadAccess"
                    value={values.projectPlan.roadAccess}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.roadAccess") &&
                          getIn(touched, "projectPlan.roadAccess")
                      )
                        ? getIn(errors, "projectPlan.roadAccess")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan.roadAccess &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="In case of gas unavailability issues, what alternative fuel are you considering and what is the storage plan of the alternative Fuel? (In-facility storage) How would you switch from gas to an alternative?*"
                />
                <AdminComment
                  name="projectPlan.alternativeFuel"
                  adminComment={
                    adminComments?.commercial.projectPlan.alternativeFuel || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.alternativeFuel
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.alternativeFuel"
                    id="projectPlan.alternativeFuel"
                    value={values.projectPlan.alternativeFuel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.alternativeFuel") &&
                          getIn(touched, "projectPlan.alternativeFuel")
                      )
                        ? getIn(errors, "projectPlan.alternativeFuel")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan.alternativeFuel &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="What is your ancillary system that might require gas for your proposed project?*"
                />
                <AdminComment
                  name="projectPlan.ancillarySystem"
                  adminComment={
                    adminComments?.commercial.projectPlan.ancillarySystem || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.ancillarySystem
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.ancillarySystem"
                    id="projectPlan.ancillarySystem"
                    value={values.projectPlan.ancillarySystem}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.ancillarySystem") &&
                          getIn(touched, "projectPlan.ancillarySystem")
                      )
                        ? getIn(errors, "projectPlan.ancillarySystem")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan.ancillarySystem &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Do you have an expansion plan? If yes, what is the plan and when will you commence the expansion?*"
                />
                <AdminComment
                  name="projectPlan.expansionPlan"
                  adminComment={
                    adminComments?.commercial.projectPlan.expansionPlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.expansionPlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.expansionPlan"
                    id="projectPlan.expansionPlan"
                    value={values.projectPlan.expansionPlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.expansionPlan") &&
                          getIn(touched, "projectPlan.expansionPlan")
                      )
                        ? getIn(errors, "projectPlan.expansionPlan")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan.expansionPlan &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Where will your meter be located and what type of metering system will you use for the project?*"
                />
                <AdminComment
                  name="projectPlan.meterLocation"
                  adminComment={
                    adminComments?.commercial.projectPlan.meterLocation || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.meterLocation
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.meterLocation"
                    id="projectPlan.meterLocation"
                    value={values.projectPlan.meterLocation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.meterLocation") &&
                          getIn(touched, "projectPlan.meterLocation")
                      )
                        ? getIn(errors, "projectPlan.meterLocation")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan.meterLocation &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Is your project phased, and what is your construction/completion timeline?*"
                />
                <AdminComment
                  name="projectPlan.constructionTimeline"
                  adminComment={
                    adminComments?.commercial.projectPlan
                      .constructionTimeline || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.constructionTimeline
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.constructionTimeline"
                    id="projectPlan.constructionTimeline"
                    value={values.projectPlan.constructionTimeline}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.constructionTimeline") &&
                          getIn(touched, "projectPlan.constructionTimeline")
                      )
                        ? getIn(errors, "projectPlan.constructionTimeline")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan
                        .constructionTimeline &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="What is your mode of evacuation?*"
                />
                <AdminComment
                  name="projectPlan.modeOfEvacuation"
                  adminComment={
                    adminComments?.commercial.projectPlan.modeOfEvacuation || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.modeOfEvacuation
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.modeOfEvacuation"
                    id="projectPlan.modeOfEvacuation"
                    value={values.projectPlan.modeOfEvacuation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.modeOfEvacuation") &&
                          getIn(touched, "projectPlan.modeOfEvacuation")
                      )
                        ? getIn(errors, "projectPlan.modeOfEvacuation")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan
                        .modeOfEvacuation &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Will you be delivering the Product to the customer’s facility or will the customer be providing their own transportation?*"
                />
                <AdminComment
                  name="projectPlan.productTransport"
                  adminComment={
                    adminComments?.commercial.projectPlan.productTransport || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.projectPlan.productTransport
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectPlan.productTransport"
                    id="projectPlan.productTransport"
                    value={values.projectPlan.productTransport}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, "projectPlan.productTransport") &&
                          getIn(touched, "projectPlan.productTransport")
                      )
                        ? getIn(errors, "projectPlan.productTransport")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.projectPlan
                        .productTransport &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              type="user"
              width
              title="3. Environmental and Safety Considerations"
            >
              <Radio
                options={[
                  { title: "Yes", value: "Yes" },
                  { title: "No", value: "No" },
                ]}
                value={values.safetyConsiderations.EIA}
                onChange={handleChange}
                name="safetyConsiderations.EIA"
                title="Has an Environmental Impact Assessment (EIA) been conducted for this project? If so, could you provide us with the report?*"
                errors={
                  Boolean(
                    getIn(errors, `safetyConsiderations.EIA`) &&
                      getIn(touched, `safetyConsiderations.EIA`)
                  )
                    ? getIn(errors, `safetyConsiderations.EIA`)
                    : ""
                }
                disabled={
                  disableForm ||
                  (!adminComments?.commercial.safetyConsiderations.EIA &&
                    siteVisitQuestionnaire?.status === "feedback")
                }
              />
              {values.safetyConsiderations.EIA === "Yes" && (
                <AdminComment
                  name="safetyConsiderations.EIAReport"
                  adminComment={
                    adminComments?.commercial.safetyConsiderations.EIAReport ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.safetyConsiderations.EIAReport
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <FileUpload
                    name="safetyConsiderations.EIAReport"
                    id="safetyConsiderations.EIAReport"
                    fileType=".pdf, .doc, .docx, .dotx"
                    format="PDF or Doc format"
                    value={values.safetyConsiderations.EIAReport}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    onBlur={handleBlur}
                    required
                    errors={
                      Boolean(
                        getIn(errors, `safetyConsiderations.EIAReport`) &&
                          getIn(touched, `safetyConsiderations.EIAReport`)
                      )
                        ? getIn(errors, `safetyConsiderations.EIAReport`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.safetyConsiderations
                        .EIAReport &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              )}
              <>
                <SectionHeader
                  border
                  title="Can you provide a detailed explanation of your emergency shutdown plan in case of fire and any emergencies?*"
                />
                <AdminComment
                  name="safetyConsiderations.emergencyShutdownPlan"
                  adminComment={
                    adminComments?.commercial.safetyConsiderations
                      .emergencyShutdownPlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.safetyConsiderations
                      .emergencyShutdownPlan
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="safetyConsiderations.emergencyShutdownPlan"
                    id="safetyConsiderations.emergencyShutdownPlan"
                    value={values.safetyConsiderations.emergencyShutdownPlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          "safetyConsiderations.emergencyShutdownPlan"
                        ) &&
                          getIn(
                            touched,
                            "safetyConsiderations.emergencyShutdownPlan"
                          )
                      )
                        ? getIn(
                            errors,
                            "safetyConsiderations.emergencyShutdownPlan"
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.safetyConsiderations
                        .emergencyShutdownPlan &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border />
                <AdminComment
                  name="safetyConsiderations.workCommenced"
                  adminComment={
                    adminComments?.commercial.safetyConsiderations
                      .workCommenced || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.safetyConsiderations.workCommenced
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.safetyConsiderations.workCommenced}
                    onChange={handleChange}
                    name="safetyConsiderations.workCommenced"
                    title="Observe if any early work has commenced on the project site?*"
                    errors={
                      Boolean(
                        getIn(errors, `safetyConsiderations.workCommenced`) &&
                          getIn(touched, `safetyConsiderations.workCommenced`)
                      )
                        ? getIn(errors, `safetyConsiderations.workCommenced`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.safetyConsiderations
                        .workCommenced &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              type="user"
              width
              title="4. Market Engagement and Employment"
            >
              <>
                <SectionHeader title="What is your market track record? Have you done this type of project before?*" />
                <AdminComment
                  name="marketExperience.ancillarySysmarketTrackRecordtem"
                  adminComment={
                    adminComments?.commercial.marketExperience
                      .ancillarySysmarketTrackRecordtem || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.marketExperience
                      .ancillarySysmarketTrackRecordtem
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.ancillarySysmarketTrackRecordtem"
                    id="marketExperience.ancillarySysmarketTrackRecordtem"
                    value={
                      values.marketExperience.ancillarySysmarketTrackRecordtem
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `marketExperience.ancillarySysmarketTrackRecordtem`
                        ) &&
                          getIn(
                            touched,
                            `marketExperience.ancillarySysmarketTrackRecordtem`
                          )
                      )
                        ? getIn(
                            errors,
                            `marketExperience.ancillarySysmarketTrackRecordtem`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.marketExperience
                        .ancillarySysmarketTrackRecordtem &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader border />
                <AdminComment
                  name="marketExperience.workCommenced"
                  adminComment={
                    adminComments?.commercial.marketExperience.workCommenced ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.marketExperience.workCommenced
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.marketExperience.workCommenced}
                    onChange={handleChange}
                    name="marketExperience.workCommenced"
                    title="Has any early work commenced on the project site?*"
                    errors={
                      Boolean(
                        getIn(errors, `marketExperience.workCommenced`) &&
                          getIn(touched, `marketExperience.workCommenced`)
                      )
                        ? getIn(errors, `marketExperience.workCommenced`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.marketExperience
                        .workCommenced &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Can you confirm the employment opportunities your project will generate for locals and others and the category of the Jobs?*"
                />
                <AdminComment
                  name="marketExperience.employmentOpportunities"
                  adminComment={
                    adminComments?.commercial.marketExperience
                      .employmentOpportunities || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.marketExperience
                      .employmentOpportunities
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.employmentOpportunities"
                    id="marketExperience.employmentOpportunities"
                    value={values.marketExperience.employmentOpportunities}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `marketExperience.employmentOpportunities`
                        ) &&
                          getIn(
                            touched,
                            `marketExperience.employmentOpportunities`
                          )
                      )
                        ? getIn(
                            errors,
                            `marketExperience.employmentOpportunities`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.marketExperience
                        .employmentOpportunities &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader border />
                <AdminComment
                  name="marketExperience.offtakeAgreement"
                  adminComment={
                    adminComments?.commercial.marketExperience
                      .offtakeAgreement || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.marketExperience.offtakeAgreement
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.marketExperience.offtakeAgreement}
                    onChange={handleChange}
                    name="marketExperience.offtakeAgreement"
                    title="Do you have a direct off-take agreement with off-takers?*"
                    errors={
                      Boolean(
                        getIn(errors, `marketExperience.offtakeAgreement`) &&
                          getIn(touched, `marketExperience.offtakeAgreement`)
                      )
                        ? getIn(errors, `marketExperience.offtakeAgreement`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.marketExperience
                        .offtakeAgreement &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="What is the minimum and maximum gas supply capacity that you can provide to a third-party facility?*"
                />
                <AdminComment
                  name="marketExperience.gasSupplyCapcity"
                  adminComment={
                    adminComments?.commercial.marketExperience
                      .gasSupplyCapcity || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercial.marketExperience.gasSupplyCapcity
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="commercial"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketExperience.gasSupplyCapcity"
                    id="marketExperience.gasSupplyCapcity"
                    value={values.marketExperience.gasSupplyCapcity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      Boolean(
                        getIn(errors, `marketExperience.gasSupplyCapcity`) &&
                          getIn(touched, `marketExperience.gasSupplyCapcity`)
                      )
                        ? getIn(errors, `marketExperience.gasSupplyCapcity`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercial.marketExperience
                        .gasSupplyCapcity &&
                        siteVisitQuestionnaire?.status === "feedback")
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            {user?.role === USER_TYPE.USER || user?.role === USER_TYPE.PROXY
              ? (!siteVisitQuestionnaire ||
                  siteVisitQuestionnaire?.status === "pending" ||
                  siteVisitQuestionnaire?.status === "feedback") && (
                  <div className="flex justify-end gap-6 pt-16">
                    {/* <Button
                      disabled={isSubmitting}
                      onClick={() => {
                        setErrors({});
                        handleSaveSiteVisitQuestionnaire(
                          "comm",
                          values,
                          setSubmitting
                        );
                        const res = validateForm();
                        setErrors({ ...res });
                      }}
                      loading={isSubmitting}
                      className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                    >
                      Save progress
                    </Button> */}
                    <Button
                      onClick={async () => {
                        setSubmitting(false);
                        const res = await validateForm();
                        if (Object.keys(res).length !== 0)
                          toast.error("Please fill all the required fields");
                        else
                          handleSaveSiteVisitQuestionnaire(
                            "comm",
                            values,
                            setSubmitting
                          );
                      }}
                      disabled={!isValid || isSubmitting}
                      className="py-4 px-6 self-end"
                      loading={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                )
              : status !== "approved" && (
                  <div className="flex justify-end gap-6 w-full pt-6">
                    <Button
                      onClick={() =>
                        handleAddAdminComment(
                          adminComments.commercial,
                          "adminUpdateComm"
                        )
                      }
                      disabled={loadingAddComments || !isCommentNonNull}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700"
                      loading={loadingAddComments}
                    >
                      Submit Comment
                    </Button>
                    <Button
                      onClick={() =>
                        handleApproveSiteVisitQuestionnaire(currentUserId)
                      }
                      disabled={
                        loading ||
                        status === "feedback" ||
                        isCommentNonNull ||
                        siteVisit?.status !== "meeting_held"
                      }
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                      loading={loading}
                    >
                      Approve Questionnaire
                    </Button>
                  </div>
                )}
          </Form>
        );
      }}
    </Formik>
  );
}
