import React, { useState } from "react";
import Button from "../../ui/button";
import Dialog from "../../ui/dialog";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../../utils/constants";

function DeleteUserDialog({
  toggleDeleteUserDialog,
  isDeleteUserDialogOpen,
  userTobeDeleted,
  team,
  role,
  type,
  from,
}) {
  const navigate = useNavigate();
  const { deleteUser, deleteTeamMember } = useUser();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  return (
    <Dialog isDialogModalOpen={isDeleteUserDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Confirm Removal</h2>
          <p>Are you sure you want to remove this {role}?</p>
          <p>
            This action cannot be undone. The removed {role} will lose access to
            all {role} privileges and functions immediately.
          </p>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleDeleteUserDialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              const res =
                type === USER_TYPE.PROXY
                  ? await deleteTeamMember(userTobeDeleted, team)
                  : await deleteUser(userTobeDeleted, team, role);
              // if userTobeDeleted === logged in user ID... clear localStorage and redirect user to /admin/login
              if (res) {
                toggleDeleteUserDialog(false);
                if (
                  user?._id === userTobeDeleted ||
                  user?.superuser_id === userTobeDeleted
                ) {
                  localStorage.clear();
                  navigate("/admin/login");
                } else from === "/due-diligence" && navigate(from);
              }

              setLoading(false);
            }}
            disabled={loading}
            className="!bg-error-1"
            loading={loading}
          >
            remove
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteUserDialog;
