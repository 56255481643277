import React, { useState } from "react";
import { useSelector } from "react-redux";
import FinalizeStatus from "./status";
import AdditionalDocument from "./additionalDocument";
import useFinalize from "./useFinalize";
import Loader from "../../../../ui/loader";
import UserResponse from "./userResponse";

export default function StageSeven() {
  const { loading, id } = useFinalize();

  const { finalize } = useSelector((state) => state.user);

  const [stageSevenStep, setStageSevenStep] = useState(
    finalize?.status === "awaiting" ? 3 : finalize?.status === "pending" ? 2 : 1
  );

  const handleStageSevenStep = (value) => setStageSevenStep(value);

  return loading ? (
    <Loader />
  ) : stageSevenStep === 3 ? (
    <UserResponse handleStageSevenStep={handleStageSevenStep} id={id} />
  ) : stageSevenStep === 2 ? (
    <AdditionalDocument handleStageSevenStep={handleStageSevenStep} id={id} />
  ) : (
    <FinalizeStatus handleStageSevenStep={handleStageSevenStep} />
  );
}
