import Modal from "../../../ui/modal";
import AddTeamForm from './addTeamForm'

function AddTeamModal({
  toggleAddTeamModal,
  isAddTeamModalOpen,
  setEmail,
  toggleInviteSentDialog,
  createTeamMember,
}) {
  return (
    <Modal
      isModalOpen={isAddTeamModalOpen}
      onClick={() => toggleAddTeamModal(false)}
      width="max-w-[400px]"
    >
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center">
          <h2 className="text-h2 text-black">Add new team member</h2>
          <p className="text-sm">
            Complete the form to send an invitation email to the new member.
            They will be prompted to complete their registration.
          </p>
        </div>
        <AddTeamForm
          toggleAddTeamModal={toggleAddTeamModal}
          setEmail={setEmail}
          toggleInviteSentDialog={toggleInviteSentDialog}
          createTeamMember={createTeamMember}
        />
      </div>
    </Modal>
  );
}

export default AddTeamModal;
