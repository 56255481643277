import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../config/reducers/adminSlice";

export default function useRequests() {
  const { requests, loading } = useSelector((state) => state.admin);
  const [searchRequestText, setSearchRequestText] = useState("");
  const dispatch = useDispatch();

  const handleSearch = (value) => setSearchRequestText(value);

  useEffect(() => {
    dispatch(setLoading({ loading: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { loading, requests, searchRequestText, handleSearch };
}
