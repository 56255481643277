import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Icons from "../../assets/icons";
import { toast } from "react-toastify";

export default function CopyText({ value }) {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) toast.success("Copied");
  }, [isCopied]);

  return (
    <CopyToClipboard text={value} onCopy={() => setIsCopied(true)}>
      <button className="text-grey-400"> {Icons.ic_copy}</button>
    </CopyToClipboard>
  );
}
