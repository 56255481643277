import { Form, Formik, FieldArray, getIn } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { commercialPowerValidationSchema } from "../../../../../utils/validation";
import useQuestionnaire from "../useQuestionnaire";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import AdminComment from "../../../../ui/adminComment";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import { STAGE_FOUR_STATUS, USER_TYPE } from "../../../../../utils/constants";
import Radio from "../../../../ui/radio";

export default function PowerCommercial({ handleStageFourTabClick }) {
  const { user } = useSelector((state) => state.auth);
  const { questionnaire } = useSelector((state) => state.user);

  const {
    disableForm,
    loading,
    canAdminComment,
    initialValues,
    handleSubmitPowerCommercial,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useQuestionnaire(handleStageFourTabClick);

  return (
    <Formik
      initialValues={initialValues.commercialPower}
      validationSchema={commercialPowerValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setErrors,
          setSubmitting,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <div className="py-8 space-y-3">
              <h6 className="font-semibold text-black">
                Section 3: Commercial Due Diligence
              </h6>
              <p className="text-sm max-w-[324px]">
                Provide Detailed Commercial Information and Supporting Documents
                for the Proposed Project
              </p>
            </div>
            <FormContainer width type="user" title="1. Project Description">
              <SectionHeader title="Provide a comprehensive description of the project, including its scope, objectives, and key components*" />
              <AdminComment
                name="projectDescription"
                adminComment={
                  adminComments?.commercialPower?.projectDescription || ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialPower?.projectDescription
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setAdminComments={setAdminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                type="commercialPower"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="projectDescription"
                  id="projectDescription"
                  value={values.projectDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.projectDescription && touched.projectDescription
                      ? errors.projectDescription
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialPower?.projectDescription &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="2. Projected Product Streams"
            >
              <SectionHeader title="Detail the expected outputs of the project, including types and quantities of products*" />
              <AdminComment
                name="projectedProductStreams"
                adminComment={
                  adminComments?.commercialPower?.projectedProductStreams || ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialPower?.projectedProductStreams
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                setAdminComments={setAdminComments}
                type="commercialPower"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="projectedProductStreams"
                  id="projectedProductStreams"
                  value={values.projectedProductStreams}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.projectedProductStreams &&
                    touched.projectedProductStreams
                      ? errors.projectedProductStreams
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialPower?.projectedProductStreams &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="3. Planned Generation Capacity"
            >
              <SectionHeader title="Specify the projected energy or product generation capacity of the project*" />
              <AdminComment
                name="plannedGenerationCapacity"
                adminComment={
                  adminComments?.commercialPower?.plannedGenerationCapacity ||
                  ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialPower?.plannedGenerationCapacity
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                setAdminComments={setAdminComments}
                type="commercialPower"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="plannedGenerationCapacity"
                  id="plannedGenerationCapacity"
                  value={values.plannedGenerationCapacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.plannedGenerationCapacity &&
                    touched.plannedGenerationCapacity
                      ? errors.plannedGenerationCapacity
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialPower
                      ?.plannedGenerationCapacity &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer width type="user" title="4. Feedstock Requirements">
              <SectionHeader title="Outline the types and quantities of feedstock needed for the project, including sourcing strategies.*" />
              <AdminComment
                name="feedstockRequirements"
                adminComment={
                  adminComments?.commercialPower?.feedstockRequirements || ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialPower?.feedstockRequirements
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                setAdminComments={setAdminComments}
                type="commercialPower"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="feedstockRequirements"
                  id="feedstockRequirements"
                  value={values.feedstockRequirements}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.feedstockRequirements &&
                    touched.feedstockRequirements
                      ? errors.feedstockRequirements
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialPower?.feedstockRequirements &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="5. Plan for Product Off-take:"
            >
              <>
                <SectionHeader
                  title="Off-Take Plan Overview"
                  description="Describe your current off-take agreements or arrangements. Include the nature and duration of these agreements*"
                />
                <AdminComment
                  name="offTakePlanOverview"
                  adminComment={
                    adminComments?.commercialPower?.offTakePlanOverview || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.offTakePlanOverview
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="offTakePlanOverview"
                    id="offTakePlanOverview"
                    value={values.offTakePlanOverview}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.offTakePlanOverview && touched.offTakePlanOverview
                        ? errors.offTakePlanOverview
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.offTakePlanOverview &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Evidence of Off-Take Agreements"
                  description="If available, provide copies of off-take agreements or official letters of intent."
                />
                <AdminComment
                  name="evidenceOfOffTakeAgreements"
                  adminComment={
                    adminComments?.commercialPower
                      ?.evidenceOfOffTakeAgreements || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.evidenceOfOffTakeAgreements
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="evidenceOfOffTakeAgreements"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.evidenceOfOffTakeAgreements
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    onBlur={handleBlur}
                    required
                    errors={
                      errors.evidenceOfOffTakeAgreements &&
                      touched.evidenceOfOffTakeAgreements
                        ? getIn(errors, `evidenceOfOffTakeAgreements`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower
                        ?.evidenceOfOffTakeAgreements &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Other Supporting Document"
                  description="Upload other related and supporting documents eg market assessment plans, off-take logistics etc."
                />
                <FieldArray
                  name="otherSupportingDocument"
                  render={
                    (arrayHelpers) => (
                      // values.otherSupportingDocument.map((document, index) => (
                      <AdminComment
                        name={`otherSupportingDocument[${0}]`}
                        adminComment={
                          adminComments?.commercialPower
                            ?.otherSupportingDocument[0] || ""
                        }
                        isAdminCommentAvailable={
                          adminComments?.commercialPower
                            ?.otherSupportingDocument[0]
                            ? true
                            : false
                        }
                        canAdminComment={canAdminComment}
                        allComments={adminComments}
                        setIsCommentNonNull={setIsCommentNonNull}
                        setAdminComments={setAdminComments}
                        type="commercialPower"
                      >
                        <FileUpload
                          // key={index}
                          name={`otherSupportingDocument[${0}]`}
                          fileType=".pdf, .xls, .xlsx"
                          format="PDF or Excel format"
                          value={
                            questionnaire?.CommercialDueDiligence
                              ? values.otherSupportingDocument[0]
                              : ""
                          }
                          onBlur={handleBlur}
                          onChange={(fieldName, file) => {
                            setFieldTouched(fieldName, true);
                            setFieldValue(fieldName, file);
                          }}
                          required
                          errors={
                            errors.otherSupportingDocument &&
                            touched.otherSupportingDocument
                              ? getIn(errors, `otherSupportingDocument[${0}]`)
                              : ""
                          }
                          disabled={
                            disableForm ||
                            (!adminComments?.commercialPower
                              ?.otherSupportingDocument?.["0"] &&
                              questionnaire?.status ===
                                STAGE_FOUR_STATUS.FEEDBACK)
                          }
                        />
                      </AdminComment>
                    )
                    // ))
                  }
                />
              </>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="6. Project delivery timescales"
            >
              <SectionHeader title="Work plan/schedule for project execution up to first gas date, indicating time towards financial closure, FEED etc. Highlight all critical dependencies" />
              <AdminComment
                name="projectDeliveryTimescales"
                adminComment={
                  adminComments?.commercialPower?.projectDeliveryTimescales ||
                  ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialPower?.projectDeliveryTimescales
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                setAdminComments={setAdminComments}
                type="commercialPower"
              >
                <FileUpload
                  name="projectDeliveryTimescales"
                  fileType=".pdf, .xls, .xlsx"
                  format="PDF or Excel format"
                  value={
                    questionnaire?.CommercialDueDiligence
                      ? values.projectDeliveryTimescales
                      : ""
                  }
                  onBlur={handleBlur}
                  onChange={(fieldName, file) => {
                    setFieldValue(fieldName, file);
                    setFieldTouched(fieldName, true);
                  }}
                  required
                  errors={
                    errors.projectDeliveryTimescales &&
                    touched.projectDeliveryTimescales
                      ? getIn(errors, `projectDeliveryTimescales`)
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialPower
                      ?.projectDeliveryTimescales &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer width type="user" title="7. Financing Plan">
              <SectionHeader title="Provide details of the planned debt/equity ratio and status of engagement of financing parties.*" />
              <AdminComment
                name="financingPlan"
                adminComment={
                  adminComments?.commercialPower?.financingPlan || ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialPower?.financingPlan ? true : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                setAdminComments={setAdminComments}
                type="commercialPower"
              >
                <Textarea
                  placeholder="Enter description here"
                  name="financingPlan"
                  id="financingPlan"
                  value={values.financingPlan}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.financingPlan && touched.financingPlan
                      ? errors.financingPlan
                      : ""
                  }
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialPower?.financingPlan &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>

              <AdminComment
                name="financialPlanningDocument"
                adminComment={
                  adminComments?.commercialPower?.financialPlanningDocument ||
                  ""
                }
                isAdminCommentAvailable={
                  adminComments?.commercialPower?.financialPlanningDocument
                    ? true
                    : false
                }
                canAdminComment={canAdminComment}
                allComments={adminComments}
                setIsCommentNonNull={setIsCommentNonNull}
                setAdminComments={setAdminComments}
                type="commercialPower"
              >
                <FileUpload
                  name="financialPlanningDocument"
                  fileType=".pdf, .xls, .xlsx"
                  format="PDF or Excel format"
                  value={
                    questionnaire?.CommercialDueDiligence
                      ? values.financialPlanningDocument
                      : ""
                  }
                  onBlur={handleBlur}
                  onChange={(fieldName, file) => {
                    setFieldValue(fieldName, file);
                  }}
                  disabled={
                    disableForm ||
                    (!adminComments?.commercialPower
                      ?.financialPlanningDocument &&
                      questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                  }
                />
              </AdminComment>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="8. GACNs Payment Assurance Framework"
            >
              <>
                <AdminComment
                  name="commitToPaymentAssuranceFramework"
                  adminComment={
                    adminComments?.commercialPower
                      ?.commitToPaymentAssuranceFramework || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower
                      ?.commitToPaymentAssuranceFramework
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <Radio
                    options={[
                      { title: "Yes", value: "Yes" },
                      { title: "No", value: "No" },
                    ]}
                    value={values.commitToPaymentAssuranceFramework}
                    onChange={handleChange}
                    name="commitToPaymentAssuranceFramework"
                    title="Do you Commit to abide by GACN’s Payment Assurance Framework by showing proof of creditworthiness by either bank guarantee, letter of credit, or prepayment as may be required?"
                    errors={
                      Boolean(
                        getIn(errors, "commitToPaymentAssuranceFramework") &&
                          getIn(touched, "commitToPaymentAssuranceFramework")
                      )
                        ? getIn(errors, "commitToPaymentAssuranceFramework")
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower
                        ?.commitToPaymentAssuranceFramework &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
                {values.commitToPaymentAssuranceFramework === "No" && (
                  <AdminComment
                    name="reasonsForNotCommitting"
                    adminComment={
                      adminComments?.commercialPower?.reasonsForNotCommitting ||
                      ""
                    }
                    isAdminCommentAvailable={
                      adminComments?.commercialPower?.reasonsForNotCommitting
                        ? true
                        : false
                    }
                    canAdminComment={canAdminComment}
                    allComments={adminComments}
                    setIsCommentNonNull={setIsCommentNonNull}
                    setAdminComments={setAdminComments}
                    type="commercialPower"
                  >
                    <Textarea
                      placeholder="Describe your reasons"
                      name="reasonsForNotCommitting"
                      id="reasonsForNotCommitting"
                      value={values.reasonsForNotCommitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={
                        Boolean(
                          getIn(errors, "reasonsForNotCommitting") &&
                            getIn(touched, "reasonsForNotCommitting")
                        )
                          ? getIn(errors, "reasonsForNotCommitting")
                          : ""
                      }
                      disabled={
                        disableForm ||
                        (!adminComments?.commercialPower
                          ?.reasonsForNotCommitting &&
                          questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                      }
                    />
                  </AdminComment>
                )}
              </>
              <>
                <SectionHeader
                  border
                  title="Kindly provide a letter of creditworthiness from your bank, if available (Optional)"
                />
                <AdminComment
                  name="letterOfCreditworthiness"
                  adminComment={
                    adminComments?.commercialPower?.letterOfCreditworthiness ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.letterOfCreditworthiness
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="letterOfCreditworthiness"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.letterOfCreditworthiness
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower
                        ?.letterOfCreditworthiness &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            <FormContainer
              width
              type="user"
              title="9. Evidence of status of all relevant statutory permits or progress towards securing these permits (including but not limited to the under-listed)"
            >
              <>
                <SectionHeader title="National Electricity Regulatory Commission (NERC) Generation License" />
                <AdminComment
                  name="statutoryPermits.nercLicensing"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.nercLicensing || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.nercLicensing
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.nercLicensing"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.nercLicensing
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(errors, `statutoryPermits.nercLicensing`) &&
                          getIn(touched, `statutoryPermits.nercLicensing`)
                      )
                        ? getIn(errors, `statutoryPermits.nercLicensing`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.nercLicensing &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              {/* =====NEW UPDATE===== */}
              <>
                <SectionHeader title="Evidence of Nigerian Bulk Electricity Trading (NBET) Power Purchase Agreement (PPA)" />
                <AdminComment
                  name="nbetPowerPurchaseAgreement"
                  adminComment={
                    adminComments?.commercialPower
                      ?.nbetPowerPurchaseAgreement || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.nbetPowerPurchaseAgreement
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="nbetPowerPurchaseAgreement"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.nbetPowerPurchaseAgreement
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(errors, `nbetPowerPurchaseAgreement`) &&
                          getIn(touched, `nbetPowerPurchaseAgreement`)
                      )
                        ? getIn(errors, `nbetPowerPurchaseAgreement`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower
                        ?.nbetPowerPurchaseAgreement &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Application to Transmission Company of Nigeria (TCN) for evacuation for transmission or distribution"
                />
                <AdminComment
                  name="statutoryPermits.tcnEvacuationApplication"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.tcnEvacuationApplication || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.tcnEvacuationApplication
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.tcnEvacuationApplication"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.tcnEvacuationApplication
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.tcnEvacuationApplication`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.tcnEvacuationApplication`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.tcnEvacuationApplication`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.tcnEvacuationApplication &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Environmental Permits, including Environment Impact Assessment"
                />
                <AdminComment
                  name="statutoryPermits.environmentalPermits"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.environmentalPermits || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.environmentalPermits
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.environmentalPermits"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.environmentalPermits
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.environmentalPermits`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.environmentalPermits`
                          )
                      )
                        ? getIn(errors, `statutoryPermits.environmentalPermits`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.environmentalPermits &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Study approved by the Federal Ministry of Housing and Environment"
                />
                <AdminComment
                  name="statutoryPermits.fedMinOfHousingAndEnvStudy"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.fedMinOfHousingAndEnvStudy || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.fedMinOfHousingAndEnvStudy
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.fedMinOfHousingAndEnvStudy"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.fedMinOfHousingAndEnvStudy
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.fedMinOfHousingAndEnvStudy`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.fedMinOfHousingAndEnvStudy`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.fedMinOfHousingAndEnvStudy`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.fedMinOfHousingAndEnvStudy &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="Evidence of Tax Registration" />
                <AdminComment
                  name="statutoryPermits.taxRegistration"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.taxRegistration || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.taxRegistration
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.taxRegistration"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.taxRegistration
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(errors, `statutoryPermits.taxRegistration`) &&
                          getIn(touched, `statutoryPermits.taxRegistration`)
                      )
                        ? getIn(errors, `statutoryPermits.taxRegistration`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.taxRegistration &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="Gas Use License" />
                <AdminComment
                  name="statutoryPermits.gasUseLicense"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.gasUseLicense || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.gasUseLicense
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.gasUseLicense"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.gasUseLicense
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(errors, `statutoryPermits.gasUseLicense`) &&
                          getIn(touched, `statutoryPermits.gasUseLicense`)
                      )
                        ? getIn(errors, `statutoryPermits.gasUseLicense`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.gasUseLicense &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Risk Assessment Safety and HAZOP Reviews"
                />
                <AdminComment
                  name="statutoryPermits.riskAssessmentSafetyHazop"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.riskAssessmentSafetyHazop || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.riskAssessmentSafetyHazop
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.riskAssessmentSafetyHazop"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.riskAssessmentSafetyHazop
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.riskAssessmentSafetyHazop`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.riskAssessmentSafetyHazop`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.riskAssessmentSafetyHazop`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.riskAssessmentSafetyHazop &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="Simulation Studies" />
                <AdminComment
                  name="statutoryPermits.simulationStudies"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.simulationStudies || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.simulationStudies
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.simulationStudies"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.simulationStudies
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(errors, `statutoryPermits.simulationStudies`) &&
                          getIn(touched, `statutoryPermits.simulationStudies`)
                      )
                        ? getIn(errors, `statutoryPermits.simulationStudies`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.simulationStudies &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Pollution Control, Effluent Treatment"
                />
                <AdminComment
                  name="statutoryPermits.pollutionControlEffluentTreatment"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.pollutionControlEffluentTreatment || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.pollutionControlEffluentTreatment
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.pollutionControlEffluentTreatment"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits
                            .pollutionControlEffluentTreatment
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.pollutionControlEffluentTreatment`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.pollutionControlEffluentTreatment`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.pollutionControlEffluentTreatment`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.pollutionControlEffluentTreatment &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="Construction/operating permits" />
                <AdminComment
                  name="statutoryPermits.constructionOperatingPermits"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.constructionOperatingPermits || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.constructionOperatingPermits
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.constructionOperatingPermits"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.constructionOperatingPermits
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.constructionOperatingPermits`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.constructionOperatingPermits`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.constructionOperatingPermits`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.constructionOperatingPermits &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="Local Government Approvals" />
                <AdminComment
                  name="statutoryPermits.localGovernmentApprovals"
                  adminComment={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.localGovernmentApprovals || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.commercialPower?.statutoryPermits
                      ?.localGovernmentApprovals
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  setAdminComments={setAdminComments}
                  type="commercialPower"
                >
                  <FileUpload
                    name="statutoryPermits.localGovernmentApprovals"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.CommercialDueDiligence
                        ? values.statutoryPermits.localGovernmentApprovals
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      Boolean(
                        getIn(
                          errors,
                          `statutoryPermits.localGovernmentApprovals`
                        ) &&
                          getIn(
                            touched,
                            `statutoryPermits.localGovernmentApprovals`
                          )
                      )
                        ? getIn(
                            errors,
                            `statutoryPermits.localGovernmentApprovals`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.commercialPower?.statutoryPermits
                        ?.localGovernmentApprovals &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Any other permit. (Note that the project sponsor is responsible for ascertaining all permit requirements.)"
                />

                <FieldArray
                  name="statutoryPermits.otherPermits"
                  render={(arrayHelpers) => (
                    <AdminComment
                      name="statutoryPermits.otherPermits[0]"
                      adminComment={
                        adminComments?.commercialPower?.statutoryPermits
                          ?.otherPermits?.[0] ?? ""
                      }
                      isAdminCommentAvailable={
                        adminComments?.commercialPower?.statutoryPermits
                          ?.otherPermits?.[0]
                          ? true
                          : false
                      }
                      canAdminComment={canAdminComment}
                      allComments={adminComments}
                      setIsCommentNonNull={setIsCommentNonNull}
                      setAdminComments={setAdminComments}
                      type="commercialPower"
                    >
                      <FileUpload
                        name="statutoryPermits.otherPermits[0]"
                        fileType=".pdf, .xls, .xlsx"
                        format="PDF or Excel format"
                        value={
                          questionnaire?.CommercialDueDiligence
                            ? values.statutoryPermits?.otherPermits[0]
                            : ""
                        }
                        onBlur={handleBlur}
                        onChange={(fieldName, file) => {
                          setFieldTouched(fieldName, true);
                          setFieldValue(fieldName, file);
                        }}
                        disabled={
                          disableForm ||
                          (!adminComments?.commercialPower?.statutoryPermits
                            ?.otherPermits?.["0"] &&
                            questionnaire?.status ===
                              STAGE_FOUR_STATUS.FEEDBACK)
                        }
                      />
                    </AdminComment>
                  )}
                />
              </>
            </FormContainer>

            {(user?.role === USER_TYPE.USER ||
              user?.role === USER_TYPE.PROXY) &&
            (!questionnaire ||
              questionnaire?.status === STAGE_FOUR_STATUS.PENDING ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) ? (
              <div className="flex justify-between w-full pt-16">
                <button
                  onClick={() => handleStageFourTabClick(1)}
                  className="text-primary-1 uppercase text-sm font-bold leading-[16.8px]"
                >
                  Previous
                </button>
                <div className="flex justify-end gap-6 ">
                  <Button
                    disabled={isSubmitting || loading}
                    onClick={() => {
                      setErrors({});
                      handleSubmitPowerCommercial(
                        values,
                        "save",
                        setSubmitting
                      );
                      const res = validateForm();
                      setErrors({ ...res });
                    }}
                    loading={isSubmitting}
                    className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                  >
                    Save progress
                  </Button>
                  <Button
                    onClick={async () => {
                      setSubmitting(false);
                      const res = validateForm();
                      setErrors({ ...res });

                      if (Object.keys(res).length !== 0)
                        toast.error("Please fill all the required fields");
                      else
                        handleSubmitPowerCommercial(
                          values,
                          "next",
                          setSubmitting
                        );
                    }}
                    disabled={!isValid || loading || isSubmitting}
                    className="py-4 px-6 self-end"
                    loading={loading}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end gap-6 w-full pt-16">
                <div className="flex justify-end gap-6">
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(1)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    {Icons.ic_arrow_left} Previous
                  </Button>
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(3)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    Next {Icons.ic_arrow_right}
                  </Button>
                </div>
                {(user?.role === USER_TYPE.ADMIN ||
                  user?.role === USER_TYPE.SUPER_ADMIN) &&
                  (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
                    questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
                    <Button
                      disabled={loadingAddComments || !isCommentNonNull}
                      onClick={() => {
                        handleAddAdminComment(
                          adminComments.commercialPower,
                          "adminUpdateCommercial"
                        );
                      }}
                      loading={loadingAddComments}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                    >
                      Save Comment
                    </Button>
                  )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
