import AuthLayout from "../../../components/Layout/authLayout";
import CheckEmail from "../../../components/AuthForm/checkEmail";

const AdminConfirmationPage = () => {
  return (
    <AuthLayout page="confirmation" type="admin">
      <CheckEmail type="admin" />
    </AuthLayout>
  );
};

export default AdminConfirmationPage;
