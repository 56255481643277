import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useUser from "../../../../hooks/useUser";
import { USER_TYPE } from "../../../../utils/constants";

export default function useSiteVisitQuestionnaire(handleStageSixStep) {
  const { user } = useSelector((state) => state.auth);
  const { siteVisitQuestionnaire, siteVisit } = useSelector(
    (state) => state.user
  );

  const [disableForm, setDisableForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCommentNonNull, setIsCommentNonNull] = useState(false);

  const {
    saveSiteVisitQuestionnaire,
    submitSiteVisitQuestionnaire,
    approveSiteVisitQuestionnaire,
    siteVisitQuestionnaireAdminNote,
  } = useUser();

  const userType =
    user?.role !== USER_TYPE.USER && user?.role !== USER_TYPE.PROXY
      ? USER_TYPE.ADMIN
      : USER_TYPE.USER;

  const canAdminComment =
    siteVisitQuestionnaire?.status !== "approved" && userType !== USER_TYPE.USER
      ? true
      : false;

  useEffect(() => {
    if (userType === USER_TYPE.USER && !siteVisitQuestionnaire)
      setDisableForm(false);
    else if (
      userType !== USER_TYPE.USER ||
      (siteVisitQuestionnaire?.status !== "pending" &&
        siteVisitQuestionnaire?.status !== "feedback")
    )
      setDisableForm(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, siteVisitQuestionnaire]);

  const initialValues = {
    power: {
      coordinates:
        siteVisitQuestionnaire?.Power?.coordinates?.userResponse || "",
      layoutMap: siteVisitQuestionnaire?.Power?.layoutMap?.userResponse || "",
      plotPlan: siteVisitQuestionnaire?.Power?.plotPlan?.userResponse || "",
      landSizeDoc:
        siteVisitQuestionnaire?.Power?.landSizeDoc?.userResponse || "",
      proximityToGasSupplier:
        siteVisitQuestionnaire?.Power?.proximityToGasSupplier?.userResponse ||
        "",
      facilityLayout:
        siteVisitQuestionnaire?.Power?.facilityLayout?.userResponse || "",
      roadAccess: siteVisitQuestionnaire?.Power?.roadAccess?.userResponse || "",
      evacuationPlan: {
        powerEvacuationPlan:
          siteVisitQuestionnaire?.Power?.evacuationPlan?.powerEvacuationPlan
            ?.userResponse || "",
        alternativeEvacuationPlan:
          siteVisitQuestionnaire?.Power?.evacuationPlan
            ?.alternativeEvacuationPlan?.userResponse || "",
        backupEvacuationStrategy:
          siteVisitQuestionnaire?.Power?.evacuationPlan
            ?.backupEvacuationStrategy?.userResponse || "",
      },
      emergencyPlanning: {
        alternativeFuel:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.alternativeFuel
            ?.userResponse || "",
        expansionPlan:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.expansionPlan
            ?.userResponse || "",
        expansionTimeline:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.expansionTimeline
            ?.userResponse || "",
        employmentOpportunities:
          siteVisitQuestionnaire?.Power?.emergencyPlanning
            ?.employmentOpportunities?.userResponse || "",
        powerDistribution:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.powerDistribution
            ?.userResponse || "",
        emergencyShutdownPlan:
          siteVisitQuestionnaire?.Power?.emergencyPlanning
            ?.emergencyShutdownPlan?.userResponse || "",
        thirdPartyPower:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.thirdPartyPower
            ?.userResponse || "",
        thirdPartyPowerSpace:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.thirdPartyPowerSpace
            ?.userResponse || "",
      },
      marketExperience: {
        meterLocation:
          siteVisitQuestionnaire?.Power?.marketExperience?.meterLocation
            ?.userResponse || "",
        unusedGridSales:
          siteVisitQuestionnaire?.Power?.marketExperience?.unusedGridSales
            ?.userResponse || "",
        marketTrackRecord:
          siteVisitQuestionnaire?.Power?.marketExperience?.marketTrackRecord
            ?.userResponse || "",
        powerPurchaseAgreement:
          siteVisitQuestionnaire?.Power?.marketExperience
            ?.powerPurchaseAgreement?.userResponse || "",
        offtakeAgreement:
          siteVisitQuestionnaire?.Power?.marketExperience?.offtakeAgreement
            ?.userResponse || "",
      },
    },
    gas: {
      layoutMap: siteVisitQuestionnaire?.Gas?.layoutMap?.userResponse || "",
      plotPlan: siteVisitQuestionnaire?.Gas?.plotPlan?.userResponse || "",
      landSizeDoc: siteVisitQuestionnaire?.Gas?.landSizeDoc?.userResponse || "",
      proximityToGasSupplier:
        siteVisitQuestionnaire?.Gas?.proximityToGasSupplier?.userResponse || "",
      projectPlan: {
        gasInletPoint:
          siteVisitQuestionnaire?.Gas?.projectPlan?.gasInletPoint
            ?.userResponse || "",
        gasInletPointFile:
          siteVisitQuestionnaire?.Gas?.projectPlan?.gasInletPointFile
            ?.userResponse || "",
        roadAccess:
          siteVisitQuestionnaire?.Gas?.projectPlan?.roadAccess?.userResponse ||
          "",
        meterLocation:
          siteVisitQuestionnaire?.Gas?.projectPlan?.meterLocation
            ?.userResponse || "",
        constructionTimeline:
          siteVisitQuestionnaire?.Gas?.projectPlan?.constructionTimeline
            ?.userResponse || "",
        modeOfEvacuation:
          siteVisitQuestionnaire?.Gas?.projectPlan?.modeOfEvacuation
            ?.userResponse || "",
        technologyType:
          siteVisitQuestionnaire?.Gas?.projectPlan?.technologyType
            ?.userResponse || "",
      },
      safetyConsiderations: {
        alternativeFuel:
          siteVisitQuestionnaire?.Gas?.safetyConsiderations?.alternativeFuel
            ?.userResponse || "",
        howToSwitch:
          siteVisitQuestionnaire?.Gas?.safetyConsiderations?.howToSwitch
            ?.userResponse || "",
        workCommenced:
          siteVisitQuestionnaire?.Gas?.safetyConsiderations?.workCommenced
            ?.userResponse || "",
      },
      marketExperience: {
        ancillarySystem:
          siteVisitQuestionnaire?.Gas?.marketExperience?.ancillarySystem
            ?.userResponse || "",
        expansionPlan:
          siteVisitQuestionnaire?.Gas?.marketExperience?.expansionPlan
            ?.userResponse || "",
        employmentOpportunities:
          siteVisitQuestionnaire?.Gas?.marketExperience?.employmentOpportunities
            ?.userResponse || "",
        productDistribution:
          siteVisitQuestionnaire?.Gas?.marketExperience?.productDistribution
            ?.userResponse || "",
        marketTrackRecord:
          siteVisitQuestionnaire?.Gas?.marketExperience?.marketTrackRecord
            ?.userResponse || "",
      },
    },
    commercial: {
      layoutMap: siteVisitQuestionnaire?.Comm?.layoutMap?.userResponse || "",
      landSizeDoc:
        siteVisitQuestionnaire?.Comm?.landSizeDoc?.userResponse || "",
      proximityToGasSupplier:
        siteVisitQuestionnaire?.Comm?.proximityToGasSupplier?.userResponse ||
        "",
      projectPlan: {
        gasInletPoint:
          siteVisitQuestionnaire?.Comm?.projectPlan?.gasInletPoint
            ?.userResponse || "",
        gasInletPointFile:
          siteVisitQuestionnaire?.Comm?.projectPlan?.gasInletPointFile
            ?.userResponse || "",
        gasGeneratorLocation:
          siteVisitQuestionnaire?.Comm?.projectPlan?.gasGeneratorLocation
            ?.userResponse || "",
        roadAccess:
          siteVisitQuestionnaire?.Comm?.projectPlan?.roadAccess?.userResponse ||
          "",
        alternativeFuel:
          siteVisitQuestionnaire?.Comm?.projectPlan?.alternativeFuel
            ?.userResponse || "",
        ancillarySystem:
          siteVisitQuestionnaire?.Comm?.projectPlan?.ancillarySystem
            ?.userResponse || "",
        expansionPlan:
          siteVisitQuestionnaire?.Comm?.projectPlan?.expansionPlan
            ?.userResponse || "",
        meterLocation:
          siteVisitQuestionnaire?.Comm?.projectPlan?.meterLocation
            ?.userResponse || "",
        constructionTimeline:
          siteVisitQuestionnaire?.Comm?.projectPlan?.constructionTimeline
            ?.userResponse || "",
        modeOfEvacuation:
          siteVisitQuestionnaire?.Comm?.projectPlan?.modeOfEvacuation
            ?.userResponse || "",
        productTransport:
          siteVisitQuestionnaire?.Comm?.projectPlan?.productTransport
            ?.userResponse || "",
      },
      safetyConsiderations: {
        EIA:
          siteVisitQuestionnaire?.Comm?.safetyConsiderations?.EIA
            ?.userResponse || "",
        EIAReport:
          siteVisitQuestionnaire?.Comm?.safetyConsiderations?.EIAReport
            ?.userResponse || "",
        emergencyShutdownPlan:
          siteVisitQuestionnaire?.Comm?.safetyConsiderations
            ?.emergencyShutdownPlan?.userResponse || "",
        workCommenced:
          siteVisitQuestionnaire?.Comm?.safetyConsiderations?.workCommenced
            ?.userResponse || "",
      },
      marketExperience: {
        ancillarySysmarketTrackRecordtem:
          siteVisitQuestionnaire?.Comm?.marketExperience
            ?.ancillarySysmarketTrackRecordtem?.userResponse || "",
        workCommenced:
          siteVisitQuestionnaire?.Comm?.marketExperience?.workCommenced
            ?.userResponse || "",
        employmentOpportunities:
          siteVisitQuestionnaire?.Comm?.marketExperience
            ?.employmentOpportunities?.userResponse || "",
        offtakeAgreement:
          siteVisitQuestionnaire?.Comm?.marketExperience?.offtakeAgreement
            ?.userResponse || "",
        gasSupplyCapcity:
          siteVisitQuestionnaire?.Comm?.marketExperience?.gasSupplyCapcity
            ?.userResponse || "",
      },
    },
  };

  const handleSubmitSiteVisitQuestionnaire = async (id, values) => {
    setLoading(true);

    const response = await submitSiteVisitQuestionnaire(id, values);
    if (response) handleStageSixStep(2);

    setLoading(false);
  };

  const handleSaveSiteVisitQuestionnaire = async (
    project_type,
    values,
    setSubmitting
  ) => {
    setSubmitting(true);

    const response = await saveSiteVisitQuestionnaire(project_type, values);
    if (response) handleStageSixStep(2);

    setSubmitting(false);
  };

  const handleApproveSiteVisitQuestionnaire = async (currentUserId) => {
    setLoading(true);

    await approveSiteVisitQuestionnaire(currentUserId);
    setLoading(false);
  };

  // ==== Admin Comments
  const initialValuesForAdmin = {
    power: {
      coordinates:
        siteVisitQuestionnaire?.Power?.coordinates?.adminNote || null,
      layoutMap: siteVisitQuestionnaire?.Power?.layoutMap?.adminNote || null,
      plotPlan: siteVisitQuestionnaire?.Power?.plotPlan?.adminNote || null,
      landSizeDoc:
        siteVisitQuestionnaire?.Power?.landSizeDoc?.adminNote || null,
      proximityToGasSupplier:
        siteVisitQuestionnaire?.Power?.proximityToGasSupplier?.adminNote ||
        null,
      facilityLayout:
        siteVisitQuestionnaire?.Power?.facilityLayout?.adminNote || null,
      roadAccess: siteVisitQuestionnaire?.Power?.roadAccess?.adminNote || null,
      evacuationPlan: {
        powerEvacuationPlan:
          siteVisitQuestionnaire?.Power?.evacuationPlan?.powerEvacuationPlan
            ?.adminNote || null,
        alternativeEvacuationPlan:
          siteVisitQuestionnaire?.Power?.evacuationPlan
            ?.alternativeEvacuationPlan?.adminNote || null,
        backupEvacuationStrategy:
          siteVisitQuestionnaire?.Power?.evacuationPlan
            ?.backupEvacuationStrategy?.adminNote || null,
      },
      emergencyPlanning: {
        alternativeFuel:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.alternativeFuel
            ?.adminNote || null,
        expansionPlan:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.expansionPlan
            ?.adminNote || null,
        expansionTimeline:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.expansionTimeline
            ?.adminNote || null,
        employmentOpportunities:
          siteVisitQuestionnaire?.Power?.emergencyPlanning
            ?.employmentOpportunities?.adminNote || null,
        powerDistribution:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.powerDistribution
            ?.adminNote || null,
        emergencyShutdownPlan:
          siteVisitQuestionnaire?.Power?.emergencyPlanning
            ?.emergencyShutdownPlan?.adminNote || null,
        thirdPartyPower:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.thirdPartyPower
            ?.adminNote || null,
        thirdPartyPowerSpace:
          siteVisitQuestionnaire?.Power?.emergencyPlanning?.thirdPartyPowerSpace
            ?.adminNote || null,
      },
      marketExperience: {
        meterLocation:
          siteVisitQuestionnaire?.Power?.marketExperience?.meterLocation
            ?.adminNote || null,
        unusedGridSales:
          siteVisitQuestionnaire?.Power?.marketExperience?.unusedGridSales
            ?.adminNote || null,
        marketTrackRecord:
          siteVisitQuestionnaire?.Power?.marketExperience?.marketTrackRecord
            ?.adminNote || null,
        powerPurchaseAgreement:
          siteVisitQuestionnaire?.Power?.marketExperience
            ?.powerPurchaseAgreement?.adminNote || null,
        offtakeAgreement:
          siteVisitQuestionnaire?.Power?.marketExperience?.offtakeAgreement
            ?.adminNote || null,
      },
    },
    gas: {
      layoutMap: siteVisitQuestionnaire?.Gas?.layoutMap?.adminNote || null,
      plotPlan: siteVisitQuestionnaire?.Gas?.plotPlan?.adminNote || null,
      landSizeDoc: siteVisitQuestionnaire?.Gas?.landSizeDoc?.adminNote || null,
      proximityToGasSupplier:
        siteVisitQuestionnaire?.Gas?.proximityToGasSupplier?.adminNote || null,
      projectPlan: {
        gasInletPoint:
          siteVisitQuestionnaire?.Gas?.projectPlan?.gasInletPoint?.adminNote ||
          null,
        gasInletPointFile:
          siteVisitQuestionnaire?.Gas?.projectPlan?.gasInletPointFile
            ?.adminNote || null,
        roadAccess:
          siteVisitQuestionnaire?.Gas?.projectPlan?.roadAccess?.adminNote ||
          null,
        meterLocation:
          siteVisitQuestionnaire?.Gas?.projectPlan?.meterLocation?.adminNote ||
          null,
        constructionTimeline:
          siteVisitQuestionnaire?.Gas?.projectPlan?.constructionTimeline
            ?.adminNote || null,
        modeOfEvacuation:
          siteVisitQuestionnaire?.Gas?.projectPlan?.modeOfEvacuation
            ?.adminNote || null,
        technologyType:
          siteVisitQuestionnaire?.Gas?.projectPlan?.technologyType?.adminNote ||
          null,
      },
      safetyConsiderations: {
        alternativeFuel:
          siteVisitQuestionnaire?.Gas?.safetyConsiderations?.alternativeFuel
            ?.adminNote || null,
        howToSwitch:
          siteVisitQuestionnaire?.Gas?.safetyConsiderations?.howToSwitch
            ?.adminNote || null,
        workCommenced:
          siteVisitQuestionnaire?.Gas?.safetyConsiderations?.workCommenced
            ?.adminNote || null,
      },
      marketExperience: {
        ancillarySystem:
          siteVisitQuestionnaire?.Gas?.marketExperience?.ancillarySystem
            ?.adminNote || null,
        expansionPlan:
          siteVisitQuestionnaire?.Gas?.marketExperience?.expansionPlan
            ?.adminNote || null,
        employmentOpportunities:
          siteVisitQuestionnaire?.Gas?.marketExperience?.employmentOpportunities
            ?.adminNote || null,
        productDistribution:
          siteVisitQuestionnaire?.Gas?.marketExperience?.productDistribution
            ?.adminNote || null,
        marketTrackRecord:
          siteVisitQuestionnaire?.Gas?.marketExperience?.marketTrackRecord
            ?.adminNote || null,
      },
    },
    commercial: {
      layoutMap: siteVisitQuestionnaire?.Comm?.layoutMap?.adminNote || null,
      landSizeDoc: siteVisitQuestionnaire?.Comm?.landSizeDoc?.adminNote || null,
      proximityToGasSupplier:
        siteVisitQuestionnaire?.Comm?.proximityToGasSupplier?.adminNote || null,
      projectPlan: {
        gasInletPoint:
          siteVisitQuestionnaire?.Comm?.projectPlan?.gasInletPoint?.adminNote ||
          null,
        gasInletPointFile:
          siteVisitQuestionnaire?.Comm?.projectPlan?.gasInletPointFile
            ?.adminNote || null,
        gasGeneratorLocation:
          siteVisitQuestionnaire?.Comm?.projectPlan?.gasGeneratorLocation
            ?.adminNote || null,
        roadAccess:
          siteVisitQuestionnaire?.Comm?.projectPlan?.roadAccess?.adminNote ||
          null,
        alternativeFuel:
          siteVisitQuestionnaire?.Comm?.projectPlan?.alternativeFuel
            ?.adminNote || null,
        ancillarySystem:
          siteVisitQuestionnaire?.Comm?.projectPlan?.ancillarySystem
            ?.adminNote || null,
        expansionPlan:
          siteVisitQuestionnaire?.Comm?.projectPlan?.expansionPlan?.adminNote ||
          null,
        meterLocation:
          siteVisitQuestionnaire?.Comm?.projectPlan?.meterLocation?.adminNote ||
          null,
        constructionTimeline:
          siteVisitQuestionnaire?.Comm?.projectPlan?.constructionTimeline
            ?.adminNote || null,
        modeOfEvacuation:
          siteVisitQuestionnaire?.Comm?.projectPlan?.modeOfEvacuation
            ?.adminNote || null,
        productTransport:
          siteVisitQuestionnaire?.Comm?.projectPlan?.productTransport
            ?.adminNote || null,
      },
      safetyConsiderations: {
        EIA:
          siteVisitQuestionnaire?.Comm?.safetyConsiderations?.EIA?.adminNote ||
          null,
        EIAReport:
          siteVisitQuestionnaire?.Comm?.safetyConsiderations?.EIAReport
            ?.adminNote || null,
        emergencyShutdownPlan:
          siteVisitQuestionnaire?.Comm?.safetyConsiderations
            ?.emergencyShutdownPlan?.adminNote || null,
        workCommenced:
          siteVisitQuestionnaire?.Comm?.safetyConsiderations?.workCommenced
            ?.adminNote || null,
      },
      marketExperience: {
        ancillarySysmarketTrackRecordtem:
          siteVisitQuestionnaire?.Comm?.marketExperience
            ?.ancillarySysmarketTrackRecordtem?.adminNote || null,
        workCommenced:
          siteVisitQuestionnaire?.Comm?.marketExperience?.workCommenced
            ?.adminNote || null,
        employmentOpportunities:
          siteVisitQuestionnaire?.Comm?.marketExperience
            ?.employmentOpportunities?.adminNote || null,
        offtakeAgreement:
          siteVisitQuestionnaire?.Comm?.marketExperience?.offtakeAgreement
            ?.adminNote || null,
        gasSupplyCapcity:
          siteVisitQuestionnaire?.Comm?.marketExperience?.gasSupplyCapcity
            ?.adminNote || null,
      },
    },
  };
  const [adminComments, setAdminComments] = useState(initialValuesForAdmin);
  const [loadingAddComments, setLoadingAddComments] = useState(false);
  const { currentUserId } = useSelector((state) => state.admin);

  const handleAddAdminComment = async (values, name) => {
    setLoadingAddComments(true);
    await siteVisitQuestionnaireAdminNote(values, name, currentUserId);
    setLoadingAddComments(false);
  };

  return {
    disableForm,
    loading,
    setLoading,
    initialValues,
    handleSaveSiteVisitQuestionnaire,
    handleSubmitSiteVisitQuestionnaire,
    handleApproveSiteVisitQuestionnaire,
    status: siteVisitQuestionnaire?.status,
    siteVisitQuestionnaire,
    siteVisit,

    canAdminComment,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  };
}
