import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { setCurrentStep } from "../../../../../config/reducers/userSlice";
import { STATUS } from "../../../../../utils/constants";
import Loader from "../../../../ui/loader";
import Button from "../../../../ui/button";
import DueDiligenceStatus from "../../../General/Status";
import useUser from "../../../../../hooks/useUser";

const RequestStatus = ({ handleStageOneStep }) => {
  const dispatch = useDispatch();
  const { userRequest } = useSelector((state) => state.user);

  const { restartGasRequest, createPaymentAccounts } = useUser();

  const [loading, setLoading] = useState(false);
  const [loadingAccountDetails, setLoadingAccountDetails] = useState(false);

  const handleRestartGasRequest = async () => {
    setLoading(true);
    const response = await restartGasRequest();
    if (response) handleStageOneStep(1);
    setLoading(false);
  };

  const getDetails = async () => {
    if (userRequest?.bankAccounts?.length === 0) {
      setLoadingAccountDetails(true);

      const response = await createPaymentAccounts();
      if (response) {
        dispatch(
          setCurrentStep({
            currentStep: 2,
          })
        );
      }
      setLoadingAccountDetails(false);
    } else {
      dispatch(
        setCurrentStep({
          currentStep: 2,
        })
      );
    }
  };

  return userRequest?.status === "accepted" ? (
    <DueDiligenceStatus
      type="success"
      title="You have been accepted"
      description=" Proceed to make your BACF payment"
    >
      <div className="flex gap-4">
        <Button
          onClick={() => handleStageOneStep(1)}
          className="bg-transparent border-[1.5px] border-primary-1 !text-primary-1"
        >
          View request form
        </Button>
        <Button onClick={getDetails} className="w-fit">
          {loadingAccountDetails ? <Loader /> : "Proceed to payment"}
        </Button>
      </div>
    </DueDiligenceStatus>
  ) : (
    <DueDiligenceStatus
      type={STATUS.REJECTED}
      title="Gas Request Form Rejection"
      description="Your Gas Request Form has been reviewed and unfortunately, we cannot proceed with it at this time."
      note={userRequest?.admin_note}
      date={
        userRequest?.updatedAt &&
        format(new Date(userRequest?.updatedAt), "LLL dd, yyyy")
      }
    >
      <div className="flex gap-4">
        <Button
          onClick={() => handleStageOneStep(1)}
          className="bg-transparent border-[1.5px] border-primary-1 !text-primary-1"
        >
          View Submission
        </Button>
        <Button
          onClick={() => handleRestartGasRequest()}
          className="w-fit"
          loading={loading}
        >
          Restart Due Diligence
        </Button>
      </div>
    </DueDiligenceStatus>
  );
};

export default RequestStatus;
