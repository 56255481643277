import React from "react";

import AuthLayout from "../../components/Layout/authLayout";
import ResetPasswordForm from "../../components/AuthForm/resetPassword";

const ResetPasswordPage = () => {
  return (
    <AuthLayout
      type="user"
      page="resetPassword"
      title="Create new password"
      description="Create a new password to your account"
    >
      <ResetPasswordForm />
    </AuthLayout>
  );
};

export default ResetPasswordPage;
