import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../../../../../utils/constants";
import { setCurrentStep } from "../../../../../config/reducers/userSlice";
import useUser from "../../../../../hooks/useUser";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import PdfDownload from "../../../../ui/pdfDownload";
import DueDiligenceStatus from "../../../General/Status";
import {
  getDownloadFileLink,
  getFileNameFromLink,
  getFileType,
} from "../../../../../utils/functions";

export default function NDAStatus({ handleStageThreeStep }) {
  const dispatch = useDispatch();
  const { userRequest } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const { resetNda } = useUser();

  const handleRestartGasRequest = async () => {
    setLoading(true);

    const response = await resetNda(userRequest?.userId);
    if (response) {
      handleStageThreeStep(1);
    }
    setLoading(false);
  };

  return userRequest?.nda_status === STATUS.ACCEPTED ? (
    <div className="space-y-12 flex flex-col items-center py-6 max-w-[496px] mx-auto">
      <div className="space-y-8 flex flex-col items-center w-full">
        <div className="space-y-6 flex flex-col items-center">
          <img src={Icons.ic_success} alt="" />
          <div className="space-y-4 text-center">
            <h2 className="text-lg text-grey-800 font-semibold">
              NDA Approval Confirmed!
            </h2>
            <p className="text-base text-grey-500">
              Your Non-Disclosure Agreement has been approved
            </p>
          </div>
        </div>

        <PdfDownload
          type={getFileType(userRequest?.nda_doc?.userResponse)}
          title={
            userRequest?.nda_doc?.userResponse
              ? getFileNameFromLink(userRequest?.nda_doc?.userResponse)
              : "Approved NDA document"
          }
          pdfFile={getDownloadFileLink(userRequest?.nda_doc?.userResponse)}
        />
      </div>

      <Button
        onClick={() =>
          dispatch(
            setCurrentStep({
              currentStep: 4,
            })
          )
        }
      >
        Proceed
      </Button>
    </div>
  ) : userRequest?.nda_status === STATUS.REJECTED ? (
    <DueDiligenceStatus
      type={STATUS.REJECTED}
      title="NDA Form Rejection"
      description="Your NDA Form has been reviewed and unfortunately, we cannot proceed with it at this time."
      note={userRequest?.nda_doc?.adminNote}
      date={userRequest?.nda_date}
    >
      <div className="flex gap-4">
        <Button
          disabled={loading}
          className="w-fit"
          onClick={handleRestartGasRequest}
          loading={loading}
        >
          Restart NDA Agreement
        </Button>
      </div>
    </DueDiligenceStatus>
  ) : (
    <DueDiligenceStatus
      type={STATUS.PENDING}
      title="NDA Submission Received"
      description={
        <>
          Your NDA is under review.
          <br /> You'll be notified and can proceed to the questionnaire once
          approved. Thank you for your patience.
        </>
      }
    />
  );
}
