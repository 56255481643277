import Navbar from "./navbar";

const Header = ({type}) => {
  return (
    <header
      id="header"
      className="header w-full !fixed md:h-[77px] md:h-screen md:max-w-[272px] z-[99] top-0 left-0 bg-white border-b border-grey-200"
    >
      <Navbar type={type} />
    </header>
  );
};

export default Header;
