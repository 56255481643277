import { Form, Formik, getIn } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { economicPowerValidationSchema } from "../../../../../utils/validation";
import useQuestionnaire from "../useQuestionnaire";
import Icons from "../../../../../assets/icons";
import Button from "../../../../ui/button";
import Textarea from "../../../../ui/textarea";
import FileUpload from "../../../../ui/fileUpload";
import AdminComment from "../../../../ui/adminComment";
import SectionHeader from "../../SectionHeader";
import FormContainer from "../../FormContainer";
import { STAGE_FOUR_STATUS, USER_TYPE } from "../../../../../utils/constants";

export default function PowerEconomic({ handleStageFourTabClick }) {
  const { user } = useSelector((state) => state.auth);
  const { questionnaire } = useSelector((state) => state.user);

  const {
    disableForm,
    loading,
    canAdminComment,
    initialValues,
    handleSubmitPowerEconomic,
    adminComments,
    setAdminComments,
    loadingAddComments,
    handleAddAdminComment,
    isCommentNonNull,
    setIsCommentNonNull,
  } = useQuestionnaire(handleStageFourTabClick);

  return (
    <Formik
      initialValues={initialValues.economicPower}
      validationSchema={economicPowerValidationSchema}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
          setSubmitting,
          setErrors,
          validateForm,
        } = props;

        return (
          <Form className="flex flex-col divide-y divide-grey-200 !border-none">
            <div className="py-8 space-y-3">
              <h6 className="font-semibold text-black">
                Section 5: Economic Due Diligence
              </h6>
              <p className="text-sm max-w-[324px]">
                Please provide more Key Information and Supporting Documents for
                the Current or Proposed Project.
              </p>
            </div>
            <FormContainer width type="user" title="">
              <>
                <SectionHeader title="Business Plan" />
                <AdminComment
                  name="businessPlan"
                  adminComment={
                    adminComments?.economicPower?.businessPlan || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.businessPlan ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <FileUpload
                    name="businessPlan"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.EconomicDueDiligence
                        ? values.businessPlan
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.businessPlan && touched.businessPlan
                        ? getIn(errors, `businessPlan`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower?.businessPlan &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Project Economics and Financial Ratios*"
                />
                <AdminComment
                  name="projectEconomicsAndFinancialRatios"
                  adminComment={
                    adminComments?.economicPower
                      ?.projectEconomicsAndFinancialRatio || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower
                      ?.projectEconomicsAndFinancialRatios
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="projectEconomicsAndFinancialRatios"
                    id="projectEconomicsAndFinancialRatios"
                    value={values.projectEconomicsAndFinancialRatios}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.projectEconomicsAndFinancialRatios &&
                      touched.projectEconomicsAndFinancialRatios
                        ? errors.projectEconomicsAndFinancialRatios
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower
                        ?.projectEconomicsAndFinancialRatios &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader border title="Financial Ratios*" />
                <AdminComment
                  name="financialRatios"
                  adminComment={
                    adminComments?.economicPower?.financialRatios || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.financialRatios ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="financialRatios"
                    id="financialRatios"
                    value={values.financialRatios}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.financialRatios && touched.financialRatios
                        ? errors.financialRatios
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower?.financialRatios &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>

              <>
                <SectionHeader
                  border
                  title="Kindly provide a letter of creditworthiness from your bank, if available."
                />
                <AdminComment
                  name="guaranteesCreditworthiness"
                  adminComment={
                    adminComments?.economicPower?.guaranteesCreditworthines ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.guaranteesCreditworthiness
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <FileUpload
                    name="guaranteesCreditworthiness"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.EconomicDueDiligence
                        ? values.guaranteesCreditworthiness
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.guaranteesCreditworthiness &&
                      touched.guaranteesCreditworthiness
                        ? getIn(errors, `guaranteesCreditworthiness`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower
                        ?.guaranteesCreditworthiness &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Competitive Advantage (incl. product substitution)*"
                />
                <AdminComment
                  name="competitiveAdvantage"
                  adminComment={
                    adminComments?.economicPower?.competitiveAdvantage || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.competitiveAdvantage
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="competitiveAdvantage"
                    id="competitiveAdvantage"
                    value={values.competitiveAdvantage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.competitiveAdvantage &&
                      touched.competitiveAdvantage
                        ? errors.competitiveAdvantage
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower?.competitiveAdvantage &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="competitiveAdvantageDocument"
                  adminComment={
                    adminComments?.economicPower?.competitiveAdvantageDocumen ||
                    ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.competitiveAdvantageDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <FileUpload
                    name="competitiveAdvantageDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.EconomicDueDiligence
                        ? values.competitiveAdvantageDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower
                        ?.competitiveAdvantageDocument &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title={
                    <>
                      <span>Risk Assessments Study: Criteria*</span>
                      <br />
                      <ul className="ml-5 list-decimal">
                        <li>Demand Risk - Volume Stability &amp; Growth</li>
                        <li>Price Risks (degree of control over pricing)</li>
                        <li>Political / Policy Risks</li>
                      </ul>
                    </>
                  }
                />
                <AdminComment
                  name="riskAssessmentStudy"
                  adminComment={
                    adminComments?.economicPower?.riskAssessmentStudy || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.riskAssessmentStudy
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="riskAssessmentStudy"
                    id="riskAssessmentStudy"
                    value={values.riskAssessmentStudy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.riskAssessmentStudy && touched.riskAssessmentStudy
                        ? errors.riskAssessmentStudy
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower?.riskAssessmentStudy &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="riskAssessmentDocument"
                  adminComment={
                    adminComments?.economicPower?.riskAssessmentDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.riskAssessmentDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <FileUpload
                    name="riskAssessmentDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.EconomicDueDiligence
                        ? values.riskAssessmentDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower?.riskAssessmentDocument &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Market Viability: Target market, Competitive Advantage*"
                />

                <AdminComment
                  name="marketViability"
                  adminComment={
                    adminComments?.economicPower?.marketViability || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.marketViability ? true : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="marketViability"
                    id="marketViability"
                    value={values.marketViability}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.marketViability && touched.marketViability
                        ? errors.marketViability
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower?.marketViability &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>

                <AdminComment
                  name="marketViabilityDocument"
                  adminComment={
                    adminComments?.economicPower?.marketViabilityDocument || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.marketViabilityDocument
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <FileUpload
                    name="marketViabilityDocument"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.EconomicDueDiligence
                        ? values.marketViabilityDocument
                        : ""
                    }
                    onChange={(fieldName, file) => {
                      setFieldValue(fieldName, file);
                    }}
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower?.marketViabilityDocument &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Commitment by Investors (e.g., FDI)"
                />
                <AdminComment
                  name="commitmentByInvestors"
                  adminComment={
                    adminComments?.economicPower?.commitmentByInvestors || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.commitmentByInvestors
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <FileUpload
                    name="commitmentByInvestors"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.EconomicDueDiligence
                        ? values.commitmentByInvestors
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.commitmentByInvestors &&
                      touched.commitmentByInvestors
                        ? getIn(errors, `commitmentByInvestors`)
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower?.commitmentByInvestors &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader
                  border
                  title="Evidence of Federal / State Government Support "
                />
                <AdminComment
                  name="evidenceOfFederalOrStateGovernmentSupport"
                  adminComment={
                    adminComments?.economicPower
                      ?.evidenceOfFederalOrStateGovernmentSupport || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower
                      ?.evidenceOfFederalOrStateGovernmentSupport
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <FileUpload
                    name="evidenceOfFederalOrStateGovernmentSupport"
                    fileType=".pdf, .xls, .xlsx"
                    format="PDF or Excel format"
                    value={
                      questionnaire?.EconomicDueDiligence
                        ? values.evidenceOfFederalOrStateGovernmentSupport
                        : ""
                    }
                    onBlur={handleBlur}
                    onChange={(fieldName, file) => {
                      setFieldTouched(fieldName, true);
                      setFieldValue(fieldName, file);
                    }}
                    required
                    errors={
                      errors.evidenceOfFederalOrStateGovernmentSupport &&
                      touched.evidenceOfFederalOrStateGovernmentSupport
                        ? getIn(
                            errors,
                            `evidenceOfFederalOrStateGovernmentSupport`
                          )
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower
                        ?.evidenceOfFederalOrStateGovernmentSupport &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
              <>
                <SectionHeader border title="Involvement of any NGO*" />
                <AdminComment
                  name="involvementOfNGO"
                  adminComment={
                    adminComments?.economicPower?.involvementOfNGO || ""
                  }
                  isAdminCommentAvailable={
                    adminComments?.economicPower?.involvementOfNGO
                      ? true
                      : false
                  }
                  canAdminComment={canAdminComment}
                  allComments={adminComments}
                  setAdminComments={setAdminComments}
                  setIsCommentNonNull={setIsCommentNonNull}
                  type="economicPower"
                >
                  <Textarea
                    placeholder="Enter description here"
                    name="involvementOfNGO"
                    id="involvementOfNGO"
                    value={values.involvementOfNGO}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={
                      errors.involvementOfNGO && touched.involvementOfNGO
                        ? errors.involvementOfNGO
                        : ""
                    }
                    disabled={
                      disableForm ||
                      (!adminComments?.economicPower?.involvementOfNGO &&
                        questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK)
                    }
                  />
                </AdminComment>
              </>
            </FormContainer>

            {(user?.role === USER_TYPE.USER ||
              user?.role === USER_TYPE.PROXY) &&
            (!questionnaire ||
              questionnaire?.status === STAGE_FOUR_STATUS.PENDING ||
              questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK) ? (
              <div className="flex justify-between w-full pt-16">
                <button
                  onClick={() => handleStageFourTabClick(3)}
                  className="text-primary-1 uppercase text-sm font-bold leading-[16.8px]"
                >
                  Previous
                </button>
                <div className="flex justify-end gap-6 ">
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      setErrors({});
                      handleSubmitPowerEconomic(values, "save", setSubmitting);
                      const res = validateForm();
                      setErrors({ ...res });
                    }}
                    loading={isSubmitting}
                    className="bg-white border-2 border-primary-1 disabled:!border-grey-10 !text-primary-1 disabled:!text-grey-400"
                  >
                    Save progress
                  </Button>
                  <Button
                    onClick={async () => {
                      setSubmitting(false);
                      const res = await validateForm();
                      setErrors({ ...res });

                      if (Object.keys(res).length !== 0)
                        toast.error("Please fill all the required fields");
                      else
                        handleSubmitPowerEconomic(
                          values,
                          "next",
                          setSubmitting
                        );
                    }}
                    disabled={!isValid || loading}
                    className="py-4 px-6 self-end"
                    loading={loading}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end gap-6 w-full pt-16">
                <div className="flex justify-end gap-6">
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(3)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    {Icons.ic_arrow_left} Previous
                  </Button>
                  <Button
                    disabled={isCommentNonNull}
                    onClick={() => handleStageFourTabClick(5)}
                    className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal bg-transparent border border-grey-200 !text-grey-700 disabled:opacity-50"
                  >
                    Next {Icons.ic_arrow_right}
                  </Button>
                </div>
                {(user?.role === USER_TYPE.ADMIN ||
                  user?.role === USER_TYPE.SUPER_ADMIN) &&
                  (questionnaire?.status === STAGE_FOUR_STATUS.FEEDBACK ||
                    questionnaire?.status === STAGE_FOUR_STATUS.AWAITING) && (
                    <Button
                      disabled={loadingAddComments || !isCommentNonNull}
                      onClick={() => {
                        handleAddAdminComment(
                          adminComments?.economicPower,
                          "adminUpdateEconomic"
                        );
                      }}
                      loading={loadingAddComments}
                      className="flex items-center gap-2 py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal disabled:opacity-50"
                    >
                      Save Comment
                    </Button>
                  )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
