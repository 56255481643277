import React, { useState } from "react";
import { useSelector } from "react-redux";
import Icons from "../../assets/icons";
import { FileUploader } from "react-drag-drop-files";
import useUser from "../../hooks/useUser";
import Loader from "./loader";
import { bytesToMB, getFileType } from "../../utils/functions";
import { DOCUMENT_ICON_TYPE } from "../../utils/constants";

export default function DragAndDrop({ name, onChange, disabled }) {
  const user = useSelector((state) => state.auth.user);

  const [fileDetails, setFileDetails] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const { uploadFile } = useUser();

  const handleFileInputChange = async (file) => {
    setFileUploadLoading(true);
    setFileType(getFileType(file.name));
    const formData = new FormData();
    formData.append("doc", file);
    formData.append("folder", user?.superuser_id ?? user?._id);
    const { data } = await uploadFile(formData);

    if (data) {
      onChange(data.url);
      setFileDetails(file);
    }

    setFileUploadLoading(false);
  };
  return (
    <div
      className={`py-3 px-4 border-2 border-dashed border-grey-300 rounded-lg min-w-[auto] min-h-[104px] flex flex-col items-center justify-center
    ${disabled && "opacity-50"}`}
    >
      {fileUploadLoading ? (
        <Loader />
      ) : fileDetails ? (
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-4">
            <img
              src={
                fileType === DOCUMENT_ICON_TYPE.DOC ||
                fileType === DOCUMENT_ICON_TYPE.DOCX ||
                fileType === DOCUMENT_ICON_TYPE.DOTX
                  ? Icons.ic_doc
                  : Icons.ic_pdf
              }
              alt=""
            />
            <div className="w-full leading-[23.2px] text-left">
              <p className="text-grey-800 font-medium">
                {fileDetails.name || "Completed NDA Form"}
              </p>
              <p className="divide-x text-sm text-grey-400">
                <span className="font-medium text-grey-500">pdf.5mb</span>{" "}
                <span className="pl-1"> {bytesToMB(fileDetails?.size)}MB</span>
              </p>
            </div>
          </div>
          <button
            onClick={() => {
              setFileDetails(null);
              onChange(name);
            }}
          >
            {Icons.ic_delete}
          </button>
        </div>
      ) : (
        <FileUploader
          multiple={false}
          handleChange={handleFileInputChange}
          name={name}
          types={["pdf", "docx", "doc", "dotx"]}
          maxSize={5}
          disabled={disabled}
          classes="!p-0 !border-none !min-w-[auto] !h-auto !flex !flex-col !items-center !justify-center w-full"
        >
          <img src={Icons.ic_file_upload} alt="" />
          <p className="pt-3 pb-1">
            <span className="text-primary-1 font-semibold">
              Click to upload{" "}
            </span>
            or drag and drop
          </p>
          <p className="text-sm text-grey-400">PDF (max. 5MB)</p>
        </FileUploader>
      )}
    </div>
  );
}
