import { Form, Formik } from "formik";

import Button from "../../../ui/button";
import Input from "../../../ui/input";
import Icons from "../../../../assets/icons";
import { addTeamValidation } from "../../../../utils/validation";

export default function AddTeamForm({
  toggleAddTeamModal,
  setEmail,
  toggleInviteSentDialog,
  createTeamMember,
}) {
  return (
    <Formik
      initialValues={{
        fullName: "",
        email: "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const user = await createTeamMember(values);
        if (user) {
          setEmail(values.email);
          toggleInviteSentDialog(true);
          toggleAddTeamModal(false);
        }
        setSubmitting(false);
      }}
      validationSchema={addTeamValidation}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isValid,
          dirty,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;

        return (
          <Form className="flex flex-col gap-12">
            <div className="flex flex-col gap-6">
              <Input
                title="FULL NAME"
                icon={Icons.ic_user}
                placeholder="First and last name"
                type="text"
                name="fullName"
                id="fullName"
                value={values.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={
                  errors.fullName && touched.fullName ? errors.fullName : ""
                }
              />
              <Input
                title="EMAIL"
                icon={Icons.ic_email}
                placeholder="Eample@email.com"
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.email && touched.email ? errors.email : ""}
              />
            </div>
            <Button
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              className="py-4 px-6"
              fullWidth
              loading={isSubmitting}
            >
              Invite Member
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
