import { useState } from "react";
import api from "../utils/api";
import { login, logout } from "../config/reducers/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setIsPasswordStep } from "../config/reducers/loginFormSlice";

export default function useAuthentication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reqError, setReqError] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);

  const toggleHiddenPassword = () => setIsPasswordHidden(!isPasswordHidden);
  const toggleHiddenConfirmPassword = () =>
    setIsConfirmPasswordHidden(!isConfirmPasswordHidden);

  const isLengthValid = (password) => {
    const isLengthValid = password.length >= 8;
    return isLengthValid;
  };

  const isCaseValid = (password) => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;

    const hasUppercase = uppercaseRegex.test(password);
    const hasLowercase = lowercaseRegex.test(password);

    return hasUppercase && hasLowercase;
  };

  const isCharacterValid = (password) => {
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[@$!#%*?_&]/;

    const hasNumber = numberRegex.test(password);
    const hasSpecialChar = specialCharRegex.test(password);

    return hasNumber && hasSpecialChar;
  };

  function handleError(error, showToast, message, redirectRoute) {
    console.log(error?.response?.data?.message);

    if (error?.response?.status === 400) {
      if (showToast) {
        if (Array.isArray(error?.response?.data?.message)) {
          toast.error(error?.response?.data?.message[0]);
        } else {
          if (error?.response?.data?.message === "Wrong credentials provided") {
            toast.error(
              "Incorrect login details. Please check your details and try again"
            );
          } else {
            toast.error(message);
          }
        }
      }
    }

    if (error?.response?.status === 406) {
      toast.error("Not allowed");
    }

    if (error?.response?.status === 401) {
      if (error?.response?.data?.message === "email not confirmed") {
        navigate(redirectRoute);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  const checkIfUserEmailExists = async (email) => {
    try {
      const res = await api.get(`/auth/checkEmailRegistered/${email}`);

      if (res.data.emailRegistered) {
        toast.error("Email Already Registered");
        return false;
      } else {
        return true;
      }
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);
      return false;
    }
  };

  const createUserAccount = async (values) => {
    try {
      await api.post("/auth/register", values);

      dispatch(setIsPasswordStep(false));
      navigate(`/confirmation/${values.email}`);
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);
    }
  };

  const loginUser = async (values) => {
    try {
      const res = await api.post("/auth/login", values);

      if (res.data.email) {
        dispatch(
          login({
            refreshToken: res.data.currentHashedRefreshToken,
            user: res.data,
            type: "user",
          })
        );
        navigate("/due-diligence", { replace: true });
      }
    } catch (error) {
      setReqError(true);

      handleError(
        error,
        true,
        error?.response?.data?.message,
        `/confirmation/${values.email}`
      );
    }
  };

  const loginAdmin = async (values) => {
    try {
      const res = await api.post("/auth/admin/login", values);

      if (res.data.email) {
        dispatch(
          login({
            refreshToken: res.data.currentHashedRefreshToken,
            user: res.data,
            type: "admin",
          })
        );
        navigate("/admin/due-diligence", { replace: true });
      }
    } catch (error) {
      setReqError(true);

      handleError(
        error,
        true,
        error?.response?.data?.message,
        `/admin/confirmation/${values.email}`
      );
    }
  };

  const verifyUserEmail = (token) => {
    setIsLoading(true);
    api
      .get(`/auth/verify/${token}`)
      .then((res) => {})
      .catch((error) => {
        handleError(error, true, error?.response?.data?.message);
        setError(error?.response?.data?.message);
      })
      .finally(() => setIsLoading(false));
  };

  const resendConfirmationLink = (email) => {
    setIsLoading(true);
    api
      .get(`/auth/resendToken/${email}`)
      .then((res) => {
        toast.success("New confirmation link has been sent!");
      })
      .catch((error) => {
        handleError(error, true, error?.response?.data?.message);
      })
      .finally(() => setIsLoading(false));
  };

  const forgotPassword = async (values, type) => {
    try {
      const res = await api.post("/auth/forgotPassword", values);
      if (res.data) {
        navigate(
          type === "admin"
            ? `/admin/confirmation/${values.email}`
            : `/confirmation/${values.email}`
        );
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        toast.error(
          "No account was found with that email. Double-check and try again."
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const resetPassword = async (values, type) => {
    try {
      await api.post("/auth/resetPassword", values);

      navigate(
        type === "admin"
          ? `/admin/reset-password-successful`
          : `/reset-password-successful`,
        { state: { success: true } }
      );
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);
    }
  };

  const logUserout = async (type) => {
    try {
      await api.post("/auth/logout");

      dispatch(logout({}));
      navigate(type === "admin" ? "/admin/login" : "/login");
    } catch (error) {
      handleError(error, true, error?.response?.data?.message);

      dispatch(logout({}));
      navigate(type === "admin" ? "/admin/login" : "/login");
    }
  };

  return {
    isLoading,
    reqError,
    error,
    isPasswordHidden,
    isConfirmPasswordHidden,
    toggleHiddenPassword,
    toggleHiddenConfirmPassword,
    isLengthValid,
    isCaseValid,
    isCharacterValid,
    loginUser,
    loginAdmin,
    checkIfUserEmailExists,
    createUserAccount,
    verifyUserEmail,
    resendConfirmationLink,
    forgotPassword,
    resetPassword,
    logUserout,
  };
}
