import { format } from "date-fns";
import { USER_TYPE, tableHeadItems } from "../../../utils/constants";
import MoreButton from "./dropDown";
import RemoveButton from "./removeButton";

const TableRowMobileCell = ({
  title,
  content,
  className,
  contentClassName,
  titleClassName,
}) => (
  <div
    className={`flex justify-between items-center px-6 py-3 ${className ?? ""}`}
  >
    <p className={`text-xs text-grey-500 ${titleClassName ?? ""}`}>{title}</p>
    <p className={`text-sm text-grey-900 ${contentClassName ?? ""}`}>
      {content}
    </p>
  </div>
);

const TableRowMobile = ({
  items,
  handleDeleteUser,
  resendInvite,
  resendInviteLoading,
}) => {
  return (
    <div className="divide-y divide-grey-200 border border-grey-200 rounded-lg bg-white">
      <TableRowMobileCell
        title={items?.fullName}
        content={
          !items?.isActive ? (
            <MoreButton
              removeUser={() => handleDeleteUser(items?._id)}
              resendInvite={() => resendInvite(items?.email)}
              resendInviteLoading={resendInviteLoading}
            />
          ) : items?.role !== USER_TYPE.SUPER_ADMIN ? (
            <RemoveButton onClick={() => handleDeleteUser(items?._id)} />
          ) : (
            ""
          )
        }
        titleClassName="!text-sm text-grey-900 font-medium"
      />
      <TableRowMobileCell title={tableHeadItems[1]} content={items?.email} />
      <TableRowMobileCell
        title={tableHeadItems[2]}
        content={items?.role === USER_TYPE.PROXY ? "User" : "Admin"}
      />
      <TableRowMobileCell
        title={tableHeadItems[3]}
        content={
          items?.createdAt && format(new Date(items?.createdAt), "LLL dd, yyyy")
        }
      />
      <TableRowMobileCell
        title={tableHeadItems[4]}
        content={items?.isActive ? "Active" : "Pending"}
        contentClassName={items?.isActive ? "text-primary-1" : "!text-grey-400"}
      />
    </div>
  );
};

export default TableRowMobile;
