import React from "react";

import AuthLayout from "../../../components/Layout/authLayout";
import ResetPasswordSuccess from "../../../components/AuthForm/resetPasswordSuccess";

const AdminResetPasswordSuccessPage = () => {
  return (
    <AuthLayout page="confirmation" type="admin">
      <ResetPasswordSuccess type="admin" />
    </AuthLayout>
  );
};

export default AdminResetPasswordSuccessPage;
