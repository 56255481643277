import React from "react";
import Icons from "../../assets/icons";

export default function Loader({ fullpage, position }) {
  return fullpage ? (
    <div
      className={`fixed z-[999999] h-screen w-full flex justify-center items-center ${
        position ? position : "top-0 left-0"
      }`}
    >
      {Icons.ic_loading}
    </div>
  ) : (
    <div className="flex justify-center items-center">{Icons.ic_loading}</div>
  );
}
