import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsPasswordStep } from "../../config/reducers/loginFormSlice";
import { loginValidation } from "../../utils/validation";
import useAuthentication from "../../hooks/useAuthentication";
import Input from "../ui/input";
import Icons from "../../assets/icons";
import Button from "../ui/button";

export default function LoginForm({ type }) {
  const dispatch = useDispatch();
  const {
    reqError,
    isPasswordHidden,
    toggleHiddenPassword,
    loginUser,
    loginAdmin,
  } = useAuthentication();

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          type === "user" ? await loginUser(values) : await loginAdmin(values);
          setSubmitting(false);
        }}
        validationSchema={loginValidation}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isValid,
            dirty,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props;

          return (
            <Form className="flex flex-col gap-16">
              <div className="flex flex-col gap-6 max-h-[336px] md:max-h-[374px] overflow-auto scrollbar ">
                <Input
                  title="EMAIL"
                  icon={Icons.ic_email}
                  placeholder="Eample@email.com"
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.email && touched.email ? errors.email : ""}
                  reqError={reqError}
                />
                <Input
                  title="PASSWORD"
                  icon={
                    isPasswordHidden ? Icons.ic_eye_closed : Icons.ic_eye_open
                  }
                  placeholder="Enter password"
                  type={isPasswordHidden ? "password" : "text"}
                  name="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.password && touched.password ? errors.password : ""
                  }
                  reqError={reqError}
                  toggleHiddenPassword={toggleHiddenPassword}
                />
                <div className="text-right">
                  <Link
                    to={
                      type === "admin"
                        ? "/admin/forgot-password"
                        : "/forgot-password"
                    }
                    onClick={() => dispatch(setIsPasswordStep(true))}
                    className="link-underline"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <Button
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                className="py-4 px-6"
                fullWidth
                loading={isSubmitting}
              >
                Log in to account
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
