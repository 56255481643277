import React from "react";
import Button from "../../../ui/button";
import Loader from "../../../ui/loader";
import Badge from "../../../ui/badge";
import MeetingInfo from "./info";
import useMeeting from "./useMeeting";
import CardHeader from "../CardHeader";
import { USER_TYPE } from "../../../../utils/constants";

const ListItem = ({ title, content }) => (
  <li className="list-disc text-xs leading-[17.4px] ml-[10px]">
    <span className="font-medium text-black">
      {title} <br />
    </span>
    {content}
  </li>
);

export default function MeetingDetails({
  handleStageOneStep,
  type,
  adminSwitch,
}) {
  const {
    user,
    meetingDetails,
    userRequest,
    loading,
    loadingAccountDetails,
    getAccountDetails,
  } = useMeeting(handleStageOneStep, type);

  return (
    <div
      className={`flex flex-col md:flex-row gap-6 ${
        user?.role === USER_TYPE.USER || user?.role === USER_TYPE.PROXY
          ? "justify-center"
          : "w-full lg:w-fit"
      } `}
    >
      <div
        className={`border border-grey-200 rounded-lg w-full bg-white p-6  ${
          user?.role === USER_TYPE.USER || user?.role === USER_TYPE.PROXY
            ? "md:max-w-[432px]"
            : "lg:max-w-[346px] lg:w-[346px]"
        }`}
      >
        <CardHeader
          title={
            user?.role !== USER_TYPE.USER && user?.role !== USER_TYPE.PROXY
              ? "Meeting info"
              : meetingDetails?.status !== "awaiting"
              ? "Your meeting has been held"
              : "Your meeting has been scheduled"
          }
          badge={
            user?.role !== USER_TYPE.USER &&
            user?.role !== USER_TYPE.PROXY &&
            meetingDetails && <Badge status={meetingDetails?.status} />
          }
        />
        {loading ? (
          <Loader />
        ) : (
          <MeetingInfo
            details={meetingDetails}
            handleStageOneStep={handleStageOneStep}
            user={user}
          />
        )}
        {meetingDetails && adminSwitch && adminSwitch}
      </div>
      {type === "user" && (
        <div className="border border-grey-200 rounded-lg w-full md:max-w-[376px] bg-white p-6 space-y-8">
          <div>
            <CardHeader title="Purpose of the Meeting" />
            <ul className="space-y-3 pt-3">
              <ListItem
                title="Understanding GACN:"
                content="Gain insight into the Gas Aggregator Company Nigeria Ltd/GTE (GACN), its services, and offerings."
              />
              <ListItem
                title="Project Discussion:"
                content="Delve into the details of your gas project and identify potential collaboration areas with GACN."
              />
              <ListItem
                title="Due Diligence Process:"
                content="Learn about the various stages of the Due Diligence process and what to expect."
              />
            </ul>
          </div>
          <div>
            <CardHeader title="Meeting Requirements:" />
            <ul className="space-y-3 pt-4">
              <ListItem
                title="Gas Project Presentation:"
                content="Prepare to present your Gas Project during the meeting. Be ready to discuss the specifics of your project and how GACN can contribute."
              />
            </ul>
            <div className="flex justify-end mt-6">
              {userRequest?.status === "accepted" ? (
                <Button
                  onClick={getAccountDetails}
                  className="py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal "
                >
                  {loadingAccountDetails ? <Loader /> : "Proceed to payment"}
                </Button>
              ) : (
                <Button
                  onClick={() => handleStageOneStep(1)}
                  className="py-2 px-3 h-fit w-fit capitalize text-sm tracking-normal "
                >
                  View response
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
