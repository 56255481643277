import React, { useState } from "react";
import Button from "../../../../ui/button";
import Dialog from "../../../../ui/dialog";
import useUser from "../../../../../hooks/useUser";

export default function MarkMeetingAsHeldDialog({
  toggleIsMeetingHeldDialog,
  isMeetingHeldDialogOpen,
  companyId,
  setIsMeetingHeld,
}) {
  const { markMeetingAsHeld } = useUser();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isDialogModalOpen={isMeetingHeldDialogOpen}>
      <div className="bg-white rounded-lg w-full max-w-[400px] p-6 space-y-8">
        <div className="space-y-4 text-center text-sm">
          <h2 className="text-h2 text-black">Mark Meeting As Held</h2>
          <p>Are you sure you want to mark this meeting as held?</p>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={() => toggleIsMeetingHeldDialog(false)}
            className="bg-transparent border border-grey-200 !text-black"
          >
            cancel
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              const response = await markMeetingAsHeld(companyId);
              if (response) {
                setIsMeetingHeld(true);
                toggleIsMeetingHeldDialog(false);
              }
              setLoading(false);
            }}
            disabled={loading}
            loading={loading}
          >
            Yes
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
