import React from "react";
import Header from "../Header";
import globalRouter from "../../router/globalRouter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SessionTimeoutDialog from "../AuthForm/sessionTimeOutModal";
import NoInternetDialog from "../AuthForm/noInternet";

export default function DashboardLayout({
  title,
  description,
  children,
  type,
}) {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  const { displaySessionTimeoutDialog, displayNoInternet } = useSelector(
    (state) => state.auth
  );

  return (
    <>
      <SessionTimeoutDialog
        isSessionTimeoutDialogOpen={displaySessionTimeoutDialog}
      />
      <NoInternetDialog isNoInternetDialogOpen={displayNoInternet} />

      <Header type={type} />
      <div
        className={`w-full md:w-[calc(100%-272px)] md:ml-[272px] mt-[72px] md:mt-0 px-4 md:px-8 py-10 md:py-14`}
      >
        {/* if no user data then show something went wrong here */}
        <div className="space-y-2 pb-8">
          <h2 className="text-h2 text-black">{title}</h2>
          <p>{description}</p>
        </div>
        <div className="space-y-6"> {children}</div>
      </div>
    </>
  );
}
