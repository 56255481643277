import Icons from "../../assets/icons";

export const links = [
  { name: "Due Diligence", icon: Icons.ic_scan, link: "/due-diligence" },
  {
    name: "Account Settings",
    icon: Icons.ic_settings,
    link: "/account-settings",
  },
];
