import React from "react";
import SiteVisitDetails from "./siteVisitDetails";
import Loader from "../../../../ui/loader";
import { useSelector } from "react-redux";
import SiteVisitStatus from "./status";
import useSiteVisit from "./useSiteVisit";

export default function StageFive() {
  const { loading } = useSiteVisit();

  const { siteVisit } = useSelector((state) => state.user);

  return loading ? (
    <Loader />
  ) : siteVisit ? (
    <SiteVisitDetails />
  ) : (
    <SiteVisitStatus />
  );
}
