import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  proxyUsers: [],
  totalTeam: 0,
  currentTeamPage: 1,
  searchUserText: "",
  loadingProxyUser: true,

  loading: false,
  error: "",
  userStep: 1,
  currentStep: 1,
  userRequest: null,
  meetingDetails: null,
  bacfPrice: null,
  bacfHistory: null,
  questionnaire: null,
  project_type: "",

  siteVisit: null,
  siteVisitQuestionnaire: null,
  finalize: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProxyUsers(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.proxyUsers
      };
    },

    setSearchUserText(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.searchUserText
      };
    },

    setError(state, action) {
      return { ...state, ...action.payload };
    },

    setCurrentStep(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.currentStep
      };
    },
    setUserRequest(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.userRequest
      };
    },

    setMeetingDetails(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.meetingDetails
      };
    },

    setBacfHistory(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.bacfHistory
      };
    },

    setBacfPrice(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.bacfPrice
      };
    },

    setQuestionnaire(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.questionnaire
      };
    },

    setSiteVisit(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.siteVisit
      };
    },
    setSiteVisitQuestionnaire(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.siteVisitQuestionnaire
      };
    },

    setFinalize(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.finalize
      };
    },
    setCurrentTeamPage(state, action) {
      return {
        ...state,
        ...action.payload, // action.payload.currentTeamPage
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProxyUsers,
  setSearchUserText,
  setError,
  setCurrentStep,
  setUserRequest,
  setMeetingDetails,
  setBacfPrice,
  setBacfHistory,
  setQuestionnaire,
  setSiteVisit,
  setSiteVisitQuestionnaire,
  setFinalize,
  setCurrentTeamPage,
} = userSlice.actions;
export default userSlice.reducer;
