import React from "react";
import InnerPageLayout from "../../../../../components/Layout/innerPageLayout";
import useAdmin from "../../../../../hooks/useAdmin";
import Loader from "../../../../../components/ui/loader";
import StageSeven from "../../../../../components/DueDiligence/Admin/stages/stageSeven";

export default function StageSevenPage() {
  const { loading, id } = useAdmin();

  return (
    <InnerPageLayout
      type="admin"
      title="Finalize"
      back={`/admin/company-info/${id}`}
    >
      {loading ? (
        <Loader />
      ) : (
          <StageSeven />
        
      )}
    </InnerPageLayout>
  );
}
