import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refreshToken: "",
  isAuthenticated: false,
  user: null,
  isInitialized: false,
  authorized: false,
  loading: false,
  error: "",
  displaySessionTimeoutDialog: false,
  displayNoInternet: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialize(state, action) {
      return { ...state, ...action.payload, isInitialized: true };
    },

    login(state, action) {
      localStorage.setItem("refresh-token", action.payload.refreshToken);
      localStorage.setItem("user_type", action.payload.type);

      return {
        ...state,
        ...action.payload,
        authroized: true,
        isInitialized: true,
        isAuthenticated: true,
      };
    },
    logout(state, action) {
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        refreshToken: "",
        isInitialized: false,
      };
    },

    error(state, action) {
      // Handle error action
    },
    setUser(state, action) {
      // Handle set user action
      return {
        ...state,
        ...action.payload, // action.payload.user
      };
    },
    setDisplaySessionTimeoutDialog: (state, action) => {
      state.displaySessionTimeoutDialog = action.payload;
    },
    setDisplayNoInternetDialog: (state, action) => {
      state.displayNoInternet = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initialize,
  login,
  logout,
  error,
  setUser,
  setDisplaySessionTimeoutDialog,
  setDisplayNoInternetDialog,
} = authSlice.actions;
export default authSlice.reducer;
