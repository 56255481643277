import React from "react";
import { contactInformation } from "../../utils/constants";

export const SalesEmail = ({ className }) => {
  return (
    <a
      className={className}
      href={`mailto:${contactInformation.salesEmail}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {contactInformation.salesEmail}
    </a>
  );
};

export const AdminEmail = ({ className }) => {
  return (
    <a
      className={className}
      href={`mailto:${contactInformation.adminEmail}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {contactInformation.adminEmail}
    </a>
  );
};

export const PaymentEmail = ({ className }) => {
  return (
    <a
      className={className}
      href={`mailto:${contactInformation.paymentEmail}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {contactInformation.paymentEmail}
    </a>
  );
};

export const Phone = ({ className }) => {
  return (
    <a
      className={className}
      href={`tel:${contactInformation.phone}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {contactInformation.phone}
    </a>
  );
};
