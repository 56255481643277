import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { initialize } from "../../config/reducers/authSlice";
import Loader from "../ui/loader";

function AuthSessionProvider({ children }) {
  const { isAuthenticated, isInitialized, user } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const token = localStorage.getItem("refresh-token");

  useEffect(() => {
    if (!token) dispatch(initialize({ isAuthenticated: false, user: null }));

    if (token) {
      dispatch(initialize({ isAuthenticated: true, refreshToken: token }));
    }
  }, [dispatch, token]);

  if (!isInitialized) {
    return <Loader fullpage />;
  }

  if (isAuthenticated && token) {
    return <Navigate to={"/due-diligence"} replace />;
  }

  if (!isAuthenticated && !user && !!token) {
    return <Navigate to={"/login"} />;
  }

  return <>{children}</>;
}

export default AuthSessionProvider;
