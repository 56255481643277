import React from "react";
import SiteVisitSuccess from "./siteVisitSuccess";
import SiteVisitDetails from "./siteVisitDetails";
import SiteVisitForm from "./siteVisitForm";
import Loader from "../../../../ui/loader";
import useSiteVisit from "./useSiteVisit";

export default function StageFive() {
  const { loading, stageFourStep, handleStageFourStep } = useSiteVisit();

  return loading ? (
    <Loader />
  ) : (
    <div>
      {stageFourStep === 1 && (
        <SiteVisitForm handleStageFourStep={handleStageFourStep} />
      )}
      {stageFourStep === 2 && <SiteVisitDetails />}
      {stageFourStep === 3 && <SiteVisitSuccess />}
    </div>
  );
}
