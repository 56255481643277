import React from "react";

export default function FormContainer({
  title,
  children,
  type,
  width,
  nowhitespace,
}) {
  return (
    <div className="flex flex-col lg:flex-row justify-between gap-10 py-8">
      <h6
        className={`font-semibold text-black
          ${width && "sm:min-w-[200px] lg:min-w-[300px] lg:w-[300px]"}
          ${nowhitespace && "sm:whitespace-nowrap"}
        `}
      >
        {title}
      </h6>
      <div
        className={`flex flex-col gap-8 w-full 
        ${type === "user" ? " w-full md:min-w-[370px] lg:min-w-[412px]" : ""}
        `}
      >
        {children}
      </div>
    </div>
  );
}
